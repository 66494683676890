import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import TableDashboard from './TableDashboard.jsx';
import {
  Button,
  Modal,
  Row,
  Col,
  Form,
} from "react-bootstrap";

import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./interDash.css";
import { SlotUrgent } from '../../../components/collegeComponents/ReminderComponent/TableReminders.jsx';
import { SlotDescription } from '../../../components/collegeComponents/ReminderComponent/TableReminders.jsx';
import { SlotUsers } from '../../../components/collegeComponents/ReminderComponent/TableReminders.jsx';
import { SlotActions } from '../../../components/collegeComponents/TableColleges.jsx';
import { SlotDateCreated } from '../RemindersComponents/TableReminders.jsx';
import { SlotName } from '../../../components/ApplicationSectionComponent/TableApplication';
import { SlotProgram } from '../SlotContacts.jsx';
import * as Ioicons from "react-icons/io";
import * as FIIcons from "react-icons/fi";
import moment from 'moment';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { constaApi } from '../../../constants/constants';
import { Checkbox } from '../../../components/collegeComponents/AddOrEditCollege';
import NotificationAlert from "react-notification-alert";
import { SlotTimeCreation, TableTickets } from '../TicketsSection/TableTickets.jsx';

import { loadLocalColleges } from '../../../helpers/collegesHelpers/loadColleges';
 import { PDFViewer,PDFDownloadLink } from '@react-pdf/renderer';
 import Layout from '../../../components/PDFComponents/InterDashBoard/Layout.jsx';


export default function InterDashboard(props) {


  // Props of Tickets
  const [ticketsOpen, setTicketsOpen] = useState([]);

  const [loading , setLoading] = useState(true);

  const inputDownloadRef = useRef(null);


  const {
    register: student,
    handleSubmit,
    errors,
    formState,
    reset: reset,
  } = useForm({});
  const notificationAlert = useRef();
  const [modalStory, setModalStory] = useState(false);
  const { remindersC: reminders } = useSelector(state => state.remindersC);
  const [show, setShow] = useState(false);
  const [row, setRow] = useState();
  const [allUsers, setAllU] = useState([]);
  const [blocked, setBlocked] = useState(false);
  const [fullName, setFullName] = useState(`${props.contact.name} ${props.contact.father_lastname} ${props.contact.mother_lastname ?? " "}`);
  const [actualGrade, setActualGrade] = useState();
  let userActive = JSON.parse(localStorage.getItem("user"));
  const [agregated, setAgregated] = useState(
    {
      id: 'Vincular', value: 'Vincular', isChecked: props.contact.agregated == "1" ? true : false,
      label: props.contact.agregated == "1" ? 'Desvincular' : 'Vincular'
    },
  );
  const [storyContact, setStoryContact] = useState();

  const [localColleges, setLocalColleges] = useState([]);

  const [dataToExport, setDataToExport ] = useState();


  const [frameworkComponents, setFramwrokw] = useState({
    slotUrgent: SlotUrgent,
    slotDate: SlotDate,
    slotDescription: SlotDescription,
    slotUsers: SlotUsers,
    slotActions: SlotActions,
    slotCreated: SlotDateCreated,
    slotName: SlotName,
    slotProgram: SlotProgram,
    slotTimeCreation:SlotTimeCreation

  });


  useEffect(() => {
    Promise.all([loadLocalColleges()])
            .then(function (result) {
                if (result) {
                    setLocalColleges(result[0]);
                }
            })

    getTickets();
    let hoy = moment().locale("es-mx").format("MM");
    let year = moment().locale("es-mx").format("YYYY");
    let result = 0;
    if (props.contact.cicly) {
      let sub = props.contact.cicly.split('-');
      if (props.contact.grade) {
        let grad = props.contact.grade.split(" ");
        if (parseInt(hoy) >= 0 && parseInt(hoy) <= 7) {
          result = (parseInt(grad[1]) + (parseInt(year) - parseInt(sub[1])));
          setActualGrade(result);
        } else {
          result = (parseInt(grad[1]) + (parseInt(year) - parseInt(sub[0])));
          setActualGrade(result);
        }
      }
    }


  }, [])
  function openModalStory(story) {
    setStoryContact(story)
    setModalStory(true);
  }
  function onSubmit() {
    let obj = {
      id: props.contact.id,
      story: storyContact
    }
    axios.post(constaApi + "updateStory", obj)
      .then(function (response) {
        props.updateRoute();
      });
  }

  const updateRouteNextLevel = () => {
    props.updateRoute();
  }


  // GetTickets

  const getTickets = () => {
    let idContact = props.contact.id;

    axios.get(constaApi + 'getTickets/module/resume/' + idContact)
      .then(function (response) {
        setTicketsOpen(response.data.open);
      }).finally(function (response) {
      });
  }

  function changeStory(e) {
    setStoryContact(e.target.value);
  }
  function method(obj) {
    setRow(obj);
    setShow(!show);
  }
  function setFlag() {
    setShow(!show);
  }
  function getChild(obj) {
    setAllU(obj);
  }
  function returnTypeRef(obj) {
    switch (obj.type_ref) {
      case "0":
        return 'Papá';
        break;
      case "1":
        return 'Mamá';
        break;
      case "2":
        return 'Hermano/Hermana';
        break;
      case "3":
        return obj.name_ref ?? 'sin asignar';
    }
  }
  const showDate = (dateBD) => {
    moment.locale('en-US')
    let datef = moment(dateBD).locale('en-US').format("MMMM D, YYYY ");
    datef = datef[0].toUpperCase() + datef.slice(1);
    datef = datef.replace(".", "");
    let tag = <span className="montse" >{datef}</span>
    return tag;
  }
  const ShowAge = (birthday) => {
    moment.locale('es-US')
    // How old are you?
    let agee = Math.floor((new Date() - new Date(birthday)) / 1000 / 60 / 60 / 24 / 365.25)
    return agee + " years";
  }
  const notification = (type, message) => {
    let place = "tc";
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    }
    notificationAlert.current.notificationAlert(options);
  }
  const changeAgregated = async () => {
    let datax = {
      id: props.contact.id,
      agregated: !agregated.isChecked
    };

    await axios.post(constaApi + 'contact/update', datax)
      .then(function (response) {
        if (datax.agregated == 1) {
          notification('success', 'Contacto vinculado correctamente');
        } else {
          notification('info', 'Contacto desvinculado correctamente');
        }
        agregated.isChecked = !agregated.isChecked;
        setAgregated({ ...agregated });
      });

  }

  const findCorrectCollege = (contact,id) => {
    if(id == 0 || id == null){
      return contact.other_School ?? ''
    }
    else{

      const correctCollege = localColleges.find((college) => college.id == contact.id_college_vinculated);
      if(correctCollege){
          return correctCollege.name
      } else {
          return 'Cargando...';
      }

    }

}


const getInfoToexport = (id) =>{
  axios.post(constaApi + "getinfo/fromdashboard",{"id":id})
  .then(function (response) {
    const { data } = response;

    console.log('DATA',data);

    setDataToExport(data);
    setLoading(false);
    // console.log('data',data);
    // props.updateRoute();
  });


}
  return (
    <>
      <NotificationAlert ref={notificationAlert} />

      <div className="mt-n1 content">

        <div style={{ }} className='rowFlexbox'>

          <div className='columnFlexbox'>
            <span style={{fontSize:'18px'}}>Resumen</span>
            <div style={{ display: 'flex',fontSize:'14px',  flexDirection: 'column', padding: '10px', border: '1px solid #babfc7', backgroundColor: 'white' }}>

              <strong style={{fontSize:'20px'}} >{fullName}</strong>
              <span><strong>DOB:</strong> {props.contact.birthday && showDate(props.contact.birthday)}</span>
              {
                props.contact.birthday &&
                <span><strong>Age:</strong> {ShowAge(props.contact.birthday)}</span>
              }
              <span><strong>City:</strong> {props.contact.city ?? ''}</span>
              <span><strong>Current Grade :</strong> {actualGrade}</span>
              <span><strong>School:  </strong>

              {
            findCorrectCollege (props.contact,props.contact.id_college_vinculated)

              }

               </span>
              <span><strong>Gender:</strong> {props.contact.sex == "Sin especificar" ? props.contact.sex : (props.contact.sex == "Femenino" ? 'Female' : 'Male')}</span>
            </div>
             <button
          style={{textDecoration: 'white' , color:'white' }}
          className={loading ? "btn btn-sm btn-info" : "btn btn-sm btn-success"}
        >
          {
            loading ?
            <button
            onClick={(e) => getInfoToexport(props.contact.id)}
            >Exportar Archivo</button>

            :

            <PDFDownloadLink
            ref={inputDownloadRef}
            document={<Layout userToRender={props.contact} dataToExport={dataToExport} />} fileName={props.contact.name+".pdf"}>
            {({ blob, url, loading, error }) =>
              loading ? 'Creando documento...' : 'Descargar'
            }
          </PDFDownloadLink>

          }
        </button>
          </div>

          <div style={{ marginLeft: '1%' }} className='columnFlexbox'>
            <span style={{fontSize:'18px'}} >Referencias</span>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                flexDirection: 'column',
                border: '1px solid #babfc7',
                backgroundColor: 'white',
                minHeight:'160px'
              }}>
              {props.contact.contacts_references &&
                props.contact.contacts_references.map(ref => {
                  return (
                    <span style={{fontSize:'16px'}}><strong>
                      {returnTypeRef(ref)}:
                    </strong>  &nbsp;	 {ref.name} {ref.father_lastname ?? ''} {ref.mother_lastname ?? ''}</span>

                  )
                })}
            </div>


          </div>

          <div style={{ marginLeft: '1%' }} className='columnFlexbox'>
            <span style={{fontSize:'18px'}}>Familia</span>
            <div style={{ display: 'flex',minHeight:'200px', padding: '10px', flexDirection: 'column', border: '1px solid #babfc7', backgroundColor: 'white' }}>
              {props.contact.family &&
                props.contact.family.map(ref => {
                  return (
                    <span style={{fontSize:'16px'}}>
                      <strong >
                        <span>{ref.type ?? ""}:</span>
                      </strong>
                      <span><strong>
                      </strong> &nbsp;	 {ref.fullname ?? ""}</span>
                    </span>

                  )
                })}
            </div>

          </div>

          <div style={{ marginLeft: '1%' }} className='columnFlexbox'>
            <span style={{fontSize:'18px'}}>Story</span>
            <div
              onClick={(e) => openModalStory(props.contact.story ?? '')}
              style={{
                padding: '10px',
                minHeight:'200px',
                flexDirection: 'column',
                backgroundColor: 'white',
                border: '1px solid #babfc7'
              }}>

              <div class="menu">
                <ul>
                  <li>
                  </li>
                  <li>
                    <a>
                      <FIIcons.FiEdit onClick={(e) => openModalStory(props.contact.story ?? '')}
                        size={18}
                        style={{
                          color: '#386CEF',
                          justifyContent: 'flex-end',
                          display: 'flex'
                        }} />
                    </a>

                  </li>
                </ul>
              </div>


              {props.contact.story &&
                <span style={{fontSize:'16px'}}>{props.contact.story != 'NULL' ? props.contact.story : ''}</span>
              }
            </div>
          </div>
          <div style={{ marginLeft: '1%' }} className='columnFlexbox'>
          <span>Source </span>


          <div style={{ display: 'flex',minHeight:'200px', padding: '10px', flexDirection: 'column', border: '1px solid #babfc7', backgroundColor: 'white' }}>

             {/* Aqui ira el Source data */}
               {props.contact.sources &&
                props.contact.sources.map(ref => {
                  return (
                    <span style={{fontSize:'16px'}}>
                      <strong>
                        <span>{ref.type_source ?? ""}:</span>
                      </strong>
                      <span><strong>
                      </strong> - {ref.name_recom ? (ref.name_recom + " " + (ref.father_recom ?? " ") + " " + (ref.mother_recom ?? " ") ) :""}</span>
                    </span>

                  )
                })}
            </div>
          </div>

        </div>
        <div style={{ marginTop: '3%' }} className='rowFlexbox'>

          <div className='columnFlexbox'>
            <span className="montse" style={{ fontSize: '18px' }}>Programas </span>
            <TableDashboard updateRoute={updateRouteNextLevel} />





          </div>

          <div style={{ marginLeft: '1%' }} className='columnFlexbox'>
            <span className="montse" style={{ fontSize: '18px' }}>Tickets </span>


            <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
			<AgGridReact
				defaultColDef={{ resizable: true }}
				rowData={ticketsOpen}
				rowHeight={40}
				frameworkComponents={frameworkComponents}
				pagination={true}
				paginationPageSize={10}
				paginationNumberFormatter={function (params) {
					return params.value.toLocaleString();
				}}
				cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
				domLayout="autoHeight"
				// onGridReady={onGridReady}
			>







				{/* Column Title */}
				<AgGridColumn
					// onCellClicked={onCellClicked}
					filter="agTextColumnFilter"

					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Titulo" sortable={true} field="title" width="200" />
				{/* Column Description with mouseOver */}


				<AgGridColumn
					// onCellClicked={onCellClicked}
					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Sección" sortable={true}
					// cellClassRules={ragCellClassRules}
					// cellClass={cellClass}
           field="section" width="120" />


				{/* Column Created_at */}
				<AgGridColumn
					// onCellClicked={onCellClicked}
					filter="agTextColumnFilter"

					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Fecha creación" sortable={true} field="created_at"
					cellRenderer="slotTimeCreation" width="125" />





				{/* Tags */}
				<AgGridColumn
					// onCellClicked={onCellClicked}
					filter="agTextColumnFilter"

					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Etiqueta" sortable={true} field="tag_name" width="130" />


			</AgGridReact>




		</div>



          </div>

          <div style={{ marginLeft: '1%' }} className='columnFlexbox'>
            <span className="montse" style={{ fontSize: '18px' }}>Recordatorios </span>


            <div
              className="ag-theme-alpine"
              style={{ height: "100%", width: "100%" }}
            >
              <AgGridReact
                rowData={reminders}
                defaultColDef={{ resizable: true }}
                rowHeight={40}
                domLayout="autoHeight"
                frameworkComponents={frameworkComponents}
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={function (params) {
                  return params.value.toLocaleString();
                }}
                rowSelection="multiple"
              >
                <AgGridColumn
                  headerName="Asunto"
                  field="subject"
                  width="250"
                />
                {/* <AgGridColumn
        headerName="Fecha creación"
        field="created_at"
        width="250"
        cellRenderer="slotCreated"
        hide={activeP ? false : true}
      /> */}
                <AgGridColumn
                  headerName="Fecha"
                  field="ciy"
                  width="250"
                  cellRenderer="slotDate"
                />


              </AgGridReact>
            </div>





          </div>





        </div>
        <div className='rowFlexbox'>
        <div >
              <Checkbox  {...agregated} changeCheck={changeAgregated} index={0} />
              <input type="checkbox"
                onClick={(e) => changeAgregated()}
                id="cbox2"
              />
            </div>

        </div>

      </div>
      {/* end className content */}




      {/* Modal Status */}
      <Modal
        show={modalStory}
        onHide={(e) => setModalStory(false)}
        dialogClassName="modal-90w"
      >
        <Modal.Header style={{ height: "60px" }} closeButton>
          <Modal.Title
            style={{
              fontFamily: "Inter",
              marginTop: "5px",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Modificar Story
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5F6", border: "0px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid">
              <Row>
                <Col className="col-8">
                  <Form.Label className="formGray">Story</Form.Label>
                  <Form.Control
                    onChange={(e) => changeStory(e)}
                    value={storyContact}
                    as="textarea"
                    placeholder="Escriba sus notas..."
                    style={{ letterSpacing: '0.2px', paddingLeft: '10px' }}
                    rows={12}
                    cols={12}
                  />
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <Button
                  className="float-right mb-3 mr-2"
                  type="button"
                  onClick={(e) => onSubmit()}
                  variant="info"
                >
                  Guardar
                </Button>
                <Button
                  onClick={(e) => setModalStory(false)}
                  className="float-right mb-3 mr-2 montse btnBee"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
      {/* End modal Story */}


    </>
  )
}




// Component SlotCreated
export const SlotDate = function SlotDate(props) {
  moment.locale("es-mx");

  const showDate = (dateBD, timeBio) => {
    let datef = moment(dateBD).locale("es-mx").format("ddd D MMMM, YYYY ");
    let timef = moment(dateBD).locale("es-mx").format("h:mm A");
    datef = datef[0].toUpperCase() + datef.slice(1);
    datef = datef.replace(".", "");
    let tag = (
      <span className="montse">
        {datef} <Ioicons.IoMdTime /> {timef}
      </span>
    );
    return tag;
  };
  return (
    <>
      <span>
        {showDate(props.data.dateReminder)}
      </span>
    </>
  );
};
