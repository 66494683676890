
import axios from 'axios';
import { constaApi } from '../../constants/constants';
import { setError,removeError, startLoading, finishLoading } from "../../actions/uiNotificactions/ui";

export const loadReminders = async  (id) => {
        // dispatch( startLoading() );
    const contactReminders = [];
    const {data} = await axios.post(constaApi+'reminders',{id:id})
    data.forEach(child => {
        contactReminders.push({
            id:child.id,
            ...child
        });
    });
        return contactReminders;
}
export const loadProspectReminders = async  (id,idProspection,type) => {
const contactReminders = [];
const {data} = await axios.post(constaApi+'remindersProspect',{id:id,id_type:idProspection,type:type})
data.forEach(child => {
    contactReminders.push({
        id:child.id,
        ...child
    });
});
    return contactReminders;
}

export const loadApplicationReminders = async  (id,idProspection,type) => {
    const contactReminders = [];
    const {data} = await axios.post(constaApi+'applicationsReminders',{id:id,id_type:idProspection,type:type})
    data.forEach(child => {
        contactReminders.push({
            id:child.id,
            ...child
        });
    });
        return contactReminders;
    }

    export const loadTrackingReminders = async  (id,idProspection,type) => {
        const contactReminders = [];
        const {data} = await axios.post(constaApi+'trackingsReminders',{id:id,id_type:idProspection,type:type})
        data.forEach(child => {
            contactReminders.push({
                id:child.id,
                ...child
            });
        });
            return contactReminders;
        }

export const loadAllReminders = async  (id = null) => {
    // dispatch( startLoading() );
const contactReminders = [];
const {data} = await axios.get(constaApi+'reminders/all/'+id)
data.forEach(child => {
    contactReminders.push({
        id:child.id,
        ...child
    });
});
    return contactReminders;
}

export const loadAllRemindersContact = async  (id = null) => {
    // dispatch( startLoading() );
const contactReminders = [];
const {data} = await axios.get(constaApi+'allReminders/contacts')
data.forEach(child => {
    contactReminders.push({
        id:child.id,
        ...child
    });
});
    return contactReminders;
}