   
import React, { useState, useEffect, Suspense,useRef } from 'react'



import axios from 'axios';
import swal from 'sweetalert';

import { constaApi } from '../../../constants/constants';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import Layout from './Layout/Layout.jsx';
import { PDFViewer } from '@react-pdf/renderer';


export default function PreviewForm( props ) {



    // Manage of States
    const [dataApplicants,setDataApplicants] = useState();
    const [init] = useState(JSON.parse(localStorage.getItem('user')) || { logged: false });
    const notificationAlert = useRef();
    // const [frameworkComponents, setFramwrokw] = useState({ slotName: SlotName, slotActions: SlotActions,slotCreated:SlotCreated});
    const [gridApi, setGridApi] = useState();
    const [columnApi, setColumnApi] = useState();
    const [state, setState] = useState({});

    const [modalPermissons,setModalPermissons] = useState(false);

    const [userToRenderinPDF, setUserToRenderinPDF] = useState();

    // ComponenDidMount && ComponentDidUpdate

    useEffect(()=>{

      getDatafromForm();
    },[])

    const getDatafromForm = () => {

      axios.post(constaApi+'applicants/preview/getApplicants')
      .then(function (response) {
         setDataApplicants(response.data);
          });
    }
    // useEffect(() => {
    //   if (props.param) {
    //     quickSearch(props.param);
    //   }
    //     setUser(props.rowData);
    //     return () => {
    //       setState({}); // This worked for me
    //     };
    // }, [props]);  


    // functions
    function quickSearch(value) {
      let objx = gridApi;
      value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
      setGridApi(objx);
  }
    function alertCustom(message,type){
        alert.show(message ,{
            timeout: 2000, 
            type: type,
        });

    }
    const notification =  (type,message) => {
      let place = "tc";
      var options = {};
      options = {
        place: place,
        message: (
          <div>
            <div>
              {message}
            </div>
          </div>
        ),
        type: type,
        icon: "nc-icon nc-bell-55",
        autoDismiss: 7,
        }
      notificationAlert.current.notificationAlert(options);
   }
     function deleteUser(id){
        swal({
            title: "Estas seguro?",
            text: "Una vez eliminado,no podras recuperar este usuario!",
            icon: "warning",
            dangerMode: true,
            buttons: ["No","Si"],
          })
          .then((willDelete) => {
            if (willDelete) {
                if(init.id === id){
                    alertCustom('Accion no permitida','error');
                } else {
                    let datax = {id:id};
                     axios.post(constaApi+'users/delete',datax)
                   .then(function (response) {
                       if(response.status === 200){
                         notification('success','Contacto eliminado correctamente');
                           props.handleupdateTable();
                           }
                       });
               }
            } else {
              swal("Operacion cancelada!");
            }
          });

        }
        const onGridReady = (params) => {
          setGridApi(params);
          setColumnApi(params);
      }
      
      const autoSizeAll = (skipHeader) => {
        var allColumnIds = [];
        columnApi.columnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        columnApi.columnApi.autoSizeColumns(allColumnIds, skipHeader);
      };
      const onFirstDataRendered = (event) => {
        autoSizeAll(false);
      };
      const edit = (obj) => {
        props.clickHandler(obj);
      }

      const changePermissions = (data) => {
        props.parametersToModal(data);
      }
 
    
      const selectApplicant = (obj) => {
        setUserToRenderinPDF(obj.data);
        console.log('OBJ',obj.data);
      }
      return(
        <div className='content'>

        <div
        className="ag-theme-alpine"
        style={{ height: '100%', width: '100%' }}
    >
        <AgGridReact
             context={{
              deleteUser,
              edit,
              changePermissions
                }}
                defaultColDef={{ resizable: true }}
                rowData={dataApplicants}
            rowHeight={40}
            domLayout="autoHeight"
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            // frameworkComponents={frameworkComponents}
            pagination={true}
            paginationPageSize={10}
            paginationNumberFormatter={function (params) {
                return params.value.toLocaleString();
            }}
            rowSelection="multiple"
            onCellClicked={(e) => selectApplicant(e)}
        >
           <AgGridColumn
                headerName="ID" field="id" width="100" />
            <AgGridColumn
                cellRenderer="slotName"
                headerName="Encargado" field="fullnameUser" width="100" />
            <AgGridColumn headerName="Email" field="email" width="200" />
            <AgGridColumn headerName="Aplicante" field="fullnameApplicant" width="200" />
            
            
        </AgGridReact>
    </div>


    <div style={{marginTop:'5%' , height:'500px'}}>

      {
      userToRenderinPDF &&
    <PDFViewer width={'100%'} height={'500px'}>

<Layout userToRender={userToRenderinPDF}/>
      </PDFViewer>
      
      } 




    </div>
      </div>

      )
  }