
import axios from 'axios';
import { constaApi } from '../../constants/constants';
import { setError,removeError, startLoading, finishLoading } from "../../actions/uiNotificactions/ui";

export const loadColleges = async  () => {
        // dispatch( startLoading() );
    const colleges = [];
    const {data} = await axios.post(constaApi+'colleges')
    data.forEach(child => {
        colleges.push({
            id:child.id,
            ...child
        });
    });
        return colleges;
}

export const loadCollegesByProspeccion = async  (type = null) => {
    // dispatch( startLoading() );
const colleges = [];
const {data} = await axios.post(constaApi+'colleges/ByProspeccion',{type:type})
data.forEach(child => {
    colleges.push({
        id:child.id,
        ...child
    });
});
    return colleges;
}

export const loadLocalColleges = async  (type = null) => {
    // dispatch( startLoading() );
const colleges = [];
const {data} = await axios.post(constaApi+'colleges/local')
data.forEach(child => {
    colleges.push({
        id:child.id,
        ...child
    });
});
    return colleges;
}