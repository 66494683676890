import React, { useState, useRef, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  useLocation,
} from "react-router-dom";
import Nav from "../../components/Nav/Nav";
import SideBar from '../../components/SideBar/new/SideBar';
import routes from "../../routes.js";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import TopicsView from "./Topics/TopicsView.jsx";
import Articles from "./TemplateArticles/Articles.jsx";

import { articlesGeneral , articlesProspections ,articlesApplications , articlesTracking, articlesInConstruction} from "./staticsResources";
import ProspectionsFAQ from "./ProspectionFAQ/ProspectionsFAQ";

function FaqRouter(props) {
  const dispatch = useDispatch();
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [backgroundColor, setBackGroundColor] = useState(
    JSON.parse(localStorage.getItem("bgColor")) || "white"
  );
  const [activeColor, setActiveColor] = useState(
    JSON.parse(localStorage.getItem("activeColor")) || "info"
  );
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const mainPanel = useRef();

  return (
    <div className="wrapper">
      <SideBar
        {...props}
        routes={routes}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div
        style={{ backgroundColor: "#f9fafb" }}
        className="main-panel"
        ref={mainPanel}
      >
        <Nav page={"FAQ"} {...props} />
        <Switch>
          <Route
            exact
            path="/FAQ"
            render={(props) => <TopicsView {...props} />}
          />

          <Route
            exact
            path="/FAQ/General"
            render={(props) => <Articles articles={articlesGeneral} section={'General'} {...props} />}
          />

          <Route
            exact
            path="/FAQ/Prospection"
            render={(props) => <Articles articles={articlesProspections} section={'Prospección'} {...props} />}
          />

          <Route
            exact
            path="/FAQ/Prospection/:name"
            render={(props) => <ProspectionsFAQ {...props} />}
          />

          <Route
            exact
            path="/FAQ/Applications"
            render={(props) => <Articles articles={articlesApplications} section={'Aplicaciónes'} {...props} />}
          />

          <Route
            exact
            path="/FAQ/Track"
            render={(props) => <Articles articles={articlesTracking} section={'Seguimiento'} {...props} />}
          />

            <Route
            exact
            path="/FAQ/Construction"
            render={(props) => <Articles articles={articlesInConstruction} section={'En construcción'} {...props} />}
          />

          <Redirect to="/login" />
        </Switch>

        {/* <Footer fluid /> */}
      </div>
    </div>
  );
}

export default FaqRouter;
