import React, { useState, useEffect, useRef } from 'react'
import *  as FAIcons from "react-icons/fa";
import * as VCIcons from "react-icons/vsc";
import { useDispatch, useSelector } from 'react-redux';
// import { loadColleges } from '../../helpers/loadColleges'; 
import { useHistory } from 'react-router-dom';
import { activeCollege, deleteCollege } from '../../actions/colleges/colleges';
import Skeleton from 'react-loading-skeleton';
import { finishLoading } from '../../actions/uiNotificactions/ui';
import swal from 'sweetalert';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import moment from 'moment';
import { Link } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { CSVLink } from "react-csv";

// Component SLotName
export const SlotName = function SlotName(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const click = (obj) => {
    dispatch(activeCollege(obj.id, obj));

  }
  const showName = (name = "", fname = "", mname = "") => {
    let {
      data: {
        local: local
      }
    } = props;
    let n = name ? name.split(" ") : " ";
    let tag = '';
    if (n.length >= 3) {
      n = n[0].charAt(0) + n[1].charAt(0) + n[2].charAt(0);
    } else if (n.length >= 2) {
      n = n[0].charAt(0) + n[1].charAt(0);
    } else {
      n = n[0].charAt(0);
    }
    tag =
      <>
        {local == 1 ?
          <Link onClick={(e) => click(props.data)} to={"localColleges/" + (props.data.id) + "/bio"}>
            <span className="mt-1 mr-2 sc-caSCKo fXbvQO styles__Company-sc-103gogw-0 jdbeFR">{n}</span>
            <span>{name} {fname} {mname}</span>
          </Link>
          :
          <Link onClick={(e) => click(props.data)} to={"colleges/" + (props.data.id) + "/bio"}>
            <span className="mt-1 mr-2 sc-caSCKo fXbvQO styles__Company-sc-103gogw-0 jdbeFR">{n}</span>
            <span>{name} {fname} {mname}</span>
          </Link>

        }
      </>;
    return tag;
  }
  return (
    <>
      <span>{showName(props.data.name, props.data.fname, props.data.mname)}</span>
    </>
  )
}
// -----------------------------End component SLotName

// Component SLotActions
export const SlotActions = function SlotActions(props) {
  function dropStaff(id) {
    props.context.dropCollege(id);
  }

  return (
    <>
      <a ><FAIcons.FaTrashAlt style={{ color: '#FE0000' }} size={18} onClick={(e) => dropStaff(props.data.id)} /> </a>
    </>
  )
}

// ----------------------------------------End component SlotACtions

// Component SlotCreated
export const SlotCreated = function SlotActions(props) {
  moment.locale('es-mx')

  const dateToMoment = (date) => {
    return moment(date).locale('es-mx').format("ddd D MMMM, YYYY HH:mm ");
  }
  return (
    <>
      <span>{dateToMoment(props.data.created_at)}</span>
    </>
  )
}

// ----------------------------------------End component SlotCreated

export const slotProspecciones = function slotProspecciones(props) {
  return (
    <>
      <span>#</span>
    </>
  )
}

export const slotApplicaciones = function slotApplicaciones(props) {
  return (
    <>
      <span>#</span>
    </>
  )
}
export const SlotTrack = function (props) {
  const { value } = props;
  const { names } = props.data;
  const PopoverComponent = (obj) => {
    return (
      <Popover id="popover-basic">
        <Popover.Content>
          {obj &&
            obj.map(ob => {
              return (
                [ob.name != "" &&
                  <li>{ob.name}</li>]
              )
            })}
        </Popover.Content>
      </Popover>
    );
  };
  return (
    <OverlayTrigger
      trigger={["hover", "hover"]}
      placement="top"
      overlay={PopoverComponent(names)}
    >
      <span>{value}</span>
    </OverlayTrigger>
  )
}
export const SlotProposal = function (props) {
  const { value } = props;
  const { namesProposal } = props.data;
  const PopoverComponent = (obj) => {
    return (
      <Popover id="popover-basic">
        <Popover.Content>
          {obj &&
            obj.map(ob => {
              return (
                [ob.name != "" &&
                  <li>{ob.name}</li>]
              )
            })}
        </Popover.Content>
      </Popover>
    );
  };
  return (
    <OverlayTrigger
      trigger={["hover", "hover"]}
      placement="top"
      overlay={PopoverComponent(namesProposal)}
    >
      <span>{value}</span>
    </OverlayTrigger>
  )
}

function TableColleges(props) {


  const dispatch = useDispatch();
  const [frameworkComponents, setFramwrokw] = useState({ slotTrack: SlotTrack, slotProposal: SlotProposal, slotName: SlotName, slotActions: SlotActions, slotApplicaciones: slotApplicaciones, slotProspecciones: slotProspecciones });
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [modalExport, setModalExport] = useState(false);
  const startValue = new Date(new Date().getFullYear(), new Date().getMonth(), 14);
  const endValue = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 15);
  const [option, setOption] = useState('');
  const [rangeDate, setRangeDate] = useState([startValue, endValue]);
  const [objToExport, setObjToExport] = useState([]);
  const [reportToExport, setReportToExport] = useState([]);
  
  const headers = [
    { label: "Nombre", key: "name" },
    { label: "Tipo", key: "type" },
    { label: "Pais", key: "country" },
    { label: "Propuesto", key: "totalProposal" },
    { label: "Estudiantes", key: "totalTrack" },
 
  ];




  useEffect(() => {
    if (props.param) {
      quickSearch(props.param);
    }

  }, [props])

  useEffect(() => {
    if (props.modalExport) {
      setModalExport(!modalExport);
    }
  }, [props.modalExport])

  const csvReport = {
    data: objToExport,
    headers: headers,
    filename: 'InternationalCollegesReport.csv'
  };


  const notificationAlert = useRef();
  const { colleges } = useSelector(state => state.colleges);
  const { loading } = useSelector(state => state.ui);
  const history = useHistory();
  const [loadingExport, setloadingExport] = useState(false);

  useEffect(() => {
    setObjToExport(colleges);
  }, [colleges])

  const closeModal = (e) => {
    setModalExport(false);
  }
  function changeDates(e) {
    let start = new Date(e.value[0]).toLocaleDateString('en-CA'); // 8/19/2020
    let end = new Date(e.value[1]).toLocaleDateString('en-CA'); // 8/19/2020
    let arr = [];
    arr[0] = start + ' ' + '00:00:00';
    arr[1] = end + ' ' + '23:59:59';
    let d = new Date(arr[0]);
    setRangeDate(arr);
  }
  const getObjToExport = (data) => {
    setObjToExport(data);
  }
  const formatDatExport = async () => {
    let aux = [];
    let newobj = {};
    let user = {};
    let inicio = moment(rangeDate[0]).format("YYYY-MM-DD");
    let fin = moment(rangeDate[1]).format("YYYY-MM-DD");
    setloadingExport(true);
    if (objToExport) {
      objToExport.map(ob => {
        if (option == 'users') {
          if (ob.names.length > 0) {
            ob.names.map(us => {
              if (us.date > inicio && us.date < fin) {
                user = us;
                user.colegio = ob.name;
                user.typocolegio = ob.type;
                user.paisColegio = ob.country;
                aux.push(us);
              }
            })
          }

        } else {

          if (ob.namesProposal.length > 0) {
            ob.namesProposal.map(us => {
              if (us.date > inicio && us.date < fin) {
                user = us;
                user.colegio = ob.name;
                user.typocolegio = ob.type;
                user.paisColegio = ob.country;
                aux.push(us);
              }
            })
          }

        }
      })
    }
    setReportToExport(aux);
    const elem = document.getElementById('CSVLINK');
    await delay(2000);
    elem.click();
    setloadingExport(false);
  }
  // wait 5 seconds functions
  const delay = ms => new Promise(res => setTimeout(res, ms));

  function handleGoto(obj) {
    dispatch(activeCollege(obj.id, obj));
    history.replace("colleges/" + obj.id + "/bio");
  }
  async function quickSearch(value) {
    let objx = gridApi;
    value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
    setGridApi(objx);
    let rowsDisplay = objx.api.rowRenderer.rowModel.rowsToDisplay;
    let result;
    if (rowsDisplay) {
      result = await rowsDisplay.map(row => {
        let { data } = row;
        return data;
      })
      setObjToExport(result);
    }
  }
  const dropCollege = (id) => {
    swal({
      title: "Estas seguro?",
      text: "Una vez eliminado,no podras recuperar este Colegio!",
      icon: "warning",
      dangerMode: true,
      buttons: ["No", "Si"],
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(deleteCollege(id, props.type == 'Locale' ? 1 : 0));
          notification('success', 'Colegio eliminado Correctamente');
        } else {
          swal("Operacion cancelada!");
        }
      });
  }
  const notification = (type, message) => {
    let place = "tc";
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    }
    notificationAlert.current.notificationAlert(options);
  }
  const onGridReady = (params) => {
    setGridApi(params);
    setColumnApi(params);
  }
  const autoSizeAll = (skipHeader) => {
    var allColumnIds = [];
    columnApi.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    columnApi.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  };
  const onFirstDataRendered = (event) => {
    autoSizeAll(false);
  };

  const onSubmit = (e) => {

  }


  const exporta = async () => {
    let {filter:value} = gridApi.api.filterManager.activeAdvancedFilters[0] ? gridApi.api.filterManager.activeAdvancedFilters[0].appliedModel : 'no';
    if(props.param){
      await quickSearch(props.param);
     
    } else {
    
    }
  }


  return (
    <> {loading ?
      <div className="row mt-2">
        <NotificationAlert ref={notificationAlert} />
        <Skeleton width="60rem" height={30} count={10} />
      </div>

      :
      <div className="content">

        <button
      style={{float:'right',marginLeft:'-5%', textDecoration:'white'}}
      className="mt-n5 btn btn-md btn-info" onClick={(e) => exporta(e)}
      >
        <CSVLink {...csvReport}><span>Exportar</span>
        </CSVLink>
        </button>


        <NotificationAlert ref={notificationAlert} />
        <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            context={{
              dropCollege
            }}
            defaultColDef={{ resizable: true }}
            rowData={colleges}
            rowHeight={40}
            domLayout="autoHeight"
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            frameworkComponents={frameworkComponents}
            pagination={true}
            paginationPageSize={10}
            paginationNumberFormatter={function (params) {
              return params.value.toLocaleString();
            }}
            rowSelection="multiple"
          >
            <AgGridColumn
              headerName="ID" sortable={true} field="id" width="100" />
            <AgGridColumn
              cellRenderer="slotName"
              sortable={true}
              headerName="Colegio" field="name" width="100" />
            <AgGridColumn hide={props.collegesLocales ? true : false} sortable={true} headerName="Tipo" field="type" width="200" />
            <AgGridColumn hide={props.collegesLocales ? true : false} sortable={true} headerName="Pais" field="country" width="200" />
            <AgGridColumn headerName="Propuesto"sortable={true}  field="totalProposal" width="200" cellRenderer="slotProposal" />
            <AgGridColumn headerName="Estudiantes" sortable={true} field="totalTrack" width="200" cellRenderer="slotTrack" />
            <AgGridColumn
              headerName="Acciones"
              width={250}
              cellRenderer="slotActions"
            />
          </AgGridReact>
        </div>

        {/* //  Modal To Export */}
        <Modal
          style={{ marginTop: '50px' }}
          dialogClassName="modal-90w"
          show={modalExport}
          onHide={e => closeModal(!modalExport)}
        >
          <Modal.Header style={{ height: '60px' }} closeButton>
            <Modal.Title style={{ fontFamily: 'Inter', marginTop: '5px', fontWeight: '600', fontSize: '18px' }}>Exportar</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ background: '#F4F5F6', border: '0px' }}>

            <form onSubmit={onSubmit} >
              <div className="container-fluid">
                <Row className="mt-1">
                  <Col className="col-4">
                    <div class="form-check">
                      <input onClick={(e) => setOption('users')} class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"  />
                      <label class="form-check-label" for="flexRadioDefault1">
                        Usuarios
                      </label>
                    </div>
                    <div class="form-check">
                      <input onClick={(e) => setOption('prospects')} class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                      <label class="form-check-label" for="flexRadioDefault2">
                        Prospectos
                      </label>
                    </div>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col className='card col-5'>
                    <DateRangePickerComponent
                      startDate={startValue}
                      endDate={endValue}
                      placeholder="Rango de fechas"
                      id="daterangepicker"
                      format="dd-MMM-yy"
                      onChange={(e) => changeDates(e)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <button
                    disabled= {option === '' ? true : false}
                      className="btn btn-info float-right mb-3 mr-2"

                      // This change is in 06/30/2023
                      // onClick={(e) => { formatDatExport() }}

                      // New method
                      onClick={(e) => exporta(e)}
                      type="button"
                    >
                      {loadingExport == true ?
                        <VCIcons.VscLoading >
                          Exportar
                        </VCIcons.VscLoading>
                        :
                        "Exportar"
                      }
                    </button>
                    <CSVLink
                      id="CSVLINK"
                      {...csvReport}><span></span>
                    </CSVLink>
                  </Col>
                </Row>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>

    }
    </>
  )
}

export default TableColleges
