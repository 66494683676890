import React,{useState,useEffect} from 'react'
import AddEditReminders from './AddEditReminders.jsx'
import TableReminders from './TableReminders.jsx'
import { useDispatch, useSelector,shallowEqual } from 'react-redux';
import { useParams } from "react-router";
import { starLoadingRemindersC } from '../../../actions/contacts/remindersContacts/remindersContact';

function Reminders(props) {
    const [show,setShow] = useState(false);
    const [row,setRow] = useState();
    const dispatch = useDispatch();
    const [blocked,setBlocked] = useState(false);
    let { id } = useParams();


    useEffect(() => {
        if(props.prospection){
            setBlocked(true);
        }else if(props.applications){
            setBlocked(true);

        } else if(props.activeTracking){
            setBlocked(true);

        }
         else {
            dispatch(starLoadingRemindersC(id))
        }
    }, [])

        function method(obj){
        setRow({});
        setRow(obj);
        setShow(!show);
    }

    const open = () => {

    }
    return (
        <div className="content">
        <div className="mt-n5 row">
            <div className="col-12">
            <div className="col d-flex justify-content-end">
            {!blocked &&
                <AddEditReminders 
                route={'General'}
                row={row} flag={show}
                bridge={(e) => method(e)}
                {...props}/>
            }
            </div>
            <TableReminders
            route={'General'}
            openModal={open}
            bridge={(e) => method(e)}
            {...props}/>
            </div>
        </div>
        </div>
    )
}

export default Reminders
