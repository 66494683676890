import React, { useEffect, useState } from 'react';
import * as FIIcons from "react-icons/fi";
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import {apiCountries,constaApi} from '../../../constants/constants';
import { activeCollege } from '../../../actions/colleges/colleges';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Checkbox } from '../../../components/collegeComponents/AddOrEditCollege';


function DetailsData(props) {
    // vars
    let {active} = useSelector( state => state.colleges);
    if(!active){
        active =  JSON.parse(localStorage.getItem('collegeActive'));
    }
    const dispatch = useDispatch();
    const [editInfo,setEditInfo] = useState(false);
    const [editFacts,setEditFacts] = useState(false);
    const [editAddress,setEditAddress] = useState(false);
    const [college,setCollege] = useState(false);
    const [countries, setCountries] = useState([]);
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const types = ['Boarding School', 'School District', 'Summer Camp', 'Language School', 'University/College', 'Work & Travel'];

    const [agregated, setAgregated] = useState(
      {
        id: 'Vincular', value: 'Vincular', isChecked: active.isverified == "1" ? true : false,
        label: active.isverified == "1" ? 'Desvincular' : 'Vincular'
      },
    );


    useEffect(() => {
        if(active){
            setCollege({...active});
        }
         axios.get(apiCountries)
            .then(function (response) {
                setCountries(response.data);
            });
    },[])
    // methods
    function edit() {
        setEditInfo(!editInfo);
    }
    function editFact(){
        setEditFacts(!editFacts);
    }
    function editAddr(){
        setEditAddress(!editAddress);
    }
    function changeName(e){
        setCollege({...college,name:e.target.value});
    }
    function changeType(e){
        setCollege({...college,type:e.target.value});
        // switch (e.target.value) {
        //     case 'Boarding School':
        //         setCountries(countriesBoardingSchools)
        //         break;
        //     case 'School District':
        //         setCountries(countriesSchoolD)
        //         break;
        //     case 'Summer Camp':
        //         setCountries(countriesSummer);
        //         break;
        //     default:
        //         setCountries(countriesBoardingSchools)
        //         break;
        // }
    }
    function changeCountry(e){
        setCollege({...college,country:e.target.value});

    }
    function changeWebSite(e){
        setCollege({...college,website:e.target.value});
    }
    function changeDayGrade(e){
        setCollege({...college,start_day_grade:e.target.value});
    }
    function changeBoardingGrade(e){
        setCollege({...college,total_boarding_grade:e.target.value});

    }
    function changeDayStudents(e){
        setCollege({...college,total_day_students:e.target.value});

    }
    function changeInternationalGrade(e){
        setCollege({...college,total_international_grade:e.target.value});

    }
    function changeStudentsInSchool(e){
        setCollege({...college,total_students_in_school:e.target.value});

    }
    function changeCity(e){
        setCollege({...college,city:e.target.value});
    }
    function changeState(e){
        setCollege({...college,state:e.target.value});

    }
    function changeStreet(e){
        setCollege({...college,street:e.target.value});

    }
    function changeCP(e){
        setCollege({...college,cp:e.target.value});

    }
    function changeNumber(e){
        setCollege({...college,number:e.target.value});
    }

  async  function changeAgregated () {

      let datax = {
        id: active.id,
        isverified: !agregated.isChecked
      };

      await axios.post(constaApi + 'colleges/update', datax)
        .then(function (response) {
          const {data} = response;
          dispatch( activeCollege( data.id,data) );
          setEditInfo(false);
          setEditFacts(false);
          setEditAddress(false);

          setAgregated({...agregated
            ,isChecked : data.isverified,
            label: active.isverified == "1" ? 'Vincular' : 'Desvincular'
          })





        });


    }
    async function onSubmit() {
         axios.post(constaApi + 'colleges/update', college)
        .then(function (response) {
            const {data} = response;
            dispatch( activeCollege( data.id,data) );
            setEditInfo(false);
            setEditFacts(false);
            setEditAddress(false);
        });

    }
    return (
        <>
        <div style={{display:'flex',justifyContent:'right',alignItems:'right',marginBottom:'5%',marginTop:'-5%' }}>
        <div className='rowFlexbox'>
        <div >
              <Checkbox  {...agregated}
              changeCheck={changeAgregated}
               index={0} />
              <input type="checkbox"
                onClick={(e) => changeAgregated()}
                id="cbox2"
              />
            </div>

        </div>
        </div>

        {!editInfo ?
                <div className="mt-n5 card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-11">
                                <h5 style={{ fontWeight: '600' }} className="Inter card-title">Información</h5>
                            </div>
                            <div style={{ marginRight: '-200px' }} className="col-1 d-flex justify-content-end">
                            <a>
                            <FIIcons.FiEdit onClick={(e) => edit()} size={18} style={{ color: '#386CEF' }} />
                            </a>
                            </div>
                        </div>
                        <div className="row mt-2 ">
                            <div className="col-3">
                                <h6 className="Inter card-subtitle mb-2 text-muted">Nombre</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="Inter card-subtitle mb-2 ">
                                    {active.name}
                                </h6>
                            </div>
                        </div>
                        {/* <div className="row mt-2 ">
                            <div className="col-3">
                                <h6 className="Inter card-subtitle mb-2 text-muted">Tipo</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="Inter card-subtitle mb-2 ">
                                    {active.type}
                                </h6>
                            </div>
                        </div> */}
                        <div className="row mt-2 ">
                            <div className="col-3">
                                <h6 className="Inter card-subtitle mb-2 text-muted">Pais</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="Inter card-subtitle mb-2 ">
                                    {active.country}
                                </h6>
                            </div>
                        </div>
                        <div className="row mt-2 ">
                            <div className="col-3">
                                <h6 className="Inter card-subtitle mb-2 text-muted">Sitio web</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="Inter card-subtitle mb-2 ">
                                    {active.website}
                                </h6>
                            </div>
                        </div>
                        <div className="row mt-2 ">
                            <div className="col-3">
                                <h6 className="Inter card-subtitle mb-2 text-muted">Nombre</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="Inter card-subtitle mb-2 ">
                                    {active.name}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            :
            <div className="mt-n5 card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-11">
                                <h5 style={{ fontWeight: '600' }} className="Inter card-title">Editar Información</h5>
                            </div>
                            <div style={{ marginRight: '-200px' }} className="col-1 d-flex justify-content-end">
                            <button onClick={(e) => edit()} type="button" className="montse btn btn-danger">Cancelar</button>
                            <button onClick={onSubmit}
                            type="submit" className="montse ml-1 btn btn-info">Guardar</button>
                            </div>
                        </div>
                        <div className="row mt-3 ">
                                <div className="col-3">
                                    <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Nombre</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control autoComplete="off"
                                        onChange={(e) => changeName(e)} value={college.name}
                                        name="name"
                                        className="formGray" type="text" placeholder="Ingrese el nombre de el colegio" />
                                </div>
                            </div>
                        {/* <div className="row mt-2 ">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 text-muted">Tipo</h6>
                            </div>
                            <div className="col">
                                    <Form.Control autoComplete="off"
                                        name="city"
                                        onChange={e => changeType(e)}
                                        value={college.type} as="select" size="sm" custom>
                                        <option disabled value="" selected></option>
                                        {types.map(typ => (
                                            <option key={typ} value={typ}>
                                                {typ}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </div>
                        </div> */}
                        <div className="row mt-2 ">
                            <div className="col-3">
                                <h6 className="Inter card-subtitle mb-2 text-muted">Pais</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="Inter card-subtitle mb-2 ">
                                    <Form.Control autoComplete="off"
                                        name="city"
                                        onChange={e => changeCountry(e)}
                                        value={college.country} as="select" size="sm" custom>
                                        <option disabled value="" selected></option>
                                        {countries &&
                                        [countries.map(countr => (
                                            <option key={countr.name} value={countr.name}>
                                            {countr.name}
                                        </option>
                                        ))]
                                    }
                                    </Form.Control>
                                </h6>
                            </div>
                        </div>
                        <div className="row mt-2 ">
                            <div className="col-3">
                                <h6 className="Inter card-subtitle mb-2 text-muted">Sitio web</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="Inter card-subtitle mb-2 ">
                                       <Form.Control autoComplete="off"
                                        onChange={(e) => changeWebSite(e)} value={college.website}
                                        name="name"
                                        className="formGray" type="text" placeholder="Ingrese su website" />
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            }

             {!editAddress
             ?
            //  {/* Addres */}
             <div className="mt-3 card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-11">
                                <h5 style={{ fontWeight: '600' }} className="Inter card-title">Direccion</h5>
                            </div>
                            <div style={{ marginRight: '-200px' }} className="col-1 d-flex justify-content-end">
                                <a>
                            <FIIcons.FiEdit onClick={(e) => editAddr()} size={18} style={{ color: '#386CEF' }} />
                                </a>
                            </div>
                        </div>
                        <div className="row mt-2 ">
                            <div className="col-3">
                                <h6 className="Inter card-subtitle mb-2 text-muted">Ciudad</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="Inter card-subtitle mb-2 ">
                                    {active.city}
                                </h6>
                            </div>
                        </div>
                        <div className="row mt-2 ">
                            <div className="col-3">
                                <h6 className="Inter card-subtitle mb-2 text-muted">Estado</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="Inter card-subtitle mb-2 ">
                                    {active.state}
                                </h6>
                            </div>
                        </div>
                        <div className="row mt-2 ">
                            <div className="col-3">
                                <h6 className="Inter card-subtitle mb-2 text-muted">Calle</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="Inter card-subtitle mb-2 ">
                                    {active.street}
                                </h6>
                            </div>
                        </div>
                        <div className="row mt-2 ">
                            <div className="col-3">
                                <h6 className="Inter card-subtitle mb-2 text-muted">Codigo postal</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="Inter card-subtitle mb-2 ">
                                    {active.cp}
                                </h6>
                            </div>
                        </div>
                        <div className="row mt-2 ">
                            <div className="col-3">
                                <h6 className="Inter card-subtitle mb-2 text-muted">Numero</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="Inter card-subtitle mb-2 ">
                                    {active.number}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            :
            <div className="mt-3 card">
            <div className="card-body">
                <div className="row">
                    <div className="col-11">
                        <h5 style={{ fontWeight: '600' }} className="Inter card-title">Editar Dirección</h5>
                    </div>
                    <div style={{ marginRight: '-200px' }} className="col-1 d-flex justify-content-end">
                            <button onClick={(e) => editAddr()} type="button" className="montse btn btn-danger">Cancelar</button>
                            <button onClick={onSubmit}
                            type="submit" className="montse ml-1 btn btn-info">Guardar</button>
                            </div>
                </div>
                <div className="row mt-2 ">
                    <div className="col-3">
                        <h6 className="Inter card-subtitle mb-2 text-muted">Ciudad</h6>
                    </div>
                    <div className="col">
                        <h6 style={{ color: '#243243', fontWeight: '600' }}
                            className="Inter card-subtitle mb-2 ">
                            <Form.Control autoComplete="off"
                                        onChange={(e) => changeCity(e)} value={college.city}
                                        name="name"
                                        className="formGray" type="text" placeholder="Ingrese su ciudad" />
                        </h6>
                    </div>
                </div>
                <div className="row mt-2 ">
                    <div className="col-3">
                        <h6 className="Inter card-subtitle mb-2 text-muted">Estado</h6>
                    </div>
                    <div className="col">
                        <h6 style={{ color: '#243243', fontWeight: '600' }}
                            className="Inter card-subtitle mb-2 ">
                            <Form.Control autoComplete="off"
                                        onChange={(e) => changeState(e)} value={college.state}
                                        name="name"
                                        className="formGray" type="text" placeholder="Ingrese su Estado" />
                        </h6>
                    </div>
                </div>
                <div className="row mt-2 ">
                    <div className="col-3">
                        <h6 className="Inter card-subtitle mb-2 text-muted">Calle</h6>
                    </div>
                    <div className="col">
                        <h6 style={{ color: '#243243', fontWeight: '600' }}
                            className="Inter card-subtitle mb-2 ">
                            <Form.Control autoComplete="off"
                                        onChange={(e) => changeStreet(e)} value={college.street}
                                        name="name"
                                        className="formGray" type="text" placeholder="Ingrese su Calle" />
                        </h6>
                    </div>
                </div>
                <div className="row mt-2 ">
                    <div className="col-3">
                        <h6 className="Inter card-subtitle mb-2 text-muted">Codigo postal</h6>
                    </div>
                    <div className="col">
                        <h6 style={{ color: '#243243', fontWeight: '600' }}
                            className="Inter card-subtitle mb-2 ">
                             <Form.Control autoComplete="off"
                                        onChange={(e) => changeCP(e)} value={college.cp}
                                        name="name"
                                        className="formGray" type="text" placeholder="Ingrese su Codigo Postal" />
                        </h6>
                    </div>
                </div>
                <div className="row mt-2 ">
                    <div className="col-3">
                        <h6 className="Inter card-subtitle mb-2 text-muted">Numero</h6>
                    </div>
                    <div className="col">
                        <h6 style={{ color: '#243243', fontWeight: '600' }}
                            className="Inter card-subtitle mb-2 ">
                            <Form.Control autoComplete="off"
                                        onChange={(e) => changeNumber(e)} value={college.number}
                                        name="name"
                                        className="formGray" type="text" placeholder="Ingrese su #" />
                        </h6>
                    </div>
                </div>
            </div>
        </div>
            }
        </>
        )
    }

    export default DetailsData
