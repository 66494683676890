import React, { useState,useRef, useEffect } from 'react'
import {
    BrowserRouter as Router, Switch, Redirect,
    Route,useLocation
} from 'react-router-dom';
import CollegesView from '../pages/CollegesView';
import Bio from '../components/collegeComponents/Bio';
import Detail from '../components/collegeComponents/detailsComponent/Detail';
import Docs from '../components/collegeComponents/docsComponents/Docs';
import Reminds from '../components/collegeComponents/ReminderComponent/Reminds';
import Nav from "../components/Nav/Nav";
import SideBar from '../components/SideBar/new/SideBar';
import routes from "../routes.js";
import { useParams } from "react-router";
import Staff from '../components/collegeComponents/staffComponents/Staff';
import { useDispatch } from 'react-redux';
import { startLoadingStaffs } from '../actions/colleges/staff/staff';
import { starLoadingRemindersColleges } from '../actions/colleges/remindersColleges/remindersColleges';
import Resume from '../components/collegeComponents/resume/Resume';
import { constaApi } from '../constants/constants';
import axios from 'axios';
import Colleges from '../pages/Colleges';
import PermissionsHook from '../customHook/PermissionsHook/PermissionsHook';



function CollegesRouter(props) {
  const { pathname } = useLocation();

    const dispatch = useDispatch();
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [backgroundColor, setBackGroundColor] = useState(JSON.parse(localStorage.getItem('bgColor')) || 'white');
    const [activeColor, setActiveColor] = useState(JSON.parse(localStorage.getItem('activeColor')) || 'info');
    const [user,setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const mainPanel = useRef();
    useEffect(() => {

      axios.get(constaApi + 'geTotalLeads', {
        headers: {
            "Accept": "application/json"
        }
    }).then(function (response) {
      const {data} = response;
      localStorage.setItem('countLeads', JSON.stringify(data));
    }).catch(error => {
        console.log('Error')
    });

  })

  
    useEffect(() => {
      dispatch( startLoadingStaffs(id));
      dispatch( starLoadingRemindersColleges (id));
    }, [dispatch])

    const finishLoading = () =>{
      setLoading(false);
    }
    
   const fromSidebar = () => {
    mainPanel.current.classList.toggle('withSidebar');
}
    return (
        <div className="wrapper">
          <SideBar
            {...props}
            routes={routes}
            bgColor={backgroundColor}
            activeColor={activeColor}
            fromSidebar={fromSidebar}

          />

        { loading ? 
          
          <PermissionsHook  finishLoadin={finishLoading} module={'Colegios'} /> 
          
          :

        <div style={{backgroundColor:'#f9fafb'}}className="main-panel" ref={mainPanel}>
            <Nav {...props} />

            {
                        pathname !== '/colleges' &&
                        <CollegesView  />


                    }


            <Switch>
            <Route exact path="/colleges/:id/bio"
            render={(props) => (
                <Bio {...props}
                />
            )}
            />
            <Route exact path="/colleges/:id/detail"
            render={(props) => (
                <Detail {...props}
                />
            )}
            />
            <Route exact path="/colleges/:id/staff"
            render={(props) => (
                    <Staff {...props}
                        />
            )}
        />
          <Route exact path="/colleges/:id/docs"
            render={(props) => (
                    <Docs {...props}
                        />
            )}
        />
          <Route exact path="/colleges/:id/reminds"
            render={(props) => (
                    <Reminds {...props}
                        />
            )}
        />

<Route exact path="/colleges/:id/resume"
            render={(props) => (
                    <Resume {...props}
                        />
            )}
        />

<Route exact path="/colleges"
            render={(props) => (
                    <Colleges {...props}
                        />
            )}
        />


            <Redirect to="/login" />
  </Switch>

        </div>
       }
      </div>
        
                    
    )
}

export default CollegesRouter
