import React from 'react'
import * as MDicons from "react-icons/md";
import { useHistory } from "react-router-dom";
import Accordion from '../Templates/Accordion.jsx';

function Articles(props) {

  let history = useHistory();
  const { location } = history;
  const whereIam = location.pathname.split("/")[(location.pathname.split("/")).length - 1];
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
      }}
      className='content'>
      <button onClick={(e) => history.goBack()}>
        <MDicons.MdKeyboardBackspace size={24} />
      </button>
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h4>{props.section ?? 'In construction'}</h4>
      </div>
      {/* Zona de interacción */}
      {/* Div de fondo donde iran las columnas y renglones */}
      <div class="container">
        <div
          className='inBlock'>
          {
            props.articles.map(art => {
              return (


                <Accordion title={art.name}>
                  <p>{art.text}</p>
                </Accordion>



              )
            })
          }


        </div>

      </div>


    </div>
  )
}

export default Articles