
import React, { useEffect,useCallback, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import axios from 'axios';
import { constaApi } from '../../constants/constants';
import NotificationAlert from "react-notification-alert";

function AccountingProspection() {
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '1200px', width: '1200px' }), []);
  const [rowData, setRowData] = useState();
  const notificationAlert = useRef();
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Nombre',
      field: 'fullname',
      minWidth: 180,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    { headerName: "Ciudad" ,field: 'city' },
    { headerName: "Programa" ,field: 'name_prospection' },
    { headerName: "Estado" ,field: 'state' },
    { headerName: "Advisor" ,field: 'name_advisor' },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
  }, []);

  const [rows,setRows] = useState([]);
  const [selectRow,setSelectRow] = useState([]);
  useEffect(()=>{
    consultCountacts();
  },[])

  const consultCountacts = () =>{
       axios.get(constaApi + 'getAccountinProspection', {
        headers: {
            "Accept": "application/json"
        }
          }).then((response) => {
            let {data} = response;
            let invoice = data.invoices;
            let contacts = data.contacts;
            setRows(contacts);
        })
       .catch((error)=>{

      })
  }

  const onGridReady = useCallback((params) => {
   
  }, []);

  const onQuickFilterChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById('quickFilter').value
    );
  }, []);
  const onRowSelected = useCallback((event) => {
  }, []);

  const onSelectionChanged = useCallback((event) => {
   
    let newArray = event.api.getSelectedNodes().map(node => {
      return node.data;
    })
    setSelectRow(newArray);
  }, []);

  const generateInvoices = () => {
     axios.post(constaApi + 'generateInvoiceProspection', selectRow)
    .then(function (response) {
       setSelectRow([]);
       notification('success', 'Generado correctamente');
       consultCountacts();
    });
  }

  const notification = (type, message) => {
    let place = "tc";
    var options = {};
    options = {
        place: place,
        message: (
            <div>
                <div>
                    {message}
                </div>
            </div>
        ),
        type: type,
        icon: "nc-icon nc-bell-55",
        autoDismiss: 7,
    }
    notificationAlert.current.notificationAlert(options);
}

  return (
   <>
   <div className='content'>
   <NotificationAlert ref={notificationAlert} />
   <button
          style={{ float: 'right', textDecoration: 'white' }}
          disabled={selectRow.length <= 0}
          className="mt-n5 btn btn-sm btn-info" onClick={(e) => generateInvoices()}
        >
          Generar Invoice {selectRow.length > 0 && selectRow.length}
        </button>
    <div className='ag-theme-alpine' style={{height:'100%',width:"100%"}}>
    <AgGridReact
                                rowHeight={40}
                                cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                                domLayout="autoHeight"
          rowData={rows}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowSelection={'multiple'}
          onGridReady={onGridReady}
          onRowSelected={onRowSelected}
          onSelectionChanged={onSelectionChanged}
          rowMultiSelectWithClick={"true"}
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={function (params) {
              return params.value.toLocaleString();
          }}
        ></AgGridReact>

    </div>
   </div>
   </>
  )
}

export default AccountingProspection