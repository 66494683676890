import SideBar from '../../components/SideBar/new/SideBar';
import React, { useRef, useState } from 'react'
import routes from '../../routes';
import estilos from'./styles/WithoutPermissonsStyle.module.css'


export default function WithoutPermissons(props) {

  const [backgroundColor, setBackGroundColor] = useState(JSON.parse(localStorage.getItem('bgColor')) || 'white');
    const [activeColor, setActiveColor] = useState(JSON.parse(localStorage.getItem('activeColor')) || 'info');
    const mainPanel = useRef();

    const routeChange = () =>{ 
        window.location.replace("/dashboard");

    }

  return (

    <div className="wrapper">
    <SideBar
      {...props}
      routes={routes}
      bgColor={backgroundColor}
      activeColor={activeColor}
    />

    <div style={{backgroundColor:'#f9fafb'}}className="main-panel" ref={mainPanel}>

      <div className={estilos.father}>
      <div className="w3-display-middle">
  <h1 className="w3-jumbo w3-animate-top w3-center">
    <center>
      <code>Acceso denegado</code>
      
    </center>
    </h1>
  <hr className="w3-border-white w3-animate-left" /> 
  <h3 className="w3-center w3-animate-right">No tienes permiso para ver este modulo.</h3>
  <center>
    <h3 className="w3-center w3-animate-zoom">🚫🚫🚫🚫</h3>
    <h6 className="w3-center w3-animate-zoom"><strong>Contacta al administrador</strong></h6>
    </center>
</div>    
        
      </div>  
    </div>
  

    {/* <div className='content'>
    <img 
    onClick={routeChange}
    className="mb-2" style={{width:"100%",height:'100%', display:'flex',alignItems:'center',justifyContent:'center'}} src={UNAUTHORIZED} alt="Logo"></img>
    </div>
     */}
  </div>



  
  )
}
