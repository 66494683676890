import React, { useEffect, useState, useRef } from 'react';
import * as FIIcons from "react-icons/fi";
import * as TIicons from "react-icons/ti";
import { Row, Col, Button, Modal, Form, InputGroup } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import "../../styles/RBCheckboxFormStyles.css";
import NotificationAlert from "react-notification-alert";
import { constaApi, constzipCodeApi } from '../../constants/constants';
import *  as FAIcons from "react-icons/fa";
import { loadColleges } from '../../helpers/collegesHelpers/loadColleges';
import { loadLocalColleges } from '../../helpers/collegesHelpers/loadColleges';
import { states as jsonState } from '../../MyJson/statesJson';
import { municipios } from '../../MyJson/municipiosJson';
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import { FaIcons } from 'react-icons/fa';
var _ = require('lodash');


function PersonalData(props) {
    useEffect(() => {
        setFilterValues(props.contact);
        consultStates();
        consultCountries();
        Promise.all([loadLocalColleges()])
            .then(function (result) {
                if (result) {
                    setLocalColleges(result[0]);
                }
            })
        Promise.all([loadColleges()])
            .then(function (result) {
                if (result) {
                    setForeignCol(result[0]);
                }
            })
        let other = municipios[0];
        let aux = [];
        if (props.contact.state) {
            Object.keys(other).map((name, i) => {
                if (name === props.contact.state) {
                    aux = other[name];
                }
            })
            setCities(aux);
            setCity(props.contact.city);
        }
    }, [props]);

   


    const [flagCountry, setFlagCoutnry] = useState({
        value: 'Pais',
        isChecked: false,
        label: 'Pais'
    });

    const collegesInitial = ( localCollegesArray ) => {

        let aux = [];
        localCollegesArray.map(loc => {
            if(loc.state != null){
                if(loc?.state?.toLowerCase() == props.contact?.state_school?.toLowerCase()){
                    aux.push(loc);
                }
            }
        })

        setUbicationColleges(aux);
    }

    // Family concepts



    const [modalFamily, setModalFamily] = useState(false);

    const idContact = props.contact.id ?? 0;

    const [contactItems, setContactItems] = useState([]);
    const [inputValue, setValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);

    const fullnameOtherSide =  props.name + " " + (props.father_lastname ? props.father_lastname + " " : " ") + (props.mother_lastname ? props.mother_lastname : " ");


    const [arrFamily, setArrayFamily] = useState(
        [
            {
                fullname:" ",
                id_family: 0,
                type: 'Hermano',
                id_own_contact: idContact,
                fullname_otherside :fullnameOtherSide
            }
        ]
    )



    // Family methods



        // handle input change event
        const handleInputChangeOptions = value => {

            // if value exists
            if (value) {
    
                // if value lenght is >= 3 
                if (value.length >= 3) {
                    // fetchContacts(value);
                }
            }
    
            setValue(value);
        };

    const saveFamily = async () =>{
        let datax = {
           family: arrFamily,
           fullname_otherside:fullnameOtherSide
        }
        await axios.post(constaApi + 'family/save', datax)
            .then(function (response) {
                if (response.status === 200) {
                    // props.handlerUpdate();
                    notification('success', 'Datos actualizados correctamente');
                    setTimeout(() => {
                        props.handleUpdate();
                    }, 1500);
                    // props.handleUpdate();

                } else {
                    notification('warning', 'Ocurrio un problema');
                }
            });


    }

    const handleChangeOptions = ( event ,position) => {
        const { value , label } = event;
        const list = [...arrFamily];
        list[position]["id_family"] = value;
        list[position]["fullname"] = label;

        setArrayFamily(list);
 
     }


    const selectContact = (event,position) => {
        const {value} = event.target;
        const list = [...arrFamily];
        list[position]["type"] = value;
        setArrayFamily(list);
    }

    const addMember = () => {
        setArrayFamily([...arrFamily, { fullname:"", id_family:0, type: "" , id_own_contact : idContact }]);
    }
    const deleteMember = () => { }


    const fetchOptions = async (value) => {
        if (value.length >= 3) {
            let data = "";
            await axios
                .get(constaApi + "search/selectContact/" + value, {
                    headers: {
                        Accept: "application/json",
                    },
                }).then((response) => {
                    data = response.data
                })
            return data;
        }
    }


    const promiseOptions = (value) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(fetchOptions(inputValue));
            }, 1000);
        });



    const [foreignCol, setForeignCol] = useState([]);
    const [optionscols, setCols] = useState([{ colonia: "" }]);
    const [muns, setMuns] = useState([]);
    const notificationAlert = useRef();
    const [directions, setDirections] = useState(props.contact.contacts_direction);
    const [phones, setPhones] = useState(props.contact.contacts_phones);
    const [emails, setEmails] = useState(props.contact.contacts_emails);
    const [contactSources, setContactSources] = useState(props.contact.sources);
    const [storyContact, setStoryContact] = useState(props.contact.story);
    const [inputList, setInputList] = useState([{ street: "", number: "", cp: "", city: "", state: "", typeAddress: "", country: "Mexico", otherDirection: "" }]);
    const [inputPhone, setInputPhone] = useState([{ phone: "", typePhone: "", otherPhone: "" }]);
    const [inputEmail, setInputEmail] = useState([{ email: "", typeEmail: "", otherEmail: "" }]);
    const [inputSource, setInputSource] = useState([{ name_recom: "", father_recom: "", mother_recom: "", type_source: "", option_source: "", college_source: "", other_source: "" }]);
    const [editInfo, setEditInfo] = useState(false);
    const [editAcademicProfile, setAcademicProfile] = useState(false);
    const [editStory, setEditStory] = useState(false);
    const [editDetails, setEditDetails] = useState(false);
    const [editDirection, setEditDirection] = useState(false);
    const [editFamily, setEditFamily] = useState(false);
    const [birthday, setBirthday] = useState();
    const [city, setCity] = useState();
    const [email, setEmail] = useState();
    const [fName, setFname] = useState();
    const [mName, setMname] = useState();
    const [sex,setSex] = useState();
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [schoool, setSchoool] = useState();
    const [other_School, setotherScho] = useState();
    const [grade, setGrade] = useState();
    const [cicly, setCicly] = useState();
    const [state, setState] = useState();
    const [state_school, setStateSchool] = useState();
    const [colName , setColName] = useState();
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState();
    const [localColleges, setLocalColleges] = useState([]);
    const [ubicationColleges, setUbicationColleges] = useState([]);
    const [nameRecom, setnameRecom] = useState();
    const [fatherRecom, setFatherRecom] = useState();
    const [motherRecom, setMotherRecom] = useState();
    const [modalRecomd, setModalRecomd] = useState(false);
    const sources = [
        'Recomendado por',
        'Internet',
        'Instagram',
        'Facebook',
        'Otro',
        'Forma de contacto'
    ];
    // Methods


    useEffect(() => {
        
        collegesInitial(localColleges);

    },[localColleges])
    
    const tagMun = (mun) => {
        let tag = '';
        return tag = <span><strong className="montse">Municipio: </strong>{mun}</span>;
    }
    const tagCOL = (col) => {
        let tag = '';
        return tag = <span><strong className="montse">Colonia: </strong>{col}     </span>;
    }
    const tagSpan = (num) => {
        let tag = '';
        return tag = <span><strong className="montse">Exterior: </strong>{num}</span>;
    }
    const tagSpanInt = (num) => {
        let tag = '';
        return tag = <span><strong className="montse">Interior: </strong>{num}</span>;
    }
    const callCP = async (index, val) => {
        await axios.get(constzipCodeApi + val + '?token=099f3303-f1b8-40c9-88e0-7d16c4d5c481', {
        }).then(function (response) {
            let { data } = response;
            if (data.error != true) {
                let colx = [];
                let resp = data.map(d => {
                    return d.response;
                })
                const list = [...inputList];
                list[index]['state'] = resp[0].estado === "Coahuila de Zaragoza" ? "Coahuila" : resp[0].estado;
                list[index]['city'] = resp[0].municipio;
                list[index]['col'] = resp[0].asentamiento;
                list[index]['mun'] = resp[0].municipio;
                setInputList(list);
                colx = resp.map(re => {
                    return { colonia: re.asentamiento };
                });
                setCols(colx);
            }
        });
    }
    const handleInputChangeSource = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputSource];
        list[index][name] = value;
        setInputSource(list);
    }
    const handleInputChangeEmail = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputEmail];
        list[index][name] = value;
        setInputEmail(list);
    };
    const handleInputTypeEmail = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputEmail];
        list[index][name] = value;
        setInputEmail(list);
    }
    const editRecomenBy = () => {
        setModalRecomd(!modalRecomd);
    }
    // handle click event of the Remove button
    const handleRemoveClickEmail = index => {
        let list = [...inputEmail];
        if (index === 0) {
            list = [{ email: "", typeEmail: "", otherEmail: "" }];
        } else {
            list.splice(index, 1);
        }
        setInputEmail(list);
    };

    // handle click event of the Add button
    const handleAddClickEmail = () => {
        setInputEmail([...inputEmail, { email: "" }]);
    };

    // ----------------------------------------------------------    
    const handleInputChangePhone = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputPhone];
        list[index][name] = value;
        setInputPhone(list);
    };

    // handle click event of the Remove button
    const handleRemoveClickPhone = index => {
        let list = [...inputPhone];
        if (index === 0) {
            list = [{ phone: "", typePhone: "", otherPhone: "" }];
        } else {
            list.splice(index, 1);
        }
        setInputPhone(list);
    };

    // handle click event of the Add button
    const handleAddClickPhone = () => {
        setInputPhone([...inputPhone, { phone: "" }]);
    };
    // ---------------------------------------------------------
    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        if (name === "cp" && value.length === 5) {
            callCP(index, value);
        }
        const list = [...inputList];
        if (name === "country") {
            list[index]['col'] = "";
            list[index]['mun'] = "";
        }
        list[index][name] = value;
        setInputList(list);
    };
    const handleRemoveSource = index => {
        let list = [...inputSource];
        if (index === 0) {
            list = [{ name_recom: "", father_recom: "", mother_recom: "", type_source: "", option_source: "", college_source: "", other_source: "" }];
        } else {
            list.splice(index, 1);
        }
        setInputSource(list);
    }

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        let list = [...inputList];
        if (index === 0) {
            list = [{ street: "", number: "", cp: "", city: "", state: "", typeAddress: "", country: "Mexico", otherDirection: "" }];
        } else {
            list.splice(index, 1);
        }
        setInputList(list);
    };

    const handleAddClickSource = () => {
        setInputSource([...inputSource, { name_recom: "", father_recom: "", mother_recom: "", type_source: "", option_source: "", college_source: "", other_source: "" }]);
    }
    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { street: "", country: "Mexico", number: "", cp: "" }]);
    };
    function changeCiti(e) {
        setCity(e.target.value);
    }
    function changeChecked() {
        let check = flagCountry.isChecked;
        check = check ? false : true;
        setFlagCoutnry({ ...flagCountry, isChecked: check });
    }
    // Api to cities
    async function changeCities(e, i = 0) {
        let val = e.target.value;
        if (val === undefined) {
            val = props.contact.state;
            if (val === undefined && inputList[i].state != null) {
                val = inputList[i].state;
            }
        } else {
            val = e.target.value;
        }
        if (e.target) {
            let val = e.target.value;
            let other = municipios[0];
            let aux = [];
            Object.keys(other).map((name, i) => {
                if (name === e.target.value) {
                    aux = other[name];
                }
            })
            setCity(aux[0]);
            setCities(aux);
            setState(e.target.value);
            // if (e.target.name) {
            //     handleInputChange(e, i);
            // }
        }
        // await axios.get('https://www.universal-tutorial.com/api/cities/' + val, {
        //     headers: {
        //         Authorization: 'Bearer ' + props.token,
        //         Accept: "application/json"
        //     }
        // }).then(function (response) {
        //     setCities(response.data);
        // });

    }
    // Api to states
    async function consultStates() {
        setStates(jsonState);
        // await axios.get('https://www.universal-tutorial.com/api/states/Mexico', {
        //     headers: {
        //         Authorization: 'Bearer ' + props.token,
        //         Accept: "application/json"
        //     }
        // }).then(function (response) {
        //     setStates(response.data);
        //     let obj = {
        //         target: {
        //             value: state
        //         }
        //     };
        //     changeCities(obj);
        // });
    }
    async function consultCountries() {
        await axios.get('https://restcountries.com/v3.1/all', {
            // headers: {
            //     Authorization: 'Bearer ' + auth,
            //     Accept: "application/json"
            // }
        }).then(async function (response) {
            let newCountries = await response.data.map( re => {
                return {
                    name: re.translations.spa.common
                }
            });
            let or = _.orderBy(newCountries,'name','asc');
            setCountries(or);
          
        });
    }

    function setFilterValues(props) {
        setBirthday(props.birthday);
        setEmail(props.email);
        setFname(props.father_lastname);
        setMname(props.mother_lastname);
        setSex(props.sex);
        setName(props.name);
        setPhone(props.phone);
        setSchoool(props.schoool);
        setState(props.state);
        setStateSchool(props.state_school)
        setGrade(props.grade);
        setCicly(props.cicly);
        setCountry(props.country);
        setotherScho(props.other_School);
        setnameRecom(props.name_recom);
        setFatherRecom(props.father_recom);
        setMotherRecom(props.mother_recom);
        setColName(props.id_college_vinculated);
        if(props.family.length > 0){
            setArrayFamily(props.family);
        }
        if (directions.length > 0) {
            setInputList(directions);
        }
        if (phones.length > 0) {
            setInputPhone(phones);
        }
        if (emails.length > 0) {
            setInputEmail(emails);
        }
        if (contactSources.length > 0) {
            setInputSource(contactSources);
        }
    }
    const { handleSubmit } = useForm({});

    function changeGrade(e) {
        setGrade(e.target.value);
    }
    function changeCicly(e) {
        setCicly(e.target.value);
    }
    function changeSchool(e) {
        setColName(e.target.value);
    }
    function changeStory(e){
        setStoryContact(e.target.value);
    }
    function changeOtherSchool(e) {
        setotherScho(e.target.value);
    }
    function changeName(e) {
        setName(e.target.value);
    }
    function changeFname(e) {
        setFname(e.target.value);
    }
    function changeMName(e) {
        setMname(e.target.value);
    }
    function changeSex(e){
        setSex(e.target.value)
    }
    function changeEmail(e) {
        setEmail(e.target.value)
    }
    function changePhone(e) {
        setPhone(e.target.value)
    }
    function changeCountries(e, i) {
        setCountry(e.target.value);
    }
    async function onSubmit(data) {
        let datax = {
            id: props.contact.id,
            father_lastname: fName,
            name: name,
            email: inputEmail,
            mother_lastname: mName,
            birthday: birthday,
            // city: city,
            phone: inputPhone,
            schoool: schoool,
            // state: state,
            cicly: cicly,
            grade: grade,
            state: state,
            city: city,
            country: country,
            direction: inputList,
            other_School: colName == 0 ? other_School : other_School,
            recom: inputSource,
            story:storyContact,
            sex:sex,
            id_college_vinculated : colName,
            state_school : state_school
        };
        await axios.post(constaApi + 'contact/update', datax)
            .then(function (response) {
                if (response.status === 200) {
                    notification('info', 'Datos actualizados correctamente');
                } else {
                    notification('danger', 'Ocurrio un error,por favor contacta a soporte');
                }
            });
        if (editInfo) {
            edit();
        } else {
            editCDetails();
        }
        props.handleUpdate();
    }
    function changeBirtday(e) {
        setBirthday(e.target.value);
    }
    function edit() {
        setEditInfo(!editInfo);
    }
    function editCDetails() {
        setEditDetails(!editDetails);
    }
    function editD() {
        if (inputList) {
            callCP(0, inputList[0].cp)
        }
        setEditDirection(!editDirection);
    }
    function changeNameRecom(e) {
        setnameRecom(e.target.value);
    }
    function changeFatherRecom(e) {
        setFatherRecom(e.target.value);
    }
    function changeMotherRecom(e) {
        setMotherRecom(e.target.value);
    }
    function editAcademicP() {
        setAcademicProfile(!editAcademicProfile);
    }
    const notification = (type, message) => {
        let place = "tc";
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        }
        notificationAlert.current.notificationAlert(options);
    }
    const showDate = (dateBD) => {
        moment.locale('es-mx')
        let datef = moment(dateBD).locale('es-mx').format("D MMMM, YYYY ");
        datef = datef[0].toUpperCase() + datef.slice(1);
        datef = datef.replace(".", "");
        let tag = <span className="montse" >{datef}</span>
        return tag;
    }
    const changeUbication = (e) => {
        let value = e.target.value;
        setStateSchool(e.target.value);
        let aux = [];
        localColleges.map(loc => {
            if(loc.state){
                if(loc.state.toLowerCase() == value.toLowerCase()){
                    aux.push(loc);
                }
            }
        })
        setColName(aux[0].id);
        setUbicationColleges(aux);
    }

    const findCorrectCollege = () => {
        const correctCollege = localColleges.find((college) => college.id == props.contact.id_college_vinculated);
        if(correctCollege){
            return correctCollege.name
        } else {
            return 'Cargando...';
        }
        
    }
    return (
        <>
            <NotificationAlert ref={notificationAlert} />
            {!editInfo ?
                <div style={{ boxShadow: 'none', borderColor: '#717d95' }} className="border mt-5 card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-11">
                                <h5 style={{ fontWeight: '600', fontSize: '20px' }} className="montse card-title">Información</h5>
                            </div>
                            <div style={{ marginRight: '-200px' }} className="col-1 d-flex justify-content-end">
                                <a>
                                    <FIIcons.FiEdit onClick={(e) => edit()} size={18} style={{ color: '#386CEF' }} />
                                </a>
                            </div>
                        </div>
                        <div className="row mt-2 ">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Nombre</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2 ">
                                    {props.contact.name}
                                </h6>
                            </div>
                        </div>
                        <div className="row mt-3 ">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Apellido paterno</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2 ">
                                    {props.contact.father_lastname}
                                </h6>
                            </div>
                        </div>
                        <div className="row mt-3 ">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Apellido materno</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2 ">
                                    {props.contact.mother_lastname}
                                </h6>
                            </div>
                        </div>

                        <div className="row mt-3 ">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Sexo</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '##243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2">
                                    {props.contact.sex}
                                </h6>
                            </div>
                        </div>

                        <div className="row mt-3 ">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Fecha de Nacimiento</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ letterSpacing: '1px', color: '##243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2">
                                    {props.contact.birthday &&
                                        [showDate(props.contact.birthday)]
                                    }
                                </h6>
                            </div>
                        </div>
                        <div className="row mt-3 ">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Lugar de Origen</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '##243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2">
                                    {props.contact.city ? " " + props.contact.city : ''}
                                    {props.contact.state ? ',' + " " + props.contact.state + ',' : ''}
                                    {props.contact.country ? " " + props.contact.country : ''}
                                </h6>
                            </div>
                        </div>

                       

                    </div>
                </div>
                :
                <div style={{ boxShadow: 'none', borderColor: '#717d95' }} className="border mt-5 card">
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-11">
                                    <h5 style={{ fontWeight: '600', fontSize: '20px' }} className="montse card-title">Información</h5>
                                </div>
                                <div style={{ marginRight: '-200px' }} className="col-1 d-flex justify-content-end">
                                    <button onClick={(e) => edit()} type="button" className="montse btn btnBeewhite">Cancelar</button>
                                    <button onSubmit={handleSubmit(onSubmit)}
                                        type="submit" className="montse ml-1 btn btn-info">Guardar</button>
                                </div>
                            </div>
                            <div className="row mt-3 ">
                                <div className="col-3">
                                    <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Nombre</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control autoComplete="off"
                                        onChange={(e) => changeName(e)} value={name}
                                        name="name"
                                        style={{ letterSpacing: '0.1px' }}
                                        className="formGray montse" type="text" placeholder="Ingrese su nombre" />
                                </div>
                            </div>
                            <div className="row mt-3 ">
                                <div className="col-3">
                                    <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Apellido paterno</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control autoComplete="off"
                                        onChange={(e) => changeFname(e)} value={fName}
                                        name="father_lastname"
                                        style={{ letterSpacing: '0.1px' }}
                                        className="formGray montse" type="text" placeholder="Ingrese su email" />
                                </div>
                            </div>
                            <div className="row mt-3 ">
                                <div className="col-3">
                                    <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Apellido materno</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control autoComplete="off"
                                        onChange={(e) => changeMName(e)} value={mName}
                                        name="mother_lastname"
                                        style={{ letterSpacing: '0.1px' }}
                                        className="formGray montse" type="text" placeholder="Ingrese su email" />
                                </div>
                            </div>

                            <div className="row mt-3 ">
                                <div className="col-3">
                                    <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Sexo</Form.Label>
                                </div>
                                <div className="col">

                                <Form.Control
                                onChange={(e) => changeSex(e)}
                                autoComplete="off" name="sex" value={sex} as="select" size="sm" custom>
                                <option disabled value="" selected></option>
                                <option value="Masculino" >Masculino</option>
                                <option value="Femenino" >Femenino</option>
                                <option value="Sin especificar" >Sin especificar</option>
                                </Form.Control>

                                </div>
                            </div>

                            <div className="row mt-3 ">
                                <div className="col-3">
                                    <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Fecha</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control autoComplete="off"
                                        onChange={(e) => changeBirtday(e)} value={birthday}
                                        name="birthday"
                                        className="formGray" type="date" placeholder="Ingrese su email" />
                                </div>
                            </div>





                            
                            

                            <div className="row mt-3 ">
                                <div className="col-3">
                                    <Form.Label style={{ fontSize: '16px' }} className="formGray">Pais</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control autoComplete="off"
                                        name="country"
                                        onChange={e => changeCountries(e)}
                                        value={country} as="select" size="sm" custom>
                                        <option disabled value="" selected></option>
                                        {countries.map(countri => (
                                                        <option ket={countri.name} value={countri.name}>
                                                            {countri.name}
                                                        </option>
                                                    ))} 
                                    </Form.Control>
                                </div>
                            </div>
                            <div className="row mt-3 ">
                                <div className="col-3">
                                    <Form.Label style={{ fontSize: '16px' }} className="formGray">Estado</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control autoComplete="off"
                                        name="state"
                                        onChange={e => changeCities(e, 0)}
                                        value={state} as="select" size="sm" custom>
                                        <option disabled value="" selected></option>
                                        {states.map(state => (
                                            <option key={state.clave} value={state.nombre}>
                                                {state.nombre}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </div>
                            </div>
                            <div className="row mt-3 ">
                                <div className="col-3">
                                    <Form.Label style={{ fontSize: '16px' }} className="formGray">Ciudad</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control autoComplete="off"
                                        name="city"
                                        onChange={e => changeCiti(e)}
                                        value={city} as="select" size="sm" custom>
                                        <option disabled value="" selected></option>
                                        {cities.map(city => (
                                            <option key={city} value={city}>
                                                {city}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </div>
                            </div>
                            <div className="row mt-3 ">
                                <div className="col-3">
                                    <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Nombre de el Recomendado</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control autoComplete="off"
                                        onChange={(e) => changeNameRecom(e)} value={nameRecom}
                                        name="name"
                                        style={{ letterSpacing: '0.1px' }}
                                        className="formGray montse" type="text" placeholder="Ingrese su nombre" />
                                </div>
                            </div>
                            <div className="row mt-3 ">
                                <div className="col-3">
                                    <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Apellido P. de el Recomendado</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control autoComplete="off"
                                        onChange={(e) => changeFatherRecom(e)} value={fatherRecom}
                                        name="name"
                                        style={{ letterSpacing: '0.1px' }}
                                        className="formGray montse" type="text" placeholder="Ingrese su nombre" />
                                </div>
                            </div>
                            <div className="row mt-3 ">
                                <div className="col-3">
                                    <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Apellido M de el Recomendado</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control autoComplete="off"
                                        onChange={(e) => changeMotherRecom(e)} value={motherRecom}
                                        name="name"
                                        style={{ letterSpacing: '0.1px' }}
                                        className="formGray montse" type="text" placeholder="Ingrese su nombre" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            }

            {!modalRecomd ?
                <div style={{ boxShadow: 'none', borderColor: '#717d95' }} className="border mt-3 card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <h5 style={{ fontWeight: '600', fontSize: '20px' }} className="montse card-title">Source </h5>
                            </div>
                            <div className="col-1 d-flex justify-content-end">
                                <a>
                                    <FIIcons.FiEdit onClick={(e) => editRecomenBy()} size={18} style={{ color: '#386CEF' }} />
                                </a>
                            </div>
                        </div>
                        {inputSource.map((x, i) => {
                            return (
                                <>
                                    <div className="row mt-2">
                                        <div className="col-3">
                                            <h6 className="montse card-subtitle mb-2 formGrayTwo">Tipo</h6>
                                        </div>
                                        <div className="col">
                                            <h6 style={{ color: '#243243', fontWeight: '600' }}
                                                className="montse card-subtitle mb-2 ">
                                                {x.type_source}
                                            </h6>
                                        </div>
                                    </div>
                                    {x.type_source == 'Recomendado por'
                                        &&
                                        <Row className="mt-2">
                                            <Col className="col-3">

                                            </Col>
                                            <Col>
                                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                                    className="montse card-subtitle mb-2 ">
                                                    {x.name_recom ? x.name_recom + " " : " "}
                                                    {x.father_recom ? x.father_recom + " " : " "}
                                                    {x.mother_recom ? x.mother_recom : " "}
                                                </h6>

                                            </Col>
                                        </Row>
                                    }
                                    {x.option_source &&
                                        <div className="row mt-3">
                                            <div className="col-3">
                                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Opcion</h6>
                                            </div>
                                            <div className="col">
                                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                                    className="montse card-subtitle mb-2 ">
                                                    {x.option_source}
                                                </h6>
                                            </div>
                                        </div>
                                    }
                                    {x.college_source &&
                                        <div className="row mt-3">
                                            <div className="col-3">
                                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Colegio</h6>
                                            </div>
                                            <div className="col">
                                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                                    className="montse card-subtitle mb-2 ">
                                                    {x.college_source}
                                                </h6>
                                            </div>
                                        </div>
                                    }
                                </>
                            )
                        })}
                    </div>
                </div>
                :
                <div style={{ boxShadow: 'none', borderColor: '#717d95' }} className="border mt-3 card">
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col">
                                    <h5 style={{ fontWeight: '600', fontSize: '20px' }} className="montse card-title">Source </h5>
                                </div>
                                <div className="col-1 d-flex justify-content-end">
                                    <button onClick={(e) => editRecomenBy()} type="button" className="montse btn btnBeewhite">Cancelar</button>
                                    <button onSubmit={handleSubmit(onSubmit)}
                                        type="submit" className="montse ml-1 btn btn-info">Guardar</button>
                                </div>
                            </div>
                            {inputSource.map((x, i) => {
                                return (
                                    <>
                                        <div className="row">
                                            <div className="col-3">
                                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Tipo</h6>
                                            </div>
                                            <div className="col-6">
                                                <Form.Control
                                                    onChange={(e) => handleInputChangeSource(e, i)}
                                                    autoComplete="off" name="type_source" value={x.type_source} as="select" size="sm" custom>
                                                    <option disabled value="" selected></option>
                                                    {sources.map(so => (
                                                        <option key={so} value={so}>
                                                            {so}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                                <div>
                                                </div>
                                                {x.type_source == 'Recomendado por'
                                                    &&
                                                    <Row>
                                                        <Col>
                                                            <Form.Label className="formGray">Nombre</Form.Label>
                                                            <Form.Control autoComplete="off" onChange={(e) => handleInputChangeSource(e, i)} name="name_recom" value={x.name_recom}
                                                                className="formGray" type="text" placeholder="Ingrese el nombre del Rec." />
                                                        </Col>
                                                        <Col>
                                                            <Form.Label className="formGray">Apellido P.</Form.Label>
                                                            <Form.Control autoComplete="off" onChange={(e) => handleInputChangeSource(e, i)} name="father_recom" value={x.father_recom}
                                                                className="formGray" type="text" placeholder="Ingrese su apellido p" />
                                                        </Col>
                                                        <Col>
                                                            <Form.Label className="formGray">Apellido M.</Form.Label>
                                                            <Form.Control autoComplete="off" onChange={(e) => handleInputChangeSource(e, i)} name="mother_recom" value={x.mother_recom}
                                                                className="formGray" type="text" placeholder="Ingrese su apellido m" />
                                                        </Col>
                                                    </Row>
                                                }
                                                {x.type_source == 'Otro'
                                                    &&
                                                    <Row>
                                                        <Col className="">
                                                            <Form.Label className="formGray">Otro</Form.Label>
                                                            <Form.Control autoComplete="off" onChange={(e) => handleInputChangeSource(e, i)} name="other_source" value={x.other_source}
                                                                className="formGray" type="text" placeholder="Especifique el source" />
                                                        </Col>
                                                    </Row>
                                                }
                                                {x.type_source == 'Forma de contacto'
                                                    &&
                                                    <Row className="mt-2">
                                                        <Col className="col-6">
                                                            <Form.Label className="formGray">Opcion</Form.Label>
                                                            <Form.Control
                                                                onChange={(e) => handleInputChangeSource(e, i)}
                                                                autoComplete="off" name="option_source" value={x.option_source} as="select" size="sm" custom>
                                                                <option disabled value="" selected></option>
                                                                <option value="General" >General</option>
                                                                <option value="Colegio" >Colegio</option>
                                                            </Form.Control>
                                                        </Col>
                                                    </Row>
                                                }
                                                {x.option_source == 'Colegio'
                                                    &&
                                                    <Row className="mt-2">
                                                        <Col className="col-8">
                                                            <Form.Control
                                                                onChange={(e) => handleInputChangeSource(e, i)}
                                                                autoComplete="off" name="college_source" value={x.college_source} as="select" size="sm" custom>
                                                                <option disabled value="" selected></option>
                                                                {foreignCol.map(fC => (
                                                                    <option key={fC.name} value={fC.name}>
                                                                        {fC.name}
                                                                    </option>
                                                                ))}
                                                            </Form.Control>
                                                        </Col>
                                                    </Row>
                                                }

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4 ">
                                                {inputSource.length !== 0 &&
                                                    <button type="button"
                                                        style={{ width: '12%' }}
                                                        onClick={() => handleRemoveSource(i)} className="montse ml-1 btn btn-danger btn-sm">
                                                        <FAIcons.FaTrashAlt style={{ color: 'white' }} size={18} />
                                                    </button>
                                                }
                                                {inputSource.length - 1 === i && <button onClick={handleAddClickSource}
                                                    type="submit"
                                                    style={{ width: '12%' }}
                                                    className="montse ml-1 btn btn-info btn-sm"><span style={{ fontSize: '18px' }} className="montse">+</span></button>
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </form>
                    </div>
                </div>
            }


            {!editStory ?
                <div style={{ boxShadow: 'none', borderColor: '#717d95' }} className="border card mt-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-11">
                                <h5 style={{ fontWeight: '600', fontSize: '20px' }} className="montse card-title">Story</h5>
                            </div>
                            <div style={{ marginRight: '-200px' }} className="col-1 d-flex justify-content-end">
                                <a>
                                    <FIIcons.FiEdit onClick={(e) => setEditStory(!editStory)} size={18} style={{ color: '#386CEF' }} />
                                </a>
                            </div>
                        </div>
                        <div className="row mt-3 ">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Story</h6>
                            </div>
                            <div className="col-6">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2 ">
                                    {props.contact.story}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="card">
                    <div style={{ boxShadow: 'none', borderColor: '#717d95' }} className="border card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-11">
                                    <h5 style={{ fontWeight: '600', fontSize: '20px' }} className="montse card-title">Story</h5>
                                </div>
                                <div style={{ marginRight: '-200px' }} className="col-1 d-flex justify-content-end">
                                    <button onClick={(e) => setEditStory(!editStory)}type="button" className="montse btn btnBeewhite">Cancelar</button>
                                    <button onSubmit={handleSubmit(onSubmit)}
                                        type="submit" className="montse ml-1 btn btn-info">Guardar</button>
                                </div>
                            </div>
                           
                            <div className="row mt-3 ">
                                <div className="col-3">
                                    <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Story</Form.Label>
                                </div>
                                <div className="col">
                                <Form.Control autoComplete="off"
                                                    onChange={(e) => changeStory(e)} value={storyContact}
                                                    name="Otro nombre"
                                                    className="formGray" type="text" placeholder="" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            }

            {!editAcademicProfile ?
                <div style={{ boxShadow: 'none', borderColor: '#717d95' }} className="border card mt-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-11">
                                <h5 style={{ fontWeight: '600', fontSize: '20px' }} className="montse card-title">Perfil académico</h5>
                            </div>
                            <div style={{ marginRight: '-200px' }} className="col-1 d-flex justify-content-end">
                                <a>
                                    <FIIcons.FiEdit onClick={(e) => editAcademicP()} size={18} style={{ color: '#386CEF' }} />
                                </a>
                            </div>
                        </div>
                        <div className="row mt-2 ">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Grado</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2 ">
                                    {props.contact.grade}
                                </h6>
                            </div>
                        </div>
                        <div className="row mt-3 ">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Ciclo escolar</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2 ">
                                    {props.contact.cicly}
                                </h6>
                            </div>
                        </div>
                        <div className="row mt-3 ">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Colegio</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2 ">
                                    {props.contact.id_college_vinculated == 0 ?
                                        [props.contact.other_School]
                                        :
                                        [findCorrectCollege(props.contact.id_college_vinculated)]
                                    }
                                </h6>
                            </div>
                        </div>

                    </div>
                </div>
                :
                <div className="card">
                    <div style={{ boxShadow: 'none', borderColor: '#717d95' }} className="border card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-11">
                                    <h5 style={{ fontWeight: '600', fontSize: '20px' }} className="montse card-title">Perfil académico</h5>
                                </div>
                                <div style={{ marginRight: '-200px' }} className="col-1 d-flex justify-content-end">
                                    <button onClick={(e) => editAcademicP()} type="button" className="montse btn btnBeewhite">Cancelar</button>
                                    <button onSubmit={handleSubmit(onSubmit)}
                                        type="submit" className="montse ml-1 btn btn-info">Guardar</button>
                                </div>
                            </div>
                            <div className="row mt-3 ">
                                <div className="col-3">
                                    <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Grado</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control
                                        onChange={(e) => changeGrade(e)} value={grade}
                                        autoComplete="off" name="grade" as="select" size="sm" custom>
                                        <option disabled value="" selected></option>
                                        <option value="Grado 1">Grado 1</option>
                                        <option>Grado 2</option>
                                        <option>Grado 3</option>
                                        <option>Grado 4</option>
                                        <option>Grado 5</option>
                                        <option>Grado 6</option>
                                        <option>Grado 7</option>
                                        <option>Grado 8</option>
                                        <option>Grado 9</option>
                                        <option>Grado 10</option>
                                        <option>Grado 11</option>
                                        <option>Grado 12</option>
                                    </Form.Control>
                                </div>
                            </div>
                            <div className="row mt-3 ">
                                <div className="col-3">
                                    <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Ciclo escolar</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control
                                        onChange={(e) => changeCicly(e)} value={cicly}
                                        autoComplete="off" name="cicly" as="select" size="sm" custom>
                                        <option disabled value="" selected></option>
                                        <option value="2015-2016">2015 - 2016</option>
                                        <option>2016 - 2017</option>
                                        <option>2017 - 2018</option>
                                        <option>2018 - 2019</option>
                                        <option>2019 - 2020</option>
                                        <option>2020 - 2021</option>
                                        <option>2021 - 2022</option>
                                        <option>2022 - 2023</option>
                                        <option>2023 - 2024</option>
                                        <option>2024 - 2025</option>
                                        <option>2025 - 2026</option>
                                        <option>2026 - 2027</option>
                                        <option>2027 - 2028</option>
                                        <option>2028 - 2029</option>
                                        <option>2029 - 2030</option>
                                        <option>2030 - 2031</option>
                                    </Form.Control>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className='col-3'>
                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Ubicación</Form.Label>
                                </div>
                                <div className='col'>
                                <Form.Control
                                        onChange={(e) => changeUbication(e)} 
                                        autoComplete="off"
                                        name="Colegio"
                                        className="formGray"
                                        value={state_school}
                                        as="select" size="sm" custom>
                                        <option value="" selected disabled></option>
                                        {states.map(state => (
                                            <option key={state.clave} value={state.nombre}>
                                                {state.nombre}
                                            </option>
                                        ))}
                                        <option key="Otro" value="Otro">Otro</option>
                                    </Form.Control>
                                </div>
                            </div>
                            <div className="row mt-3 ">
                                <div className="col-3">
                                    <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Colegio</Form.Label>
                                </div>
                                <div className="col">
                                    <Form.Control
                                        onChange={(e) => changeSchool(e)} value={colName}
                                        autoComplete="off"
                                        name="Colegio"
                                        className="formGray"
                                        as="select" size="sm" custom>
                                        <option value="" selected disabled></option>
                                        {ubicationColleges &&
                                            [ubicationColleges.map(colL => {
                                                return (
                                                    <option key={colL.id} value={colL.id}>
                                                        {colL.name}
                                                    </option>
                                                )
                                            })]
                                        }
                                        <option key="Otro" value= {0} >Otro</option>
                                    </Form.Control>
                                    <div className="mt-3 row">
                                        {colName == 0 &&
                                            <div className="col">
                                                <Form.Control autoComplete="off"
                                                    onChange={(e) => changeOtherSchool(e)} value={other_School}
                                                    name="Otro nombre"
                                                    className="formGray" type="text" placeholder="Ingrese el nombre de su colegio" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            }

            {!editDetails ?
                <div style={{ boxShadow: 'none', borderColor: '#717d95' }} className="border mt-3 card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <h5 style={{ fontWeight: '600', fontSize: '20px' }} className="montse card-title">Detalles de contacto</h5>
                            </div>
                            <div className="col-1 d-flex justify-content-end">
                                <a>
                                    <FIIcons.FiEdit onClick={(e) => editCDetails()} size={18} style={{ color: '#386CEF' }} />
                                </a>
                            </div>
                        </div>
                        {inputEmail.map((x, i) => {
                            return (
                                <div className="row mt-2 ">
                                    <div className="col-3">
                                        <h6 className="montse card-subtitle mb-2 formGrayTwo">Email {x.typeEmail}</h6>
                                    </div>
                                    <div className="col">
                                        <h6 style={{ color: '#243243', fontWeight: '600' }}
                                            className="montse card-subtitle mb-2 ">
                                            {x.email}
                                        </h6>
                                    </div>
                                </div>
                            );
                        })}
                        {inputPhone.map((x, i) => {
                            return (
                                <div className="row mt-3 ">
                                    <div className="col-3">
                                        <h6 className="montse card-subtitle mb-2 formGrayTwo">Teléfono {x.typePhone}</h6>
                                    </div>
                                    <div className="col">
                                        <h6 style={{ color: '#243243', fontWeight: '600' }}
                                            className="montse card-subtitle mb-2 ">
                                            {x.phone}
                                        </h6>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                :
                <div style={{ boxShadow: 'none', borderColor: '#717d95' }} className="border mt-3 card">
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col">
                                    <h5 style={{ fontWeight: '600', fontSize: '20px' }} className="montse card-title">Detalles de contacto</h5>
                                </div>
                                <div className="col-1 d-flex justify-content-end">
                                    <button onClick={(e) => editCDetails()} type="button" className="montse btn btnBeewhite">Cancelar</button>
                                    <button onSubmit={handleSubmit(onSubmit)}
                                        type="submit" className="montse ml-1 btn btn-info">Guardar</button>
                                </div>
                            </div>
                            <div className="d-flex justify-content-left">
                                <h6 >
                                    Email
                                </h6>
                            </div>
                            {inputEmail.map((x, i) => {
                                return (
                                    <div className="box">
                                        <div className="row mt-3 ">
                                            <div className="col-3">
                                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Tipo</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control
                                                    onChange={e => handleInputChangeEmail(e, i)}
                                                    value={x.typeEmail}
                                                    autoComplete="off"
                                                    name="typeEmail"
                                                    className="formGray"
                                                    as="select" size="sm" custom>
                                                    <option disabled value="" selected></option>
                                                    <option>Personal</option>
                                                    <option>Trabajo</option>
                                                    <option>Estudiantil</option>
                                                    <option>Otro</option>
                                                </Form.Control>
                                            </div>
                                            {x.typeEmail === 'Otro' &&
                                                <div className="col">
                                                    <Form.Control autoComplete="off"
                                                        onChange={e => handleInputChangeEmail(e, i)}
                                                        value={x.otherEmail}
                                                        name="otherEmail"
                                                        className="formGray" type="text" placeholder="Ingrese su tipo de email" />
                                                </div>
                                            }
                                        </div>
                                        <div className="row mt-3 ">
                                            <div className="col-3">
                                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Email</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control autoComplete="off"
                                                    onChange={e => handleInputChangeEmail(e, i)}
                                                    value={x.email}
                                                    name="email"
                                                    className="formGray" type="text" placeholder="Ingrese su Email" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4 ">
                                                {inputEmail.length !== 0 &&
                                                    <button type="button"
                                                        style={{ width: '12%' }}
                                                        onClick={() => handleRemoveClickEmail(i)} className="montse ml-1 btn btn-danger btn-sm">
                                                        <FAIcons.FaTrashAlt style={{ color: 'white' }} size={18} />
                                                    </button>
                                                }
                                                {inputEmail.length - 1 === i && <button onClick={handleAddClickEmail}
                                                    type="submit"
                                                    style={{ width: '12%' }}
                                                    className="montse ml-1 btn btn-info btn-sm"><span style={{ fontSize: '18px' }} className="montse">+</span></button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <Row>
                                <Col>
                                    <hr></hr>
                                    <h6 >
                                        Teléfono
                                    </h6>
                                </Col>
                            </Row>
                            {inputPhone.map((x, i) => {
                                return (
                                    <div className="box mt-1">

                                        <div className="row mt-3">
                                            <div className="col-3">
                                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Tipo</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control
                                                    autoComplete="off"
                                                    onChange={e => handleInputChangePhone(e, i)}
                                                    value={x.typePhone}
                                                    name="typePhone"
                                                    className="formGray" type="text"
                                                    as="select" size="sm" custom>
                                                    <option disabled value="" selected></option>
                                                    <option>Móvil</option>
                                                    <option>Personal</option>
                                                    <option>Trabajo</option>
                                                    <option>Otro</option>
                                                </Form.Control>
                                            </div>
                                            {x.typePhone === 'Otro' &&
                                                <div className="col">
                                                    <Form.Control autoComplete="off"
                                                        onChange={e => handleInputChangePhone(e, i)}
                                                        value={x.otherPhone}
                                                        name="otherPhone"
                                                        className="formGray" type="text" placeholder="Ingrese su tipo de teléfono" />
                                                </div>
                                            }
                                        </div>
                                        <div className="row mt-3 ">
                                            <div className="col-3">
                                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Teléfono</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control autoComplete="off"
                                                    onChange={e => handleInputChangePhone(e, i)}
                                                    value={x.phone}
                                                    name="phone"
                                                    className="formGray" type="text" placeholder="Ingrese su numero" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4 ">
                                                {inputPhone.length !== 0 &&
                                                    <button type="button"
                                                        style={{ width: '12%' }}
                                                        onClick={() => handleRemoveClickPhone(i)} className="montse ml-1 btn btn-danger btn-sm">
                                                        <FAIcons.FaTrashAlt style={{ color: 'white' }} size={18} />
                                                    </button>
                                                }
                                                {inputPhone.length - 1 === i && <button onClick={handleAddClickPhone}
                                                    type="submit"
                                                    style={{ width: '12%' }}
                                                    className="montse ml-1 btn btn-info btn-sm"><span style={{ fontSize: '18px' }} className="montse">+</span></button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </form>
                    </div>
                </div>
            }
            {!editDirection ?
                <div style={{ boxShadow: 'none', borderColor: '#717d95' }} className="border mt-3 card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <h5 style={{ fontWeight: '600', fontSize: '20px' }} className="montse card-title">Dirección</h5>
                            </div>
                            <div className="col-1 d-flex justify-content-end">
                                <a>
                                    <FIIcons.FiEdit onClick={(e) => editD()} size={18} style={{ color: '#386CEF' }} />
                                </a>
                            </div>
                        </div>
                        {inputList.map((x, i) => {
                            return (
                                <>
                                    <div className="row mt-3 ">
                                        <div className="col-3">
                                            <h6 className="montse card-subtitle mb-2 formGrayTwo">Dirección {x.typeAddress}</h6>
                                        </div>
                                        <h6 style={{ color: '#243243', fontWeight: '600' }}
                                            className="montse card-subtitle mb-2 ">
                                            {x.street} &nbsp;&nbsp;
                                            {x.extNum &&
                                                tagSpan(x.extNum)
                                            }
                                            &nbsp;
                                            {x.intNum &&
                                                tagSpanInt(x.intNum)
                                            }
                                        </h6>
                                    </div>
                                    <div className="row mr-5">
                                        <div className="col-3">

                                        </div>
                                        <div className="col">
                                            <h6 style={{ color: '#243243', fontWeight: '600' }}
                                                className="mt-2 montse card-subtitle mb-2 ">
                                                {x.col &&
                                                    tagCOL(x.col)
                                                }
                                                {x.mun &&
                                                    tagMun(x.mun)
                                                }
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="row mr-5">
                                        <div className="col-3">

                                        </div>
                                        <div className="col">
                                            <h6  style={{ color: '#243243', fontWeight: '600' }}
                                                className="mt-2 montse card-subtitle mb-2 ">
                                                {/* {props.contact.country ? props.contact.country : ''}
                                    {props.contact.state ? ',' + props.contact.state : ''}
                                    {props.contact.state ? ',' + props.contact.city : ''} */}
                                                {x.city ? x.city + ", " : ' '} {x.state ? x.state + ', ' : ' '}
                                                {x.country ? (x.country && x.state) ? x.country : ' ' : ' '}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="row mr-5">
                                        <div className="col-3">

                                        </div>
                                        <div className="col">
                                            <h6 style={{ color: '#243243', fontWeight: '600' }}
                                                className="mt-2 montse card-subtitle mb-2 ">
                                                {x.cp}
                                            </h6>
                                        </div>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
                :
                <div style={{ boxShadow: 'none', borderColor: '#717d95' }} className="border mt-3 card">
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col">
                                    <h5 style={{ fontWeight: '600', fontSize: '20px' }} className="montse card-title">Dirección</h5>
                                </div>
                                <div className="col-1 d-flex justify-content-end">
                                    <button onClick={(e) => editD()} type="button" className="montse btn btnBeewhite">Cancelar</button>
                                    <button onSubmit={handleSubmit(onSubmit)}
                                        type="submit" className="montse ml-1 btn btn-info">Guardar</button>
                                </div>
                            </div>
                            {inputList.map((x, i) => {
                                return (
                                    <div className="box">
                                        <div className="row mt-3">
                                            <div className="col-3">
                                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Tipo</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control autoComplete="off"
                                                    onChange={e => handleInputChange(e, i)}
                                                    value={x.typeAddress}
                                                    name="typeAddress"
                                                    className="informGray"
                                                    as="select" size="sm" custom>
                                                    <option disabled value="" selected></option>
                                                    <option>Trabajo</option>
                                                    <option>Casa</option>
                                                    <option>Negocio</option>
                                                    <option>Otro</option>
                                                </Form.Control>
                                            </div>

                                            {x.typeAddress === 'Otro' &&
                                                <div className="col">
                                                    <Form.Control autoComplete="off"
                                                        onChange={e => handleInputChange(e, i)}
                                                        value={x.otherDirection}
                                                        name="otherDirection"
                                                        className="informGray" type="text" placeholder="Ingrese su tipo de dirección" />
                                                </div>
                                            }
                                        </div>
                                        <div className="row mt-3 ">
                                            <div className="col-8">
                                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Calle</Form.Label>
                                                <InputGroup>
                                                    <Form.Control autoComplete="off"
                                                        onChange={e => handleInputChange(e, i)}
                                                        value={x.street}
                                                        name="street"
                                                        className="informGray" type="text" placeholder="Ingrese su Calle" />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text className="informGray" ><TIicons.TiHome /></InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </div>
                                            <div className="col-2">
                                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Numero exterior</Form.Label>
                                                <InputGroup>
                                                    <Form.Control autoComplete="off"
                                                        onChange={e => handleInputChange(e, i)}
                                                        value={x.extNum}
                                                        name="extNum"
                                                        className="informGray" type="text" placeholder="#" />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text className="informGray" ><TIicons.TiHome /></InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </div>
                                            <div className="col-2">
                                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Numero interior</Form.Label>
                                                <InputGroup>
                                                    <Form.Control autoComplete="off"
                                                        onChange={e => handleInputChange(e, i)}
                                                        value={x.intNum}
                                                        name="intNum"
                                                        className="informGray" type="text" placeholder="#" />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text className="informGray"><TIicons.TiHome /></InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col">
                                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Codigo postal</Form.Label>
                                                <InputGroup>
                                                    <Form.Control autoComplete="off"
                                                        onChange={e => handleInputChange(e, i)}
                                                        value={x.cp}
                                                        title="respeta el formato,solo numeros"
                                                        pattern="[0-9]{5}"
                                                        name="cp"
                                                        className="informGray" type="text" placeholder="Ingrese su codigo postal" />
                                                    <InputGroup.Append>
                                                        <InputGroup.Text className="informGray"><TIicons.TiHome /></InputGroup.Text>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </div>
                                            {x.country === "Mexico" &&
                                                <>
                                                    <div className="col">
                                                        <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Colonia</Form.Label>
                                                        <InputGroup>
                                                            <Form.Control onChange={e => handleInputChange(e, i)} autoComplete="off"
                                                                name="col"
                                                                value={x.col} as="select" size="sm" custom>
                                                                {optionscols.map(opcol => (
                                                                    <option key={opcol.opcolonia} value={opcol.colonia}>
                                                                        {opcol.colonia}
                                                                    </option>
                                                                ))}
                                                            </Form.Control>
                                                            <InputGroup.Append>
                                                            </InputGroup.Append>
                                                        </InputGroup>
                                                    </div>
                                                    <div className="col">
                                                        <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Delegacion o Municipio</Form.Label>
                                                        <InputGroup>
                                                            <Form.Control autoComplete="off"
                                                                onChange={e => handleInputChange(e, i)}
                                                                value={x.mun}
                                                                name="mun"
                                                                disabled
                                                                className="informGray" type="text" placeholder="Ingrese su Municipio" />
                                                            <InputGroup.Append>
                                                                <InputGroup.Text className="informGray"><TIicons.TiHome /></InputGroup.Text>
                                                            </InputGroup.Append>
                                                        </InputGroup>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        {x.country === 'Mexico' ?
                                            <>
                                                <div className="row mt-3 ">
                                                    <div className="col">
                                                        <Form.Label style={{ fontSize: '16px' }} className="formGray">Estado</Form.Label>
                                                        <Form.Control
                                                            className="informGray"
                                                            onChange={e => handleInputChange(e, i)}
                                                            autoComplete="off" name="state"
                                                            value={x.state} size="sm"
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Ciudad</Form.Label>
                                                        <Form.Control
                                                            className="informGray"
                                                            onChange={e => handleInputChange(e, i)}
                                                            autoComplete="off" name="city"
                                                            value={x.city} size="sm"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            // Manual 
                                            <>
                                                <div className="row mt-3 ">
                                                    <div className="col">
                                                        <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Ciudad</Form.Label>
                                                        <InputGroup>
                                                            <Form.Control
                                                                className="informGray"
                                                                onChange={e => handleInputChange(e, i)}
                                                                autoComplete="off" name="city"
                                                                value={x.city} size="sm"
                                                            />
                                                            <InputGroup.Append>
                                                                <InputGroup.Text className="informGray"><TIicons.TiHome /></InputGroup.Text>
                                                            </InputGroup.Append>
                                                        </InputGroup>
                                                    </div>
                                                    <div className="col">
                                                        <Form.Label style={{ fontSize: '16px' }} className="formGray">Estado</Form.Label>
                                                        <InputGroup>
                                                            <Form.Control autoComplete="off"
                                                                onChange={e => handleInputChange(e, i)}
                                                                value={x.state}
                                                                name="state"
                                                                className="informGray" type="text" placeholder="Ingrese su Estado" />
                                                            <InputGroup.Append>
                                                                <InputGroup.Text className="informGray"><TIicons.TiHome /></InputGroup.Text>
                                                            </InputGroup.Append>
                                                        </InputGroup>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <Row className="mt-3">
                                            <Col>
                                                <label className="custom-radio-checkbox">
                                                    <input className="custom-radio-checkbox__input"
                                                        value={flagCountry}
                                                        checked={flagCountry.isChecked} type="checkbox" onClick={(e) => changeChecked(e)} />
                                                    <span className="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
                                                    <span className="custom-radio-checkbox__text">Cambiar país</span>
                                                </label>
                                            </Col>
                                        </Row>
                                        {flagCountry.isChecked &&
                                            <div className="row mt-3 ">
                                                <div className="col-3">
                                                    <Form.Label style={{ fontSize: '16px' }} className="formGray">Pais</Form.Label>
                                                </div>
                                                <div className="col">
                                                    <Form.Control onChange={e => handleInputChange(e, i)} autoComplete="off"
                                                        name="country"
                                                        value={x.country} as="select" size="sm" custom>
                                                        <option disabled value="" selected></option>
                                                        {countries.map(countri => (
                                                            <option key={countri.name} value={countri.name}>
                                                                {countri.name}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                </div>
                                            </div>
                                        }
                                        <div className="row">
                                            <div className="col-4 ">
                                                {inputList.length !== 0 &&
                                                    <button type="button"
                                                        style={{ width: '12%' }}
                                                        onClick={() => handleRemoveClick(i)} className="montse ml-1 btn btn-danger btn-sm">
                                                        <FAIcons.FaTrashAlt style={{ color: 'white' }} size={18} />
                                                    </button>
                                                }
                                                {inputList.length - 1 === i && <button onClick={handleAddClick}
                                                    type="submit"
                                                    style={{ width: '12%' }}
                                                    className="montse ml-1 btn btn-info btn-sm"><span style={{ fontSize: '18px' }} className="montse">+</span></button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </form>
                    </div>
                </div>
            }

            {!editFamily ?
             <div style={{ boxShadow: 'none', borderColor: '#717d95' }} className="border mt-3 card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <h5 style={{ fontWeight: '600', fontSize: '20px' }} className="montse card-title">Familia</h5>
                            </div>
                            <div className="col-1 d-flex justify-content-end">
                                <a>
                                    <FIIcons.FiEdit onClick={(e) => setEditFamily(!editFamily)} size={18} style={{ color: '#386CEF' }} />
                                </a>
                            </div>
                        </div>
                        {arrFamily.map((x, i) => {
                            return (
                                <>
                                    <div className="row mt-3 ">
                                        <div className="col-3">
                                            <h6 className="montse card-subtitle mb-2 formGrayTwo">Nombre: {x.fullname}</h6>
                                        </div>
                                       
                                    </div>
                                    <div className="row mt-3 ">
                                        <div className="col-3">
                                            <h6 className="montse card-subtitle mb-2 formGrayTwo">Tipo: {x.type}</h6>
                                        </div>
                                       
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>

                :

                <div style={{ boxShadow: 'none', borderColor: '#717d95' }} className="border mt-3 card">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <h5 style={{ fontWeight: '600', fontSize: '20px' }} className="montse card-title">Familia</h5>
                        </div>
                        <div className="col-1 d-flex justify-content-end">
                                    <button onClick={(e) => setEditFamily(!editFamily)} type="button" className="montse btn btnBeewhite">Cancelar</button>
                                    <button onClick={(e) => saveFamily()} className="montse ml-1 btn btn-info">Guardar</button>
                        </div>
                    </div>


                    <div className='container'>
                            {arrFamily.map((member, i) => {
                                return (
                                    <>
                                        <div className='row mb-5'>
                                            <div className='col'>
                                                Contacto:
                                            </div>
                                            <div className='col-10'>

                                                <AsyncSelect
                                                    cacheOptions
                                                    defaultOptions
                                                    value = {{value: arrFamily[i].value, label: arrFamily[i].fullname}}
                                                    loadOptions={promiseOptions}
                                                    onInputChange={handleInputChangeOptions}
                                                    onChange={(e) => handleChangeOptions(e,i)}
                                                />

                                            </div>
                                        </div>
                                        <div className='row mb-5'>
                                            <div className='col-2'>
                                                Tipo:
                                            </div>
                                            <div className='col'>
                                                <Form.Control
                                                    onChange={(e) => selectContact(e,i)}
                                                    value={arrFamily[i].type}
                                                    autoComplete="off"
                                                    name="advisor"
                                                    // value={theUser}
                                                    as="select"
                                                    size="sm"
                                                    custom
                                                >
                                                    <option disabled value="" selected></option>
                                                    <option value="Hermano" >Hermano</option>
                                                    <option value="Hermana" >Hermana</option>
                                                    <option value="Primo" >Primo</option>
                                                    <option value="Prima" >Prima</option>

                                                </Form.Control>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-4 ">
                                                {arrFamily.length - 1 === i &&
                                                    <button
                                                        onClick={(arrFamily.length - 1) === i ? (e) => addMember(member, i) : deleteMember(member, i)}
                                                        type="submit" style={{ fontSize: '16px' }}
                                                        className="montse ml-1 btn btn-info btn-sm">
                                                        {(arrFamily.length - 1) === i ?
                                                            <span>+</span>
                                                            :
                                                            <FaIcons.FaTrashAlt style={{ color: 'white' }} size={18} />

                                                        }

                                                    </button>
                                                }
                                            </div>
                                           
                                        </div>


                                    </>
                                )
                            })}
                                           
                                  
                        </div>


                </div>
            </div>

            }
        </>
    )
}

export default PersonalData
