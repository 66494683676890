import React , { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet , Title } from '@react-pdf/renderer';

export default function Layout( props ) {

  const [user,setUser] = useState({
    title: ' Aplicante'
  });

  useEffect(() => {

    if(props.userToRender){

    
      setUser(props.userToRender);
    }
    return () => {}
  },[props])


    // Create styles

    const iscomplete = (user) =>{
      if(
        user.questionary__student__information.isComplete == 1 &&
        user.questionary__academic__information.isComplete == 1 &&
        user.questionary__family__information.isComplete == 1 &&
        user.questionary__student__profile.isComplete == 1
      ){
        return 'Complete'
      } else {
        return 'Incomplete'

      }

    }
const styles = StyleSheet.create({
  text : {
    marginBottom:'20px'
  },
    page: {
      flexDirection: 'column',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 5,
      flexGrow: 3
    }
  });




  return (
    <Document title='Aplicante'>
   {user.fullnameUser
   &&

   <Page size="A2" wrap="false" >
   <View >
   <Text style={styles.text}>Responsable : { user.fullnameUser} </Text>
   <Text style={styles.text}>Aplicante : { user.fullnameApplicant } </Text>
   <Text style={styles.text}>Email : { user.email}</Text>
   <Text style={styles.text}>Estado : { iscomplete(user)}</Text>
   </View>

   <View style={{marginTop:'20px',fontWeight:'700',border:'1px solid black',padding:'10px'}} >
   <Text>Sección Informacion del estudiante : </Text>
   <Text style={{marginntop:'5px'}}>Primer nombre  : {user.questionary__student__information.firstNameQuestion}</Text>
   <Text>Segundo nombre  : {user.questionary__student__information.secondNameQuestion}</Text>
   <Text>Apellido paterno  : {user.questionary__student__information.father_lastnameQuestion}</Text>
   <Text>Apellido materno  : {user.questionary__student__information.mother_lastnameQuestion}</Text>
   <Text>Fecha nacimiento  : {user.questionary__student__information.birthdayQuestion}</Text>
   <Text>Nacionalidad  : {user.questionary__student__information.nationalityQuestion}</Text>
   <Text>Email  : {user.questionary__student__information.emailQuestion}</Text>
   <Text>Celular  : {user.questionary__student__information.cellPhoneQuestion}</Text>
   {/* <Text>Direccion  : {user.questionary__student__information.firstNameQuestion}</Text> */}
   <Text>Calle y numero  : {user.questionary__student__information.streetQuestion }</Text>
   <Text>Colonia  : {user.questionary__student__information.colonyQuestion}</Text>
   <Text>Ciudad  : {user.questionary__student__information.cityQuestion}</Text>
   <Text>Estado  : {user.questionary__student__information.stateQuestion}</Text>
   <Text>Codigo postal  : {user.questionary__student__information.zipCodeQuestion}</Text>
   <Text>Pais  : {user.questionary__student__information.countryQuestion}</Text>
   </View>

   <View style={{marginTop:'20px',fontWeight:'700',border:'1px solid black',padding:'10px'}} >
     <Text>Sección Informacion Academica:</Text>
     <Text style={{marginTop:'5px'}}>Nombre colegio actual : {user.questionary__academic__information.currentCollegeName}</Text>
     <Text >Fecha ingreso a la institución  : {user.questionary__academic__information.yearGetIn}</Text>
     <Text >Idioma de instrucción en el colegio actual  : {user.questionary__academic__information.languagefromCurrentCollege}</Text>
     <Text >Dirección  : {user.questionary__academic__information.address}</Text>
     <Text >Nombre del director  : {user.questionary__academic__information.nameAdministratorCollege}</Text>
     <Text >Grado actual  : {user.questionary__academic__information.currentGrade}</Text>
     <Text >Grado que desea aplicar  : {user.questionary__academic__information.gradewished}</Text>
     <Text >Ciclo escolar que desea aplicar  : {user.questionary__academic__information.ciclyWished}</Text>
     <Text >Tiempo de estudio  : {user.questionary__academic__information.durationStudy}</Text>
     <Text >He repetido año ( año que repitio )  : {user.questionary__academic__information.yearRepetead}</Text>
     <Text >Nombre y grado de estudios en colegios anteriores  : {user.questionary__academic__information.nameAndGradeBefore}</Text>

   </View>


   <View style={{marginTop:'20px',fontWeight:'700',border:'1px solid black',padding:'10px'}} >
   <Text>Sección Informacion Familiar</Text>
   <Text style={{marginTop:'5px'}}>Guardian 1</Text>
   <Text style={{marginTop:'5px'}}>Nombre : {user.questionary__family__information.nameFirstWarden}</Text>
     <Text >Primer apellido  : {user.questionary__family__information.lastNameFirstWarden}</Text>
     <Text >Segundo apellido  : {user.questionary__family__information.secondLastNameFirstWarden}</Text>
     <Text >Relacion con el estudiante  : {user.questionary__family__information.relationshipWithStudentFirstWarden}</Text>
     <Text >Correo  : {user.questionary__family__information.emailFirstWarden}</Text>
     <Text >Celular  : {user.questionary__family__information.phoneFirstWarden}</Text>
     <Text >Ocupacion  : {user.questionary__family__information.jobFirstWarden}</Text>
     <Text >Empleador  : {user.questionary__family__information.employerFirstWarden}</Text>
     <Text >Estado civil  : {user.questionary__family__information.stateCivilFirstWarden}</Text>
     <Text >Direccion  : {user.questionary__family__information.addressFirstWarden}</Text>


     <Text style={{marginTop:'10px'}}>Guardian 2</Text>
   <Text style={{marginTop:'5px'}}>Nombre : {user.questionary__family__information.nameSecondWarden}</Text>
     <Text >Primer apellido  : {user.questionary__family__information.lastNameSecondWarden}</Text>
     <Text >Segundo apellido  : {user.questionary__family__information.secondLastNameSecondWarden}</Text>
     <Text >Relacion con el estudiante  : {user.questionary__family__information.relationshipWithStudentSecondWarden}</Text>
     <Text >Correo  : {user.questionary__family__information.emailSecondWarden}</Text>
     <Text >Celular  : {user.questionary__family__information.phoneSecondWarden}</Text>
     <Text >Ocupacion  : {user.questionary__family__information.jobSecondWarden}</Text>
     <Text >Empleador  : {user.questionary__family__information.employerSecondWarden}</Text>
     <Text >Estado civil  : {user.questionary__family__information.stateCivilSecondWarden }</Text>
     <Text >Direccion  : {user.questionary__family__information.addressSecondWarden}</Text>


     <Text style={{marginTop:'10px'}}>Hermano 1</Text>
   <Text style={{marginTop:'5px'}}>Nombre y apellidos : {user.questionary__family__information.nameBrothers}</Text>
   <Text >Edad  : {user.questionary__family__information.ageBrothers}</Text>
   <Text >Grado actual  : {user.questionary__family__information.currentGradeBrothers}</Text>



   <Text style={{marginTop:'10px'}}>Hermano 2</Text>
   <Text style={{marginTop:'5px'}}>Nombre y apellidos : {user.questionary__family__information.nameBrotherstwo}</Text>
   <Text >Edad  : {user.questionary__family__information.ageBrotherstwo}</Text>
   <Text >Grado actual  : {user.questionary__family__information.currentGradeBrotherstwo}</Text>



   <Text style={{marginTop:'10px'}}>Hermano 3</Text>
   <Text style={{marginTop:'5px'}}>Nombre y apellidos : {user.questionary__family__information.nameBrothersthree}</Text>
   <Text >Edad  : {user.questionary__family__information.ageBrothersthree}</Text>
   <Text >Grado actual  : {user.questionary__family__information.currentGradeBrothersthree}</Text>



   <Text style={{marginTop:'10px'}}>Hermano 4</Text>
   <Text style={{marginTop:'5px'}}>Nombre y apellidos : {user.questionary__family__information.nameBrothersfour}</Text>
   <Text >Edad  : {user.questionary__family__information.ageBrothersfour}</Text>
   <Text >Grado actual  : {user.questionary__family__information.currentGradeBrothersfour}</Text>


   </View>


   <View style={{marginTop:'20px',fontWeight:'700',border:'1px solid black',padding:'10px'}} >
     <Text>Sección Perfil del estudiante</Text>

     <Text style={{marginTop:'5px'}}>Dominio del idioma Inglés (Hablado y escrito)     : {user.questionary__student__profile.firstLanguage}</Text>
   <Text >Dominio del idioma Francés (Hablado y escrito)   : {user.questionary__student__profile.secondLanguage}</Text>
   <Text >Intereses académicos   : {user.questionary__student__profile.academicWishes}</Text>
   <Text >Deportes que practica   : {user.questionary__student__profile.sportTry}</Text>
   <Text >Actividades extracurriculares que realiza   : {user.questionary__student__profile.extraActivities}</Text>
   <Text >Intereses que tenga en el arte   : {user.questionary__student__profile.artWishes}</Text>



   </View>


 </Page>
 }
  </Document>
  )
}
