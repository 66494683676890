import axios from 'axios'
import { constaApi } from '../../constants/constants'
import React, { useEffect, useState } from 'react'
import TableAccountingProspection from './TableAccountingProspection.jsx';


function AccountingGeneral() {

  const[invoiceProspection,setInvoiceProspection] = useState([]);
  const[invoiceApplication,setInvoiceApplication] = useState([]);
  const[invoiceTracking,setInvoiceTracking] = useState([]);
  const [gridApi, setGridApi] = useState();
    const [columnApi, setColumnApi] = useState();

  useEffect(() => {
    getInvoices();
  },[])

  const getInvoices = () => {
    let prospection = [];
    let tracking = [];
    let application = [];
    axios.get(constaApi + 'getAllInvoices', {
      headers: {
          "Accept": "application/json"
      }
        }).then((response) => {
          response.data.map(el => {
            if(el.tp == 'prospeccion'){
              prospection.push(el);

            }
            else if(el.tp == 'aplicacion'){
              application.push(el);

            }else{
              tracking.push(el);

            }
            
          })
          setInvoiceApplication(application);
            setInvoiceProspection(prospection);
            setInvoiceTracking(tracking);
      })
     .catch((error)=>{
      
     })

    }

  const onGridReady = (params) => {
    setGridApi(params);
    setColumnApi(params);
}
  return (
    <>
    <div className="content">
    <TableAccountingProspection title={"Prospección"} invoice={invoiceProspection} />
    <TableAccountingProspection title={"Applicaciones"} invoice={invoiceApplication} />
    <TableAccountingProspection title={"Seguimientos"} invoice={invoiceTracking} />
    <div>
    </div>
   
    </div>
    </>
  )
}

export default AccountingGeneral