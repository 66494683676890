import React, { useState, useEffect, Suspense, useRef } from 'react'
import *  as FAIcons from "react-icons/fa";
import *  as RIIcons from "react-icons/ri";



import axios from 'axios';
import { useAlert } from 'react-alert'
import swal from 'sweetalert';
import moment from 'moment';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { constaApi } from '../../constants/constants';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';

// Component SLotName
export const SlotName = function SlotName(props) {
  const showName = (id = null, fullname = "") => {
    let n = fullname ? fullname.split(" ") : " ";
    let tag = '';
    if (n.length >= 3) {
      n = n[0].charAt(0) + n[1].charAt(0) + n[2].charAt(0);
    } else if (n.length >= 2) {
      n = n[0].charAt(0) + n[1].charAt(0);
    } else {
      n = n[0].charAt(0);
    }
    tag = <>
      <span className="mt-1 mr-2 sc-caSCKo fXbvQO styles__Company-sc-103gogw-0 jdbeFR">{n}</span>
      <span>{fullname} </span>
    </>;
    return tag;
  }
  return (
    <>
      <span>{showName(props.data.id, props.data.fullname)}</span>
    </>
  )
}

// -----------------------------End component SLotName

// Component SLotActions
export const SlotActions = function SlotActions(props) {

  function show_in_selects(obj){
    props.context.show_in_selects(obj);
  }
  function dropStaff(id) {
    props.context.deleteUser(id);
  }
  function editStaff(obj) {
    props.context.edit(obj);
  }

  function changePermissions(obj) {
    props.context.changePermissions(obj);

  }
  return (
    <>
      <a ><FAIcons.FaTrashAlt style={{ marginRight: '10px', color: '#FF0000' }} size={18} onClick={(e) => dropStaff(props.data.id)} /> </a>
      <a><FAIcons.FaRegEdit size={18} style={{ marginRight: '10px', color: '#497CFF' }} onClick={e => editStaff(props.data)} /> </a>
      <a><RIIcons.RiUserSettingsFill size={18} onClick={e => changePermissions(props.data)} /> </a>
      {
      props.data.status == 1 ?
      <a title="Inhabilitar" ><FAIcons.FaUnlock size={18} style={{ color:'#50b0fb'}} onClick={e => show_in_selects(props.data)} /> </a>
      :
      <a title="Habilitar"><FAIcons.FaLock size={18} style={{ color:'#13131f'}} onClick={e => show_in_selects(props.data)} /> </a>
    }


    </>
  )
}

// ----------------------------------------End component SlotACtions

// Component SlotCreated
export const SlotCreated = function SlotActions(props) {
  moment.locale('es-mx')

  const dateToMoment = (date) => {
    return moment(date).locale('es-mx').format("ddd D MMMM, YYYY HH:mm ");
  }
  return (
    <>
      <span>{dateToMoment(props.data.created_at)}</span>
    </>
  )
}

// ----------------------------------------End component SlotCreated

function TableUsers(props) {


  // Manage of States
  const [user, setUser] = useState();
  const [init] = useState(JSON.parse(localStorage.getItem('user')) || { logged: false });
  const notificationAlert = useRef();
  const [frameworkComponents, setFramwrokw] = useState({ slotName: SlotName, slotActions: SlotActions, slotCreated: SlotCreated });
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [state, setState] = useState({});

  const alert = useAlert();


  const [modalPermissons, setModalPermissons] = useState(false);

  // ComponenDidMount && ComponentDidUpdate
  useEffect(() => {
    if (props.param) {
      quickSearch(props.param);
    }
    setUser(props.rowData);
    return () => {
      setState({}); // This worked for me
    };
  }, [props]);


  // functions
  function quickSearch(value) {
    let objx = gridApi;
    value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
    setGridApi(objx);
  }
  function alertCustom(message, type) {
    alert.show(message, {
      timeout: 2000,
      type: type,
    });

  }
  const notification = (type, message) => {
    let place = "tc";
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    }
    notificationAlert.current.notificationAlert(options);
  }

  function show_in_selects(obj){

    const title = obj.status == 1 ? 'inhabilitara este usuario': 'Habilitara este usuario' ;


    swal({
      title: "Estas seguro? esta accion " + title,
      // text: title,
      icon: obj.status ==1 ? 'warning' : 'info',
      dangerMode: true,
      buttons: ["No", "Si"],
    })
      .then((willDelete) => {
        if (willDelete) {
          if (init.id === obj.id) {
            alertCustom('No puedes ajustar tu propio usuario, accion no permitida', 'error');
          } else {
            let datax = {...obj,status:obj.status == 1 ? 0 : 1};
            axios.post(constaApi+'users/update',datax)
            .then(function (response) {
                if(response.status === 200){
                    // props.handleupdateTable();
                    alert.show('Contacto Actualizado correctamente', {
                        timeout: 2000, // custom timeout just for this one alert
                        type: 'success'
                    })
                } else {
                    alert.show('Ocurrio un error por favor intentar mas tarde');
                }
            }).catch(error => {
                notification('warning','Algo salio mal');

            }).finally( order =>{
              props.handleupdateTable();
            });
          }
        } else {
          swal("Operacion cancelada!");
        }
      });

      props.handleupdateTable();

      
  }
  function deleteUser(id) {
    swal({
      title: "Estas seguro?",
      text: "Una vez eliminado,no podras recuperar este usuario!",
      icon: "warning",
      dangerMode: true,
      buttons: ["No", "Si"],
    })
      .then((willDelete) => {
        if (willDelete) {
          if (init.id === id) {
            alertCustom('Accion no permitida', 'error');
          } else {
            let datax = { id: id };
            axios.post(constaApi + 'users/delete', datax)
              .then(function (response) {
                if (response.status === 200) {
                  notification('success', 'Contacto eliminado correctamente');
                  props.handleupdateTable();
                }
              });
          }
        } else {
          swal("Operacion cancelada!");
        }
      });

  }
  const onGridReady = (params) => {
    setGridApi(params);
    setColumnApi(params);
  }

  const autoSizeAll = (skipHeader) => {
    var allColumnIds = [];
    columnApi.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    columnApi.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  };
  const onFirstDataRendered = (event) => {
    autoSizeAll(false);
  };
  const edit = (obj) => {
    props.clickHandler(obj);
  }

  const changePermissions = (data) => {
    props.parametersToModal(data);
  }
  return (
    <>
      {user ?
        <div className="content">
          <NotificationAlert ref={notificationAlert} />
          <div
            className="ag-theme-alpine"
            style={{ height: '100%', width: '100%' }}
          >
            <AgGridReact
              context={{
                deleteUser,
                edit,
                changePermissions,
                show_in_selects,
              }}
              defaultColDef={{ resizable: true }}
              rowData={user}
              rowHeight={40}
              domLayout="autoHeight"
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              frameworkComponents={frameworkComponents}
              pagination={true}
              paginationPageSize={10}
              paginationNumberFormatter={function (params) {
                return params.value.toLocaleString();
              }}
              rowSelection="multiple"
            >
              <AgGridColumn
                sortable={true}

                headerName="ID" field="id" width="100" />
              <AgGridColumn
                cellRenderer="slotName"
                sortable={true}

                headerName="Nombre" field="fullname" width="100" />
              <AgGridColumn headerName="Email" sortable={true}
                field="email" width="200" />
              <AgGridColumn headerName="Tipo" sortable={true}
                field="type" width="200" />
              <AgGridColumn headerName="Fecha creación" sortable={true}
                field="created_at" width="200" cellRenderer="slotCreated" />
              {init.type === 'Administrador'
                ?
                <AgGridColumn
                  headerName="Acciones"
                  width={300}
                  cellRenderer="slotActions"
                />
                :
                <></>
              }


            </AgGridReact>
          </div>

        </div>
        :
        ''
      }

    </>
  )
}

export default TableUsers
