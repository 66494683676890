import React from 'react'
import * as MDicons from "react-icons/md";
import { useHistory } from "react-router-dom";
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { articlesProspections } from '../staticsResources';

function ProspectionsFAQ(props) {

    const param = useParams();


    let history = useHistory();
    const { location } = history;
     const whereIam = location.pathname.split("/")[(location.pathname.split("/")).length - 1];
  return (
    <div 
    style={{
        height:'100%',
        width:'100%',
    }}
    className='content'>
        <button onClick={(e) => history.goBack()}>
        <MDicons.MdKeyboardBackspace size={24}/>
        </button>
    <div
    style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
    <h3>{articlesProspections.filter(art => art.id == whereIam)[0].title}</h3>
    </div>
    {/* Zona de interacción */}
    {/* Div de fondo donde iran las columnas y renglones */}
    <div class="container">

        {
            articlesProspections.filter(art => art.id == whereIam).map( art => {

                return(
                    <div>
                        { art.text }
                    </div>
                )
            })
        }
       
      {
        // props.articles.map(art => {
        //     return (
        //         <div>
        //           <Link id="faQ" to={"/FAQ/"+whereIam + "/"+art.id}>
        //             <span className='popularArticles'>
        //                 {art.name}
        //             </span>
        //           </Link>
        //         </div>
        //     )
        // })
      }
      

        
      </div>
    

    </div>
  )
}

export default ProspectionsFAQ