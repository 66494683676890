import React, { useState,useRef, useEffect } from 'react'
import {
    BrowserRouter as Router, Switch, Redirect,
    Route,useLocation
} from 'react-router-dom';
import Nav from "../../components/Nav/Nav";
import SideBar from '../../components/SideBar/new/SideBar';
import routes from "../../routes.js";
import { useParams } from "react-router";
import { useDispatch } from 'react-redux';
import { constaApi } from '../../constants/constants';
import axios from 'axios';
import TrackingSection from './TrackingSection';
import PermissionsHook from '../../customHook/PermissionsHook/PermissionsHook';



function TrackingRouter(props) {
    const dispatch = useDispatch();
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [backgroundColor, setBackGroundColor] = useState(JSON.parse(localStorage.getItem('bgColor')) || 'white');
    const [activeColor, setActiveColor] = useState(JSON.parse(localStorage.getItem('activeColor')) || 'info');
    const [user,setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const mainPanel = useRef();
   
    useEffect(() => {

      axios.get(constaApi + 'geTotalLeads', {
        headers: {
            "Accept": "application/json"
        }
    }).then(function (response) {
      const {data} = response;
      localStorage.setItem('countLeads', JSON.stringify(data));
    }).catch(error => {

        
    });
  })

  const finishLoading = () =>{
    setLoading(false);
  }
  const fromSidebar = () => {
    mainPanel.current.classList.toggle('withSidebar');
}
    return (
        <div className="wrapper">
          <SideBar
            {...props}
            routes={routes}
            bgColor={backgroundColor}
            activeColor={activeColor}
            fromSidebar={fromSidebar}

          />

{
             loading ? 
  
             <PermissionsHook  finishLoadin={finishLoading} module={'Seguimiento'} /> 
             
             :
             

        <div style={{backgroundColor:'#f9fafb'}}className="main-panel" ref={mainPanel}>
            <Nav {...props} />
            <Switch>
            <Route exact path="/Tracking"
            render={(props) => (
                <TrackingSection {...props}
                />
            )}
            />
           
            <Redirect to="/login" />
  </Switch>

        </div>
        
      }
      </div>


    )
}

export default TrackingRouter
