import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import axios from 'axios';
import { constaApi } from '../../constants/constants';
import swal from 'sweetalert';
import StarRatings from 'react-star-ratings';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from "react-csv";
var _ = require('lodash');

export default function TableHistory(props) {
  // Execute when the props change
  useEffect(() => {
    if (props.param) {
      quickSearch(props.param);
    }
  }, [props])

  //This function will execute when you open this section 
  useEffect(() => {
    loadApplications();
  }, [])

  const loadApplications = () => {
    axios.post(constaApi + 'allHistory')
      .then(function (response) {
        const { merge } = response.data
        checkValues(response.data.merge);
        // const result = _.groupBy(response.data,"name")
        // setAuxRow(result);
      }).catch(error => {

      });
  }
  const [frameworkComponents, setFramwrokw] = useState({ slotRating: SlotRating, slotName: SlotName, slotProspection: SlotProspection, slotOrigin: SlotOrigin, slotCollege: SlotCollege, slotCollegeTwo: SlotCollegeTwo });
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rows, setRows] = useState(null);
  const [auxRow, setAuxRow] = useState([]);
  const [newObj, setNewObj] = useState([]);
  const headers = [
    { label: "Nombre", key: "nameContact" },
    { label: "Programa", key: "name_prospection" },
    { label: "Ciudad", key: "colOrigin" },
    { label: "Colegio", key: "colMex" },
    { label: "Colegio Extranjero", key: "name" },
    { label: "País", key: "country" },
    { label: "Status", key: "status" },
    { label: "Advisor", key: "name_advisor" },
  ];
  const csvReport = {
    data: newObj,
    headers: headers,
    filename: 'HistoryReport.csv'
  };
  const [colors] = useState([
    'red', 'blue', 'green', 'gray', 'white'
  ]);
  const checkValues = (obj) => {
    let color = 0;
    let newObj = obj.map((o, index) => {
      if (index > 0) {
        if (obj[index - 1].name == o.name) {
          return {
            ...o,
            otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            colMex: (o.schoool != null && o.schoool != 'Otro') ? o.schoool : o.other_School ?? "" ,
            colExt: "RAUL",
            otherCity: o.city ? o.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            otherState: o.state ? o.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            repeat: true, color: color
          }
        } else {
          color = color == 0 ? -1 : 0;
          return {
            ...o,
            colMex: (o.schoool != null && o.schoool != 'Otro') ? o.schoool : o.other_School ?? "" ,
            colExt: o.local ? o.local == 0 ? o.name : "" : " ",
            otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            otherCity: o.city ? o.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            otherState: o.state ? o.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            origin: true, color: color
          }
        }
      } else {
        return {
          ...o,
          colMex: o.schoool ? o.schoool ?? o.other_School : "" ,
          colExt: o.local ? o.local == 0 ? o.name : "" : " ",
          otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          otherCity: o.city ? o.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
          otherState: o.state ? o.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
          origin: true, color: color
        };
      }
    })
    // ----------------
    newObj = newObj.map((o, index) => {
      if (index > 0) {
        if (newObj[index - 1].name == o.name && newObj[index - 1].name_prospection == o.name_prospection) {
          return {
            ...o,
            colMex: (o.schoool != null && o.schoool != 'Otro') ? o.schoool : o.other_School ?? "" ,
            colExt: o.local ? o.local == 0 ? o.name : "" : " ",
            otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            otherCity: o.city ? o.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            otherState: o.state ? o.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            prospectionRepeat: true
          }
        } else {
          color = color == 2 ? 3 : 2;
          return {
            ...o,
            colMex: (o.schoool != null && o.schoool != 'Otro') ? o.schoool : o.other_School ?? "" ,
            colExt: o.local ? o.local == 0 ? o.name : "" : " ",
            otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            otherCity: o.city ? o.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            otherState: o.state ? o.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            prospectionOrigin: true
          }
        }
      } else {
        return {
          ...o,
          colMex: (o.schoool != null && o.schoool != 'Otro') ? o.schoool : o.other_School ?? "" ,
          colExt: o.local ? o.local == 0 ? o.name : "" : " ",
          otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          otherCity: o.city ? o.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
          otherState: o.state ? o.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
          prospectionOrigin: true
        };
      }
    })
    //   --------------------
    if (newObj) {
      setRows(newObj);
      console.log('ROWS',rows);
    }

  }
  const quickSearch = async (value) => {
    let objx = gridApi;
    value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
    setGridApi(objx);
    let rowsDisplay = objx.api.rowRenderer.rowModel.rowsToDisplay;
    let result;
    if (rowsDisplay) {
      result = await rowsDisplay.map(row => {
        let { data } = row;
        return data;
      })
      setNewObj(result);
    }
  }
  const onGridReady = (params) => {
    setGridApi(params);
    setGridColumnApi(params);
  };
  const exporta = () => {
    if (newObj.length > 0) {
    } else {
      setNewObj(rows);
    }
  }
  return (
    <div className="content">
      <div>
        <button
          style={{ float: 'right', textDecoration: 'white' }}
          className="mt-n5 btn btn-sm btn-info" onClick={(e) => exporta(e)}
        >
          <CSVLink {...csvReport}><span>Exportar</span>
          </CSVLink>
        </button>
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: '100%', width: '100%' }}
      >
        <AgGridReact
          rowData={rows}
          rowHeight={40}
          cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
          domLayout="autoHeight"
          context={{
            loadApplications
          }}
          rowClassRules={{
            'colorGrayAG': function (params) {
              var backColor = params.data.color;
              return params.data.color === 0;
            },
            'colorWhiteAG': 'data.color === -1',

          }}
          onGridReady={onGridReady}
          suppressRowTransform={true}
          pagination={true}
          paginationPageSize={10}
          frameworkComponents={frameworkComponents}
          paginationNumberFormatter={function (params) {
            return params.value.toLocaleString();
          }}
          rowSelection="multiple"
        >
          <AgGridColumn
            field="nameContact"
            headerName="Nombre"
            cellRenderer="slotName"
            wrapText={true}
            filter="agTextColumnFilter"
            width={300}
          />
          <AgGridColumn
            field="otherName"
            headerName="Nombre"
            cellRenderer="slotName"
            wrapText={true}
            filter="agTextColumnFilter"
            width={300}
            hide={true}
          />

          <AgGridColumn
            headerName="Programa"
            field="name_prospection" width={250}
            wrapText={true}
            filter="agTextColumnFilter"
            cellRenderer="slotProspection"
          />
          <AgGridColumn
            cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
            headerName="Ciudad" sortable={true} field="city" width="200" cellRenderer="slotOrigin" />
          {/* <AgGridColumn
                            cellStyle={{ fontFamily:'Montserrat,sans-serif',fontSize:'13px',fontWeight:'500', color:'#3B3B3B'}}
                            headerName="colOrigin" sortable={true} field="colOrigin" width="200" /> */}
          <AgGridColumn
            cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
            headerName="Ciudad" hide={true} sortable={true} field="city" width="200" />
          <AgGridColumn
            cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
            headerName="Estado" hide={true} sortable={true} field="state" width="200" />
          <AgGridColumn
            cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
            headerName="Ciudad" hide={true} sortable={true} field="otherCity" width="200" />
          <AgGridColumn
            cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
            headerName="Estado" hide={true} sortable={true} field="otherState" width="200" />
          <AgGridColumn
            headerName="Colegio"
            filter="agTextColumnFilter"
            field="colMex" width={250}
            // cellRenderer="slotCollege"
          />
          <AgGridColumn
            headerName="C. Extranjero"
            filter="agTextColumnFilter"
            field="name"
            // cellRenderer="slotCollegeTwo"
            width={250} />
          <AgGridColumn
            headerName="Pais"
            filter="agTextColumnFilter"
            field="country" width={250} />

          <AgGridColumn
            headerName="Status"
            filter="agTextColumnFilter"
            field="status" width={250} />
          <AgGridColumn
            headerName="Advisor"
            filter="agTextColumnFilter"
            field="name_advisor" width={250} />
          {/* <span style={{textTransform:'none'}}>Nombre</span>
                        <span style={{textTransform:'none', paddingLeft:'18%'}}>Prospeccion</span>
                        <span style={{textTransform:'none',paddingLeft:'8%'}}>Colegio</span>
                        <span style={{textTransform:'none',paddingLeft:'8%'}}>Pais</span>
                        <span style={{textTransform:'none',paddingLeft:'12%'}}>Status</span>
                         </th>   
        */}
        </AgGridReact>
      </div>
    </div>
  )
}


export const SlotCollege = function SlotCollege(props) {
  const { city, state } = props.data;
  return (
    <>
      <span>{props.data.local ? props.data.local == 1 ? props.data.name : " " : " "}</span>
    </>
  )
}
export const SlotCollegeTwo = function SlotCollegeTwo(props) {
  const { city, state } = props.data;
  return (
    <>
      <span>{props.data.local ? props.data.local == 0 ? props.data.name : " " : " "}</span>

    </>
  )
}
export const SlotOrigin = function SlotOrigin(props) {
  const { city, state } = props.data;
  return (
    <>
      <span>{city ? city != 'NULL' ? city + ', ' : ', ' : ''}{state ? state != 'NULL' ? state : ' ' : ''}</span>
    </>
  )
}
export const SlotName = function SlotName(props) {
  const dispatch = useDispatch();
  const { rowIndex, value } = props
  const array = props.agGridReact.props.rowData;
  const click = (data) => {
    let newObj = { ...data, id: data.id_last_contact, fullname: data.name }
    // dispatch( activeContact(newObj) );
  }
  const showName = (obj) => {
    let text = " ";
    const { data } = obj;
    // if(data.origin === true){
    //     text = value;
    // } else {
    //   text = " ";
    // }
    text = value;

    return text;
  }
  return (
    <>
      <Link onClick={(e) => click(props.data)} to={"contacts/" + (props.data.id_last_contact) + "/Interdashboard"}>
        <span>{showName(props)}</span>
      </Link>
    </>
  )
}
export const SlotProspection = function SlotProspection(props) {
  const { rowIndex, value } = props
  const array = props.agGridReact.props.rowData;
  const showName = (obj) => {
    let text = " ";
    const { data } = obj;
    text = value;
    return text;
  }
  return (
    <>
      <span>{showName(props.data)}</span>
    </>
  )
}

export const SlotRating = function SlotRating(props) {
  const [rating, setRating] = useState(props.value ? parseInt(props.value) : 0);
  const changeRating = (e) => {
    let contact = props.data;
    contact.id = contact.id_last_contact;
    contact.rating = e;
    swal({
      title: "Estas seguro?",
      text: "Usted modificara la calificacion de este contacto",
      icon: "info",
      dangerMode: true,
      buttons: ["No", "Si"],
    })
      .then((willDelete) => {
        if (willDelete) {
          axios.post(constaApi + 'contact/update', contact, {
            headers: {
              "Accept": "application/json"
            }
          }).then(function (response) {
            props.context.loadApplications();
            //    loadRating();
          }).catch(error => {
          });
        } else {
          swal("Operacion cancelada!");
        }
      });
  }
  return (
    <>
      <StarRatings
        rating={rating}
        starDimension={'16px'}
        starEmptyColor={'gray'}
        starRatedColor={'#ffd200'}
        starHoverColor={'#ffd200'}
        starSpacing="2px"
        changeRating={(e) => changeRating(e)}
        numberOfStars={5}
        name='rating'
      />
    </>
  )
}