import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { constaApi } from '../../constants/constants';
import React, { useEffect, useState } from 'react'
import { SlotDate, SlotName, SlotNumber, SlotOrigin, SlotRating } from './TableProspection';
import { SlotProgram } from './TableRating';
import * as FaIcons from "react-icons/fa";
import swal from 'sweetalert';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import moment from 'moment';
var _ = require('lodash');


function TableVinculated(props) {
    const [auxRow, setAuxRow] = useState([]);
    const [newObj, setNewObj] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rows, setRows] = useState(null);
    const [vinculateds, setVinculateds] = useState([]);
    const [frameworkComponents, setFramwrokw] = useState({
        slotDate: SlotDate, slotRating: SlotRating, slotName: SlotName,
        slotNumber: SlotNumber, slotOrigin: SlotOrigin, slotProgram: SlotProgram,
        slotActions: SlotActions,slotStory :SlotStory,slotBitacora:SlotBitacora,slotReference:SlotReference
    });
    const [filterRating, setFilterRating] = useState(null);
    const filename = 'Vinculated_'+ moment().format('DD/MMMM/YYYY') + '.csv'



    const headers = [
      { label: "Nombre", key: "name" },
      { label: "Rating", key: "rating" },
      { label: "Prospeccion", key: "name_prospection" },
      { label: "Colegio Local", key: "colName" },
      { label: "Grado", key: "grade" },
      { label: "Ciudad", key: "city" },
      { label: "Estado", key: "state" },
      { label: "Substatus", key: "substatusFormated" },
      { label: "Status", key: "status" },
      { label: "Sexo", key: "sex" },
      { label: "Advisor", key: "name_advisor" },
      { label: "Creación", key: "created_at" },
      { label: "Actualización", key: "updated_at" },
    ];

    const csvReport = {
        data: newObj,
        headers: headers,
        filename: filename
      };

    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: "#",
            cellRenderer: 'slotNumber',
            wrapText: 'true',
            sortable: 'true',
            filter: "agTextColumnFilter",
            width: '50'
        },
        {
            headerName: "Nombre",
            field: "name",
            cellRenderer: 'slotName',
            wrapText: 'true',
            sortable: 'true',
            filter: "agTextColumnFilter",
            width: '300',
            //   getQuickFilterText: params => {
            //     return params.value.name;
            // }
        },
        {
            headerName: "Nombre sin acento",
            field: "otherName",
            cellRenderer: 'slotName',
            wrapText: 'true',
            sortable: 'true',
            filter: "agTextColumnFilter",
            width: '300',
            hide: "true"
        },
        {
            headerName: "Rating",
            field: "rating",
            cellRenderer: 'slotRating',
            wrapText: 'true',
            sortable: 'true',
            filter: "agTextColumnFilter",
            width: '250',
            filterParams: "ratingFilter"
        },
        {
            headerName: "Prospeccion",
            field: "name_prospection",
            sortable: 'true',
            filter: "agTextColumnFilter",
            width: '250',
        },
        {
            headerName: "Story",
            field: "story",
            sortable: 'true',
            filter: "agTextColumnFilter",
            width: '100',
            cellRenderer: 'slotStory',
        },
        {
            headerName: "Bitacora",
            field: "comments",
            filter: "agTextColumnFilter",
            width: '150',
            sortable: 'true',
            cellRenderer: 'slotBitacora',
        },
        {
            headerName: "Referencia",
            field: "contacts_references",
            filter: "agTextColumnFilter",
            width: '250',
            sortable: 'true',
            cellRenderer: 'slotReference',

        },
        {
            headerName: "Colegio",
            field: "colName",
            sortable: 'true',
            filter: "agTextColumnFilter",
            width: '150',
        },
        // {
        //   headerName: "Colegio",
        //   field: "otherSchool",
        //   filter: "agTextColumnFilter",
        //   width: '150',
        // },

        {
            headerName: "Grado",
            field: "grade",
            sortable: 'true',
            filter: "agTextColumnFilter",
            width: '150',
        },
        {
            headerName: "Ciudad",
            field: "ciy",
            sortable: "true",
            filter: "agTextColumnFilter",
            width: '200',
            cellRenderer: "slotOrigin",
            cellStyle: "fontFamily:'Montserrat,sans-serif',fontSize:'13px',fontWeight:'500', color:'#3B3B3B'"
        },
        {
            headerName: "Ciudad",
            field: "city",
            sortable: 'true',
            filter: "agTextColumnFilter",
            width: '150',
            hide: "true",

        },
        {
            headerName: "Estado",
            field: "state",
            sortable: 'true',
            filter: "agTextColumnFilter",
            width: '150',
        },
        {
            headerName: "Status",
            field: "status",
            sortable: 'true',
            filter: "agTextColumnFilter",
            width: '150',
        },
        {
            headerName: "Status",
            field: "otherStatus",
            filter: "agTextColumnFilter",
            width: '150',
            sortable: 'true',
            hide: 'true'
        },
        {
            headerName: "Acciones",
            width: '150',
            cellRenderer: 'slotActions'
        },
        {
            headerName: "Advisor",
            field: "name_advisor",
            filter: "agTextColumnFilter",
            width: '250',
            sortable: 'true',
            hide: 'true'
        },
        // {
        //   headerName: "Creacion",
        //   field: "created_at",
        //   width: '250',
        //   cellRenderer: 'slotDate',
        //   getQuickFilterText: params => {
        //     return params.value.name;
        //   }
        // },
        // {
        //   headerName: "Actualización",
        //   field: "updated_at",
        //   width: '250',
        //   cellRenderer: 'slotDate',
        //     getQuickFilterText: params => {
        //       return params.value.name;
        //   }
        // },
        {
            headerName: "Email",
            field: "email",
            width: '250',
            sortable: 'true',
            hide: true,
        },
        {
            headerName: "EmalRef1",
            field: "ref1",
            sortable: 'true',
            width: "150",
            hide: true,

        },
        {
            headerName: "EmalRef2",
            field: "ref2",
            sortable: 'true',
            width: "150",
            hide: true,

        },
        {
            headerName: "EmalRef3",
            field: "ref3",
            sortable: 'true',
            width: "150",
            hide: true,

        }
    ]);


    useEffect(() => {
        loadVinculateds();
    }, [])

    // Execute when the props change
    useEffect(() => {
        if (props.param) {
            quickSearch(props.param);
        }
    }, [props])


    const exporta = async () => {
        let {filter:value} = gridApi.api.filterManager.activeAdvancedFilters[0] ? gridApi.api.filterManager.activeAdvancedFilters[0].appliedModel : 'no';
        if(props.param){
          await quickSearch(props.param);
          if(filterRating != null && filterRating === value){
            let aux = [];
            await newObj.map(r =>{
              if(r.rating){
                if(r.rating == filterRating){
                  aux.push(r);
                }
              }
            })

            setNewObj(aux);
          } else {
            await quickSearch(props.param);
          }
        } else {
          if(filterRating != null && filterRating === value){
            let aux = [];
            await rows.map(r =>{
              if(r.rating){
                if(r.rating == filterRating){
                  aux.push(r);
                }
              }
            })
            setNewObj(aux);
          } else {
            if(newObj.length > 0){

            } else {
              setNewObj(vinculateds);
            }
          }
        }


      }


    const quickSearch = async (value) => {
        let objx = gridApi;
        value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
        setGridApi(objx);
        let rowsDisplay = objx.api.rowRenderer.rowModel.rowsToDisplay;
        let result;
        if (rowsDisplay) {
            result = await rowsDisplay.map(row => {
                let { data } = row;
                return data;
            })
            setNewObj(result);
        }

    }
    const loadVinculateds = () => {
        axios.post(constaApi + 'prospectionSectionAgregated')
            .then(function (response) {
                checkValues(response.data, 1);
                const result = _.groupBy(response.data, "name")
                setAuxRow(result);
            }).catch(error => {
            });
    }

    const checkValues = (obj, option = 0) => {

        let color = 0;
        let newObj = obj.map((o, index) => {
            if (index > 0) {
                if (obj[index - 1].name == o.name) {
                    return {
                        ...o,
                        otherName: o.name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                        otherStatus: o.status.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                        otherSchool: o.schoool ? o.schoool.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
                        repeat: true, color: color,
                        ref1: o.contacts_references[0] ? o.contacts_references[0].email : '',
                        ref2: o.contacts_references[1] ? o.contacts_references[1].email : '',
                        ref3: o.contacts_references[2] ? o.contacts_references[2].email : '',

                    }
                } else {
                    color = color == 0 ? -1 : 0;
                    return {
                        ...o,
                        otherName: o.name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                        otherStatus: o.status.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                        otherSchool: o.schoool ? o.schoool.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
                        origin: true, color: color,
                        ref1: o.contacts_references[0] ? o.contacts_references[0].email : '',
                        ref2: o.contacts_references[1] ? o.contacts_references[1].email : '',
                        ref3: o.contacts_references[2] ? o.contacts_references[2].email : '',
                    }
                }
            } else {
                return {
                    ...o,
                    otherName: o.name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                    otherStatus: o.status.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                    otherSchool: o.schoool ? o.schoool.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
                    origin: true, color: color,
                    ref1: o.contacts_references[0] ? o.contacts_references[0].email : '',
                    ref2: o.contacts_references[1] ? o.contacts_references[1].email : '',
                    ref3: o.contacts_references[2] ? o.contacts_references[2].email : '',
                };
            }
        })
        if (newObj) {
            if (option == 0) {
                setRows(newObj);
            } else {
                setVinculateds(newObj);
            }
        }

    }

    const onGridReady = (params) => {
        setGridApi(params);
        setGridColumnApi(params);
    };

    const desvinculate = (obj) => {
        swal({
            title: "¿Desea desvincular el registro?",
            text: "Esto eliminara cualquier relación",
            icon: "warning",
            dangerMode: false,
            buttons: ["No", "Si"],
        }).then(async (willDelete) => {
            if (willDelete) {
                let datax = {
                    id: obj.id,
                    agregated: false
                };
                 axios.post(constaApi + 'contact/update', datax)
                    .then(function (response) {
                        loadVinculateds();
                    });
            } else {
                swal("Operacion cancelada!");
            }
        });

}
return (
    <div>

    <div>
    <button
      style={{ float: 'right', textDecoration: 'white' }}
      className="mt-n5 btn btn-sm btn-info" onClick={(e) => exporta(e)}
    >
      <CSVLink {...csvReport}><span>Exportar</span>
      </CSVLink>
    </button>
  </div>
    <div
        className="ag-theme-alpine"
        style={{ marginTop: '-2%', height: '10%', width: '100%' }}>
        <h4>Vinculados</h4>
        <AgGridReact
            rowData={vinculateds}
            context={{
                desvinculate
                //    loadProspections
            }}
            rowHeight={40}
            cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
            domLayout="autoHeight"
            rowClassRules={{
                'colorGrayAG': function (params) {
                    var backColor = params.data.color;
                    return params.data.color === 0;
                },
                'colorWhiteAG': 'data.color === -1',
            }}
            onGridReady={onGridReady}
            suppressRowTransform={true}
            pagination={true}
            paginationPageSize={10}
            frameworkComponents={frameworkComponents}
            paginationNumberFormatter={function (params) {
                return params.value.toLocaleString();
            }}
            rowSelection="multiple"
            columnDefs={columnDefs}
        >
        </AgGridReact>
    </div>
    </div>

)
}

export default TableVinculated




// Component SlotCreated

export const SlotBitacora = (props) => {
    const {value} = props;

    const PopoverComponent = (text) => {
        return (<Popover id="popover-basic">
            <Popover.Content>
           <span>{text}</span>
            </Popover.Content>
        </Popover>)
      }

    return(
        <>
         <OverlayTrigger trigger={["hover", "hover"]} placement="top"
     overlay={PopoverComponent(value ? value.text : " Sin especificar ")}>
       <a>
             <svg width="16" height="16" viewBox="0 0 16 16" style={{ color: 'rgb(192, 203, 227)' }}>
                 <path fill="currentColor"
                     d="M9.944 0a.72.72 0 0 1 .511.213l4.333 4.364A.73.73 0 0 1 15 5.09v8.727C15 15.023 14.03 16 12.833 16H4.167A2.174 2.174 0 0 1 2 13.818V2.182C2 .977 2.97 0 4.167 0h5.777zm-.299 1.455H4.167a.725.725 0 0 0-.723.727v11.636c0 .402.324.727.723.727h8.666a.725.725 0 0 0 .723-.727V5.392l-3.91-3.937z"></path><path fill="currentColor" d="M10.667 4.364h3.61c.4 0 .723.325.723.727a.725.725 0 0 1-.722.727H9.944a.725.725 0 0 1-.722-.727V.727c0-.401.324-.727.722-.727.4 0 .723.326.723.727v3.637zM11.389 8c.399 0 .722.326.722.727a.725.725 0 0 1-.722.728H5.61a.725.725 0 0 1-.722-.728c0-.401.323-.727.722-.727h5.778zM11.389 10.91c.399 0 .722.325.722.726a.725.725 0 0 1-.722.728H5.61a.725.725 0 0 1-.722-.728c0-.401.323-.727.722-.727h5.778zM7.056 5.09c.398 0 .722.327.722.728a.725.725 0 0 1-.722.727H5.61a.725.725 0 0 1-.722-.727c0-.401.323-.727.722-.727h1.445z">
                 </path>
             </svg>
         </a>
       </OverlayTrigger>
        </>
    )

}

export const SlotReference = (props) =>  {
    return(
        <span>{props.value[0] && (props.value[0]?.name + " " + props.value[0]?.father_lastname + " " + (props.value[0]?.mother_lastname ? props.value[0].mother_lastname : " "))}</span>
    )
}

export const SlotStory = (props) => {
    const {value} = props;

    const PopoverComponent = (text) => {
        return (<Popover id="popover-basic">
            <Popover.Content>
           <span>{text}</span>
            </Popover.Content>
        </Popover>)
      }

    return(
        <>
         {value != null &&
         <OverlayTrigger trigger={["hover", "hover"]} placement="top"
         overlay={PopoverComponent(value)}>
           <a>
                 <svg width="16" height="16" viewBox="0 0 16 16" style={{ color: 'rgb(192, 203, 227)' }}>
                     <path fill="currentColor"
                         d="M9.944 0a.72.72 0 0 1 .511.213l4.333 4.364A.73.73 0 0 1 15 5.09v8.727C15 15.023 14.03 16 12.833 16H4.167A2.174 2.174 0 0 1 2 13.818V2.182C2 .977 2.97 0 4.167 0h5.777zm-.299 1.455H4.167a.725.725 0 0 0-.723.727v11.636c0 .402.324.727.723.727h8.666a.725.725 0 0 0 .723-.727V5.392l-3.91-3.937z"></path><path fill="currentColor" d="M10.667 4.364h3.61c.4 0 .723.325.723.727a.725.725 0 0 1-.722.727H9.944a.725.725 0 0 1-.722-.727V.727c0-.401.324-.727.722-.727.4 0 .723.326.723.727v3.637zM11.389 8c.399 0 .722.326.722.727a.725.725 0 0 1-.722.728H5.61a.725.725 0 0 1-.722-.728c0-.401.323-.727.722-.727h5.778zM11.389 10.91c.399 0 .722.325.722.726a.725.725 0 0 1-.722.728H5.61a.725.725 0 0 1-.722-.728c0-.401.323-.727.722-.727h5.778zM7.056 5.09c.398 0 .722.327.722.728a.725.725 0 0 1-.722.727H5.61a.725.725 0 0 1-.722-.727c0-.401.323-.727.722-.727h1.445z">
                     </path>
                 </svg>
             </a>
           </OverlayTrigger>
         }
        </>
    )

}


export const SlotActions = function SlotActions(props) {

    const desvinculate = (obj) => {
        props.context.desvinculate(obj);
    }

    return (
        <>
            <a>
                <FaIcons.FaExchangeAlt onClick={(e) => desvinculate(props.data)} size={16} color='#7F7FFF' />
            </a>
        </>
    )
}
