import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import axios from 'axios';
import { constaApi } from '../../constants/constants';
import swal from 'sweetalert';
import StarRatings from 'react-star-ratings';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from "react-csv";
import TableTotals from './TableTotals';
import TableSubstatus from './TableSubstatus.jsx';
// import '../../resources/css/main.css';
var _ = require('lodash');

export default function TableTracking(props) {
  // Execute when the props change
  useEffect(() => {
    if (props.param) {
      quickSearch(props.param);
    }
  },[props])

  // Execute when you open this section
  useEffect(() => {
   loadApplications();
  }, [])



  const loadApplications = () => {
    axios.post(constaApi + 'trackingSection')
    .then(function (response) {
      checkValues(response.data);
      const result = _.groupBy(response.data,"name")
      setAuxRow(result);
    }).catch(error => {
    });
  }
  const [ratingTotal, setRatingTotal] = useState([]);
  const [frameworkComponents, setFramwrokw] = useState({slotSubstatus:SlotSubstatus,slotRating: SlotRating ,slotName: SlotName, slotProspection: SlotProspection,slotOrigin:SlotOrigin });
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rows, setRows] = useState(null);
  const [auxRow,setAuxRow] = useState([]);
  const [newObj,setNewObj] = useState([]);
  const [filterRating,setFilterRating] = useState(null);
  const [rowsRating,setRowsRating] = useState([]);
  const [totalSum,setSumTotal] = useState([]);
  const [rowsOrder,setRowsOrder] = useState([]);

  useEffect(()=>{

    let orderBY = _.orderBy(rows, ['name_prospection'],
             ['asc']);
    // orderBy.push(props.totalSum);
    let newArr = [];
    newArr = orderBY;
    setRowsOrder(orderBY);

  },[rows])

    // Other method
    useEffect(() => {
      let totalRatings = [];
      let finalCo = { program: 'TOTAL', act: 0, mat: 0, fcp: 0, dic: 0, ene:0,scp:0,fdc:0,rev:0,ree:0,rec:0,ter:0,tC:0};
      const ratinGroup = _.groupBy(rows, "name_prospection")
      Object.keys(ratinGroup).map((name, i) => {
        let objRating = { program: name, act: 0, mat: 0, fcp: 0, dic: 0, ene:0,scp:0,fdc:0,rev:0,ree:0,rec:0,ter:0,tC:0};
        ratinGroup[name].map(rG => {
          switch (rG.APStatus) {
            case 'Activo':
              objRating.act = objRating.act + 1;
              break;
            case 'Materias':
              objRating.mat = objRating.mat + 1;
              break;
            case 'Fall Check Point':
              objRating.fcp = objRating.fcp + 1;
              break;
            case 'Diciembre':
              objRating.dic = objRating.dic + 1;
              break;
            case 'Enero':
              objRating.ene = objRating.ene + 1;
            case 'Spring Check Point':
              objRating.scp = objRating.scp + 1;
              break;
            case 'Fin de curso':
              objRating.fdc = objRating.fdc + 1;
              break;
            case 'Revalidación':
              objRating.rev = objRating.rev + 1;
            break;
            case 'Reembolso':
              objRating.ree = objRating.ree + 1;
              break;
            case 'Recomendacion':
              objRating.rec = objRating.rec + 1;
            break;
            case 'Termino':
              objRating.ter = objRating.ter + 1;
            break;
          }
        })
        objRating.tC = objRating.act + objRating.mat + objRating.fcp + objRating.dic + objRating.ene + objRating.scp + objRating.fdc + objRating.rev + objRating.ree + objRating.rec
        + objRating.ter;
        finalCo.act = finalCo.act + objRating.act;
        finalCo.mat = finalCo.mat + objRating.mat;
        finalCo.fcp = finalCo.fcp + objRating.fcp;
        finalCo.dic = finalCo.dic + objRating.dic;
        finalCo.ene = finalCo.ene + objRating.ene;
        finalCo.scp = finalCo.scp + objRating.scp;
        finalCo.fdc = finalCo.fdc + objRating.fdc;
        finalCo.rev = finalCo.rev + objRating.rev;
        finalCo.ree = finalCo.ree + objRating.ree;
        finalCo.rec = finalCo.rec + objRating.rec;
        finalCo.ter = finalCo.ter + objRating.ter;
        finalCo.tC = finalCo.act + finalCo.mat + finalCo.fcp + finalCo.dic + finalCo.ene + finalCo.scp + finalCo.fdc + finalCo.rev + finalCo.ree + finalCo.rec
        + finalCo.ter;
        totalRatings.push(objRating);
  });
  setSumTotal(finalCo);
  setRatingTotal(totalRatings);
  setRowsRating(totalRatings);
  },[rows])

  const headers = [
    { label: "Nombre", key: "nameContact" },
    { label: "Rating", key: "rating"},
    { label: "Prospeccion", key: "name_prospection" },
    { label: "Colegio", key: "colName" },
    { label: "Pais", key: "colCountry" },
    { label: "Ciudad", key: "city" },
    { label: "Estado", key: "state" },


    { label: "Colegio Local", key: "localCollege" },
    { label: "Status", key: "APStatus" },
    { label: "Substatus", key: "substatusFormated" },
    { label: "Sexo", key: "sex" },
    { label: "Advisor", key: "name_advisor" },
  ];
  const csvReport =  {
    data: newObj,
    headers: headers,
    filename: 'Trackingsreport.csv'
  };
  const [colors] = useState([
    'red', 'blue', 'green', 'gray', 'white'
  ]);
  const checkValues = (obj) => {
    let color = 0;
    let newObj = obj.map((o,index) => {
      if(index > 0){
        if(obj[index - 1 ].name == o.name){
          return {...o,
            otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            otherCity: o.city ? o.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            otherState: o.state ? o.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            substatusFormated : o.substatus ? 'Substatus ' + o.substatus : "",
            localCollege: o.schoool == "Otro" ? o.other_School : o.schoool,
            repeat:true,color:color}
        } else {
          color = color == 0 ? -1 : 0;
          return {...o,
            otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            otherCity: o.city ? o.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            otherState: o.state ? o.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            substatusFormated : o.substatus ? 'Substatus ' + o.substatus : "",
            localCollege: o.schoool == "Otro" ? o.other_School : o.schoool,
            origin:true,color:color}
        }
      } else {
        return {...o,
          otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          otherCity: o.city ? o.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
          otherState: o.state ? o.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
          substatusFormated : o.substatus ? 'Substatus ' + o.substatus : "",
          localCollege: o.schoool == "Otro" ? o.other_School : o.schoool,
          origin:true,color:color};
      }
    })
    // ----------------
     newObj = newObj.map((o,index) => {
        if(index > 0){
          if(newObj[index - 1 ].name == o.name && newObj[index - 1 ].name_prospection == o.name_prospection){
            return {...o,
              otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
              otherCity: o.city ? o.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
              otherState: o.state ? o.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
              localCollege: o.schoool == "Otro" ? o.other_School : o.schoool,
              prospectionRepeat:true}
          } else {
            color = color == 2 ? 3 : 2;
            return {...o,
              otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
              otherCity: o.city ? o.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
              otherState: o.state ? o.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
              localCollege: o.schoool == "Otro" ? o.other_School : o.schoool,
              prospectionOrigin:true}
          }
        } else {
          return {...o,
            otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            otherCity: o.city ? o.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            otherState: o.state ? o.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            localCollege: o.schoool == "Otro" ? o.other_School : o.schoool,
            prospectionOrigin:true};
        }
      })
    //   --------------------
    if(newObj){
      setRows(newObj);
    }

  }
  const quickSearch = async (value) => {
    let objx = gridApi;
    value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
    setGridApi(objx);
    let rowsDisplay = objx.api.rowRenderer.rowModel.rowsToDisplay;
    let result ;
    if(rowsDisplay){
      result = await rowsDisplay.map(row => {
       let {data} = row;
       return data;
     })
     setNewObj(result);
    }
  }
  const onGridReady = (params) => {
    setGridApi(params);
    setGridColumnApi(params);
  };
  const exporta = async () => {
    let {filter:value} = gridApi.api.filterManager.activeAdvancedFilters[0] ? gridApi.api.filterManager.activeAdvancedFilters[0].appliedModel : 'no';
    if(props.param){
      await quickSearch(props.param);
      if(filterRating != null && filterRating === value){
        let aux = [];
        await newObj.map(r =>{
          if(r.rating){
            if(r.rating == filterRating){
              aux.push(r);
            }
          }
        })
        setNewObj(aux);
      } else {
        await quickSearch(props.param);
      }
    } else {
      if(filterRating != null && filterRating === value){
        let aux = [];
        await rows.map(r =>{
          if(r.rating){
            if(r.rating == filterRating){
              aux.push(r);
            }
          }
        })
        setNewObj(aux);
      } else {
        if(newObj.length > 0){
          // console.log('newObj1',newObj);
        } else {
          setNewObj(rows);
        }
      }
    }

  }
  const ratingFilter = {
    textCustomComparator: (filter, value, filterText) => {
        const filterTextLowerCase = filterText.toLowerCase();
        const valueLowerCase = value.toString().toLowerCase();
        if(filterRating == null){
          setFilterRating(filterTextLowerCase);
        }
        else {
          if(filterRating != filterTextLowerCase){
            setFilterRating(filterTextLowerCase);
          }
        }
        switch (filter) {
            case 'contains':
                return valueLowerCase.indexOf(filterTextLowerCase) >= 0;
            case 'notContains':
                return valueLowerCase.indexOf(filterTextLowerCase) === -1;
            case 'equals':
                return valueLowerCase === filterTextLowerCase;
            case 'notEqual':
                return valueLowerCase != filterTextLowerCase;
            case 'startsWith':
                return valueLowerCase.indexOf(filterTextLowerCase) === 0;
            case 'endsWith':
                var index = valueLowerCase.lastIndexOf(filterTextLowerCase);
                return index >= 0 && index === (valueLowerCase.length - filterTextLowerCase.length);
            default:
                // should never happen
                console.warn('invalid filter type ' + filter);
                return false;
        }
    }
};

const onCellValueChanged = () => {

  let objx = gridApi;
  let rowsDisplay = objx?.api?.rowRenderer?.rowModel?.rowsToDisplay;
  let result ;
  if(rowsDisplay){
    result =  rowsDisplay.map(row => {
     let {data} = row;
     return data;
   })

   setNewObj(result);
  }
}

  return (
    <div className="content">
       <div>
      <button
      style={{float:'right',textDecoration:'white'}}
      className="mt-n5 btn btn-sm btn-info" onClick={(e) => exporta(e)}
      >
        <CSVLink {...csvReport}><span>Exportar</span>
        </CSVLink>
        </button>
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: '100%', width: '100%' }}
      >
         <AgGridReact
           rowData={rows}
           rowHeight={40}
           cellStyle={{ fontFamily:'Montserrat,sans-serif',fontSize:'13px',fontWeight:'500', color:'#3B3B3B'}}
           domLayout="autoHeight"
           context={{
            loadApplications
           }}
           rowClassRules={{
            'colorGrayAG': function (params) {
              var backColor = params.data.color;
              return params.data.color === 0 ;
            },
            'colorWhiteAG': 'data.color === -1',

          }}
           onGridReady={onGridReady}
           onModelUpdated={onCellValueChanged}
          onCellValueChanged={onCellValueChanged}
           suppressRowTransform={true}
           pagination={true}
           paginationPageSize={10}
           frameworkComponents={frameworkComponents}
           paginationNumberFormatter={function (params) {
               return params.value.toLocaleString();
           }}
           rowSelection="multiple"
        >
          <AgGridColumn
            field="nameContact"
            headerName="Nombre"
            cellRenderer="slotName"
            sortable={true}
            wrapText={true}
            filter="agTextColumnFilter"
            width={300}
          />
          <AgGridColumn
            field="otherName"
            headerName="Nombre"
            sortable={true}
            cellRenderer="slotName"
            wrapText={true}
            filter="agTextColumnFilter"
            width={300}
            hide={true}
          />
          <AgGridColumn
            field="rating"
            headerName="Rating"
            cellRenderer="slotRating"
            wrapText={true}
            sortable={true}
            filter="agTextColumnFilter"
            filterParams={ratingFilter}
            width={200}
          />
          <AgGridColumn
          headerName="Prospeccion"
          sortable={true}
          field="name_prospection" width={250}
          wrapText={true}
          filter="agTextColumnFilter"
          cellRenderer="slotProspection"
          />
            <AgGridColumn
          headerName="Colegio"
          sortable={true}
          filter="agTextColumnFilter"
          field="colName" width={250} />
           <AgGridColumn
          headerName="Pais"
          sortable={true}
          filter="agTextColumnFilter"
          field="colCountry" width={250} />
          <AgGridColumn
                            cellStyle={{ fontFamily:'Montserrat,sans-serif',fontSize:'13px',fontWeight:'500', color:'#3B3B3B'}}
                            headerName="Ciudad" sortable={true} field="city" width="200"  />
                             {/* <AgGridColumn
                            cellStyle={{ fontFamily:'Montserrat,sans-serif',fontSize:'13px',fontWeight:'500', color:'#3B3B3B'}}
                            headerName="colOrigin" sortable={true} field="colOrigin" width="200" /> */}
                              <AgGridColumn
                            cellStyle={{ fontFamily:'Montserrat,sans-serif',fontSize:'13px',fontWeight:'500', color:'#3B3B3B'}}
                            headerName="Ciudad" hide={true} sortable={true} field="city" width="200"  />
                              <AgGridColumn
                            cellStyle={{ fontFamily:'Montserrat,sans-serif',fontSize:'13px',fontWeight:'500', color:'#3B3B3B'}}
                            headerName="Estado"  sortable={true} field="state" width="200"  />
                              <AgGridColumn
                            cellStyle={{ fontFamily:'Montserrat,sans-serif',fontSize:'13px',fontWeight:'500', color:'#3B3B3B'}}
                            headerName="Ciudad" hide={true} sortable={true} field="otherCity" width="200"  />
                              <AgGridColumn
                            cellStyle={{ fontFamily:'Montserrat,sans-serif',fontSize:'13px',fontWeight:'500', color:'#3B3B3B'}}
                            headerName="Estado"  hide={true}sortable={true} field="otherState" width="200"  />

         <AgGridColumn
          headerName="Colegio Local"
          filter="agTextColumnFilter"
          field="localCollege" width={250} />

           <AgGridColumn
          headerName="Status"
          filter="agTextColumnFilter"
          field="APStatus" width={250} />

<AgGridColumn
          headerName="Substatus"
          filter="agTextColumnFilter"
          field="substatusFormated" width={250} />


           <AgGridColumn
          headerName="Advisor"
          filter="agTextColumnFilter"
          field="name_advisor" width={250} />

<AgGridColumn
          headerName="Sexo"
          filter="agTextColumnFilter"
          field="sex" width={250} />


            {/* <span style={{textTransform:'none'}}>Nombre</span>
                        <span style={{textTransform:'none', paddingLeft:'18%'}}>Prospeccion</span>
                        <span style={{textTransform:'none',paddingLeft:'8%'}}>Colegio</span>
                        <span style={{textTransform:'none',paddingLeft:'8%'}}>Pais</span>
                        <span style={{textTransform:'none',paddingLeft:'12%'}}>Status</span>
                         </th>
        */}
        </AgGridReact>
      </div>
      <TableTotals totalSum={totalSum} rowsRating={rowsRating} />
      <div className='mt-5'>
        <h3>Substatus</h3>
      <TableSubstatus rows={rowsOrder} />
      </div>
    </div>
  )
}

export const SlotSubstatus = function SlotSubstatus(props) {
  return (
      <>
          <span>PARANGUATIRIMICARO</span>
      </>
  )
}

export const SlotOrigin = function SlotOrigin(props) {
  const { city, state } = props.data;
  return (
      <>
          <span>{city ? city != 'NULL' ? city + ', ' : ', ': ''}{state ? state != 'NULL' ?  state  : ' ': ''}</span>
      </>
  )
}
export const SlotName = function SlotName(props) {
  const dispatch = useDispatch();
 const {rowIndex,value} = props
 const array = props.agGridReact.props.rowData;
 const click = (data) => {
  let newObj = {...data,id:data.id_last_contact,fullname:data.name}
  // dispatch( activeContact(newObj) );
 }
  const showName = (obj) => {
    let text = " ";
    const {data} = obj;
    // if(data.origin === true){
    //     text = value;
    // } else {
    //   text = " ";
    // }
    text = value;

    return text;
  }
  return (
      <>
          <Link  onClick={(e) =>click(props.data)} to={"contacts/" + (props.data.id_last_contact) + "/Interdashboard"}>
          <span>{showName(props)}</span>
          </Link>
      </>
  )
}
export const SlotProspection = function SlotProspection(props) {
    const {rowIndex,value} = props
    const array = props.agGridReact.props.rowData;
     const showName = (obj) => {
       let text = " ";
       const {data} = obj;
       text = value;
      //  if(data.prospectionOrigin === true){
      //   text = value;
      //  } else {
      //    text = " ";
      //  }
       return text;
     }
     return (
         <>
             <span>{showName(props)}</span>
         </>
     )
}

export const SlotRating = function SlotRating(props) {
  const [rating,setRating] = useState(props.value ? parseInt(props.value) : 0);
  const changeRating = (e) => {
      let contact = props.data;
      contact.id = contact.id_last_contact;
      contact.rating = e;
      swal({
          title: "Estas seguro?",
          text: "Usted modificara la calificacion de este contacto",
          icon: "info",
          dangerMode: true,
          buttons: ["No","Si"],
        })
        .then((willDelete) => {
          if (willDelete) {
               axios.post(constaApi+'contact/update',contact, {
                  headers: {
                      "Accept": "application/json"
                  }
              }).then(function (response) {
                  props.context.loadApplications();
              //    loadRating();
              }).catch(error =>{
              });
          } else {
            swal("Operacion cancelada!");
          }
        });
  }
  return (
  <>
    <StarRatings
      rating={rating}
      starDimension={'16px'}
      starEmptyColor={'gray'}
      starRatedColor={'#ffd200'}
      starHoverColor={'#ffd200'}
      starSpacing="2px"
      changeRating={(e) => changeRating(e)}
     numberOfStars={5}
      name='rating'
                    />
  </>
  )
}
