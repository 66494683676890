import React, { useState } from 'react'
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom';

export default function TicketsView(props) {
  const { pathname } = useLocation();

  return (
    <div style={{ minHeight: '0px' }} className="pb-0 content">
       <div style={{ marginTop: '-20px' }} className=" mt-3sc-bdVaJa styles__Nav-sc-19n49a3-0 gOZeoI">
        <Link className={['/moduleTickets'].includes(pathname) ?
          'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active Inter600' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl Inter600'}
          to={"/moduleTickets"}>Prospección</Link>

        <Link className={['/moduleTickets/applications'].includes(pathname) ?
          'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active Inter600' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl Inter600'}
          to={"/moduleTickets/applications"}>Aplicacion</Link>

        <Link className={['/moduleTickets/trackings'].includes(pathname) ?
          'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active Inter600' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl Inter600'}
          to={"/moduleTickets/trackings"}>Seguimiento</Link>


        <Link className={['/moduleTickets/others'].includes(pathname) ?
          'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active Inter600' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl Inter600'}
          to={"/moduleTickets/others"}>Otros</Link>
          </div>
    </div>
  )
}

