import React, { useState, useEffect } from 'react'
import { useParams } from "react-router";
import axios from 'axios';
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { constaApi } from '../../../constants/constants';
import moment from "moment";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { starLoadingRemindersC } from '../../../actions/contacts/remindersContacts/remindersContact';
import { useDispatch, useSelector } from 'react-redux';
import ModalSubstatusFast from './componentsDashboard/ModalSubstatusFast.jsx';


function TableDashboard(props) {

  const _ = require("lodash");

  let { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    consultContact(id);
    dispatch(starLoadingRemindersC(id))
  }, [])
  async function consultContact() {
    await axios.get(constaApi + 'contacts/' + id + '/dashboard', {
      headers: {
        "Accept": "application/json"
      }
    }).then(function (response) {
      let array = [];
      if (response.data.merge) {


        // Requerimientos
        // - Programas de prospección que estén en cualquier status excepto aplicar [ LISTO ]
        // - Programas de prospección que estén en status de cancelado solo si no tienen otra prospección del mismo año en aplicación o seguimiento.

        // - Programas de aplicaciones que estén en cualquier status excepto Activo [ LISTO ]
        // - Programas de aplicaciones que estén en status de Cancelado solo si no tiene otra programa  del mismo año que hubiera pasado a seguimiento

        // - Programas de seguimiento que esten en cualquier status, incluso terminado o cancelado


        response.data.merge.map((item,index) => {

          if(item.type == 'Seguimiento'){

            array.push(item);

          } else if (item.type == 'Prospeccion'){

            if( !(item.applications.length > 0) ){
              array.push(item);
            }

          } else if(item.type == 'Aplicacion'){
            if(item.trackings.length > 0 ){

              if( !(item.id_application == item.trackings[0].id_application) ){
                array.push(item);
              }
            } else {
              array.push(item);
            }

          }

        })
      }

      let arraySorter = _.sortBy(array,['created_at']);
      setrows(arraySorter);

    });
  }

  const [modalSubstatus,setModalSubstatus] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [rows, setrows] = useState([]);
  const [columnApi, setColumnApi] = useState();
  const [reminders, setReminders] = useState([]);
  const [frameworkComponents, setFramwrokw] = useState({ slotStatus : CustomSlot, slotName: SlotName, slotDate: SlotDate, slotType: SlotType, slotSuggestGrade: SlotslotSuggestGrade });
  const autoSizeAll = (skipHeader) => {
    var allColumnIds = [];
    columnApi.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    columnApi.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  };
  const onFirstDataRendered = (event) => {
    // autoSizeAll(false);
  };
  const onGridReady = (params) => {
    setGridApi(params);
    setColumnApi(params);
  };

  const [objToPass,setObjTopass] = useState();
  const closeModalSubstatus  = () => {
    setModalSubstatus(false);

  }
  const openModalSubstatus = (obj) => {
    setModalSubstatus(true);
    setObjTopass(obj);
  }

  const updateRouteNextLevel = () => {
    props.updateRoute();
  }
  return (
    <div
      className="ag-theme-alpine"
      style={{ height: "100%", width: "100%" }}
    >
       {
      modalSubstatus &&

       <ModalSubstatusFast  updateRoute={updateRouteNextLevel} closemodal={closeModalSubstatus}  obj={objToPass}/>

      }

      <AgGridReact

context={{
  openModalSubstatus
}}

        rowData={rows}
        rowHeight={40}
        domLayout="autoHeight"
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        frameworkComponents={frameworkComponents}
        pagination={true}
        paginationPageSize={10}
        paginationNumberFormatter={function (params) {
          return params.value.toLocaleString();
        }}
        rowSelection="multiple"
      >
        <AgGridColumn
          headerName="Grado Sugerido"
          cellRenderer="slotSuggestGrade"
          width="150"
        ></AgGridColumn>


        <AgGridColumn
          headerName="Tipo"
          field="type"
          cellRenderer="slotType"
          width="65"
        ></AgGridColumn>

         <AgGridColumn
          headerName="Status"
          field="type"
          cellRenderer="slotStatus"
          width="150"
        ></AgGridColumn>

        <AgGridColumn
          headerName="Nombre"
          cellRenderer="slotName"
          width="250"
        ></AgGridColumn>

          {/* <AgGridColumn
            headerName="Fecha/Descripcion"
            field="subject"
            width="220"
            cellRenderer="slotDate"
          />

          <AgGridColumn
            headerName="Status"
            field="status"
            width="100"
          /> */}

      </AgGridReact>
    </div>
  )
}

export default TableDashboard

export const SlotslotSuggestGrade = function (props) {
  const { data } = props
  const [grade, setGrade] = useState("");


  useEffect(() => {
    let hoy = moment().locale("es-mx").format("MM");
    if (data.name_prospection.includes('Boarding') || data.name_prospection.includes('District')) {
      if (data.cicly) {
        let sub = data.cicly.split('-');
        if (data.grade) {
          let grad = data.grade.split(" ");
          let yearProspection = data.name_prospection.split(" ");
          let result = (parseInt(grad[1]) + (parseInt(yearProspection[2]) - parseInt(sub[0])));
          setGrade(result);

        }
      }
    } else {
      if(data.cicly){
        if (data.cicly) {
          let sub = data.cicly.split('-');
          if (data.grade) {
            let grad = data.grade.split(" ");
            let yearProspection = data.name_prospection.split(" ");
            let result = (parseInt(grad[1]) + (parseInt(yearProspection[2]) - parseInt(sub[0])));
            setGrade(result);

          }
        };
      }
    }

  }, [])

  return (
    <span>{grade}</span>
  )

}
export const CustomSlot = function (props) {
  const { value , data } = props;
  // status
 return(

  <button
  disabled = { data.status == 'Cancelar' ? true : data.status == 'Aplicar' ? true : data.status == 'Terminado' ? true : data.status == 'Cancelado' ? true : false
  }
  onClick={(e) => props.context.openModalSubstatus(props.data)}
  >
     {props.data.status} { props.data.substatus }

  </button>
 )
  };

export const SlotType = function (props) {
  const { value } = props;
  const PopoverComponent = (text) => {
    return (
      <Popover id="popover-basic">
        <Popover.Content>
          <strong>{text}</strong>
        </Popover.Content>
      </Popover>
    );
  };

  const formattType = (value, obj) => {
    let param = 'Sin tipo';
    switch (obj.status) {
      case 'Cancelar':
        param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btnGray">{obj.type.charAt(0)}</span>;
        break;
          case 'Contacto Previo':
            param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-info">{obj.type.charAt(0)}</span>;
            break;
            case 'Contacto Formal':
              param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-info">{obj.type.charAt(0)}</span>;
              break;
              case 'Presentación':
                param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-info">{obj.type.charAt(0)}</span>;
                break;
                case 'Aclaración de dudas':
                  param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-info">{obj.type.charAt(0)}</span>;
                  break;
                  case 'Decisión':
                    param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-info">{obj.type.charAt(0)}</span>;
                    break;
                    case 'Evaluación':
                      param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-info">{obj.type.charAt(0)}</span>;
                      break;
                    // Until here, Status prospection , now aplication status

                    case 'Cancelado':
                      param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btnGray">{obj.type.charAt(0)}</span>;
                      break;
                       case 'Aplicación':
                           param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-yellow">{obj.type.charAt(0)}</span>;
                           break;
                           case 'Admisión':
                            param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-yellow">{obj.type.charAt(0)}</span>;
                            break;
                            case 'Aceptación':
                              param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-yellow">{obj.type.charAt(0)}</span>;
                              break;
                              case 'Visado':
                                param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-yellow">{obj.type.charAt(0)}</span>;
                                break;
                                case 'Trámites de viaje':
                                  param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-yellow">{obj.type.charAt(0)}</span>;
                                  break;
                                  case 'Ultimos detalles':
                                    param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-yellow">{obj.type.charAt(0)}</span>;
                                    break;

                                    // Tracking
                                    case 'Activo':
                                      param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-success">{obj.type.charAt(0)}</span>;
                                      break;
                                      case 'Materias':
                                        param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-success">{obj.type.charAt(0)}</span>;
                                        break;
                                        case 'Fall Check Point':
                                          param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-success">{obj.type.charAt(0)}</span>;
                                          break;
                                          case 'Diciembre':
                                            param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-success">{obj.type.charAt(0)}</span>;
                                            break;
                                            case 'Enero':
                                              param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-success">{obj.type.charAt(0)}</span>;
                                              break;
                                              case 'Spring Check Point':
                                                param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-success">{obj.type.charAt(0)}</span>;
                                                break;
                                                case 'Fin de curso':
                                                  param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-success">{obj.type.charAt(0)}</span>;
                                                  break;
                                                  case 'Revalidación':
                                                    param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-success">{obj.type.charAt(0)}</span>;
                                                    break;
                                                    case 'Reembolso':
                                                      param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-success">{obj.type.charAt(0)}</span>;
                                                      break;
                                                      case 'Recomendacion':
                                                        param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-success">{obj.type.charAt(0)}</span>;
                                                        break;


                                    default:
                                      param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btnPurpleBee">{obj.type.charAt(0)}</span>;
                                      break;



      //   if (obj.status == 'Termino' || obj.status == 'Cancelar') {
      //     param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btnPurpleBee">{obj.type.charAt(0)}</span>;
      //   } else {
      //     param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-success">{obj.type.charAt(0)}</span>;
      //   }
      //   break;
      // case 'Prospeccion':
      //   if (obj.status == 'Termino' || obj.status == 'Cancelar') {
      //     param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btnPurpleBee">{obj.type.charAt(0)}</span>;
      //   } else {
      //     param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-info">{obj.type.charAt(0)}</span>;
      //   }

      //   break;
      // case 'Aplicación':
      //   param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btn-yellow">{obj.type.charAt(0)}</span>;
      //   break;
      // default:
      //   param = <span key={0} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV btnPurpleBee">H</span>;
      //   break;
      //   break;
    }

    param =
    <OverlayTrigger
    trigger={["hover", "hover"]}
    placement="top"
    overlay={PopoverComponent(obj.type)}
  >
    {param}
    </OverlayTrigger>;
    return param;
  }
  return (
    <>

        <span>
          {formattType(value, props.data)}
        </span>



    </>
  );
};
export const SlotName = function (props) {
  const PopoverComponent = (text) => {
    return (
      <Popover id="popover-basic">
        <Popover.Content>
          <strong>{text}</strong>
        </Popover.Content>
      </Popover>
    );
  };
  const reRender = (obj) => {
    let tag = '';
    let param = '';
    let year = obj.name_prospection ? obj.name_prospection.substr((obj.name_prospection.length - 4), obj.name_prospection.length) : null;
    if (obj) {
      if (obj.name_prospection.includes('Boarding')) {
        param = obj.name ? ('BS ' + year + ' - ') + obj.name : 'BS ' + year;

      }
      else if (obj.name_prospection.includes('Año')) {
        param = obj.name ? ('AE ' + year + ' - ') + obj.name : 'AE ' + year;
      }
      else if (obj.name_prospection.includes('Summer')) {
        param = obj.name ? ('Summer ' + year + ' - ') + obj.name : 'Summer ' + year;
      }
      else if (obj.name_prospection.includes('Idiomas')) {
        param = obj.name ? ('Idiomas ' + year + ' - ') + obj.name : 'Idiomas ' + year;
      }
      else if (obj.name_prospection.includes('Au')) {
        param = obj.name ? ('Au Pair ' + year + ' - ') + obj.name : 'Au Pair ' + year;
      }
      else if (obj.name_prospection.includes('Carreras')) {
        param = obj.name ? ('C & M ' + year + ' - ') + obj.name : 'C & M ' + year;
      }
      else {
        param = obj.name_prospection;
      }
    }
    tag = (
      <p className="Inter">
        <OverlayTrigger
          trigger={["hover", "hover"]}
          placement="top"
          overlay={PopoverComponent(obj.name_prospection + (obj.name ? (' - ' + obj.name) : ""))}
        >
          <span>{param}</span>
        </OverlayTrigger>
      </p>
    );
    return tag;
  }
  return (
    <>
      {reRender(props.data)}
    </>
  );
};

export const SlotDate = function (props) {
  const PopoverComponent = (text) => {
    return (
      <Popover id="popover-basic">
        <Popover.Content>
          <strong>{text}</strong>
        </Popover.Content>
      </Popover>
    );
  };

  function showDate(dateBD, time) {
    let datef = moment(dateBD).locale("es-mx").format("ddd D MMMM, YYYY ");
    datef = datef[0].toUpperCase() + datef.slice(1);
    datef = datef.replace(".", "");
    let tag = (
      <p className="Inter">
        {datef}
        <OverlayTrigger
          trigger={["hover", "hover"]}
          placement="top"
          overlay={PopoverComponent(props.data.comments.text ?? '')}
        >
          <a className="ml-1" >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              style={{ color: "rgb(192, 203, 227)" }}
            >
              <path
                fill="currentColor"
                d="M9.944 0a.72.72 0 0 1 .511.213l4.333 4.364A.73.73 0 0 1 15 5.09v8.727C15 15.023 14.03 16 12.833 16H4.167A2.174 2.174 0 0 1 2 13.818V2.182C2 .977 2.97 0 4.167 0h5.777zm-.299 1.455H4.167a.725.725 0 0 0-.723.727v11.636c0 .402.324.727.723.727h8.666a.725.725 0 0 0 .723-.727V5.392l-3.91-3.937z"
              ></path>
              <path
                fill="currentColor"
                d="M10.667 4.364h3.61c.4 0 .723.325.723.727a.725.725 0 0 1-.722.727H9.944a.725.725 0 0 1-.722-.727V.727c0-.401.324-.727.722-.727.4 0 .723.326.723.727v3.637zM11.389 8c.399 0 .722.326.722.727a.725.725 0 0 1-.722.728H5.61a.725.725 0 0 1-.722-.728c0-.401.323-.727.722-.727h5.778zM11.389 10.91c.399 0 .722.325.722.726a.725.725 0 0 1-.722.728H5.61a.725.725 0 0 1-.722-.728c0-.401.323-.727.722-.727h5.778zM7.056 5.09c.398 0 .722.327.722.728a.725.725 0 0 1-.722.727H5.61a.725.725 0 0 1-.722-.727c0-.401.323-.727.722-.727h1.445z"
              ></path>
            </svg>
          </a>
        </OverlayTrigger>
      </p>
    );
    return dateBD ? tag : "";
  }
  return <>
    {
      [props.data.comments &&
        showDate(props.data.comments.date)
      ]
    }

  </>;
};
