import SearchBar from '../../components/GeneralComponents/SearchBar';
import React,{useState} from 'react';
import TableHistory from './TableHistory.jsx';

export default function HistorySection() {
    const [param,setParam] = useState(null);

    const consult = (e) => {
        e.target.value === "" ?  setParam('keyWordSeccret302') :  setParam(e.target.value);
    }
    return (
        <div className="content">
        <div className="row">
        <div className="col-12">
        <div className="searchBarTable">
        <SearchBar consult={(e) => consult(e)}/>
        </div>
         <TableHistory  param={param} /> 
        </div>
        </div>
        </div>
    )
}
