import React from 'react'
 import './topicsCss.css';
 import * as FaIcons from "react-icons/fa";
import { Link } from 'react-router-dom';

function TopicsView() {
  return (
    <div 
    style={{
        height:'100%',
        width:'100%',
    }}
    className='content'>
    <div
    style={{display:'flex', alignItems:'center',justifyContent:'center'}}>
    <h4>Temas populares</h4>
    </div>
    {/* Zona de interacción */}
    {/* Div de fondo donde iran las columnas y renglones */}
    <div class="flexContainer">

        <div class="caja">
        <Link id="faQ" to={"/FAQ/General"}>
                        <div
            className='insideContainer'>
                <div
                style={{marginTop:'10%'}}>
                <FaIcons.FaQuestionCircle size={64}/>
                </div>
                <div
                style={{fontWeight:'bold',marginTop:'10%',textAlign:'left',marginLeft:'10%'}}>
                    General
                </div>
                <div
                style={{textAlign:'left',width:'70%', marginLeft:'10%'}}>
                    Este solo es un parrafo de ejemplo , aqui puedes ver el ejemplo del texto
                    que trato de escribir
                </div>

            </div>
        </Link>
        </div>
        {/* End Div of Caja  */}

        <div class="caja">
        <Link id="faQ" to={"/FAQ/Prospection"}>
            <div
            className='insideContainer'>
                <div
                style={{marginTop:'10%'}}>
                <FaIcons.FaQuestionCircle size={64}/>
                </div>
                <div
                style={{fontWeight:'bold',marginTop:'10%',textAlign:'left',marginLeft:'10%'}}>
                    Prospección
                </div>
                <div
                style={{textAlign:'left',width:'70%', marginLeft:'10%'}}>
                    Este solo es un parrafo de ejemplo , aqui puedes ver el ejemplo del texto
                    que trato de escribir
                </div>

            </div>
        </Link>
        </div>
        {/* End Div of Caja  */}
        <div class="caja">
        <Link id="faQ" to={"/FAQ/Applications"}>
            <div
            className='insideContainer'>
                <div
                style={{marginTop:'10%'}}>
                <FaIcons.FaQuestionCircle size={64}/>
                </div>
                <div
                style={{fontWeight:'bold',marginTop:'10%',textAlign:'left',marginLeft:'10%'}}>
                    Aplicaciones
                </div>
                <div
                style={{textAlign:'left',width:'70%', marginLeft:'10%'}}>
                    Este solo es un parrafo de ejemplo , aqui puedes ver el ejemplo del texto
                    que trato de escribir
                </div>

            </div>
        </Link>
        </div>
        {/* End Div of Caja  */}
        <div class="caja">
        <Link id="faQ" to={"/FAQ/Track"}>
            <div
            className='insideContainer'>
                <div
                style={{marginTop:'10%'}}>
                <FaIcons.FaQuestionCircle size={64}/>
                </div>
                <div
                style={{fontWeight:'bold',marginTop:'10%',textAlign:'left',marginLeft:'10%'}}>
                    Seguimiento
                </div>
                <div
                style={{textAlign:'left',width:'70%', marginLeft:'10%'}}>
                    Este solo es un parrafo de ejemplo , aqui puedes ver el ejemplo del texto
                    que trato de escribir
                </div>

            </div>
        </Link>
        </div>
        {/* End Div of Caja  */}
        <div class="caja">
        <Link id="faQ" to={"/FAQ/Construction"}>
            <div
            className='insideContainer'>
                <div
                style={{marginTop:'10%'}}>
                <FaIcons.FaQuestionCircle size={64}/>
                </div>
                <div
                style={{fontWeight:'bold',marginTop:'10%',textAlign:'left',marginLeft:'10%'}}>
                    Configuracion de la cuenta
                </div>
                <div
                style={{textAlign:'left',width:'70%', marginLeft:'10%'}}>
                    Este solo es un parrafo de ejemplo , aqui puedes ver el ejemplo del texto
                    que trato de escribir
                </div>

            </div>
        </Link>
        </div>
        {/* End Div of Caja  */}
        <div class="caja">
        <Link id="faQ" to={"/FAQ/Construction"}>
            <div
            className='insideContainer'>
                <div
                style={{marginTop:'10%'}}>
                <FaIcons.FaQuestionCircle size={64}/>
                </div>
                <div
                style={{fontWeight:'bold',marginTop:'10%',textAlign:'left',marginLeft:'10%'}}>
                    Configuracion de la cuenta
                </div>
                <div
                style={{textAlign:'left',width:'70%', marginLeft:'10%'}}>
                    Este solo es un parrafo de ejemplo , aqui puedes ver el ejemplo del texto
                    que trato de escribir
                </div>

            </div>
        </Link>
        </div>
        {/* End Div of Caja  */}

        
       
      </div>
    

    </div>
  )
}

export default TopicsView