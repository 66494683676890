// colleges :{
//     name:
//     type:
//     Country:
//     Website:
//     description:
//     start boarding grades:
//     end boarding grades :
//     start day grades:
//     end day grades:
//     total boarding grades:
//     total international grades:
//     total day students:
//     total students in school
//     location
//     Sports
//     Arts
//     Special clinics

import { types } from "../../types/types";


// }
const initialState = {
    remindersC :[],
    active: null,
}
export const reminderContactReducer = ( state = initialState,action ) => {
    switch (action.type) {
            case types.remindersCActive:
                localStorage.setItem( 'remindersCActive', JSON.stringify(action.payload));
                return {
                    ...state,
                    active: {
                        ...action.payload
                    }
                }
            case types.remindersCLoad:
                return {
                    ...state,
                    remindersC: [...action.payload]
                }
        default:
            return state;
    }

}