import React, { useState, useEffect } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import StarRatings from 'react-star-ratings';
import SearchBar from '../../components/GeneralComponents/SearchBar';
var _ = require('lodash');

export default function TableTotals(props) {


  const [frameworkComponents, setFramwrokw] = useState({ slotProgram: SlotProgram,slotTotal:SlotTotal });
  const [ratingTotal, setRatingTotal] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [newObj, setNewObj] = useState([]);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [param, setParam] = useState(null);
  const [flag,setFlag] = useState(false);
  const [rowsRating, setRowsRating] = useState([]);

  useEffect(() =>{

    let orderBY = _.orderBy(props.rowsRating, ['program'],
             ['asc']);
    // orderBy.push(props.totalSum);
    let newArr = [];
    newArr = orderBY;
    newArr.push(props.totalSum)
    setRowsRating(orderBY);
  },[props.rowsRating])
  const consult = (e) => {
    e.target.value === "" ? quickSearch('keyWordSeccret302') : quickSearch(e.target.value);
  }
  const quickSearch = async (value) => {
    let objx = gridApi;
    value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
    setGridApi(objx);
  }
  const onGridReady = (params) => {
    setGridApi(params);
    setGridColumnApi(params);
  };

  return (
      <div className='mt-3'>
        <div className='row'>
          <div className='col-4'>
            <SearchBar consult={(e) => consult(e)} />
          </div>
        </div>
        <div
          className="ag-theme-alpine mt-1"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact

            rowData={rowsRating}
            rowHeight={40}
            onGridReady={onGridReady}
            cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
            domLayout="autoHeight"
            rowClassRules={{
              'colorGrayAG': function (params) {
                var backColor = params.data.color;
                return params.data.color === 0;
              },
              'colorWhiteAG': 'data.color === -1',
            }}
            suppressRowTransform={true}
            //    pagination={true}
            //    paginationPageSize={10}
            frameworkComponents={frameworkComponents}
          //    paginationNumberFormatter={function (params) {
          //        return params.value.toLocaleString();
          //    }}
          //    rowSelection="multiple"
          >
            <AgGridColumn
              headerName="Programa"
              sortable={true}
              wrapText={true}
              field="program"
              cellRenderer="slotProgram"
              width={250}
            />
            <AgGridColumn
            headerName='Activo'
            sortable={true}
            field="act"
            wrapText={true}
            width={150}
            />



            <AgGridColumn
              field="mat"
              headerName="Materias"
              sortable={true}
              wrapText={true}
              width={100}
            />
              <AgGridColumn
              field="fcp"
              headerName="Fall Check Point"
              sortable={true}
              wrapText={true}
              width={150}
            />
               <AgGridColumn
              field="dic"
              sortable={true}
              headerName="Diciembre"
              wrapText={true}
              width={150}
            />
            <AgGridColumn
              headerName="Enero"
              sortable={true}
              field="ene"
              wrapText={true}
              width={150}
            />
            <AgGridColumn
              headerName="Spring Check Point"
              sortable={true}
              field="scp"
              wrapText={true}
              width={150}
            />

         <AgGridColumn
              headerName="Fin de curso"
              sortable={true}
              field="fdc"
              wrapText={true}
              width={150}
            />
              <AgGridColumn
              headerName="Revalidación"
              sortable={true}
              field="rev"
              wrapText={true}
              width={150}
            />
             <AgGridColumn
              headerName="Reembolso"
              sortable={true}
              field="ree"
              wrapText={true}
              width={150}
            />
              <AgGridColumn
              headerName="Recomendacion"
              sortable={true}
              field="rec"
              wrapText={true}
              width={150}
            />
              <AgGridColumn
              headerName="Termino"
              sortable={true}
              field="ter"
              wrapText={true}
              width={150}
            />
            <AgGridColumn
            sortable={true}
              headerName="Total"
              cellRenderer="slotTotal"
              field="tC" width={150}
            />
          </AgGridReact>
        </div>
      </div>
  );
}

// Slots
export const SlotProgram = function SlotProgram(props) {
  function whatIs(value) {
    return typeof value === 'string' ? 1 : 0;
  }
  const { value } = props;
  return (
    [
      whatIs(value) === 1
        ?
        <b>{value} </b>
        :
        <StarRatings
          rating={value}
          starDimension={'20px'}
          starEmptyColor={'gray'}
          starRatedColor={'#ffd200'}
          starHoverColor={'#ffd200'}
          starSpacing="2px"
          numberOfStars={5}
          name='rating'
        />
    ]
  )
}

export const SlotTotal = function SlotTotal(props){
  const {value} = props;
  return(
    <b>{value} </b>
  )
}
