import React, { useState,useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { constaApi } from "../../../constants/constants";
import * as FIIcons from "react-icons/fi";
import swal from 'sweetalert';
import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  InputGroup,
  Popover,
  OverlayTrigger,
  FormControl,
  Tabs,
  Tab,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import Skeleton from 'react-loading-skeleton';
import Reminders from '../../contactComponents/RemindersComponents/Reminders';
import { starLoadingProspectRemindersC } from "../../../actions/contacts/remindersContacts/remindersContact";
import { setRemindersC } from "../../../actions/contacts/remindersContacts/remindersContact";
import Bio from "../../../components/bioComponents/Bio";
import { starLoadingProspect } from "../../../actions/contacts/bioContact/bioContact";
import { activeContact } from "../../../actions/contacts/contacts/contacts";
import Proposals from "../../../components/proposals/Proposals";
import moment from 'moment';
import { starLoadingCollegesByProspeccion } from "../../../actions/colleges/colleges";
import { setColleges } from "../../../actions/colleges/colleges";
import {
  SelectionState,
  GroupingState,
  IntegratedGrouping,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import { select } from "d3-selection";
import NotificationAlert from "react-notification-alert";
import { setError } from "../../../actions/uiNotificactions/ui";
import { setMessage } from "../../../actions/uiNotificactions/ui";

var _ = require('lodash');

export  function CustomComponent(props){
  const {obj} = props;
  const [columns] = useState([
    { name: 'name', title: 'Nombre' },
  ]);
  const [selection, setSelection] = useState([]);

  function changeSelection(e){
    setSelection(e); 
    let aux = obj[e];
     props.theSelection(aux);
  }
  return(
    <div>
       <Grid
              style={{marginTop:'30px'}}
              rows={obj}
              columns={columns}
            >
              <SelectionState
                selection={selection}
                onSelectionChange={(e) => {changeSelection(e)}}
              />
              <Table />
              <TableSelection /> 
            </Grid>
    </div>
  );
}

export default function Prospection(props) {
  const [editingStoryOrNote,setEditingStoryOrNote] = useState(true);
  const [modalSubstatus,setModalSubstatus] = useState(false);
  const [notificationReminder, setNotificationReminder] = useState();
  const [modalReminder,setModalReminder] = useState(false);
  const [notes,setNotes] = useState("");
  const [timeReminder, setTimeReminder] = useState("");
  const [dateReminder, setDateReminder] = useState("");
  const [now, setNow] = useState();
  const [nowTime, setNowTime] = useState();
  const [storyContact,setStoryContact] = useState(props.contact.story)
  const [noteProspection,setNoteProspection] = useState("");
  const [enableButton,setEnableButton] = useState(false);
  const [flagEdit,setFlagEdit] = useState(false);
  const [title,setTitle] = useState('Agregar Prospección');
  const notificationAlert = useRef();
  const [modalAdvisor,setModalAdvisor] = useState(false);
  const dispatch = useDispatch();
  const [aux, setAux] = useState({ id: "", story: "", status: "Evaluación", name_prospection: "", last_modification: "" });
  const [activeProspect, setActiveProspect] = useState({ id: "", story: "", status: "Evaluación", name_prospection: "", last_modification: "" });
  const [load, setLoad] = useState(false);
  const [prospections, SetProspections] = useState(null);
  const [selection, SetSelection] = useState(0);
  const [active,setActive]  = useState(JSON.parse(localStorage.getItem('ActiveContact')));
  const [modalProspection, setModalProspection] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalStory, setModalStory] = useState(false);
  const [program, SetProgram] = useState();
  const [objAux, setObjAux] = useState({ program: "", year: "" });
  const [modalGrouped, setModalGrouped] = useState(false);
  const {colleges} = useSelector( state => state.colleges);
  const {active:theContact} = useSelector( state => state.contacts);
  const [filterColleges,setFilterColleges] = useState();
  const [auxobj,setAuxObj] = useState();
  const [users,setUsers] = useState([]);
  const [theUser,setTheUser] = useState();
  const programs = [
    "Boarding School",
    "School District",
    "Summer Camp",
    "Language School",
    "University/College",
    "Work & Travel"
  ];
  const status = [
    "Contacto Previo",
    "Contacto Formal",
    "Presentación",
    "Aclaración de dudas",
    "Decisión",
    "Aplicar",
    "Cancelar",
  ];
  const years = [
    2019,
    2020,
    2021,
    2022,
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
  ];
  var arrayObj = [];
  const [year, SetYear] = useState();
  const {
    register: student,
    handleSubmit,
    errors,
    formState,
    reset: reset,
  } = useForm({});
  
  useEffect(() => {
    let or = _.orderBy(colleges,'name','asc');
    const result = _.groupBy(or,"country")
    setFilterColleges(result);
  },[colleges]);
  useEffect(() => {
    thisDay();
    present();
      consultAllProspections(active.id);
    if (active) {
      // localStorage.setItem('ActiveContact', JSON.stringify(active));
      if (activeProspect) {
        dispatch(starLoadingProspect(active.id, activeProspect.id));
      }
    }
  }, []);

  const theSelection = (obj) => {
    setAuxObj(obj);
  }
  const consultAllProspections = async (id) => {
    changeLoad(true);
    await axios
      .get(constaApi + "getProspection/" + id, {
        headers: {
          Accept: "application/json",
        },
      })
      .then(async function (response) {
        const {data} = response;
        let auxArray = [];
         await data.map(da => {
          if(da.status == 'Cancelar' || da.status == 'Aplicar'){
            auxArray.push(da);
          } else {
            auxArray.unshift(da);
          }
        })
        if (auxArray[0]) {
          dispatch(setColleges([]));
          let newcadena = auxArray[0].name_prospection.replace(/\d/g, "");
          dispatch(starLoadingCollegesByProspeccion(newcadena));
          firstTime(auxArray[0]);
          SetProspections(auxArray);
        } else {
          SetProspections(null);
          // dispatch( starLoadingProspectRemindersC(active.id,0,'Prospeccion'));
        }
        changeLoad(false);
      });
  };

  const firstTime = (data) => {
    setActiveProspect(data);
    setNoteProspection(data.noteProspection ?? "")
    SetSelection(data.id);
    dispatch(starLoadingProspectRemindersC(active.id, data.id, 'Prospeccion'));
    dispatch(starLoadingProspect(active.id, data.id));
  }
  const changeButton = async (id) => {
    dispatch(setRemindersC([]));
    dispatch(setColleges([]));
    changeLoad(true);
    await axios.post(constaApi + "showProspection", { id: id })
      .then(function (response) {
        let newcadena = response.data.name_prospection.replace(/\d/g, "");
        dispatch(starLoadingCollegesByProspeccion(newcadena));
        SetSelection(id);
        setActiveProspect(response.data);
        setNoteProspection(response.data.noteProspection);
        dispatch(starLoadingProspectRemindersC(active.id, response.data.id, 'Prospeccion'));
        dispatch(starLoadingProspect(active.id, response.data.id));
        changeLoad(false);
      });
    // SetSelection(id);
    // let auxxx = prospections.filter(prospect => prospect.id === id );
    //  setActiveProspect(auxxx);
    // changeLoad(false);

  };
  const changeLoad = (val) => {
    setLoad(val);
  }
  const changeModal = (flag = 0) => {
    if(flag == 1){
      setFlagEdit(true);
      let name = activeProspect.name_prospection.substr(0,(activeProspect.name_prospection.length - 4))
      let year = activeProspect.name_prospection.substr((activeProspect.name_prospection.length - 4),activeProspect.name_prospection.length)
      setObjAux({...objAux,program: name.trim(),year:year});
      setTitle('Editar Prospección');
   
    } else{
      setFlagEdit(false);
      setTitle('Agregar Prospección');

    }
    setModalProspection(!modalProspection);
  };

  const changeModalSubStatus = () => {
    setAux({ ...activeProspect });
    setModalSubstatus(!modalSubstatus);
  }
  const changeModalStatus = () => {
    setAux({ ...activeProspect });
    setModalStatus(!modalStatus);
  }

  const changeModalStory = ( flag) => {
    setEditingStoryOrNote( flag == 1 ? true : false);
    setAux({ ...activeProspect });
    setModalStory(!modalStory);
  }
  const closeModal = async () => {
    setActiveProspect({ ...aux });
    setModalProspection(false);
    setModalStory(false);
    setModalStatus(false);
    setModalGrouped(false);
    setModalAdvisor(false);
    setEnableButton(false);
    setModalReminder(false);
    setModalSubstatus(false);

  };
  const changeSubstatus = (e) => {
    setActiveProspect({ ...activeProspect, substatus: e.target.value });
  }

  const changeStatus = (e) => {
    setActiveProspect({ ...activeProspect, status: e.target.value });
    if (e.target.value == 'Aplicar') {
      setModalGrouped(true);
    }
  }
  const changeModalAdvisor = () => {
    axios.post(constaApi + "allUsers")
    .then(function (response) {
      setUsers(response.data);
      setModalAdvisor(!modalAdvisor);
    });
    setTheUser(active.id_advisor);
  }
  const changeAdvisor = (e) => {
    setTheUser(e.target.value);
  }
  const updatedContact = () => {
    let obj = users.filter(us => us.id == theUser);
    obj = {
      name_advisor: obj[0].name + " " + obj[0].father_lastname + " " + obj[0].mother_lastname,
      id_advisor : obj[0].id,
      id: active.id
    };
     axios.post(constaApi + 'contact/update', obj)
    .then(function (response) {
        if (response.status === 200) {
          dispatch(activeContact(response.data));
          setTheUser(null);
          closeModal();
            // notification('info', 'Datos actualizados correctamente');
        } else {
            // notification('danger', 'Ocurrio un error,por favor contacta a soporte');
        }
    });
  }
  const updateStoryContact = () => {
    let obj = {
      id: props.contact.id,
      story : storyContact
    }
    axios.post(constaApi + "updateStory",obj)
    .then(function (response) {
      props.updateRoute();
    });
  }

  const updateNoteProspection = () => {
    let obj = {
      id: activeProspect.id,
      name_prospection: activeProspect.name_prospection,
      status : activeProspect.status,
      last_modification : activeProspect.last_modification,
      id_last_contact : activeProspect.id_last_contact,
      last_contact : activeProspect.last_contact,
      noteProspection : noteProspection
    }

    axios.post(constaApi + "updatedProspection",obj)
    .then(function (response) {
      props.updateRoute();
    });
  }




  const changeStory = (e) => {
    if(editingStoryOrNote){
      setStoryContact(e.target.value);
    } else {
      setNoteProspection(e.target.value);
    }



  }
  const deleteProspection = () => {
    changeLoad(true);
    swal({
      title: "Estas seguro?",
      text: "Usted eliminara la prospeccion de este contacto",
      icon: "info",
      dangerMode: true,
      buttons: ["No","Si"],
    })
    .then((willDelete) => {
      if (willDelete) {
        axios.get(constaApi + "deleteProspection/" + activeProspect.id)
        .then(function (response) {
          consultAllProspections(active.id);
          changeLoad(false);
        });
      } else {
        swal("Operacion cancelada!");
        changeLoad(false);
      }
    });
  
  }

  const saveSubstatus = () => {
    let newObj = {
      id: activeProspect.id,
      name_prospection: activeProspect.name_prospection,
      status: activeProspect.status,
      story: activeProspect.story,
      last_modification: moment().format("YYYY-MM-DD HH:mm"),
      id_last_contact: active ? active.id : theContact ? theContact.id : null,
      last_contact: active ? active.name : theContact ? theContact.name : null,
      name: auxobj ? auxobj.name : null,
      id_application : auxobj ? auxobj.id : null,
      locaStorage: JSON.parse(localStorage.getItem('ActiveContact')),
      statusProvitional : 'Aplicación',
      substatus : activeProspect.substatus ?? 1,
      edit : true
    };

    changeLoad(true);
    axios.post(constaApi + "updatedProspection", newObj)
      .then(function (response) {
        consultAllProspections(active.id);
        closeModal();
      }).catch(err =>{
        if(err.response.status == 401){
          let message = 'Datos faltantes : ';
          // Error dato faltante
          Object.keys(err.response.data).map((oneKey,i) => {
                message += oneKey + ',';
          })
          closeModal();
          dispatch(setMessage(message));
        }
        else {
          let {message} = err.response.data;
                if(!message){
                  message = err.response.data;
                }
                dispatch(setMessage(message));
          closeModal();
        }
      });

  }
  const saveChanges = () => {
    setEnableButton(true);
    moment.locale("es-mx");
     let newObj = {
       id: activeProspect.id,
       name_prospection: activeProspect.name_prospection,
       status: activeProspect.status,
       story: activeProspect.story,
       substatus:1,
       last_modification: moment().format("YYYY-MM-DD HH:mm"),
       id_last_contact: active ? active.id : theContact ? theContact.id : null,
       last_contact: active ? active.name : theContact ? theContact.name : null,
       name: auxobj ? auxobj.name : null,
       id_application : auxobj ? auxobj.id : null,
       locaStorage: JSON.parse(localStorage.getItem('ActiveContact')),
       statusProvitional : 'Aplicación'
     };

    if(newObj.status == 'Aplicar' && !auxobj ){
      dispatch(setError('Error al insertar el Colegio,Ya se encuentra aplicando'));
      closeModal();

    } else {  
      changeLoad(true);
      axios.post(constaApi + "updatedProspection", newObj)
        .then(function (response) {
          consultAllProspections(active.id);
          closeModal();
        }).catch(err =>{
          if(err.response.status == 401){
            let message = 'Datos faltantes : ';
            // Error dato faltante
            Object.keys(err.response.data).map((oneKey,i) => {
                  message += oneKey + ',';
            })
            closeModal();
            dispatch(setMessage(message));
          }
          else {
            let {message} = err.response.data;
                  if(!message){
                    message = err.response.data;
                  }
                  dispatch(setMessage(message));
            closeModal();
          }
        });
    }
    changeLoad(false);
    setEnableButton(false);
  }
  const formatDate = (date) => {
    moment.locale('es-mx')
    let datef = '';
    if (date) {
      datef = moment(date).locale('es-mx').format("DD/MMM/YYYY ");
    } else {
      datef = '';
    }
    return datef;
  }
  const updateReminders = () => {
    dispatch(starLoadingProspectRemindersC(active.id, activeProspect.id, 'Prospeccion'));
  }
  const handleMiddle = () => {
    setEnableButton(true);
    setModalProspection(false);
    setModalReminder(true);
    // if(!flagEdit){
    //   onSubmit();
    // } else {
    //   handleEdit();
    // }
  }
  const handleEdit = () => {
    let newObj = {...activeProspect,name_prospection :objAux.program + " " + objAux.year};
   axios.post(constaApi + "updatedProspection", newObj)
   .then(function (response) {
     changeButton(response.data.id);
     consultAllProspections(active.id);
     closeModal();
   }).catch(err =>{
    if(err.response.status == 401){
      let message = 'Datos faltantes : ';
      // Error dato faltante
      Object.keys(err.response.data).map((oneKey,i) => {
            message += oneKey + ',';
      })
      closeModal();
      dispatch(setMessage(message));
    }
    else {
      let {message} = err.response.data;
            if(!message){
              message = err.response.data;
            }
            dispatch(setMessage(message));
      closeModal();
    }
  });
  }
  const onSubmit = (data) => {
    moment.locale("es-mx");
    const {email,id,name,token,type} = JSON.parse(localStorage.getItem('user'));
    let selectValue = [{
        id:id,
        type:type,
        fullname:name,
        email:email
    }];
    let datex = dateReminder + " " + timeReminder;

    let obj = {
      id:  null,
      id_college: null,
      college:  null,
      subject: 'Recordatorio Automático',
      emailTo: selectValue ?? null,
      dateReminder: datex ?? null,
      timenotification: notificationReminder ?? null,
      notes: notes ?? null,
      departament: null,
      urgent:  0,
  };
    let newObj = {
      edit : title === 'Editar Prospección' ? true : false ,
      id_prospec : activeProspect.id ?? null,
      name_prospection: objAux.program + " " + objAux.year,
      status: title === 'Editar Prospección' ? activeProspect.status : 'Evaluación',
      story: null,
      last_modification: moment().format("YYYY-MM-DD HH:mm"),
      id_last_contact: active ? active.id : theContact ? theContact.id : null,
      last_contact: active ? active.name : theContact ? theContact.name : null,
      locaStorage: JSON.parse(localStorage.getItem('ActiveContact'))
    };
    let auxThree = {...newObj,reminder:{...obj}};

    let flag = false;
    if(prospections){
      prospections.map(pro =>{
        if(pro.name_prospection == (objAux.program + " " + objAux.year)){
          flag = true;
        } else {
        }
      })
    }
    if(!flag){
      axios.post(constaApi + "saveProspection", auxThree)
        .then(function (response) {
          changeButton(response.data.id);
          consultAllProspections(active.id);
          closeModal();
        }).catch(err =>{
          if(err.response.status == 401){
            let message = 'Datos faltantes : ';
            // Error dato faltante
            Object.keys(err.response.data).map((oneKey,i) => {
                  message += oneKey + ',';
            })
            closeModal();
            dispatch(setMessage(message));
          }
          else {
            let {message} = err.response.data;
            if(!message){
              message = err.response.data;
            }
            dispatch(setMessage(message));
            closeModal();
          }
        });
    } else {
      notification('warning', 'Prospeccion Repetida');
      flag = false;
    }
  };
  const notification =  (type,message) => {
    let place = "tc";
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
      }
    notificationAlert.current.notificationAlert(options);
 }
  const checkButton = (obj) => {
    let params = "";
    if(obj.id == selection && obj.status != "Aplicar" && obj.status != 'Cancelar'){
      params = "mr-1 btn btn-sm btn-info";
    } else if (obj.id != selection && obj.status != "Aplicar" && obj.status != 'Cancelar'){
      params = "mr-1 btn btn-sm btn-infoDisabled";
    } else if (obj.status == "Aplicar" && obj.id == selection){
      params = "mr-1 btn btn-sm btn-yellow";
    } else if(obj.status == "Aplicar" && obj.id != select){
      params = "mr-1 btn btn-sm btn-yellowDisabled";
    } else if(obj.status == "Cancelar" && obj.id == selection) {
      params = "mr-1 btn btn-sm btn-disabled";
    } else if(obj.status == "Cancelar" && obj.id != selection){
      params = "mr-1 btn btn-sm btn-disabledForce";
    }
    if(obj.trackings && obj.trackings.length > 0){
      obj.trackings.map(ob => {
        if(ob.status == 'Activo'){
          params = "mr-1 btn btn-sm btn-success";
        } else if(ob.status == 'Cancelado'){
          params = "mr-1 btn btn-sm btn-disabled";
      } else if(ob.status == 'Termino'){
        params = "mr-1 btn btn-sm btnPurpleBee";
      } else {
        params = "mr-1 btn btn-sm btn-success";
      }
      })
    }
    else if(obj.applications && obj.applications.length > 0){
      obj.applications.map(ob => {
        if(ob.status == 'Activo'){
          params = "mr-1 btn btn-sm btn-success";
        } else if(ob.status == 'Cancelado'){
          params = "mr-1 btn btn-sm btn-disabled";
      }
      })
    }

    return params;
  }

  function changeNotes(e) {
    setNotes(e.target.value);
}
function changeDate(e) {
    setDateReminder(e.target.value)
}
function changeTime(e) {
    setTimeReminder(e.target.value);
}
function changeTimeReminder(e) {
  let currendate = moment(dateReminder + " "+ timeReminder).format("YYYY-MM-DD HH:mm");
  let nowDatecomparison = moment(now + " "+ nowTime).format("YYYY-MM-DD HH:mm");
  let strings = e.target.value;
  strings = strings.charAt(1)  + strings.charAt(2);
  let nowDate = moment(currendate).subtract(parseInt(strings), 'hour').format("YYYY-MM-DD HH:mm");
  if(nowDate < nowDatecomparison){
      notification('warning','Cuidado,estas ingresando un rango de valor no permitido');
      setNotificationReminder("");
  } else {
      setNotificationReminder(e.target.value);
  }

}
function thisDay(){
  let date2 = new Date();
  date2.setDate(date2.getDate() + 1);
  setTimeReminder("09:00:00");
  setNotificationReminder("-0 hour");
  setDateReminder(date2.toISOString().substr(0, 10).replace('T', ' '));
}
function present() {
  setNow(moment().format("YYYY-MM-DD"));
  setNowTime(moment().format("HH:mm"));
}
  return (
    <div className="content">
      <div className="row mt-n5">
      <div className="col-10">
      {prospections && [
        prospections.map((pros) => {
          return (
            <button
            onClick={(e) => changeButton(pros.id)}
            key={pros.id}
            // active={{backgroundColor:'#FF0000'}}
            className={checkButton(pros)}
            // style={{
              //   backgroundColor:[selection === pros.id ?  '#0062cc' : '#51cbce']
              // }}
              >
              {pros.name_prospection}
            </button>
          );
        }),
      ]}
      <button
        onClick={(e) => changeModal()}
        type="button"
        className="montse ml-1 btn btn-success btn-sm"
        >
        +
      </button>
      </div>
      <div className="col">
      {prospections &&
      <div className="row">
        <div className="col-1">
        <button
        disabled ={ activeProspect.status == 'Aplicar' ? true : false}
        onClick={(e) => changeModal(1)}
        type="button"
        className="ml-n5 Inter btn btn-primary btn-sm"
        >
          Editar 
      </button>
     
        </div>
        <div className="col">
        {/* <button
        onClick={(e) => deleteProspection()}
        type="button"
        className="float-right  Inter btn btn-danger btn-sm"
        >
          Eliminar Prospeccion
      </button> */}
          </div>

      </div>
      }
      </div>
      </div>
      {load === true ?
        <Skeleton width="60rem" height={30} count={10} />

        :
        <>
          <div className="row">
            <div className="content col-4">
              <Form.Label className="formGray">Status</Form.Label>
              <Form.Control name="status"
                style={{letterSpacing:'0.2px'}}
                autoComplete="off" className="montse formGray" type="text"
                value={activeProspect.status}
                disabled = {activeProspect.status === 'Aplicar'}
              />
              <button
                className=" mt-1 float-right Inter btn-info  btn-sm"
                disabled = {activeProspect.status === 'Aplicar'}
                onClick={(e) => changeModalStatus()}><FIIcons.FiEdit size={16} style={{ color: 'white' }} /> </button>
            </div>
            <div className="content col-4">
              <Form.Label className="formGray">Story</Form.Label>
              <Form.Control
                disabled
                value={storyContact}
                as="textarea"
                placeholder="Escriba sus notas..."
                rows={8}
                cols={20}
                style={{letterSpacing:'0.2px' , paddingLeft: '10px' }}
              />

              <button
                className=" mt-1 float-right Inter btn-info  btn-sm"
                onClick={(e) => changeModalStory(1)}><FIIcons.FiEdit size={16} style={{ color: 'white' }} /> </button>
            </div>
            <div className="col-3">
              <Form.Label className="formGray">Advisor</Form.Label>
              <Form.Control name="last_modification"
                disabled
                autoComplete="off" className="formGray" type="text"
                placeholder="Ultima fecha"
                style={{letterSpacing:'0.2px'}}
                value={active.name_advisor}
              />
            
            </div>
          </div>
          <div className="mt-n5 row">
            
          <div className="content col-4">
              <Form.Label className="formGray">Substatus</Form.Label>
              <Form.Control name="status"
                disabled
                style={{letterSpacing:'0.2px'}}
                autoComplete="off" className="montse formGray" type="text"
                value={activeProspect.substatus}
              />
              <button
                className=" mt-1 float-right Inter btn-info  btn-sm"
                onClick={(e) => changeModalSubStatus()}><FIIcons.FiEdit size={16} style={{ color: 'white' }} /> </button>
            </div>

            <div className="mt-5 col-4">

            <Form.Label className="formGray">Nota de prospección</Form.Label>
            <Form.Control
                disabled
                value={noteProspection}
                as="textarea"
                placeholder="Escriba sus notas de prospección..."
                rows={8}
                cols={20}
                style={{letterSpacing:'0.2px' , paddingLeft: '10px' }}
              />
              <button
                className=" mt-1 float-right Inter btn-info  btn-sm"
                onClick={(e) => changeModalStory(2)}><FIIcons.FiEdit size={16} style={{ color: 'white' }} /> </button>

              </div>

            <div style={{marginLeft:'66.5%',marginTop:'-5%'}} className="content col-3">
              <Form.Label className="formGray">Ultimo contacto</Form.Label>
              <Form.Control name="last_modification"
                disabled
                autoComplete="off" className="formGray" type="text"
                placeholder="Ultima fecha"
                style={{letterSpacing:'0.2px'}}
                value={formatDate(activeProspect.last_modification)}
              />
            </div>
          </div>
          <div className="mt-5 row">
          <h6 style={{marginLeft:'5px'}}>Propuestas</h6>
            <div className="mb-4 col-12">
              <Proposals
                blocked={activeProspect.status == "Aplicar" ? true : activeProspect.status == "Cancelar" ? true : false}
                updateReminders={updateReminders} activeProspect={activeProspect} />
            </div>
          </div>
          <div className="mt-5 row">
            <h6 >Recordatorios</h6>
            <div className="mt-5 col-12">
              <Reminders
                route={'Prospeccion'}
                blocked={activeProspect.status == "Aplicar" ? true : activeProspect.status == "Cancelar" ? true : false}
                activeProspect={activeProspect} prospection={true} />
            </div>
          </div>
          <div className="mt-5 row">
            <h6>Bitácora</h6>
            <div className="ml-n4  col-12">
              <Bio
              route={'General'}
                extern={true}
                blocked={activeProspect.status == "Aplicar" ? true : activeProspect.status == "Cancelar" ? true : false}
                activeProspect={activeProspect} />
            </div>
          </div>
        </>
      }



      {/* Modal prospeccion */}
      <Modal
        show={modalProspection}
        dialogClassName="modalMax"
        onHide={closeModal}
        // dialogClassName="modal-90w"
      >
        <Modal.Header style={{ height: "60px" }} closeButton>
          <Modal.Title
            style={{
              fontFamily: "Inter",
              marginTop: "5px",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5F6", border: "0px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid">
              <Row>
              <NotificationAlert ref={notificationAlert} />
                <Col className="col-4">
                  <Form.Label className="formGray">Programa</Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      setObjAux({ ...objAux, program: e.target.value })
                    }
                    disabled={flagEdit == true ? true : false}
                    autoComplete="off"
                    name="program"
                    // ref={student({
                    //   required: true,
                    // })}
                    as="select"
                    size="sm"
                    custom
                  >
                    <option disabled value="" selected></option>
                    {programs.map((pro) => (
                      <option key={pro} value={pro}>
                        {pro}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col className="col-2">
                  <Form.Label className="formGray">Año</Form.Label>
                  <Form.Control
                    onChange={(e) =>
                      setObjAux({ ...objAux, year: e.target.value })
                    }
                    autoComplete="off"
                    name="year"
                    value={objAux.year}
                    // ref={student({
                    //   required: true,
                    // })}
                    as="select"
                    size="sm"
                    custom
                  >
                    <option disabled value="" selected></option>
                    {years.map((y) => (
                      <option key={y} value={y}>
                        {y}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <Button
                  className="float-right mb-3 mr-2"
                  type="button"
                  disabled={enableButton == true ? true 
                    : !objAux.program ? true 
                    :  !objAux.year ? true 
                    : false }
                  onClick={(e) => handleMiddle()}
                  variant="info"
                >
                  Guardar
                </Button>
                <Button
                  onClick={closeModal}
                  className="float-right mb-3 mr-2 montse btnBee"
                  >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>

      {/* End Modal prospection*/}
 {/* Four Modal */}
 <Modal
                show={modalReminder}
                onHide={closeModal}
                // dialogClassName="modal-90w"
                style={{marginTop:'50px', height: '500px', maxHeight: '604px' }}
                >
                <Modal.Header style={{height:'60px'}} closeButton>
                <Modal.Title style={{ fontFamily: 'Inter', marginTop:'5px', fontWeight: '600', fontSize: '18px' }}>Agregar Recordatorio</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: '#F4F5F6', border: '0px' }}>
                <NotificationAlert ref={notificationAlert} />
                <form onSubmit={handleSubmit(onSubmit)}>
                <Row className="mt-3">
                                <Col>
                                    <Form.Label className="formGray">Notas</Form.Label>
                                    <InputGroup style={{ borderTop: '0', width: '100%', marginTop: '0px' }}>
                                        <Form.Control
                                            onChange={(e) => changeNotes(e)}
                                            value={notes} as="textarea" placeholder="Escriba su mensaje..." rows={8} />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col >
                                    <Form.Control style={{ height: '100px', width: '180px' }}
                                        onChange={(e) => changeDate(e)}
                                         disabled
                                        value={dateReminder} autoComplete="off" name="date"
                                        className="formGray" type="date" placeholder="Ingrese su Fecha" />
                                </Col>
                                <Col className="mt-4">
                                    <Form.Control style={{ height: '30px', width: '150px' }}
                                        onChange={(e) => changeTime(e)}
                                         disabled
                                        value={timeReminder} autoComplete="off" name="date"
                                        className="formGray" type="time" placeholder="Ingrese su Fecha" />
                                </Col>
                                <Col className="col-5">
                                    <Form.Label className="formGray">Notificación</Form.Label>
                                    <Form.Control onChange={(e) => changeTimeReminder(e)}
                                        autoComplete="off"
                                         disabled
                                        value={notificationReminder} name="type" as="select" size="sm" custom>
                                        <option disabled selected value=""></option>
                                        <option value="-0 hour">Misma hora</option>
                                        <option value="-1 hour">1 Hora Antes</option>
                                        <option value="-24 hour">1 Dia Antes</option>
                                        <option value="-48 hour">2 Dias Antes</option>
                                        <option value="-168 hour">1 Semana Antes</option>
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Button 
                             disabled ={!notes}
                             onSubmit={handleSubmit(onSubmit)}
                             className="float-right mb-3 mr-2" type="submit" variant="info">Guardar</Button>
                             </form>
                </Modal.Body>

            </Modal>


      {/* Modal Status */}
      <Modal
        show={modalStatus}
        dialogClassName="modalMax"
        onHide={closeModal}
        // dialogClassName="modal-90w"
      >
        <Modal.Header style={{ height: "60px" }} closeButton>
          <Modal.Title
            style={{
              fontFamily: "Inter",
              marginTop: "5px",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Modificar Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5F6", border: "0px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid">
              <Row>
                <Col className="col-4">
                  <Form.Control
                    onChange={(e) => changeStatus(e)}
                    autoComplete="off"
                    name="year"
                    value={activeProspect.status}
                    as="select"
                    size="sm"
                    custom
                  >
                    <option value="Evaluación" selected>
                      Evaluación
                </option>
                    {status.map((st) => (
                      <option key={st} value={st}>
                        {st}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <Button
                  className="float-right mb-3 mr-2 montse"
                  type="button"
                  onClick={(e) => saveChanges()}
                  variant="info"
                >
                  Guardar
                </Button>
                <Button
                  onClick={closeModal}
                  className="float-right mb-3 mr-2 montse btnBee"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>

      {/* End modal Status */}

      {/* Modal Substatus */}
       <Modal
        show={modalSubstatus}
        dialogClassName="modalMax"
        onHide={closeModal}
        // dialogClassName="modal-90w"
      >
        <Modal.Header style={{ height: "60px" }} closeButton>
          <Modal.Title
            style={{
              fontFamily: "Inter",
              marginTop: "5px",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Modificar Substatus
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5F6", border: "0px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid">
              <Row>
                <Col className="col-4">
                  <Form.Control
                    onChange={(e) => changeSubstatus(e)}
                    autoComplete="off"
                    name="year"
                    value={activeProspect.substatus}
                    as="select"
                    size="sm"
                    custom
                  >
                    <option value="1" selected>
                      1
                </option>
                <option key="2" value="2">
                        2
                      </option>
                      <option key="3" value="3">
                        3
                      </option>
                  </Form.Control>
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <Button
                  className="float-right mb-3 mr-2 montse"
                  type="button"
                  onClick={(e) => saveSubstatus()}
                  variant="info"
                >
                  Guardar
                </Button>
                <Button
                  onClick={closeModal}
                  className="float-right mb-3 mr-2 montse btnBee"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>

      {/* End modal Substatus */}

      {/* Modal Grouped */}
      <Modal
        show={modalGrouped}
        dialogClassName="modalMax"
        onHide={closeModal}
        // dialogClassName="modal-90w"
      >
        <Modal.Header style={{ height: "60px" }} closeButton>
          <Modal.Title
            style={{
              fontFamily: "Inter",
              marginTop: "5px",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Seleccionar Colegio
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5F6", border: "0px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid">
              <Row>
                <Col className="tabpanel col-12">
                  <Tabs >
                    {filterColleges &&
                    [ Object.keys(filterColleges).map((oneKey,i)=>{
                      return(
                      <Tab eventKey={oneKey} title={oneKey}>
                      <CustomComponent theSelection={theSelection} obj={filterColleges[oneKey]} />
                    </Tab>
                      )
                    })]}
                  </Tabs>
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <Button
                  disabled={enableButton == true ? true : false}
                  className="float-right mb-3 mr-2"
                  type="button"
                  onClick={(e) => saveChanges()}
                  variant="info"
                >
                  Crear
                </Button>
                <Button
                  onClick={closeModal}
                  className="float-right mb-3 mr-2 montse btnBee"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>

      {/* End modal modalGrouped */}


      {/* Modal Status */}
      <Modal
        show={modalStory}
        dialogClassName="modalMax"
        onHide={closeModal}
        // dialogClassName="modal-90w"
      >
        <Modal.Header style={{ height: "60px" }} closeButton>
          <Modal.Title
            style={{
              fontFamily: "Inter",
              marginTop: "5px",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
           {editingStoryOrNote ? 'Modificar Story' : 'Modificar Notas de prospección' } 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5F6", border: "0px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid">
              <Row>
                <Col className="col-8">
                  <Form.Label className="formGray">
                  {editingStoryOrNote ? 'Story' : 'Notas de prospección' } 

                  </Form.Label>
                  <Form.Control
                    onChange={(e) => changeStory(e)}
                    value={ editingStoryOrNote ? storyContact : noteProspection}
                    as="textarea"
                    placeholder=           
                    {editingStoryOrNote ? "Escriba sus notas..." : "Escriba sus notas de prospección...." } 
                    style={{letterSpacing:'0.2px' , paddingLeft: '10px' }}
                    rows={12}
                    cols={12}
                  />
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <Button
                  className="float-right mb-3 mr-2"
                  type="button"
                  onClick={(e) => editingStoryOrNote ? updateStoryContact() : updateNoteProspection()}
                  variant="info"
                >
                  Guardar
                </Button>
                <Button
                  onClick={closeModal}
                  className="float-right mb-3 mr-2 montse btnBee"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
      {/* End modal Story */}


      {/* Modal Advisor */}
      <Modal
        show={modalAdvisor}
        dialogClassName="modalMax"
        onHide={closeModal}
        // dialogClassName="modal-90w"
      >
        <Modal.Header style={{ height: "60px" }} closeButton>
          <Modal.Title
            style={{
              fontFamily: "Inter",
              marginTop: "5px",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Cambiar Advisor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5F6", border: "0px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid">
              <Row>
                <Col className="col-6">
              <Form.Label className="formGray">Usuario</Form.Label>
                  <Form.Control
                    onChange={(e) =>changeAdvisor(e)}
                    autoComplete="off"
                    name="advisor"
                    value={theUser}
                    as="select"
                    size="sm"
                    custom
                  >
                    <option disabled value="" selected></option>
                    {users.map((pro) => (
                      <option key={pro.id} value={pro.id}>
                        {pro.name} {pro.father_lastname} {pro.mother_lastname}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <Button
                  className="float-right mb-3 mr-2"
                  type="button"
                  onClick={(e) => updatedContact()}
                  variant="info"
                >
                  Guardar
                </Button>
                <Button
                  onClick={closeModal}
                  className="float-right mb-3 mr-2 montse btnBee"
                  >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
      {/* End modal Advisor */}

    </div>
  );
}
