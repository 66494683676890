import React,{ useState,useEffect } from 'react'
import  'bootstrap/dist/css/bootstrap.min.css';
import '../styles/GlobalStyles.css';
import { Row, Col, Button, FormControl,Form  } from 'react-bootstrap';
import MultipleModals from '../components/MultipleModals';

import TableContacts from '../components/contactComponents/TableContacts.jsx';
import '../cssResponsive/contacSectionResponsive/contacts.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import SearchBar from '../components/GeneralComponents/SearchBar';

const goRouter = function (param){
}
function Contacts() {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [obj, setObj] = useState([]);
    const [param,setParam] = useState(null);
    const [reload,setReload] = useState(false);
    const [ rowsDisplayed , setRowsToDisplayed ] = useState([]);
    const [ref,setRef] = useState({
        name:'ref',
        value:'ref',
        isChecked:false
    });
    useEffect(() => {
    }, [param]);
    const consult = (e) => {
        let fullnameFormated  = e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        e.target.value === "" ?  setParam('keyWordSeccret302') :  setParam(fullnameFormated);
    }
    const changeChecked = () => {
        setRef({...ref,isChecked:!ref.isChecked});
    }
    const asignObj = (e) => {
        setObj(e);
    }
    const consultRow = (e) => {
        if(e){
            if(e.target.value == ""){
                setParam('keyWordSeccret302');
            }
        } else{
           setReload(!reload);
        }
    }
    const updateResults = ( obj ) => {
        setRowsToDisplayed(obj);
    }
    return (
        <div style={{backgroundColor:'#f9fafb'}} className="content animate__animated animate__fadeIn">
        <div className="row">
            <div className="col-12">
            <div className="col btnsSuperior">
            <MultipleModals  
             obj = { rowsDisplayed }
            // obj={obj}
             consult={consultRow}/>
            </div>
                <div className="searchBarTable">
                        <SearchBar consult={(e) => consult(e)}/>
                </div>
              <TableContacts reloadTable={reload}  
              updateResults = { updateResults } 
            //   newObj={(e) =>asignObj(e)} 
              noexecute={ref.isChecked} param={param} refe={ref} />
            </div>
        </div>  
        </div>
    )
}

export default Contacts
