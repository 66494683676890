import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  InputGroup,
  Popover,
  OverlayTrigger,
  FormControl,
  Tabs,
  Tab,
} from "react-bootstrap";

import React, { useState, useEffect } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import axios from 'axios';
import { constaApi } from '../../constants/constants';
import StarRatings from 'react-star-ratings';
import SearchBar from '../../components/GeneralComponents/SearchBar';
import { Select } from "antd";
var _ = require('lodash');

export default function TableRating(props) {
  const [users,setUsers] = useState([]);
  const [programs,setPrograms] = useState([]);

  useEffect(() => {
    let grouped = _.groupBy(props.rows,"name_prospection");
    let orderBY = _.orderBy(props.rows, ['name_prospection'], 
             ['asc']);
    
    setPrograms(_.groupBy(orderBY,"name_prospection"));
    // setPrograms(_.groupBy(props.rows,"name_prospection"));
    // Object.keys(programsGrouped).map((name, i) => {
    // });
    // setPrograms(programsGrouped);
    axios.post(constaApi + "allUsers")
    .then(function (response) {
      setUsers(response.data);
    });

    if (props.rows) {
      let totalRatings = [];
      let objTotal =  { program: 'TOTAL', evaluation: 0, cF: 0, cP: 0, p: 0, aD: 0, deC: 0, aP: 0, tC: 0, tR: 0 };
      const ratinGroup = _.groupBy(props.rows, "rating")
      Object.keys(ratinGroup).map((name, i) => {
        let objRating = { program: i, evaluation: 0, cF: 0, cP: 0, p: 0, aD: 0, deC: 0, aP: 0, tC: 0, tR: 0 };
        ratinGroup[name].map(rG => {
          switch (rG.status) {
            case 'Decisión':
              objRating.deC = objRating.deC + 1;
              break;
            case 'Evaluación':
              objRating.evaluation = objRating.evaluation + 1;
              break;
            case 'Contacto Previo':
              objRating.cP = objRating.cP + 1;
              break;
            case 'Contacto Formal':
              objRating.cF = objRating.cF + 1;
              break;
            case 'Presentación':
              objRating.p = objRating.p + 1;
              break;
            case 'Aclaración de dudas':
              objRating.aD = objRating.aD + 1;
              break;
            case 'Aplicar':
              objRating.aP = objRating.aP + 1;
              break;
          }
        })
        objRating.tC = objRating.deC + objRating.evaluation + objRating.cP + objRating.cF + objRating.p + objRating.aD + objRating.aP;
        objTotal.tC += objRating.tC;
        objTotal.evaluation += objRating.evaluation;
        objTotal.deC += objRating.deC;
        objTotal.cP += objRating.cP;
        objTotal.cF += objRating.cF;
        objTotal.p += objRating.p;
        objTotal.aD += objRating.aD;
        objTotal.aP += objRating.aP;

        totalRatings.push(objRating);
      });
      totalRatings.push(objTotal);
      setRatingTotal(totalRatings);
      objTotal = { program: 'TOTAL', evaluation: 0, cF: 0, cP: 0, p: 0, aD: 0, deC: 0, aP: 0, tC: 0, tR: 0 };
    }
  }, [props.rows])
  const [programSearch,setProgramSearch] = useState(null);
  const [advisor,setAdvisor] = useState(null);
  const [frameworkComponents, setFramwrokw] = useState({ slotProgram: SlotProgram,slotTotal:SlotTotal });
  const [ratingTotal, setRatingTotal] = useState([]);
  const [selectValue, setSelectValue] = useState();
  const [gridApi, setGridApi] = useState(null);
  const [newObj, setNewObj] = useState([]);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [param, setParam] = useState(null);
  const [rowsRating, setRowsRating] = useState([]);
  const [values, setValues] = useState([
    {

      value: 0,
      label: 0
    },
    {
      value: 1,
      label: 1,
    },
    {
      value: 2,
      label: 2,
    },
    {
      value: 3,
      label: 3,
    },
    {
      value: 4,
      label: 4,
    },
    {
      value: 5,
      label: 5,
    },
    {
      value: 'General',
      label: 'General'
    }
  ]);
  const [substatus,setSubstatus] = useState([
    {value:1,label:1},
    {value:2,label:2},
    {value:3,label:3},
  ])
  const consult = (e) => {
    e.target.value === "" ? quickSearch('keyWordSeccret302') : quickSearch(e.target.value);
  }
  const quickSearch = async (value) => {
    let objx = gridApi;
    value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
    setGridApi(objx);
  }
  const onGridReady = (params) => {
    setGridApi(params);
    setGridColumnApi(params);
  };

  const searchProgram = async (e) => {
    const {target} = e;
    let aux = [];
    setProgramSearch(target.value);
    props.rows.map(p => {
      if(advisor){
        if(p.id_advisor == advisor){
          if(p.name_prospection == target.value){
            aux.push(p);
          }
        }
      }else {
        if(p.name_prospection == target.value){
          aux.push(p);
        }
      }
    })
    setRowsRating([]);
    await handleChange(target.value,aux,1)
  }

  const searchSubstatus = async (e) => {
    const {target} = e;
    let aux = [];
    props.rows.map(p => {
      if(p.substatus == target.value){
        if(advisor){
          if(p.id_advisor == advisor){
            if(programSearch){
              if(p.name_prospection == programSearch){
                aux.push(p);
              }
            } else {
              aux.push(p);
            }
          }
        } else {
          aux.push(p);
        }
      }
    })
    setRowsRating([]);
    if(aux.length != 0){
      await handleChange(selectValue,aux);
    }
  }

  const searchAdvisor = async (e) => {
    const {target} = e;
    setAdvisor(e.target.value);
    let aux = [];
    props.rows.map(p => {
      if(selectValue){
        if(p.rating == selectValue[0].label){
          if(p.id_advisor == target.value){
            aux.push(p);
          }
        }
      }else {
        if(p.id_advisor == target.value){
          aux.push(p);
        }
      }
    })
    setRowsRating([]);
    if(aux.length != 0){
      await handleChange(selectValue,aux);
    }
  }
  const handleChange = (e , aux = [],option = 0) => {
    setSelectValue(e);
    let orderBY = _.orderBy(props.rows, ['name_prospection'], 
    ['asc']);
    let finArray = [];
    let obj = { program: '', evaluation: 0, cF: 0, cP: 0, p: 0, aD: 0, deC: 0, aP: 0, tC: 0, tR: 0 };
    let objTotal = { program: 'TOTAL', evaluation: 0, cF: 0, cP: 0, p: 0, aD: 0, deC: 0, aP: 0, tC: 0, tR: 0 };
    if(e){
    if (e[0].value === 'General') {
      setRowsRating(ratingTotal);
    } else {
      if(!aux.length > 0 && option == 0){
        orderBY.map(r => {
          e.map(v => {
            if (v.value == r.rating) {
              aux.push(r);
            }
          });
        })
      } else if(!aux.length > 0 && option == 1){
        orderBY.map(r => {
          if(e == r.name_prospection){
            aux.push(r);
          }
        })
      }
      const result = _.groupBy(aux, "name_prospection")
      Object.keys(result).map((name, i) => {
        obj.program = name;
        result[name].map(re => {
          switch (re.status) {
            case 'Decisión':
              obj.deC = obj.deC + 1;
              break;
            case 'Evaluación':
              obj.evaluation = obj.evaluation + 1;
              break;
            case 'Contacto Previo':
              obj.cP = obj.cP + 1;
              break;
            case 'Contacto Formal':
              obj.cF = obj.cF + 1;
              break;
            case 'Presentación':
              obj.p = obj.p + 1;
              break;
            case 'Aclaración de dudas':
              obj.aD = obj.aD + 1;
              break;
          }
        })
        obj.tC = obj.deC + obj.evaluation + obj.cP + obj.cF + obj.p + obj.aD + obj.aP;
        objTotal.tC += obj.tC;
        objTotal.evaluation += obj.evaluation;
        objTotal.deC += obj.deC;
        objTotal.cP += obj.cP;
        objTotal.cF += obj.cF;
        objTotal.p += obj.p;
        objTotal.aD += obj.aD;
        objTotal.aP += obj.aP;

        finArray.push(obj);
        obj = { program: '', evaluation: 0, cF: 0, cP: 0, p: 0, aD: 0, deC: 0, aP: 0, tC: 0 };
      })
      finArray.push(objTotal);
      objTotal = { program: 'TOTAL', evaluation: 0, cF: 0, cP: 0, p: 0, aD: 0, deC: 0, aP: 0, tC: 0, tR: 0 };
      setRowsRating(finArray);

    }
  }
  }
  return (
    [props.rows &&
      <div className='mt-3'>
        <div className='row'>
          <div className='col-3 col-xs-3 col-lg-3 col-md-3'>
            <Select
              isMulti
              name="values"
              value={selectValue}
              onChange={(e) => handleChange(e)}
              className="basic-multi-select"
              classNamePrefix="select"
              options={values}
              placeholder="Seleccione el rating"
            />
          </div>
          <div className='col-3'>
          <Form.Control
                    onChange={(e) => searchAdvisor(e)}
                    autoComplete="off"
                    name="advisor"
                    // value={theUser}
                    as="select"
                    size="sm"
                    custom
                  >
                    <option disabled value="" selected></option>
                    {users.map((pro) => (
                      <option key={pro.id} value={pro.id}>
                        {pro.name} {pro.father_lastname} {pro.mother_lastname}
                      </option>
                    ))}
                  </Form.Control>
          </div>
          <div className='col-3'>
          <Form.Control
                    onChange={(e) => searchProgram(e)}
                    autoComplete="off"
                    name="advisor"
                    // value={theUser}
                    as="select"
                    size="sm"
                    custom
                  >
                    <option disabled value="" selected></option>
                     {
                            Object.keys(programs).map((name, i) => {
                              return(

                                <option key={name} value={name}>
                              {name}
                              </option>
                              )
                            })
                    }
                  </Form.Control>
          </div>
          <div className='col-3'>
          <Form.Control
                    onChange={(e) => searchSubstatus(e)}
                    autoComplete="off"
                    name="substatus"
                    // value={theUser}
                    as="select"
                    size="sm"
                    custom
                  >
                    <option disabled value="" selected></option>
                    {substatus.map((pro) => (
                      <option key={pro.value} value={pro.value}>
                        {pro.label} 
                      </option>
                    ))}
                  </Form.Control>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'>
            <SearchBar consult={(e) => consult(e)} />
          </div>
        </div>
        <div
          className="ag-theme-alpine mt-1"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            rowData={rowsRating}
            rowHeight={40}
            onGridReady={onGridReady}
            cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
            domLayout="autoHeight"
            rowClassRules={{
              'colorGrayAG': function (params) {
                var backColor = params.data.color;
                return params.data.color === 0;
              },
              'colorWhiteAG': 'data.color === -1',
            }}
            suppressRowTransform={true}
            //    pagination={true}
            //    paginationPageSize={10}
            frameworkComponents={frameworkComponents}
          //    paginationNumberFormatter={function (params) {
          //        return params.value.toLocaleString();
          //    }}
          //    rowSelection="multiple"
          >
            <AgGridColumn
              headerName="Programa"
              wrapText={true}
              field="program"
              cellRenderer="slotProgram"
              width={250}
            />
            <AgGridColumn
              field="evaluation"
              headerName="Evaluación"
              wrapText={true}
              width={100}
            />
            <AgGridColumn
              field="cP"
              headerName="Contacto previo"
              wrapText={true}
              width={150}
            />
            <AgGridColumn
              field="cF"
              headerName="Contacto formal"
              wrapText={true}
              width={150}
            />
            <AgGridColumn
              headerName="Presentación"
              field="p"
              wrapText={true}
              width={150}

            />
            <AgGridColumn
              headerName="Aclaración de dudas"
              field="aD" width={250}
            />
            <AgGridColumn
              headerName="Decisión"
              field="deC" width={150}
            />
        
            <AgGridColumn
              headerName="Total"
              cellRenderer="slotTotal"
              field="tC" width={150}
            />
          </AgGridReact>
        </div>
      </div>
    ]
  );
}

// Slots 
export const SlotProgram = function SlotProgram(props) {
  function whatIs(value) {
    return typeof value === 'string' ? 1 : 0;
  }
  const { value } = props;
  return (
    [
      whatIs(value) === 1
        ?
        <b>{value} </b>
        :
        <StarRatings
          rating={value}
          starDimension={'20px'}
          starEmptyColor={'gray'}
          starRatedColor={'#ffd200'}
          starHoverColor={'#ffd200'}
          starSpacing="2px"
          numberOfStars={5}
          name='rating'
        />
    ]
  )
}

export const SlotTotal = function SlotTotal(props){
  const {value} = props;
  return(
    <b>{value} </b>
  )
}