import {
    Button,
    Modal,
    Row,
    Col,
    Form,
    InputGroup,
    Popover,
    OverlayTrigger,
    FormControl,
    Tabs,
    Tab,
  } from "react-bootstrap";
  
  import React, { useState, useEffect } from 'react';
  import { AgGridReact, AgGridColumn } from 'ag-grid-react';
  import 'ag-grid-community/dist/styles/ag-grid.css';
  import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

  import StarRatings from 'react-star-ratings';
  import SearchBar from '../../components/GeneralComponents/SearchBar';
  var _ = require('lodash');
  
  export default function TableSubstatus(props) {
    const [users,setUsers] = useState([]);
    const [programs,setPrograms] = useState([]);
  
    useEffect(() => {
      handleChange([{value:2,label:2}],[]);
    }, [props.rows])
    const [frameworkComponents, setFramwrokw] = useState({ slotProgram: SlotProgram,slotTotal:SlotTotal });
    const [ratingTotal, setRatingTotal] = useState([]);
    const [selectValue, setSelectValue] = useState();
    const [gridApi, setGridApi] = useState(null);
    const [newObj, setNewObj] = useState([]);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [param, setParam] = useState(null);
    const [rowsRating, setRowsRating] = useState([]);
    const [values, setValues] = useState([
      {
  
        value: 0,
        label: 0
      },
      {
        value: 1,
        label: 1,
      },
      {
        value: 2,
        label: 2,
      },
      {
        value: 3,
        label: 3,
      },
      {
        value: 4,
        label: 4,
      },
      {
        value: 5,
        label: 5,
      },
      {
        value: 'General',
        label: 'General'
      }
    ]);
    const [substatus,setSubstatus] = useState([
      {value:1,label:1},
      {value:2,label:2},
      {value:3,label:3},
    ])
    const consult = (e) => {
      e.target.value === "" ? quickSearch('keyWordSeccret302') : quickSearch(e.target.value);
    }
    const quickSearch = async (value) => {
      let objx = gridApi;
      value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
      setGridApi(objx);
    }
    const onGridReady = (params) => {
      setGridApi(params);
      setGridColumnApi(params);
    };
  
    const searchProgram = async (e) => {
      const {target} = e;
      let aux = [];
      props.rows.map(p => {
        if(p.name_prospection == target.value){
          aux.push(p);
        }
      })
      setRowsRating([]);
      await handleChange(target.value,aux,1)
    }
  
    const searchSubstatus = async (e) => {
      const {target} = e;
      let aux = [];

      
      props.rows.map(p => {
        if(p.substatus == target.value){
          aux.push(p);
        }
      })
      setRowsRating([]);
      if(aux.length != 0){
        await handleChange([{value:2,label:2}],aux);
      }
    }
  
    const searchAdvisor = async (e) => {
      const {target} = e;
      let aux = [];
      props.rows.map(p => {
        if(p.id_advisor == target.value){
          aux.push(p);
        }
      })
      setRowsRating([]);
      if(aux.length != 0){
        await handleChange(selectValue,aux);
      }
    }
    const handleChange = (e , aux = [],option = 0) => {

      // setSelectValue(e);
      let finArray = [];
      let obj = { program: '', aplication: 0, adm: 0, acep: 0, vis: 0, tdv: 0, ud: 0, act: 0, tC: 0, tR: 0 };
      let objTotal = { program: 'TOTAL', aplication: 0, adm: 0, acep: 0, vis: 0, tdv: 0, ud: 0, act: 0, tC: 0, tR: 0 };
      if(e){
      if (e[0].value === 'General') {
        setRowsRating(ratingTotal);
      } else {
        if(!aux.length > 0 && option == 0){
          aux = props.rows;
          // props.rows.map(r => {
          //  e .map(v => {
          //     if (v.value == r.rating) {
          //       aux.push(r);
          //     }
          //   });
          // })
        } else if(!aux.length > 0 && option == 1){
          props.rows.map(r => {
            if(e == r.name_prospection){
              aux.push(r);
            }
          })
        }
        const result = _.groupBy(aux, "name_prospection")
        Object.keys(result).map((name, i) => {
          obj.program = name;
          result[name].map(re => {
            switch (re.APStatus) {
              case 'Admisión':
                obj.adm = obj.adm + 1;
                break;
              case 'Aplicación':
                obj.aplication = obj.aplication + 1;
                break;
              case 'Aceptación':
                obj.acep = obj.acep + 1;
                break;
              case 'Visado':
                obj.vis = obj.vis + 1;
                break;
              case 'Trámites de viaje':
                obj.tdv = obj.tdv + 1;
                break;
              case 'Ultimos detalles':
                obj.ud = obj.ud + 1;
                break;
                case 'Activo':
                  obj.act = obj.act + 1;
            }
          })
          obj.tC = obj.adm + obj.aplication + obj.acep + obj.vis + obj.tdv + obj.ud + obj.act;
          objTotal.tC += obj.tC;
          objTotal.aplication += obj.aplication;
          objTotal.adm += obj.adm;
          objTotal.acep += obj.acep;
          objTotal.vis += obj.vis;
          objTotal.tdv += obj.tdv;
          objTotal.ud += obj.ud;
          objTotal.act += obj.act;
  
          finArray.push(obj);
          obj = { program: '', aplication: 0, adm: 0, acep: 0, vis: 0, tdv: 0, ud: 0, act: 0, tC: 0 };
        })
        finArray.push(objTotal);
        objTotal = { program: 'TOTAL', aplication: 0, adm: 0, acep: 0, vis: 0, tdv: 0, ud: 0, act: 0, tC: 0, tR: 0 };
        setRowsRating(finArray);
  
      }
    }
    }
    return (
      [props.rows &&
        <div className='mt-3'>
          <div className='row'>
           
           
            
            <div className='col-3'>
            <Form.Control
                      onChange={(e) => searchSubstatus(e)}
                      autoComplete="off"
                      name="substatus"
                      // value={theUser}
                      as="select"
                      size="sm"
                      custom
                    >
                      <option disabled value="" selected></option>
                      {substatus.map((pro) => (
                        <option key={pro.value} value={pro.value}>
                          {pro.label} 
                        </option>
                      ))}
                    </Form.Control>
            </div>
          </div>
          <div className='row'>
            <div className='col-4'>
              <SearchBar consult={(e) => consult(e)} />
            </div>
          </div>
          <div
            className="ag-theme-alpine mt-1"
            style={{ height: '100%', width: '100%' }}
          >
            <AgGridReact
              rowData={rowsRating}
              rowHeight={40}
              onGridReady={onGridReady}
              cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
              domLayout="autoHeight"
              rowClassRules={{
                'colorGrayAG': function (params) {
                  var backColor = params.data.color;
                  return params.data.color === 0;
                },
                'colorWhiteAG': 'data.color === -1',
              }}
              suppressRowTransform={true}
              //    pagination={true}
              //    paginationPageSize={10}
              frameworkComponents={frameworkComponents}
            //    paginationNumberFormatter={function (params) {
            //        return params.value.toLocaleString();
            //    }}
            //    rowSelection="multiple"
            >
              <AgGridColumn
                headerName="Programa"
                wrapText={true}
                field="program"
                cellRenderer="slotProgram"
                width={250}
              />
              <AgGridColumn
                field="aplication"
                headerName="Aplicación"
                wrapText={true}
                width={100}
              />
              <AgGridColumn
                field="adm"
                headerName="Admisión"
                wrapText={true}
                width={150}

              />
              <AgGridColumn
                field="acep"
                headerName="Aceptación"
                wrapText={true}
                width={150}
              />
              <AgGridColumn
                headerName="Visado"
                field="vis"
                wrapText={true}
                width={150}
  
              />
              <AgGridColumn
                headerName="Tramites de viaje"
                field="tdv" width={250}
              />
              <AgGridColumn
                headerName="Ultimos detalles"
                field="ud" width={150}
              />
          
              <AgGridColumn
                headerName="Activo"
                field="act" width={150}
              />
               <AgGridColumn
              headerName="Total"
              cellRenderer="slotTotal"
              field="tC" width={150}
            />
            </AgGridReact>
          </div>
        </div>
      ]
    );
  }
  
  // Slots 
  export const SlotProgram = function SlotProgram(props) {
    function whatIs(value) {
      return typeof value === 'string' ? 1 : 0;
    }
    const { value } = props;
    return (
      [
        whatIs(value) === 1
          ?
          <b>{value} </b>
          :
          <StarRatings
            rating={value}
            starDimension={'20px'}
            starEmptyColor={'gray'}
            starRatedColor={'#ffd200'}
            starHoverColor={'#ffd200'}
            starSpacing="2px"
            numberOfStars={5}
            name='rating'
          />
      ]
    )
  }
  
  export const SlotTotal = function SlotTotal(props){
    const {value} = props;
    return(
      <b>{value} </b>
    )
  }