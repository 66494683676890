import React , { useState,useEffect } from 'react'
import '../../styles/flexbox/responsiveGrid.css';
import Select from 'react-select';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { constaApi } from '../../constants/constants';
import { result } from 'lodash';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import SearchBar from '../../components/GeneralComponents/SearchBar';
import axios from 'axios';

export default function AnaliticsPrograms() {


  // var

  const [frameworkComponents, setFramwrokw] = useState(
    { 
      slotSource: SlotSource,
       slotCollege: SlotCollege,
       slotGradeSuggered : SlotslotSuggestGrade ,
       slotOptionSource : SlotOptionSource
       }
    );

    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const [newObj, setNewObj] = useState([]);
    const headers = [
      { label: "Contacto", key: "nameContact" },
      { label: "Ciudad", key: "city" },
      { label: "Colegio", key: "name" },
      { label: "Pais", key: "country" },
      { label: "Colegio Local", key: "localCollege" },
      { label: "Grado sugerido", key: "suggestgrade" },
      { label: "Source", key: "sourceFormated" },
    ];


    const csvReport = {
      data: newObj,
      headers: headers,
      filename: 'AnaliticsReport.csv'
    };


  const [results,setResults] = useState([]);
  const [selectProgram,setSelectProgram] = useState();
  const [unionPrograms,setUnionPrograms] = useState();
  const [program, setProgram] = useState(["Boarding School",
  "School District",
  "Summer Camp",
  "Language School",
  "University/College",
  "Work & Travel"]);
  const years = [
      2019,
      2020,
      2021,
      2022,
      2023,
      2024,
      2025,
      2026,
      2027,
      2028,
      2029,
      2030
  ];

  // Methods


  const calculatedsuggestgrade = (data) => {
    let gradeResult = '';
    let hoy = moment().locale("es-mx").format("MM");
    if (data.name_prospection.includes('Boarding') || data.name_prospection.includes('District')) {
      if (data.cicly) {
        let sub = data.cicly.split('-');
        if (data.grade) {
          let grad = data.grade.split(" ");
          let yearProspection = data.name_prospection.split(" ");
          let result = (parseInt(grad[1]) + (parseInt(yearProspection[2]) - parseInt(sub[0])));
          gradeResult = result;
        }
      }
    } else {
      if(data.cicly){
        if (data.cicly) {
          let sub = data.cicly.split('-');
          if (data.grade) {
            let grad = data.grade.split(" ");
            let yearProspection = data.name_prospection.split(" ");
            let result = (parseInt(grad[1]) + (parseInt(yearProspection[2]) - parseInt(sub[0])));
            gradeResult = result;
  
          }
        };
      }
    }
    

    return gradeResult;
  }


  const exporta = () => {
    if (newObj.length > 0) {
    } else {
      setNewObj(results);
    }
  }

  function changeProgram (e){
    setSelectProgram(e);
  }



  useEffect(() => {

    getHistoryAnalitics();

  },[selectProgram])

  useEffect(() => {
    let x = [];
     program.map(pr => {
      years.map(ye => {
        let a = {
        name:  pr + ' ' + ye,
        value: pr + ' ' + ye,
        label: pr + ' ' + ye,
        };
       x.push(a);
      })
    })
    setUnionPrograms(x);
  
  },[])

  const getHistoryAnalitics = () => {
    let finalArray = [];
    axios.post(constaApi + 'analiticsHistory',{ name_prospection:selectProgram?.name ?? ""})
            .then(function (response) {
                if(response.data){
                  finalArray =  response.data.map(result => {
                    return {
                      ...result,
                      nameContact: result.nameContact ?? "",
                      city: result.city ?? "",
                      name: result.name ?? "",
                      localCollege: result.schoool == 'Otro' ? result.other_School : result.schoool,
                      suggestgrade: calculatedsuggestgrade(result),
                      sourceFormated : result.OptionSource ? result.OptionSource : result.TypeSource ? result.TypeSource : ""
                    }
                   })
                   
                } else {

                }
            })
            .finally(() => {
              setResults(finalArray);
            }).catch(err =>{
                // setLoading(false);
            });

  }

  const onGridReady = (params) => {
    setGridApi(params);
    setGridColumnApi(params);
  };



  const searchOnTable = (e) => {
    let fullnameFormated  = e.target.value ;
    // e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    e.target.value === "" ?  quickSearch('keyWordSeccret302') :  quickSearch(fullnameFormated);

  }



  async function quickSearch(value) {
		let objx = gridApi;
		value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
		setGridApi(objx);
		let rowsDisplay = objx.api.rowRenderer.rowModel.rowsToDisplay;
		let result;
		if (rowsDisplay) {
			result = await rowsDisplay.map(row => {
				let { data } = row;
				return data;
			})

      setNewObj(result);
			// props.newObj(result);
		}
	}



  return (
    <div className='content'>
      {/* content */}
      <div className='rowResponsive'>
        {/* First Column */}
        <div className='columnResponsive _25'>
          
        <Select
                        name="values"
                         value={selectProgram}
                         onChange={(e) => changeProgram(e)}
                        // defaultValue={[values[0]]}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Seleccionar programa"
                        options={unionPrograms}
         />

        </div>
        {/* Second Column */}
        <div className='columnResponsive _55'>
          
          {results.length >0 &&
          <strong>
            Total estudiantes : {results.length}
            </strong>
          }
        </div>
        {/* Three Column */}
        <div className='columnResponsive '>

        <button
          style={{ float: 'right', textDecoration: 'white' }}
          className="mt-n5 btn btn-sm btn-info" onClick={(e) => exporta(e)}
        >
          <CSVLink {...csvReport}><span>Exportar</span>
          </CSVLink>
        </button>

        </div>
      </div>
      {/* end div rowResponsive */}

        <div style={{marginTop:'1%'}} className='rowResponsive'>
        <div className="searchBarTable">
          <SearchBar consult={(e) => searchOnTable(e)} />
        </div>
        </div>
      {/* Three Row Responsive */}
      <div  className='rowResponsive'>
      <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
          
            defaultColDef={{ resizable: true }}
            rowData={results}
            rowHeight={40}
            domLayout="autoHeight"
             onGridReady={onGridReady}
            //  onFirstDataRendered={onFirstDataRendered}
            frameworkComponents={frameworkComponents}
            pagination={true}
            paginationPageSize={10}
            paginationNumberFormatter={function (params) {
              return params.value.toLocaleString();
            }}
            rowSelection="multiple"
          >
            <AgGridColumn
              headerName="Contacto" sortable={true} field="nameContact" width="250" />
            
            <AgGridColumn sortable={true}headerName="Ciudad " field="city" width="150" />
            
            
            <AgGridColumn headerName="Colegio"sortable={true}  field="name" width="350" />

            <AgGridColumn sortable={true} headerName="Pais" field="country" width="150" />

            <AgGridColumn headerName="Colegio local"  cellRenderer="slotCollege" sortable={true}  width="250" />
            
            
            <AgGridColumn headerName="Grado sugerido"  cellRenderer ="slotGradeSuggered" sortable={true}  width="200" />


            
            <AgGridColumn headerName="Source" field="TypeSource" sortable={true}  width="250" />


            <AgGridColumn headerName="Tipo" cellRenderer="slotOptionSource"  sortable={true}  width="350" />



          </AgGridReact>
        </div>


      </div>




      {/* End second Row  */}





      </div>


        

)
}


// SLOTS

export const SlotOptionSource = function (props){

  const {data } = props;
  const { TypeSource , OptionSource } = data;

  const getSource = () => {
    
    if(TypeSource == 'Recomendado por'){

      return data.source_complete[0]?.name_recom + " " + data.source_complete[0]?.father_recom + " " + data.source_complete[0]?.mother_recom;

    }else if( TypeSource == 'Otro'){


      return data.source_complete[0]?.other_source ;


    } else {

      return OptionSource;
    }
    
  }


  return (
    
    <span>{getSource()}</span>
  )



}
export const SlotslotSuggestGrade = function (props) {
  const { data } = props
  const [grade, setGrade] = useState("");

  
  const calculatedsuggestgrade = (data) => {
    let gradeResult = '';
    let hoy = moment().locale("es-mx").format("MM");
    if (data.name_prospection.includes('Boarding') || data.name_prospection.includes('District')) {
      if (data.cicly) {
        let sub = data.cicly.split('-');
        if (data.grade) {
          let grad = data.grade.split(" ");
          let yearProspection = data.name_prospection.split(" ");
          let result = (parseInt(grad[1]) + (parseInt(yearProspection[2]) - parseInt(sub[0])));
          gradeResult = result;
        }
      }
    } else {
      if(data.cicly){
        if (data.cicly) {
          let sub = data.cicly.split('-');
          if (data.grade) {
            let grad = data.grade.split(" ");
            let yearProspection = data.name_prospection.split(" ");
            let result = (parseInt(grad[1]) + (parseInt(yearProspection[2]) - parseInt(sub[0])));
            gradeResult = result;
  
          }
        };
      }
    }
    

    return gradeResult;
  }

  useEffect(() => {
   
    // calculatedsuggestgrade(data);

  }, [])

  return (
    <span>{calculatedsuggestgrade(data)}</span>
  )

}


export const SlotSource = function SlotSource(props){
  const { TypeSource, OptionSource } = props.data;

  return (
    <>
    <span>{OptionSource ? OptionSource : TypeSource ? TypeSource : ""}</span>

    </>
  )
}
export const SlotCollege = function SlotCollege(props) {
const { other_School, schoool } = props.data;

return (
<>
<span>{schoool == 'Otro' ? other_School : schoool}</span>
</>
)
}