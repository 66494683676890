import React, { useState, useEffect, useRef } from 'react'
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/react";
import axios from 'axios';
import { constaApi } from '../../../../constants/constants';
import { useParams, } from "react-router";
import SearchBar from '../../../../components/GeneralComponents/SearchBar';
import TicketModal from '../../../../components/contactComponents/TicketsSection/Components/TicketModal.jsx';
import { TableTickets } from '../../../../components/contactComponents/TicketsSection/TableTickets.jsx';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import NotificationAlert from "react-notification-alert";


export default function Tickets(props) {



  useEffect(() => {
    getTickets();
  }, [])

  // params of Search
  const [paramTableOne, setParamTableOne] = useState("");
  const [paramTableTwo, setParamTableTwo] = useState("");
  const [param, setparam] = useState("");
  const [contacts, setContacts] = useState([]);
  const [resultofSearch, setResultofSearch] = useState([]);
  const [members, setmembers] = useState([{}]);

  const [editing,setEditing] = useState(false);


  const notificationAlert = useRef();


  // Components of a table used to find at Contacts
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [dataUsers, setDataUsers] = useState([]);

  // Get id of contact from URL
  let { id: id_contact } = useParams();
  const [idassigned, setIDassigned] = useState(null);

  let user = JSON.parse(localStorage.getItem('user'));
  let path = (window.location.pathname).split('/');
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [showBoxTitle,setshowBoxTitle] = useState(false);
  const [boxTitle,setboxTitle] = useState("");

  const [loadEdit, setLoadEdit] = useState(false);

  const [ticketsOpen, setTicketsOpen] = useState([]);
  const [ticketsClosed, setticketsClosed] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [firstViewAddTicket, setFirstView] = useState(false);
  const [titleButton, setTitleButton] = useState('Añadir ticket');
  const [load, setLoad] = useState(false);
  const override = css`
        display: inline;
        border-color: red;
        `;

  // Methods

  const getContacts = async() => {
      await axios.get(constaApi + 'contacts', {
        headers: {
          "Accept": "application/json"
        }
      }).then((response) => {
        const { data: dx } = response;
        let array = [];
        dx.map(d => {
          let obj = {
            ...d,
            otherName: d.fullname.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            ref1: d.contacts_references[0] ? (d.contacts_references[0].name + " " + d.contacts_references[0].father_lastname + " " + (d.contacts_references[0].mother_lastname ?? " ")) : " ",
            ref2: d.contacts_references[1] ? (d.contacts_references[1].name + " " + d.contacts_references[1].father_lastname + " " + d.contacts_references[1].mother_lastname) : " ",
            ref3: d.contacts_references[2] ? (d.contacts_references[2].name + " " + d.contacts_references[2].father_lastname + " " + d.contacts_references[2].mother_lastname) : " ",
            ref1D: (d.contacts_references[0] ? d.contacts_references[0].name + " " + d.contacts_references[0].father_lastname ?? "" + " " + d.contacts_references[0].mother_lastname ?? " " : null) ? (d.contacts_references[0].name + " " + d.contacts_references[0].father_lastname ?? " " + " " + d.contacts_references[0].mother_lastname ?? " ").normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            ref2D: (d.contacts_references[1] ? d.contacts_references[1].name + " " + d.contacts_references[1].father_lastname ?? "" + " " + d.contacts_references[1].mother_lastname ?? " " : null) ? (d.contacts_references[1].name + " " + d.contacts_references[1].father_lastname ?? " " + " " + d.contacts_references[1].mother_lastname ?? " ").normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            ref3D: (d.contacts_references[2] ? d.contacts_references[2].name + " " + d.contacts_references[2].father_lastname ?? "" + " " + d.contacts_references[2].mother_lastname ?? " " : null) ? (d.contacts_references[2].name + " " + d.contacts_references[2].father_lastname ?? " " + " " + d.contacts_references[2].mother_lastname ?? " ").normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",


            //   // ref3D: (d.contacts_references[2] ? d.contacts_references[2].name + " " + d.contacts_references[2].father_lastname ?? "" + " " + d.contacts_references[2].mother_lastname ?? " " : null) ? (d.contacts_references[2].name + " " + d.contacts_references[2].father_lastname ?? " " + " " + d.contacts_references[2].mother_lastname ?? " ").normalize(" // ref1D: (d.contacts_references[0] ? d.contacts_references[0].name + " " + d.contacts_references[0].father_lastname ?? "" + " " + d.contacts_references[0].mother_lastname ?? " " : null) ? (d.contacts_references[0].name + " " + d.contacts_references[0].father_lastname ?? " " + " " + d.contacts_references[0].mother_lastname ?? " ").normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            //   // ref2D: (d.contacts_references[1] ? d.contacts_references[1].name + " " + d.contacts_references[1].father_lastname ?? "" + " " + 
            //   d.contacts_references[1].mother_lastname ?? " " : null) ? 
            //   (d.contacts_references[1].name + " " + d.contacts_references[1].father_lastname ??
            //    " " + " " + d.contacts_references[1].mother_lastname ?? " ").normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            //  NFD").replace(/[\u0300-\u036f]/g, "") : "",
          }
          array.push(obj);
        })
        setContacts(array);
      })

  }

  const closeModals = () => {
    getTickets();
    handleClose();
    setEditing(false);

  }
  const screenLoading = () => {
    setLoad(true);
    setTitleButton('Creando ticket');
  }
  const screenOffLoading = () => {
    setLoad(false);
    setTitleButton('Añadir ticket');
    handleShow();
    setLoadEdit(false);
  }

  const whereIam = () => {
    switch (props.where) {
      case 'prospection':
        return 'Prospección';
      case 'application':
        return 'Aplicación';
      case 'tracking':
        return 'Seguimiento';
      default:
        return 'Otro';
    }

  }
  const createTicket = () => {
    notification('info', 'Creando Ticket...');
    setLoad(true);
    setTitleButton('Creando ticket');
    setshowBoxTitle(true);
    setEditing(false);
    
  }

  const getTickets = () => {

    axios.get(constaApi + 'getTickets/module/resume/'+id_contact)
      .then(function (response) {
        console.log('Response',response);

        let auxOpen = [];
        let auxClosed = [];
        response.data.open.map(d => {
          let obj = {
            ...d,
            fullnameStudent: d.fullnameStudent ? d.fullnameStudent.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : " ",
            membersFormated : d.members.map(m => m?.name +" " + m?.father_lastname + " " +  m?.mother_lastname)
          }
          auxOpen.push(obj);
        })

        response.data.close.map(d => {
          let obj = {
            ...d,
            fullnameStudent: d.fullnameStudent ? d.fullnameStudent.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : " ",
            membersFormated : d.members.map(m => m?.name +" " + m?.father_lastname + " " +  m?.mother_lastname)
          }
          auxClosed.push(obj);
        })


        setTicketsOpen(auxOpen);
        setticketsClosed(auxClosed);

      }).finally(function (response) {
      });
  }

  const showTicket = (e) => {
    setLoadEdit(true);
    setEditing(true);

    axios.post(constaApi + 'showTicketProspection', { id: e })
      .then(function (response) {
        setData(response.data);
        setTimeout(screenOffLoading, 1000);
      }).finally(function (response) {
      });
  }

  const searchOnTableOne = (e) => {
    let fullnameFormated = e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    e.target.value === "" ? setParamTableOne('keyWordSeccret302') : setParamTableOne(fullnameFormated);

  }

  const searchOnTableTwo = (e) => {
    let fullnameFormated = e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    e.target.value === "" ? setParamTableTwo('keyWordSeccret302') : setParamTableTwo(fullnameFormated);
  }

  const listenOnBlur = () => {
    setparam("");
    setLoad(false);
    setTitleButton('Añadir ticket');
  }

  const closeEvents = (e) => {
    setLoad(false);
    setTitleButton('Añadir ticket');
    setshowBoxTitle(false);
    setboxTitle("");
  }

  const changeBoxTitle = (e) => {
    setboxTitle(e.target.value);
  }

  const test = () => {
    setshowBoxTitle(false);
    setboxTitle("");
     // setTimeout(screenOffLoading,3000);
    // Axios api call
    let newTicket = {
      title: boxTitle,
      description: '',
      section: whereIam(),
      open: true,
      id_contact: id_contact,
      created_by: user.id,
      fullname_creator: user.name,

    }

    axios.post(constaApi + 'createTicketProspection', newTicket)
      .then(function (response) {
        console.log('RESPONSE', response);
        setData(response.data);
        setTimeout(screenOffLoading, 1000);
      }).finally(function (response) {
      });

    // end
    // end
  }


  const PopoverBottom = (name) => {



    const findAtUser = (e) => {
      // if exist
      if (e.target.value) {
        let fullnameFormated = e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        setparam(fullnameFormated);
        secondPartOfFindAtUser(fullnameFormated);
      } else {
        setparam("");
        setResultofSearch([]);
      }
    }

    const secondPartOfFindAtUser = (value) => {
      let objx = gridApi;
      value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
      setGridApi(objx);
      let rowsDisplay = objx.api.rowRenderer.rowModel.rowsToDisplay;
      let result;
      if (rowsDisplay) {
        result = rowsDisplay.map(row => {
          let { data } = row;
          return data;
        })
        setResultofSearch(result);
        //  this.setState({resultofSearch: result});
      }
    }

    const PopoverComponent = (name) => {
      return (<Popover id="popover-basic">
        <Popover.Content>
          <span>{name}</span>
        </Popover.Content>
      </Popover>)
    }


    // Funcion to Formatt Name of members of tickets
    const formatterUser = (member) => {
      let n = member.fullname ? member.fullname.toUpperCase().split(" ") : " ";
      let tag = '';
      let classDefined = '';
      if (n.length >= 3) {
        n = n[0].charAt(0) + n[1].charAt(0) + n[2].charAt(0);
      } else if (n.length >= 2) {
        n = n[0].charAt(0) + n[1].charAt(0);
      } else {
        n = n[0].charAt(0);
      }
      classDefined = 'sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnP blueStrong';
      tag =
        <OverlayTrigger key={"Overlay" + member.fullname}
          trigger={["hover", "hover"]} placement="top"
          overlay={PopoverComponent(member.fullname)}>
          <span className={classDefined}>{n}</span>
        </OverlayTrigger>;
      return tag;
    }

    const assignIdContact = (e) => {
      setIDassigned(e.id);
      setTimeout(screenOffLoading, 3000);
      // Axios api call
      let newTicket = {
        title: boxTitle,
        description: '',
        section: whereIam(),
        open: true,
        created_by: user.id,
        id_contact: e.id,
        fullname_creator: user.name,

      }

      axios.post(constaApi + 'createTicketProspection', newTicket)
        .then(function (response) {
          setData(response.data);
          setTimeout(screenOffLoading, 1000);
        }).finally(function (response) {
        });

    }
    return (
      <Popover id={name} title="Popover bottom">
        <div style={{ width: '200px', height: '100%' }}>
          <div style={{ display: 'flex' }}>
            <div>
              <strong style={{ marginLeft: '60px', marginTop: '1px' }}>Contactos</strong>
            </div>

          </div>
          <hr style={{ marginLeft: '5px', width: '90%' }}></hr>
          <div style={{ display: 'flex', width: '200px', justifyContent: 'center' }}>
            <Form.Control
              value={param}
              onChange={(e) => findAtUser(e)}
              style={{
                width: '80%', backgroundColor: 'white',
                border: '2px solid #0079BF'
              }}
              type="text" placeholder="Seleccionar Contacto..."
            />
          </div>
          <div style={{ marginTop: '10px', marginLeft: '10%', display: 'flex', justifyContent: 'left' }}>
            <strong style={{ marginTop: '1px' }}>Resultados</strong>
          </div>
          <ul>
            {resultofSearch.map(re => {
              return (
                <li key={'liR' + re.id} style={{ marginLeft: '3%', marginTop: '5px', listStyle: 'none' }}>
                  <button key={"btnRes" + re.id} style={{ width: '90%', height: '38px', backgroundColor: '#091e420a' }}>
                    <span onClick={(e) => assignIdContact(re)} style={{ display: 'flex', justifyContent: 'left', marginLeft: '5px' }}>
                      {formatterUser(re)}
                      <span className='formaterName' >{re?.fullname?.toUpperCase()}</span>
                    </span>
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      </Popover>
    )
  }


  // Methods used in a table of QuickSearch
  const onGridReady = (params) => {
    setGridApi(params);
    setColumnApi(params);
  }

  const dropTicketAndReload = (val) => {
    axios.post(constaApi + 'deleteTicket', { id_ticket: val })
      .then(function (response) {
        getTickets();
      }).finally(function (response) {
      });
  }

  const notification = (type, message) => {
    let place = "tc";
    var options = {};
    options = {
        place: place,
        message: (
            <div>
                <div>
                    {message}
                </div>
            </div>
        ),
        type: type,
        icon: "nc-icon nc-bell-55",
        autoDismiss: 7,
    }
    notificationAlert.current.notificationAlert(options);
}

  return (
    <div className='content'>
   <NotificationAlert ref={notificationAlert} />


      {/* I dont know , but this table should are hidden */}
      <div
        hidden={true}
        className="ag-theme-alpine"
        style={{ height: '100%', width: '100%' }}
      >
        <AgGridReact

          rowData={contacts}
          rowHeight={40}
          cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
          domLayout="autoHeight"
          onGridReady={onGridReady}
        >
          {/* Column Name */}
          <AgGridColumn
            cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
            cellRenderer="slotName"
            headerName="Nombre" sortable={true} field="fullname" width="300" />
          {/* Column OtherName */}

        </AgGridReact>
      </div>


      {loadEdit &&
        <center>
          <PulseLoader color={'blue'} loading={true} css={override} size={16} />
        </center>
      }


      {/* BEFORE */}
      {/* <OverlayTrigger
        onExit={listenOnBlur}
        trigger={"click"} rootClose placement="bottom"
        overlay={PopoverBottom('popover-right')}>
        <button
          onClick={(e) => createTicket()}
          style={{ position: 'absolute', right: 50, fontSize: '14px' }}
          className='btn btn-info'>
          {titleButton}
          {load == true &&
            <PulseLoader color={'white'} loading={true} css={override} size={10} />
          }
        </button>
      </OverlayTrigger> */}

      {/* LATER */}
     <div>

        <button
          onClick={(e) => createTicket()}
          style={{ position: 'absolute', right: 50, fontSize: '14px' }}
          className='btn btn-info'>
          {titleButton}
          {load == true &&
            <PulseLoader color={'white'} loading={true} css={override} size={10} />
          }
        </button>
        {showBoxTitle &&
        <div style={{ width:'200px',position:'absolute',right:'45px',
        top:'270px',zIndex:'100', background: 'white', border: '2px solid #0079BF' }}>
        <Form.Control
          as="textarea"
          value={boxTitle}
          onChange={(e) => changeBoxTitle(e)}
          style={{
            paddingLeft:'10px',
            paddingTop:'10px',
            paddingBottom:'10px',
            marginLeft:'10%',
            marginTop:'5px',
            marginBottom:'10px',
            msOverflowY:'scroll',
            width: '150px', resize: 'none',  overflowWrap: 'break-word',
            textDecoration: 'none', padding: '5px 5px',
            backgroundColor: 'white',
            border: '2px solid #0079BF'
          }}
          placeholder="Añadir titulo a esta tarjeta."
          />
      </div>
        }
        <div style={{position:'absolute',right:'50px',top:'355px',zIndex:'100', }}>
        <button  style={{visibility: showBoxTitle == false ? 'hidden' : 'visible'}}
        onClick={(e) => test(e)} disabled={boxTitle ==""} className='btn btn-info'>Guardar</button>
        <button
        style={{visibility: showBoxTitle == false ? 'hidden' : 'visible'}}
        onClick={(e) => closeEvents(e)}  
        className='btn btn-danger'>Cancelar</button>
        </div>
      </div>





      <div>
        {show == true &&
          <TicketModal editing={editing} idassigned={idassigned} user={user} whereIam={whereIam()} data={data} closeModal={(e) => closeModals()} show={show} />
        }
      </div>

      <div className='mt-5'>
        <h2>Abierto</h2>
        <div className="searchBarTable">
          <SearchBar consult={(e) => searchOnTableOne(e)} />
        </div>
        <TableTickets deleteTicket={(e) => dropTicketAndReload(e)}  param={paramTableOne} data={ticketsOpen} showTicket={(e) => showTicket(e)}
        />
      </div>
      <div className='mt-5'>
        <h2>Cerrados</h2>
        <div className="searchBarTable">
          <SearchBar consult={(e) => searchOnTableTwo(e)} />
        </div>
        <TableTickets deleteTicket={(e) => dropTicketAndReload(e)}  param={paramTableTwo} data={ticketsClosed} showTicket={(e) => showTicket(e)}
        />
      </div>

    </div>
  )
}
