import React, { useEffect, useState } from 'react'
import AddOrEditCollege from '../components/collegeComponents/AddOrEditCollege';
import TableColleges from '../components/collegeComponents/TableColleges.jsx';
import { useDispatch } from 'react-redux';
import { starLoadingColleges } from '../actions/colleges/colleges';
import SearchBar from '../components/GeneralComponents/SearchBar';
import './cssPages/app.css';

function Colleges() {
    const dispatch = useDispatch();
    const [param, setParam] = useState(null);
    const [modalExport, setModalExport] = useState(false);
    const startValue = new Date(new Date().getFullYear(), new Date().getMonth(), 14);
    const endValue = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 15);
    const [option, setOption] = useState('users');
    const [rangeDate, setRangeDate] = useState([startValue, endValue]);
    const [objToExport,setObjToExport] = useState([]);
    const consult = (e) => {
        e.target.value === "" ? setParam('keyWordSeccret302') : setParam(e.target.value);
    }
    useEffect(() => {
        dispatch(starLoadingColleges());
    }, [])

  
    return (
        <div className="content">
            <div className="row">
                <div className="col-12">
                    <div className="col d-flex justify-content-end">
                        <AddOrEditCollege />
                    </div>
                    <div style={{
                        float: 'right',
                        marginRight: '1.9%',
                    }}>

                        {/* Change by Sandra 06/30/23 */}
                        {/* <button
                            onClick={(e) => setModalExport(!modalExport)}
                            style={{ width: '110%' }} className='btn btn-info'>Exportar</button> */}
                    </div>
                    <div className="mt-5 searchBarTable">
                        <SearchBar consult={(e) => consult(e)} />
                    </div>

                    <TableColleges param={param} modalExport={modalExport}/>

                </div>
            </div>
           

        </div>



    )
}

export default Colleges
