import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useForm } from "react-hook-form";
import axios from "axios";
import { constaApi } from "../../constants/constants";
import * as FIcons from "react-icons/fi";
import * as FAIcons from "react-icons/fa";
import * as HIcons from "react-icons/hi";
import * as Ioicons from "react-icons/io";
import * as MDIcons from "react-icons/md";
import * as VsIcons from "react-icons/vsc";
import * as RIIcons from "react-icons/ri";
import {
  Button,
  Modal,
  Form,
  InputGroup,
  Popover,
  OverlayTrigger,
  FormControl,
} from "react-bootstrap";
import swal from "sweetalert";
import NotificationAlert from "react-notification-alert";
import { Row, Col } from "reactstrap";
import Select from "react-select";
import chroma from 'chroma-js';
import Linkify from 'react-linkify';

import { newBioC } from "../../actions/contacts/bioContact/bioContact";
import { updatedBioC } from "../../actions/contacts/bioContact/bioContact";
import { deleteBioC } from "../../actions/contacts/bioContact/bioContact";
import { starLoadingProspect } from "../../actions/contacts/bioContact/bioContact";
import { starLoadingApplications } from "../../actions/contacts/bioContact/bioContact";
var _ = require('lodash');

export default function AddEditBio(props) {


  const currentDateRef = useRef(0);
  const currentTimeRef = useRef(0);
  const select_participants_ref = useRef(null);


  var contador = 0;
  const [values, setValues] = useState([]);
  const { flag } = props;
  const { id: IDX , name: nameAuth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [timeBio, setTimeBio] = useState();
  const [editing, setEditing] = useState(false);
  const { users:allUsers } = useSelector(state => state.users);
  let { id } = useParams();
  const [textFormat,setTextFormat] = useState([]);
  const [idAux,setId] = useState(null);
  const [provicional,setProvicional] = useState("");
  // Set moment spanish
  moment.locale("es-mx");
  const notificationAlert = useRef();
  const { username: email } = useSelector((state) => state.auth);

 

  useEffect (() =>{
    consultAllBound(id);
    consult();
  },[])
  useEffect(() => {
    if(props.objAux){
      setId(props.objAux.id_contact);
      consultAllBound(props.objAux.id_contact);
    }
    if (props.flagTwo) {
      showModalLog("Recordatorio");
    }
    if (props.flag) {

      let result = props.row;
      if(result.proposals){
        if(result.proposals.length > 0){
          result.proposals = _.groupBy(result.proposals,"country")
        }
      }
      showModal(result);
    }
  }, [props]);
  const [prefixSubject, setPrefix] = useState();
  const [twopart, setTwoPart] = useState(false);
  const { handleSubmit } = useForm({});
  const [modal, setModal] = useState(false);
  const [modalLog, setModalLog] = useState(false);
  const [param, setParam] = useState("");
  const [tempParam, setTempParam] = useState("");
  const [tempsubject, setTemp] = useState();
  const [subject, setSubject] = useState("");
  const [selectValue, setSelectValue] = useState();
  const [dateBio, setDateBio] = useState();
  const [textBio, setTextBio] = useState();
  const [bounds,setBounds] = useState([]);
  const[selectBound,setSelectBound] = useState();
  const[select_menu,set_select_menu] = useState(false);


  useEffect(()=>{

    if(modalLog == true) {
      select_participants_ref.current?.focus();
      document.querySelector("#select-input").select();
    }

    // if(modalLog == true){
    //   console.log('ME EXECUTE')


    //   select_participants_ref.current.focus();
    //   setTimeout(() => {
    //     handleFocus()
    //     // select_participants_ref.current?.click()
    //     // select_participants_ref.current.select();
    //   }, 1000);
    // //  console.log('SELECT',select_participants_ref.current.focus())


    // }


  },[modalLog])


  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        opacity: 1,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : data.color,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor:
            !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };

  function handleMustCloseButNo(){

  }
  function synchronizeTimeAndDate () {

    let CurrentTime = moment().format("HH:mm");
    let CurrentDate = moment().format("YYYY-MM-DD");




    setDateBio(CurrentDate)
    setTimeBio(CurrentTime)





  }
  function changeTime(e) {
    setTimeBio(e.target.value);
  }
  const handleChangeSubject = (e) => {
    setSubject(e.target.value);
  };
  const consultAllBound = async (id) => {
    axios.get(constaApi + "bound/"+id)
    .then( response =>{
      const {data} = response;
      const {applications,prospection,trackings} = data;
      let result = [];
      if(applications){
        applications.map(ap =>{
          if(ap.status == 'Activo' || ap.status == 'aplicar' || ap.status == 'Cancelado'){
          } else {
            result.push({
              value: ap.id,
              label: ap.name_prospection + '-' + ap.name,
              type: "aplicacion",
              color:'yellow'
            });
          }
        })
      }
      if(trackings){
        trackings.map(ap =>{
          if(ap.status == 'Terminado'){
          } else {
          result.push({
              value: ap.id,
              label: ap.name_prospection + '-' + ap.name,
              type: "Seguimiento",
              color:"rgb(0, 255, 133)"
            });
          }
        })
      }
      if(prospection){
          prospection.map(ap =>{
            if(ap.status == 'Activo' || ap.status == 'aplicar' || ap.status == 'cancelar'){
            }else {
            result.push({
              value: ap.id,
              label: ap.name_prospection,
              type: "Prospeccion",
              color:'rgb(0, 147, 255)'
            });
          }
          })
      }
      result.push({
        value : 'General',
        label: 'General',
        type: 'General',
        color:'rgb(190, 192, 144)',
      })
      setBounds([...result]);

    }).catch(err => {
    })
  }
  const consult = async () => {
    let data = {
      id: id,
      idx: IDX,
    };
    await axios
      .post(constaApi + "defaultSelectBio", data)
      .then(function (response) {
        let { contact,colleges, final,finalTwo,contacts, references, user, users } = response.data;

        // if(props.getAllUsers){
        //   props.getAllUsers(users);
        // }
        let result = user.map((col) => {
          return {
            value: col.name + 'u',
            secondaryParam: col.name + 'u',
            label:
              col.name + " " + (col.father_lastname ? col.father_lastname + " " : " ") + (col.mother_lastname ? col.mother_lastname : " "),
            fullname:
              col.name + " " + (col.father_lastname ? col.father_lastname + " " : " ") + (col.mother_lastname ? col.mother_lastname : " "),
            type: col.type,
          };
        });
        contact.map((u) => {
          result.push({
            value: u.name + 'c',
            secondaryParam: u.name + 'c',
            label: u.name + " "+ (u.father_lastname ? u.father_lastname + " " : " ") + (u.mother_lastname ? u.mother_lastname : " "),
            fullname:
              u.name + " " + (u.father_lastname ? u.father_lastname + " " : " ") + (u.mother_lastname ? u.mother_lastname : " "),
            type: "contactos",
          });
        });

        if (Object.keys(references).length != 0) {
          references.map((u) => {
            result.push({
              value: u.name + 'r',
              secondaryParam: u.name + 'r',
              label: u.name + " " + (u.father_lastname ? u.father_lastname + " " : " ") + (u.mother_lastname ? u.mother_lastname : " "),
              fullname:
                u.name + " "  + (u.father_lastname ? u.father_lastname + " " : " ") + (u.mother_lastname ? u.mother_lastname : " ") ,
              type: "referencias",
            });
          });
        } else {
          // nothing selection
        }
        users.forEach((us) => {
          if (us.name === result[0].value) {
          } else {
            result.push({
              value: us.name + 's',
              secondaryParam: us.name + 's ' + us.father_lastname + (us.mother_lastname ? us.mother_lastname : " "),
              label:
                us.name + " " + (us.father_lastname ? us.father_lastname + " " : " ") + (us.mother_lastname ? us.mother_lastname : " "),
              fullname:
                us.name + " " + (us.father_lastname ? us.father_lastname + " " : " ") + (us.mother_lastname ? us.mother_lastname : " "),
              type: us.type,
            });
          }
        });
        final.map((c)=>{
          result.push({
            value: c.name + 'f',
            secondaryParam: c.name + 'f',
            label: c.name,
            fullname:
             c.name,
            type: "colegios",
          })
        })
        finalTwo.map((c)=>{
          result.push({
            value: c.name + 't',
            secondaryParam: c.name + 't',
            label: c.name,
            fullname:
             c.name,
            type: "colegios",
          })
        })
        let hash = {};

        result = result.filter(o => hash[o.secondaryParam] ? false : hash[o.secondaryParam] = true);

        setValues(result);
      });
  };

  const showModal = function showModal(row) {
    if(row.type_prospection == 'General'){
      setSelectBound({
        value : 'General',
        label: 'General',
        type: 'General',
        color:'black',
      });
    } else {
      bounds.map(b => {
        if(row.id_type_prospection == b.value){
          if(row.type_prospection == b.type){
            setSelectBound({...b});
          }
        }
      })
    }
    setModal(true);
    setParam(row);
    Object.keys(row.proposals).map((name,i)=>{
      row.proposals[name].map(r => {



      })
     });
    setTempParam(row.text);
    if(row.text != null){
      // formatTextWithUrls(row.text);
    }
  };
  const handleChange = (e) => {
    if(e[1]){
      let cad = (e[1].value).substr(0,e[1].value.length -1)
      setSubject(prefixSubject + " a " + cad)
    }
    if (e != null) {
      setSelectValue(e);
    } else {
      notification("danger", "Este campo no puede estar vacio");
    }
  };
  const handleChangeBound = (e) => {
      setSelectBound(e);
  };


  const showModalLog = function showModalLog(subject , description  = '') {
   
    setPrefix(subject);
    setTextBio("");
    if(description) {
      setTextBio(description);
      synchronizeTimeAndDate();
  
      setSelectBound({
        value : 'General',
        label: 'General',
        type: 'General',
        color:'rgb(190, 192, 144)',
      })
      set_select_menu(true)
    }
    setModalLog(true);
    setTemp(subject);
    setSubject(subject + " a "
    );
  };
  const handleClose = function close() {
    setEditing(false);
    setSelectValue([values[0]]);
    setDateBio("");
    setTimeBio("");
    setModal(false);
    setModalLog(false);
    setSelectBound();
    if (modal) {
      if (props.setFlag());
    }
  };
  async function onSubmit(data) {
    let datax= {};
    let tip = "";
    if(props.activeProspect){
      tip = 'Prospeccion';
    } else if (props.activeApplication){
        tip = 'aplicacion';
    } else if( props.activeTracking){
        tip = 'Seguimiento';
    } else {
       tip = 'General'
    }
    let datex = dateBio + " " + timeBio;
    if (editing) {
       datax = {
        id: param.id,
        id_contact: id ?? idAux ?? null,
        subject: subject,
        values: selectValue ? selectValue : [values[0]],
        date: dateBio ? datex : null,
        text: textBio ? textBio : null,
        id_college: null,
        type: prefixSubject,
        type_prospection: tip,
        id_type_prospection : props.activeProspect ? props.activeProspect.id : props.activeApplication ? props.activeApplication.id : props.activeTracking ? props.activeTracking.id : 0,
        selectBound : selectBound
      };
      dispatch(updatedBioC(datax));
      if(props.activeProspect){
        dispatch(starLoadingProspect(id,props.activeProspect.id));

      } else if(props.activeApplication){
        dispatch( starLoadingApplications(id,props.activeApplication.id));
      } else if(props.activeTracking){
        dispatch(starLoadingProspect(id,props.activeTracking.id));

      }
    } else {
       datax = {
        id_contact: id ?? idAux ?? null,
        id_college: null,
        subject: subject ? subject : null,
        values: selectValue ? selectValue : [values[0]],
        date: dateBio ? datex : null,
        text: textBio ? textBio : null,
        type: prefixSubject,
        type_prospection: tip,
        id_type_prospection : props.activeProspect ? props.activeProspect.id : props.activeApplication ? props.activeApplication.id : props.activeTracking  ? props.activeTracking.id : 0,
        selectBound : selectBound,
        id_responsable : IDX,
        name_responsable: nameAuth,
        created_at : null
      };
      let indicator = props.activeProspect ? 1 : props.activeApplication ? 2 : props.activeTracking ? 1 : 0;
      let params = indicator == 1 ? {id:id,
        id_type_prospection:props.activeProspect ? props.activeProspect.id : props.activeTracking ? props.activeTracking.id : 0} : indicator == 2 ?
      {id:id,id_type_prospection:props.activeApplication ? props.activeApplication.id : 0} : null;
      dispatch(newBioC(datax,indicator,params));

      // PENDIENTE CAMBIAR CUANDO SE ACTUALIZE UNA ENTRADA DE BITACORA
      // if(props.activeProspect){
      //   dispatch(starLoadingProspect(id,props.activeProspect.id));

      // } else if(props.activeApplication){
      //   dispatch( starLoadingApplications(id,props.activeApplication.id));

      // }
      if (props.fromBio) {
        props.fromBio();
      }
    }
    if (props.noBar) {
      props.closeAll();
    }
    handleClose();
    consult();
  }

  function changeDate(e) {
    setDateBio(e.target.value);
  }
  function changeText(e) {
    setTextBio(e.target.value);
  }
  const handleEdit = (e) => {
    setTempParam(param.text);
    setTwoPart(!twopart);
  };
  const formatDatewithRealHours = (dateBD, timeBio) => {
    let datef = moment(dateBD).locale("es-mx").format("ddd D MMMM, YYYY ");
    let timef = moment(dateBD).locale("es-mx").format("h:mm A");
    datef = datef[0].toUpperCase() + datef.slice(1);
    datef = datef.replace(".", "");
    let tag = (
      <span className="montse">
        {datef} <Ioicons.IoMdTime /> {timef}
      </span>
    );
    return tag;
  };

  const showDate = (dateBD, timeBio) => {
    let datef = moment(dateBD).locale("es-mx").format("ddd D MMMM, YYYY ");
    let timef = moment(dateBD).locale("es-mx").format("h:mm A");
    datef = datef[0].toUpperCase() + datef.slice(1);
    datef = datef.replace(".", "");
    let tag = (
      <span className="montse">
        {datef} <Ioicons.IoMdTime /> {timef}
      </span>
    );
    return tag;
  };
  const showSubject = (type, subject) => {
    let tag = "";
    if (type.includes("Llamada")) {
      tag = (
        <span className="montse">
          <svg width="16" height="16" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M21 16.92v-.025a.998.998 0 0 0-.85-1.014 13.845 13.845 0 0 1-3.032-.755.998.998 0 0 0-1.05.221l-1.27 1.27a1 1 0 0 1-1.202.162 17 17 0 0 1-6.375-6.375 1 1 0 0 1 .162-1.201l1.266-1.266a1 1 0 0 0 .224-1.057 13.817 13.817 0 0 1-.753-3.02A1.003 1.003 0 0 0 7.11 3h-3a1 1 0 0 0-.996 1.074 18.8 18.8 0 0 0 2.92 8.24 18.511 18.511 0 0 0 5.7 5.697 18.774 18.774 0 0 0 8.176 2.913A1 1 0 0 0 21 19.92v-3zm2 2.996a3 3 0 0 1-3.288 2.998 20.78 20.78 0 0 1-9.058-3.22 20.49 20.49 0 0 1-6.303-6.3A20.805 20.805 0 0 1 1.124 4.27 3 3 0 0 1 4.11 1H7.1a3.002 3.002 0 0 1 3.001 2.59c.117.885.334 1.754.645 2.588a3.002 3.002 0 0 1-.679 3.17l-.717.716a15 15 0 0 0 4.586 4.586l.72-.721a3 3 0 0 1 3.164-.676c.836.312 1.705.529 2.6.647A3 3 0 0 1 23 16.93v2.985z"
            ></path>
          </svg>
          &nbsp;{subject}
        </span>
      );
    }
    if (type.includes("Whatsapp")) {
      tag = (
        <span className="montse">
          <FAIcons.FaWhatsapp />
          &nbsp; {subject}
        </span>
      );
    }
    if (type.includes("Cita")) {
      tag = (
        <span className="montse">
          <FIcons.FiCalendar />
          &nbsp;
          {subject}
        </span>
      );
    }
    if (type.includes("Email")) {
      tag = (
        <span className=" montse">
          <HIcons.HiOutlineMail size={16} />
          &nbsp;
          {subject}
        </span>
      );
    }
    if(type.includes('Video llamada')){
      tag = (
        <span className=" montse">
      <VsIcons.VscDeviceCameraVideo size={16} />
          &nbsp;
          {subject}
        </span>
      );
    }
    if(type.includes('Investigación')){
      <span
      className="montse">
      <RIIcons.RiUserSearchLine size={16} />
      &nbsp;{subject}
    </span>
    }

    if(type.includes('Otro')){
      <span
      className="montse"
    >
      <RIIcons.RiHealthBookLine size={16} />
      &nbsp;{subject}
    </span>
    }
    return tag;
  };
  const showParticipant = (type = "use", name, fullname = "") => {
    let n = fullname ? fullname.split(" ") : " ";
      let tag = '';
      if (n.length >= 3) {
          n = n[0].charAt(0) + n[1].charAt(0) + n[2].charAt(0);
      } else if(n.length >= 2) {
        n = n[0].charAt(0) + n[1].charAt(0) ;
      } else {
        n = n[0].charAt(0);
      }
    switch (type) {
        case 'user':
          allUsers.map(al => {
            if(fullname == (al.name + " " + al.father_lastname + " " + al.mother_lastname)){
              switch (al.type) {
                case 'Administrador':
                  tag = <span onClick={(e) => showModal(props.data)} className="mr-n1 sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV bgBlue">{n}</span>;
                  break;
                case 'Supervisor':
                  tag = <span onClick={(e) => showModal(props.data)} className="mr-n1 sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV bgPurple">{n}</span>;
                break;
                case 'Colaborador ':
                default:
                  tag = <span onClick={(e) => showModal(props.data)} className="mr-n1 sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV bgGreen">{n}</span>;

                  break;
              }
            }
          })
        break;
        case 'contactos':
        tag = <span onClick={(e) => showModal(props.data)} className=" sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnP bgTurquesa">{n}</span>;
            break;
        case 'colegio':
          tag = <span onClick={(e) => showModal(props.data)} className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnP bgPink">{n}</span>;
            break;
        default:
            tag = <span onClick={(e) => showModal(props.data)} className=" sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnP bgPink">{n}</span>;
        break;
    }
    return tag;
  };
  const PopoverComponent = (text) => {
    return (
      <Popover id="popover-basic">
        <Popover.Content>
          <strong>{text}</strong>
        </Popover.Content>
      </Popover>
    );
  };
  const edit = () => {
    let array = [];
    param.participants.forEach((element, index) => {
      values.forEach((el) => {
        if (element.fullname === el.fullname) {
          array[index] = el;
        }
      });
    });
    let datex = moment(param.date).format("YYYY-MM-DD");
    let timex = moment(param.date).format("HH:mm");
    setSelectValue(array);
    setEditing(true);
    showModalLog(param.type);
    setSubject(param.subject);
    setTextBio(param.text);
    setDateBio(datex);
    setTimeBio(timex);
  };
  const changeBio = async (e) => {
    setTempParam(e.target.value);
    let name = "";
    name = await e.target.value;
  };
  const deleteComment = async (ide) => {
    swal({
      title: "Estas seguro?",
      text: "Una vez eliminado,no podras recuperar este registro!",
      icon: "warning",
      dangerMode: true,
      buttons: ["No", "Si"],
    }).then(async (willDelete) => {
      if (willDelete) {
        dispatch(deleteBioC(ide, id));
      } else {
        swal("Operacion cancelada!");
      }
    });
    handleClose();
  };

  function formatTextWithUrls(text){
    function urlify(text) {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function(url) {
        return '<a href="' + url + '">' + url + '</a>';
      })
      // or alternatively
      // return text.replace(urlRegex, '<a href="$1">$1</a>')
    }

    var test = text;
    var html = urlify(test);

    return html;

  }
  function notification(type, message) {
    let place = "tc";
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  }

 
  const renderWithProposals = (param) =>{
    let tag = <span>Hola</span>;
    return tag;
  }
  return (
    <>
      <div className=" mt-1 content">
        <NotificationAlert ref={notificationAlert} />
        {props.noBar ? (
          ""
        ) : (
          <div style={{ borderRadius: "0px" }} className="card">
            <div className="card-body">
              <div className="row">
                <span
                onClick={() => props.blocked ? true:showModalLog("Llamada") } className="ml-2 montse">
                  <svg width="16" height="16" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      fillRule="nonzero"
                      d="M21 16.92v-.025a.998.998 0 0 0-.85-1.014 13.845 13.845 0 0 1-3.032-.755.998.998 0 0 0-1.05.221l-1.27 1.27a1 1 0 0 1-1.202.162 17 17 0 0 1-6.375-6.375 1 1 0 0 1 .162-1.201l1.266-1.266a1 1 0 0 0 .224-1.057 13.817 13.817 0 0 1-.753-3.02A1.003 1.003 0 0 0 7.11 3h-3a1 1 0 0 0-.996 1.074 18.8 18.8 0 0 0 2.92 8.24 18.511 18.511 0 0 0 5.7 5.697 18.774 18.774 0 0 0 8.176 2.913A1 1 0 0 0 21 19.92v-3zm2 2.996a3 3 0 0 1-3.288 2.998 20.78 20.78 0 0 1-9.058-3.22 20.49 20.49 0 0 1-6.303-6.3A20.805 20.805 0 0 1 1.124 4.27 3 3 0 0 1 4.11 1H7.1a3.002 3.002 0 0 1 3.001 2.59c.117.885.334 1.754.645 2.588a3.002 3.002 0 0 1-.679 3.17l-.717.716a15 15 0 0 0 4.586 4.586l.72-.721a3 3 0 0 1 3.164-.676c.836.312 1.705.529 2.6.647A3 3 0 0 1 23 16.93v2.985z"
                    ></path>
                  </svg>
                  &nbsp;LLamada
                </span>
                <span
                  onClick={() => props.blocked ? true:showModalLog("Whatsapp")}
                  className="ml-4 montse"
                >
                  <FAIcons.FaWhatsapp />
                  &nbsp;Whatsapp
                </span>
                <span
                  onClick={() => props.blocked ? true:showModalLog("Cita")}
                  className="ml-4 montse"
                >
                  <FIcons.FiCalendar />
                  &nbsp;Cita
                </span>
                <span
                  onClick={() => props.blocked ? true:showModalLog("Email")}
                  className="ml-4 montse"
                >
                  <HIcons.HiOutlineMail size={16} />
                  &nbsp;Email
                </span>

                <span
                  onClick={() => props.blocked ? true:showModalLog("Video llamada")}
                  className="ml-4 montse"
                >
                  <VsIcons.VscDeviceCameraVideo size={16} />
                  &nbsp;Videollamada
                </span>

                <span
                  onClick={() => props.blocked ? true:showModalLog("Investigación")}
                  className="ml-4 montse"
                >
                  <RIIcons.RiUserSearchLine size={16} />
                  &nbsp;Investigación
                </span>

                <span
                  onClick={() => props.blocked ? true:showModalLog("Otro")}
                  className="ml-4 montse"
                >
                  <RIIcons.RiHealthBookLine size={16} />
                  &nbsp;Otro
                </span>

                <span
                  onClick={() => props.blocked ? true:showModalLog("Se mandó mensaje de seguimiento" , 'Se mandó mensaje estándar de seguimiento')}
                  className="ml-4 montse"
                >
                  <FAIcons.FaPaperPlane size={16} />
                  &nbsp;Mensaje seguimiento
                </span>
              </div>
            </div>
          </div>
        )}



        {/* Modal Preview */}
        <Modal
          show={modal}
          dialogClassName="modal-90w"
         onHide={(e) => handleClose()}
          style={{ marginTop: "50px" }}
        >
          <Modal.Body style={{ background: "#F4F5F6", border: "1px" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="container">
                <Row>
                  <div style={{ fontSize: "18px" }} className="col montse">
                    {param.subject}
                  </div>
                  <div className="justify-content-end">
                    <a>
                      <FIcons.FiEdit
                        onClick={(e) => edit()}
                        size={18}
                        style={{ color: "#386CEF" }}
                      />
                    </a>
                    <FAIcons.FaTrashAlt
                      className="ml-1"
                      style={{ color: "#DC3545" }}
                      size={18}
                      onClick={(e) => {
                        deleteComment(param.id);
                      }}
                    />
                  </div>
                </Row>
                <Row className="mt-2">
                  <div style={{ fontSize: "14px" }} className="col montse ">
                    <svg
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                      style={{
                        color: "rgb(66, 82, 110)",
                        height: "16px",
                        width: "14px",
                      }}
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          fill="currentColor"
                          fillRule="nonzero"
                          d="M14 11H9v5h5v-5zM13 0v2H5V0H3v2H2C.89 2 .01 2.9.01 4L0 18a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2h-1V0h-2zm3 18H2V7h14v11z"
                        ></path>
                        <path d="M-3-1h24v24H-3z"></path>
                      </g>
                    </svg>{" "}
                    {showDate(param.date, param.timeBio)}
                  </div>
                </Row>
                {param.participants && (
                  <Row className="mt-3">
                    <div style={{ fontSize: "14px" }} className="col montse">
                      Participantes:
                      {param.participants.map((part) => (
                        <Row key={part.id} className="mt-2">
                          {showParticipant(part.type, part.name, part.fullname)}
                          <span className="ml-2 mt-1">{part.fullname}</span>
                        </Row>
                      ))}
                    </div>
                  </Row>
                )}
                <Row>

                  <div
                    onClick={(e) => handleEdit(e)}
                    style={{ width:'100%' ,backgroundColor: "white" }}
                    className="ml-3 mt-3 "
                    >
          {param.subject === 'Se le enviaron propuestas'
          ?
           Object.keys(param.proposals).map((name,i)=>{
             return(
              [param.proposals[name].map(r => {
                return(
                  <li>
                 {r.name}
                  </li>
                )
              })]
             )
           })
         :
         <div
         style={{whiteSpace:"pre-wrap",color:'#7A859C',fontSize:'16px'}}
         >
           <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
    <a target="blank" href={decoratedHref} key={key}> {decoratedText} </a>
)}>

           {param.text}
           </Linkify>
          </div>



        //  <div
        //  style={{marginLeft:'5px',whiteSpace:"pre-wrap",color:'#7A859C',fontSize:'16px'}}
        //  >
        //    {param.text &&
        //    param.text.split(" ").map(t => {
        //      if(t.includes('www') || t.includes('http')){
        //        return(
        //          <>
        //          <br></br>
        //          <a
        //          href={t} target="_blank">{t}&nbsp;</a>
        //          </>
        //        )
        //      } else {
        //        return(
        //          <span
        //          href={t}
        //          style={{ whiteSpace:"pre-wrap",color:'#7A859C',fontSize:'16px'}}
        //          >{t}&nbsp;</span>
        //        )
        //      }
        //    })
        //    }

        //  </div>
        }

                  </div>

                </Row>
                <Row
                style={{marginBottom:'10px',marginTop:'10px'}}>
                <div
                style={{
                fontStyle:'italic',
                whiteSpace:"pre-wrap",
                fontSize:'11px',position:'absolute',right:20
                }}>
                  {props.row?.name_responsable &&
                  <span
                  className="mt-1 mb-1 mr-1 ml-1">
                 Creado por {props.row?.name_responsable} , {props.row?.created_at ? formatDatewithRealHours(props.row.created_at) : ''}
                  </span>
                  }
                </div>
                </Row>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          show={modalLog}
          style={{ marginTop: "50px" }}
          dialogClassName="modal-90w"
         onHide={handleMustCloseButNo}
        >
          {/* <NotificationAlert ref={notificationAlert} /> */}
          <Modal.Body style={{ background: "#F4F5F6", border: "1px" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="container-fluid">
                {props.noBar && (
                  <Row>
                    <Col className="col-12">
                      <div style={{ borderRadius: "0px" }} className="card">
                        <div className="card-body">
                          <div className="row">
                            <span
                              onClick={() => showModalLog("Llamada")}
                              className="montse"
                            >
                              <svg width="16" height="16" viewBox="0 0 24 24">
                                <path
                                  fill="currentColor"
                                  fillRule="nonzero"
                                  d="M21 16.92v-.025a.998.998 0 0 0-.85-1.014 13.845 13.845 0 0 1-3.032-.755.998.998 0 0 0-1.05.221l-1.27 1.27a1 1 0 0 1-1.202.162 17 17 0 0 1-6.375-6.375 1 1 0 0 1 .162-1.201l1.266-1.266a1 1 0 0 0 .224-1.057 13.817 13.817 0 0 1-.753-3.02A1.003 1.003 0 0 0 7.11 3h-3a1 1 0 0 0-.996 1.074 18.8 18.8 0 0 0 2.92 8.24 18.511 18.511 0 0 0 5.7 5.697 18.774 18.774 0 0 0 8.176 2.913A1 1 0 0 0 21 19.92v-3zm2 2.996a3 3 0 0 1-3.288 2.998 20.78 20.78 0 0 1-9.058-3.22 20.49 20.49 0 0 1-6.303-6.3A20.805 20.805 0 0 1 1.124 4.27 3 3 0 0 1 4.11 1H7.1a3.002 3.002 0 0 1 3.001 2.59c.117.885.334 1.754.645 2.588a3.002 3.002 0 0 1-.679 3.17l-.717.716a15 15 0 0 0 4.586 4.586l.72-.721a3 3 0 0 1 3.164-.676c.836.312 1.705.529 2.6.647A3 3 0 0 1 23 16.93v2.985z"
                                ></path>
                              </svg>
                              &nbsp;LLamada
                            </span>
                            <span
                              onClick={() => showModalLog("Whatsapp")}
                              className="ml-4 montse"
                            >
                              <FAIcons.FaWhatsapp />
                              &nbsp;Whatsapp
                            </span>
                            <span
                              onClick={() => showModalLog("Cita")}
                              className="ml-4 montse"
                            >
                              <FIcons.FiCalendar />
                              &nbsp;Cita
                            </span>
                            <span
                              onClick={() => showModalLog("Email")}
                              className="ml-4 montse"
                            >
                              <HIcons.HiOutlineMail size={16} />
                              Email
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col className="col-2">Participantes:</Col>
                  <Col className="col-1">
                    <MDIcons.MdGroup />
                  </Col>
                  <Col style={{ marginLeft: "-30px" }} className="col">
                    {values && (
                      <Select
                        isMulti
                        menuIsOpen={select_menu}
                        onMenuOpen={() => set_select_menu(true)}
                        onMenuClose={() => set_select_menu(false)}
                        onBlur={() => console.log('JEJE')}
                        inputId = "select-input"
                        name="values"
                        value={selectValue}
                        onChange={(e) => handleChange(e)}
                        // onFocus = {handleFocus}
                        ref={select_participants_ref}
                        defaultValue={[values[0]]}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={values}
                      />
                    )}
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="col-4">
                    <Form.Control
                      style={{textTransform:'capitalize',  width: "180px" }}
                      onChange={(e) => changeDate(e)}
                      value={dateBio}
                      ref={currentDateRef}
                      autoComplete="off"
                      name="date"
                      className="montse formGray"
                      type="date"
                      placeholder="Ingrese su Fecha"
                    />
                  </Col>




                  <Col >
                    <Form.Control
                      style={{ height: "30px", width: "120px" }}
                      onChange={(e) => changeTime(e)}
                      ref={currentTimeRef}
                      value={timeBio}
                      autoComplete="off"
                      name="date"
                      className="formGray"
                      type="time"
                      placeholder="Ingrese su Fecha"
                    />
                  </Col>

                  <Col >


                    <Form.Control
                      style={{ height: "30px", width: "120px",backgroundColor:'#2ba9cd',color:'white' }}
                      onClick={(e) => synchronizeTimeAndDate()}
                      value='Sincronizar'
                      autoComplete="off"
                      name="date"
                      className="formGray"
                      type="button"
                      placeholder="Ingrese su Fecha"
                    />

                  </Col>

                </Row>
                <Row className="mt-3">
                  <InputGroup className="">
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        className="ml-3 montse"
                        style={{ backgroundColor: "#FFFFFF", borderRight: "0" }}
                      >
                        Asunto:
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderBottom: "0",
                        borderLeft: "0",
                      }}
                      onChange={(e) => handleChangeSubject(e)}
                      value={subject}
                      autoComplete="off"
                      id="inlineFormInputGroup"
                      placeholder=""
                    />
                  </InputGroup>
                  <InputGroup
                    className="ml-3"
                    style={{ borderTop: "0", width: "100%", marginTop: "0px" }}
                  >
                    <InputGroup.Text
                      onChange={(e) => changeText(e)}
                      value={textBio}
                      as="textarea"
                      placeholder="Escriba su mensaje..."
                      rows={9}
                      style={{whiteSpace:"pre-wrap",width:'100%',  backgroundColor: "#FFFFFF",textAlign:'start'}}
                    />
                  </InputGroup>
                </Row>
                <Row>
                  <Col className="col-7">
                  {bounds && (
                      <Select
                        name="values"
                        value={selectBound}
                        onChange={(e) => handleChangeBound(e)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={bounds}
                        styles={colourStyles}
                      />
                    )}
                    </Col>
                </Row>
                <Row>
                  <Col className="mt-3 ">
                    <Button
                      disabled={!dateBio ? true : !timeBio ? true : !selectBound ? true : false}
                      style={{ marginRight: "-15px" }}
                      onSubmit={handleSubmit(onSubmit)}
                      className="float-right"
                      type="submit"
                      variant="info"
                    >
                      Guardar
                    </Button>
                    <Button
                      onClick={handleClose}
                      className="float-right mb-3 mr-2 btnBee"
                    >
                      Cancelar
                    </Button>
                  </Col>
                </Row>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
