import React, { useState, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./TableTickets.css";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { addDays, format, formatRelative, subDays } from 'date-fns'
import { es } from 'date-fns/locale'
import moment from "moment";
import swal from "sweetalert";
import * as  FaIcons from "react-icons/fa";
import { Link } from "react-router-dom";



export const TableTickets = (props) => {

	useEffect(() => {
		if (props.param) {
			quickSearch(props.param);
		}
	}, [props])


	// Components of a table used to find at User
	const [gridApi, setGridApi] = useState();
	const [columnApi, setColumnApi] = useState();
	const [frameworkComponents, setFramwrokw] = useState({
		slotDescription: SlotDescription,
		slotTime: SlotTime, slotMembers: SlotMembers,
		slotActivity: SlotActivity,
		slotActions: SlotActions,
		slotContact: SlotContact,
		slotLastActivityTime:SlotLastActivityTime,
		slotTimeCreation:SlotTimeCreation
	});



	// Methods used in a table of QuickSearch
	const onGridReady = (params) => {
		setGridApi(params);
		setColumnApi(params);
	}

	const onCellClicked = ({ data }) => {
		props.showTicket(data.id);
	}

	const iWanToGo = ({ data }) => {
		const { id_contact } = data;
	}


	async function quickSearch(value) {
		let objx = gridApi;
		value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
		setGridApi(objx);
		let rowsDisplay = objx.api.rowRenderer.rowModel.rowsToDisplay;
		let result;
		if (rowsDisplay) {
			result = await rowsDisplay.map(row => {
				let { data } = row;
				return data;
			})
			// props.newObj(result);
		}
	}

	const cellClass = (params) => {
		let back = params.value === 'Prospección' ? 'rag-green' : params.value === 'Aplicación' ? 'rag-amber' :
			params.value === 'Seguimiento' ? 'rag-red' : 'rag-purple';
		return back;
	};
	const ragCellClassRules = {
		'rag-green-outer': (params) => params.value === 2008,
		'rag-amber-outer': (params) => params.value === 2004,
		'rag-red-outer': (params) => params.value === 2000,
	};


	const dropTicket = (e) => {
		props.deleteTicket(e);

	}
	return (
		<div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
			<AgGridReact
				context={{
					dropTicket
				}}
				defaultColDef={{ resizable: true }}
				rowData={props.data}
				rowHeight={40}
				frameworkComponents={frameworkComponents}
				pagination={true}
				paginationPageSize={10}
				paginationNumberFormatter={function (params) {
					return params.value.toLocaleString();
				}}
				cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
				domLayout="autoHeight"
				onGridReady={onGridReady}
			>

				{/* Columns hidden helping with accent */}

				<AgGridColumn
					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Estudiante" sortable={true} hide={"true"} field="otherName" width="300" />

<AgGridColumn
					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Estudiante" sortable={true} hide={true} field="otherNameCreator" width="300" />

<AgGridColumn
					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Estudiante" sortable={true} hide={true} field="otherTitle" width="300" />

<AgGridColumn
					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Estudiante" sortable={true} hide={true}  field="otherDescription" width="300" />


				{/* Column Name of student */}
				<AgGridColumn
					cellRenderer="slotContact"
					onCellClicked={iWanToGo}
					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					hide={props.inModule ? false : true}
					headerName="Estudiante" sortable={true} field="fullnameStudent" width="300" />

	{/* Tags */}
	<AgGridColumn
					onCellClicked={onCellClicked}
					filter="agTextColumnFilter"

					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Etiqueta" sortable={true} field="tag_name" width="300" />

					{/* Members */}
				<AgGridColumn
					onCellClicked={onCellClicked}

					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Miembros"
					cellRenderer="slotMembers" 
					sortable={true} field="members" width="300" />


						{/* Members Formatted*/}
				<AgGridColumn
					onCellClicked={onCellClicked}

					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Miembros formated"
					hide="true"
					 sortable={true} field="membersFormated" width="300" />
					

				{/* <AgGridColumn
					onCellClicked={onCellClicked}
					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					hide={props.inModule ? false : true}
					headerName="Sección" sortable={true}
					cellClassRules={ragCellClassRules}
					cellClass={cellClass} field="section" width="300" /> */}

				{/* Column Title */}
				<AgGridColumn
					onCellClicked={onCellClicked}
					filter="agTextColumnFilter"

					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Titulo" sortable={true} field="title" width="300" />
				{/* Column Description with mouseOver */}
				<AgGridColumn
					onCellClicked={onCellClicked}
					filter="agTextColumnFilter"

					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Descripción"
					hide={props.inModule ? false : true}
					cellRenderer="slotDescription"
					width="150" />


				<AgGridColumn
					onCellClicked={onCellClicked}
					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Sección" sortable={true}
					cellClassRules={ragCellClassRules}
					cellClass={cellClass} field="section" width="150" />


				{/* Column Created_at */}
				<AgGridColumn
					onCellClicked={onCellClicked}
					filter="agTextColumnFilter"

					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Fecha creación" sortable={true} field="created_at"
					cellRenderer="slotTimeCreation" width="300" />


				


				<AgGridColumn
					onCellClicked={onCellClicked}

					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Fecha Ultima iteracción" sortable={true} field="created_at"
					cellRenderer="slotTime" width="300" />

				{/* Last Activity */}
				<AgGridColumn
					onCellClicked={onCellClicked}

					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Ultima interacción" cellRenderer="slotActivity" sortable={true} field="activities" width="300" />



			
				{/* Acciónes */}
				<AgGridColumn
					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					cellRenderer="slotActions" headerName="Acciones" sortable={true} field="id" width="300" />

			</AgGridReact>




		</div>



	);
};

// slots


export const SlotContact = function SlotContact(props) {
	const { data, value } = props;
	return (

		<Link to={"/contacts/" + (data.id_contact) + "/tickets"}>
			<span>{value}</span>
		</Link>

	)
}
export const SlotActions = function SlotActions(props) {

	const { value } = props;

	const deleteTicket = (id = null) => {
		swal({
			title: "Estas seguro?",
			text: "Una vez eliminado,no podras recuperar este Ticket!",
			icon: "warning",
			dangerMode: true,
			buttons: ["No", "Si"],
		})
			.then((willDelete) => {
				if (willDelete) {
					props.context.dropTicket(id);
					// props.context.dropContact(id);
				} else {
					swal("Operacion cancelada!");
				}
			});
	}


	return (
		<>
			<FaIcons.FaTrashAlt onClick={(e) => deleteTicket(value)} title="Eliminar" style={{ color: '#DC3545' }} size={18}
			/>
		</>

	)
}

export const SlotActivity = function SlotActivity(props) {
	const { data } = props;
	const { last_activity } = data;
	const PopoverComponent = (obj) => {
		return (<Popover id="popover-basic">
			<Popover.Content>
				<strong className="line-clamp" style={{ whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{obj}</strong>
			</Popover.Content>
		</Popover>)
	}
	return (
		<>
			<OverlayTrigger trigger={["hover", "hover"]} placement="top"
				overlay={PopoverComponent(last_activity?.comment ?? last_activity?.description_action ?? '')}>
				<a>
					<svg width="16" height="16" viewBox="0 0 16 16" style={{ color: 'rgb(192, 203, 227)' }}>
						<path fill="currentColor"
							d="M9.944 0a.72.72 0 0 1 .511.213l4.333 4.364A.73.73 0 0 1 15 5.09v8.727C15 15.023 14.03 16 12.833 16H4.167A2.174 2.174 0 0 1 2 13.818V2.182C2 .977 2.97 0 4.167 0h5.777zm-.299 1.455H4.167a.725.725 0 0 0-.723.727v11.636c0 .402.324.727.723.727h8.666a.725.725 0 0 0 .723-.727V5.392l-3.91-3.937z"></path><path fill="currentColor" d="M10.667 4.364h3.61c.4 0 .723.325.723.727a.725.725 0 0 1-.722.727H9.944a.725.725 0 0 1-.722-.727V.727c0-.401.324-.727.722-.727.4 0 .723.326.723.727v3.637zM11.389 8c.399 0 .722.326.722.727a.725.725 0 0 1-.722.728H5.61a.725.725 0 0 1-.722-.728c0-.401.323-.727.722-.727h5.778zM11.389 10.91c.399 0 .722.325.722.726a.725.725 0 0 1-.722.728H5.61a.725.725 0 0 1-.722-.728c0-.401.323-.727.722-.727h5.778zM7.056 5.09c.398 0 .722.327.722.728a.725.725 0 0 1-.722.727H5.61a.725.725 0 0 1-.722-.727c0-.401.323-.727.722-.727h1.445z">
						</path>
					</svg>
				</a>
			</OverlayTrigger>
		</>
	)
}

export const SlotMembers = function SlotMembers(props) {
	const { value } = props;

	const PopoverComponent = (name) => {
		return (<Popover id="popover-basic">
			<Popover.Content>
				<span>{name}</span>
			</Popover.Content>
		</Popover>)
	}

	const showParticipant = (type = 'Administrador', name, obj) => {
		let n = name ? name.split(" ") : " ";
		let tag = '';
		let classDefined = '';
		if (n.length >= 3) {
			n = n[0].charAt(0) + n[1].charAt(0) + n[2].charAt(0);
		} else if (n.length >= 2) {
			n = n[0].charAt(0) + n[1].charAt(0);
		} else {
			n = n[0].charAt(0);
		}
		switch (type) {
			case 'contactos':
				classDefined = 'sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnP bgPink';
				break;
			case 'referencias':
				classDefined = 'sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnP bgPink';
				break;
				break;
			case 'colegios':
				classDefined = 'sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnP btn-success';
				break;
			default:
				classDefined = 'sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnP blueStrong';
				break;
		}
		tag =
			<OverlayTrigger trigger={["hover", "hover"]} placement="top"
				overlay={PopoverComponent(name)}>
				<span className={classDefined}>{n}</span>
			</OverlayTrigger>;
		return tag;
	}

	return (
		<>
			<div>
				{value.map(val => {
					return (<span key={val.id}>{showParticipant(val.type, (val.name + " " + val.father_lastname + " " + val.mother_lastname), val)}</span>)
				})}
			</div>
		</>

	)
}

export const SlotLastActivityTime = function SlotLastActivityTime(props){
	const { value } = props;
	function showDate(dateBD, time) {
		let datef = moment(dateBD).locale("es-mx").format("ddd D MMMM, YYYY ");
		let timef = moment(dateBD).locale("es-mx").format("h:mm A");
		datef = datef[0].toUpperCase() + datef.slice(1);
		datef = datef.replace(".", "");
		let tag = (
			<p className="Inter">
				{datef}
				{timef}
			</p>
		);
		return dateBD ? tag : "";
	}
	return <>{showDate(value[0].created_at)}</>;

}

export const SlotTimeCreation = function SlotTimeCreation(props){
	const { data  } = props;
	const { first_activity } = data;
	function showDate(first_activity) {
		 	const dateBD = first_activity.created_at;
		 	let datef = moment(dateBD).locale("es-mx").format("ddd D MMMM, YYYY ");
		 	let timef = moment(dateBD).locale("es-mx").format("h:mm A");
		 	datef = datef[0].toUpperCase() + datef.slice(1);
		 	datef = datef.replace(".", "");
		 	let tag = (
		 		<p className="Inter">
		 			{datef}
		 			{timef}
		 		</p>
		 	);
		 	return dateBD ? tag : "";
		

	}
	return <>{showDate(first_activity)}</>;
}



export const SlotTime = function SlotTime(props) {
	const { data  } = props;
	const { last_activity } = data;

	function showDate(last_activity) {
		 	const dateBD = last_activity.created_at;
		 	let datef = moment(dateBD).locale("es-mx").format("ddd D MMMM, YYYY ");
		 	let timef = moment(dateBD).locale("es-mx").format("h:mm A");
		 	datef = datef[0].toUpperCase() + datef.slice(1);
		 	datef = datef.replace(".", "");
		 	let tag = (
		 		<p className="Inter">
		 			{datef}
		 			{timef}
		 		</p>
		 	);
		 	return dateBD ? tag : "";
		

	}
	return <>{last_activity ? showDate(last_activity) : ''}</>;
}

export const SlotDescription = function SlotDescription(props) {
	const { data } = props;
	const PopoverComponent = (obj) => {
		return (<Popover id="popover-basic">
			<Popover.Content>
				<strong className="line-clamp" style={{ whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{obj.description}</strong>
			</Popover.Content>
		</Popover>)
	}
	return (
		<>
			<OverlayTrigger trigger={["hover", "hover"]} placement="top"
				overlay={PopoverComponent(data)}>
				<a>
					<svg width="16" height="16" viewBox="0 0 16 16" style={{ color: 'rgb(192, 203, 227)' }}>
						<path fill="currentColor"
							d="M9.944 0a.72.72 0 0 1 .511.213l4.333 4.364A.73.73 0 0 1 15 5.09v8.727C15 15.023 14.03 16 12.833 16H4.167A2.174 2.174 0 0 1 2 13.818V2.182C2 .977 2.97 0 4.167 0h5.777zm-.299 1.455H4.167a.725.725 0 0 0-.723.727v11.636c0 .402.324.727.723.727h8.666a.725.725 0 0 0 .723-.727V5.392l-3.91-3.937z"></path><path fill="currentColor" d="M10.667 4.364h3.61c.4 0 .723.325.723.727a.725.725 0 0 1-.722.727H9.944a.725.725 0 0 1-.722-.727V.727c0-.401.324-.727.722-.727.4 0 .723.326.723.727v3.637zM11.389 8c.399 0 .722.326.722.727a.725.725 0 0 1-.722.728H5.61a.725.725 0 0 1-.722-.728c0-.401.323-.727.722-.727h5.778zM11.389 10.91c.399 0 .722.325.722.726a.725.725 0 0 1-.722.728H5.61a.725.725 0 0 1-.722-.728c0-.401.323-.727.722-.727h5.778zM7.056 5.09c.398 0 .722.327.722.728a.725.725 0 0 1-.722.727H5.61a.725.725 0 0 1-.722-.727c0-.401.323-.727.722-.727h1.445z">
						</path>
					</svg>
				</a>
			</OverlayTrigger>
		</>
	)
}
