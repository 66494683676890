import axios from 'axios';
import { constaApi } from '../../constants/constants';
import React, { useEffect,useState } from 'react'
import Skeleton from 'react-loading-skeleton';


export default function PermissionsHook( props) {


    const[isAuthorized,setIsAuthorized] = useState();
    const [text,setText] = useState('Comprobando permisos...');
    useEffect(()=>{

      checkPermissions();

    },[])

    const checkPermissions = () =>{
      const result = JSON.parse(localStorage.getItem('user')) ;
      const id = result?.id;
      if(id){
          axios.post(constaApi + 'getPermissionsByContact', { id: id }, {
          }).then(function (response) {
              setText('Espere...');
              const { data } = response;
                const result = data.find(dat => dat.module === props.module);
                if(result != undefined){
                  props.finishLoadin();
                } else {
  
             
                  window.location.replace("/error/401");
  
                  return () => {}; // no-op
  
                  // Agregar el redirect to ERROR 401 ROUTE
  
                    
                }
                
            });
         }
    }

    return(
        <div style={{marginTop:'5%',marginLeft:'10%'}}>
        <Skeleton  width={210}  count={200} />

        <h2>
            {/* {text} */}
        </h2>
    </div>
    )
 
}
