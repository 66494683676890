
import SearchBar from '../../components/GeneralComponents/SearchBar';
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import '../../pages/cssPages/app.css';
import { constaApi } from '../../constants/constants';
import axios from 'axios';
import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { CSVLink } from "react-csv";
import {
    Card,
    CardHeader,
    CardBody,
    Table,
    Row,
    Col,
  } from "reactstrap";

import { countries  as countries } from '../../MyJson/countriesJson';
import { starLoadingColleges } from '../../actions/colleges/colleges';

export const SlotProposal = function SlotProposal(props){
  const {data} = props;
  const {proposal} = data;
  function showProposals (obj){
    return 'propuesta';
  }
  return (
  <>
  {proposal.isArray ?
    [proposal.map(d => {
      return (
        <span>{d.pname}</span> 
      )
    })]
  :
  [Object.keys(proposal).map((index,i)=>{
    return (
      <span>{proposal[index]['pname'] + ' ,'}</span>   
    )
  })]
}     
  </>
  )
}
export const SlotName = function SlotName(props) {
  const dispatch = useDispatch();
  const click = (data) => {
    let newObj = {...data,id:data.id_contact,fullname:data.fullname}
  // dispatch( activeContact(newObj) );
  }

  const showName = (id = null, fullname = "") => {
      let n = fullname ? fullname.split(" ") : " ";
      let tag = '';
      n = n ? n[0].charAt(0) + n[1].charAt(0) : null;
      tag = <>
          <Link  onClick={(e) => click(props.data)} to={"contacts/" + (props.data.idx) + "/Interdashboard"}>
              <span className="mt-1 mr-2 sc-caSCKo fXbvQO styles__Company-sc-103gogw-0 jdbeFR">{n}</span>
              <span>{fullname} </span>
          </Link>
      </>;
      return tag;
  }
  return (
      <>
          <span>{showName(props.data.id, props.data.fullname ? props.data.fullname : (props.data.name  + " " + props.data.father_lastname))}</span>
      </>
  )
}

export const SlotRecomend = function SlotRecomend(props){
    const {data} = props;

    return (
        <span>{data.name_recom ??''} {data.father_recom ??''}{data.mother_recom ??''}</span>
    )
}
export const SlotCollege = function SlotCollege(props){
    const {data}  = props;
    return (
        <>
            {data.schoool == 'Otro'
            ?
            [data.other_School]
            :
            [data.schoool]
        }
        </>
    )
}
export const SlotOrigin = function SlotOrigin(props){
    const {data}  = props;
      return (
          <span>{data.city ? data.city  : ''}{data.state ? ', ' + data.state : ''}</span>
      )

  }
export default function AnaliticsSection(props) {
  const [program, setProgram] = useState(["Boarding School",
  "School District",
  "Summer Camp",
  "Language School",
  "University/College",
  "Work & Travel"]);
  const years = [
      2019,
      2020,
      2021,
      2022,
      2023,
      2024,
      2025,
      2026,
      2027,
      2028,
      2029,
      2030
  ];

   const [unionPrograms,setUnionPrograms] = useState();
   const [selectProgram,setSelectProgram] = useState();
   const [selectCollege,setSelectCollege] = useState();
   const [selectCountry,setSelectCountrie] = useState();
    const startValue = new Date(new Date().getFullYear(), new Date().getMonth(), 14);
    const endValue = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 15);
    const { users } = useSelector(state => state.users);
    const [usersFormat, setUsersFormat] = useState([]);
    const [selectUser, setSelectUser] = useState(null);
    const [rangeDate, setRangeDate] = useState([startValue, endValue]);
    const [show,setShow] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    let [color, setColor] = useState("#72B6E1");
    const { colleges } = useSelector(state => state.colleges);
    const [collegesFormat,setCollegesFormat] = useState([]);
    const [frameworkComponents, setFramwrokw] = useState({
        slotRecomend: SlotRecomend,
        slotName : SlotName,
        slotCollege: SlotCollege,
        slotOrigin:SlotOrigin,
        slotProposal:SlotProposal
      });

    // Can be a string as well. Need to ensure each key-value pair ends with ;
        const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        `;
        const [param, setParam] = useState(null);

        useEffect(() => {
          dispatch ( starLoadingColleges() );
         let x = [];
          colleges.map(col => {
            let a = {
              name: col.name,
              value : col.id,
              label:col.name
            }
            x.push(a);
          })
          setCollegesFormat(x);
        }, [dispatch]);

        useEffect(() => {
          let x = [];
           program.map(pr => {
            years.map(ye => {
              let a = {
              name:  pr + ' ' + ye,
              value: pr + ' ' + ye,
              label: pr + ' ' + ye,
              };
             x.push(a);
            })
          })
          setUnionPrograms(x);
        
        },[])
        useEffect(() => {
            if (param) {
              quickSearch(param);
            }
          }, [param]);
    const [load, setLoad] = useState(false);
    const [init] = useState(JSON.parse(localStorage.getItem('user')) || { logged: false });
    const quickSearch = async (value) => {
        let objx = gridApi;
        value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
        setGridApi(objx);
        let rowsDisplay = objx.api.rowRenderer.rowModel.rowsToDisplay;
        let result;
        if (rowsDisplay) {
          result = await rowsDisplay.map(row => {
            let { data } = row;
            return data;
          })
          setNewObj(result);
        }
      }
    const consult = (e) => {
        e.target.value === "" ? setParam('keyWordSeccret302') : setParam(e.target.value);
    }
    function open() { }
    function changeDates(e) {
        let start = new Date(e.value[0]).toLocaleDateString('en-CA'); // 8/19/2020
        let end = new Date(e.value[1]).toLocaleDateString('en-CA'); // 8/19/2020
        let arr = [];
        arr[0] = start + ' ' + '00:00:00';
        arr[1] = end + ' ' + '23:59:59';
        let d = new Date(arr[0]);
        setRangeDate(arr);
    }
    function handleChangeSelect(e) {
        setSelectUser(e);
    }
    function formatResults (obj) {
      let propuestas = '';
      let equis = [];
      if(obj.length > 0 ){
        equis =  obj.map(d => {
          propuestas = '';
          if(d.proposal){
           if(d.proposal.isArray){
             d.proposal.map(pro => {
               propuestas += pro.pname + ', '
             })
           } else {
            Object.keys(d.proposal).map((index,i)=>{
             propuestas += d.proposal[index]['pname'] + ', ';
            })
           }
           return {
             ...d,
             propuestas: propuestas,
           }               
          }
          
        }); 
        setResults(equis);
      }

    }
    function Search() {
        setShow(true);
        setLoading(true);
        let obj = {
            rangeDate: rangeDate,
            program : selectProgram ? selectProgram.value : null,
            college : selectCollege ? selectCollege.label : null,
            country : selectCountry ? selectCountry.label : null,
        }
        axios.post(constaApi + 'analitics/check', obj)
            .then(function (response) {
                if(response.data){
                  formatResults(response.data);
                    setLoading(false);
                   
                } else {
                    setResults([]);
                    setLoading(false);
                }
            })
            .finally(() => {
            }).catch(err =>{
                setLoading(false);
            });

            
    }
    const [gridApi, setGridApi] = useState();
    const [columnApi, setColumnApi] = useState();
    const [newObj,setNewObj] = useState([]);
    const headers = [
      { label: "Nombre", key: "fullname" },
      { label: "Rating", key: "rating"},
      { label: "Propuesta", key: "propuestas" },
      { label: "Programa", key: "type" },
      { label: "Grado_escolar", key: "grade" },
      { label: "Pais", key: "country" },
      { label: "Ciudad", key: "origin" },
      { label: "Colegio", key: "schoool" },
      { label: "Nombre_Colegio", key: "other_School" },
      { label: "Recomendado", key: "fullnameRecom" },
    
    ];

    const csvReport =  {
      data: newObj,
      headers: headers,
      filename: 'Analitics.csv'
    };
    const exporta = () => {
        if(newObj.length > 0){

          
        } else {
          setNewObj(results);
        }
      }
      const onGridReady = (params) => {
        setGridApi(params);
        setColumnApi(params);
      };
      const autoSizeAll = (skipHeader) => {
        var allColumnIds = [];
        columnApi.columnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        columnApi.columnApi.autoSizeColumns(allColumnIds, skipHeader);
      };
      const onFirstDataRendered = (event) => {
        autoSizeAll(true);
      };
      function changeProgram (e){
        setSelectProgram(e);
      }
      function changeCollege (e){
        setSelectCollege(e);
      }
      function changeCountry (e){
        setSelectCountrie(e);
      }
    return (
        <div className="content">
            <div className="row">
            <div style={{ padding: 10 }} className="card col-3">
                <DateRangePickerComponent
                    startDate={startValue}
                    endDate={endValue}
                    placeholder="Rango de fechas"
                    id="daterangepicker"
                    format="dd-MMM-yy"
                    onChange={(e) => changeDates(e)}
                />
                <button
                    className="btn btn-info" onClick={(e) => Search()}>Buscar</button>
            </div>
            <div style={{ padding: 10,marginLeft:'10px' }} className="card col-3">
            <Select
                        name="values"
                         value={selectProgram}
                         onChange={(e) => changeProgram(e)}
                        // defaultValue={[values[0]]}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={unionPrograms}
                      />
                
            </div>
            <div style={{ padding: 10,marginLeft:'10px' }} className="card col-3">
            <Select
                        name="values"
                        // value={selectValue}
                        onChange={(e) => changeCollege(e)}
                        // defaultValue={[values[0]]}
                        className="basic-multi-select"
                        classNamePrefix="select"
                         options={collegesFormat}
                      />
                
            </div>
            <div style={{ padding: 10,marginLeft:'10px' }} className="card col-2">
            <Select
                        name="values"
                        // value={selectValue}
                        onChange={(e) => changeCountry(e)}
                        // defaultValue={[values[0]]}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={countries}
                      />
                
            </div>
            </div>
           {show && 
           [loading  ? 
            <FadeLoader color={color} loading={loading} css={override} size={50} />
            :
            <>
            <div className="row">
                <div className="col-12">
                    <div className="mt-5 col d-flex justify-content-end">
                    <button
      style={{textDecoration:'white'}}
      className="btn btn-info" onClick={(e) => exporta(e)}
      >
        <CSVLink {...csvReport}><span>Exportar</span>
        </CSVLink>
        </button>
                    </div>

                    
                    <div className="searchBarTable">
        <SearchBar consult={(e) => consult(e)}/>
        </div>
                       <div
              className="ag-theme-alpine"
              style={{ height: "100%", width: "100%" }}>

                    <AgGridReact
                            rowData={results}
                            rowHeight={40}
                            cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                            domLayout="autoHeight"
                            onGridReady={onGridReady}
                            frameworkComponents={frameworkComponents}
                            onFirstDataRendered={onFirstDataRendered}
                            pagination={true}
                            paginationPageSize={10}
                            paginationNumberFormatter={function (params) {
                                return params.value.toLocaleString();
                            }}
                            rowSelection="multiple"
                        >
                            {/* Column Name */}
                            <AgGridColumn
                                cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                                cellRenderer="slotName"
                                headerName="Nombre" sortable={true} field="fullname" width="300" />

<AgGridColumn
                                cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                                headerName="Propuesta"  cellRenderer="slotProposal" sortable={true} field="pname" width="300" />
                                <AgGridColumn
                                cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                                headerName="Programa" sortable={true} field="p_name" width="300" />
                                  <AgGridColumn
                                cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                                headerName="Pais" sortable={true} field="country" width="300" />
                            {/* Column OtherName */}
                            <AgGridColumn
                                cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                                cellRenderer="slotnameF"
                                hide={true}
                                headerName="otherName" sortable={true} field="otherName" width="300" />

                            {/* Column Rating */}
                            <AgGridColumn
                                cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                                cellRenderer="slotRating"
                                headerName="Grado" sortable={true} field="grade" width="150" />
                            {/* Column City  with state*/}
                            <AgGridColumn
                                cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                                headerName="Ciudad" sortable={true} field="origin" width="300" />

                            {/* Column City Hidde */}
                            <AgGridColumn
                                cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                                headerName="Ciudad" sortable={true} field="city" hide={true} width="200" />

                            {/* Column State hide */}
                            <AgGridColumn
                                cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                                headerName="Estadoxx" sortable={true} field="state" hide={true} width="200" />


                            {/* Column Colegio */}
                            <AgGridColumn
                                cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                                headerName="Colegio" cellRenderer="slotCollege"  field="other_School" width="200"  />
                                  <AgGridColumn
                                cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                                headerName="Colegio dos" field="other_School" hide={true} width="200"  />
                                 <AgGridColumn
                                cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                                headerName="Colegio tres" field="schoool" hide={true} width="200"  />


                            {/* Column references */}
                            <AgGridColumn
                                headerName="Recomendado" field="fullnameRecom" width="300" />
                                 <AgGridColumn
                                headerName="Recomendado" hide={true} field="name_recom" width="300" />
                                 <AgGridColumn
                                headerName="Recomendado" hide={true} field="father_recom" width="300" />
                                 <AgGridColumn
                                headerName="Recomendado" hide={true} field="mother_recom" width="300" />
                            {/* Column Advisor */}
                         
                            </AgGridReact>
                            
                    </div>
                    <div className="content">
      <div>
    
      </div>
        {!loading ?
          <Row>

            <div
              className="ag-theme-alpine"
              style={{ height: "100%", width: "100%" }}
            >
            
            </div>
          </Row>
          :
          <div className="row mt-2">
          </div>
        }

      </div>



                </div>
            </div>

           </>
            ]
           }
        </div>
    )
}
