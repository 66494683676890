
import axios from 'axios';
import { constaApi } from '../../../constants/constants';
import { setError,removeError, startLoading, finishLoading } from "../../../actions/uiNotificactions/ui";

export const loadStaffs = async  (id) => {
        // dispatch( startLoading() );
    const staffs = [];
    const {data} = await axios.post(constaApi+'colleges/staff/index',{id:id})
    data.forEach(child => {
        staffs.push({
            id:child.id,
            ...child
        });
    });
        return staffs;
}