import React,{useEffect,useState} from 'react'
import AddOrEditCollege from '../components/collegesLocalesComponents/AddOrEditCollege';
import TableColleges from '../components/collegesLocalesComponents/TableColleges.jsx';
import { useDispatch, useSelector } from 'react-redux';
import SearchBar from '../components/GeneralComponents/SearchBar';
import { starLoadingLocalColleges } from '../actions/colleges/colleges';
export default function CollegesLocales() {
    const dispatch = useDispatch();
    const [param,setParam] = useState(null);

    const consult = (e) => {
        e.target.value === "" ?  setParam('keyWordSeccret302') :  setParam(e.target.value);
    }
    useEffect(()=> {
        // dispatch ( setColleges([]) );   
        dispatch ( starLoadingLocalColleges() );
    },[])
    return (
        <div className="content">
        <div className="row">
            <div className="col-12">
            <div className="col d-flex justify-content-end">
            <AddOrEditCollege type={'Locale'} />
            </div>
            <div className="searchBarTable">
            <SearchBar consult={(e) => consult(e)}/>
            </div>
            <TableColleges type={'Locale'} collegesLocales={true} param={param}/>
                </div>
        </div>
        </div>
    )
}