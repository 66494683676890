import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import { constaApi } from '../../constants/constants';
import NotificationAlert from "react-notification-alert";
import {
  Row,
  Col,
} from "reactstrap";
import {  Popover,OverlayTrigger } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';


function LeadsHistory() {
  useEffect(() => {
    // loadLeds
    loadLeds();

  }, [])

  // data
  const [leads, setLeads] = useState([]);
  const notificationAlert = useRef();
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [frameworkComponents, setFramwrokw] = useState({ slotComments:SlotComments, slotDate: SlotDate});


  const loadLeds = () => {
    axios.get(constaApi + 'getLeadsHistory', {
      headers: {
        "Accept": "application/json"
      }
    }).then(function (response) {
      let { data } = response;
      setLeads(data);
      // setLeads(response.data);
    }).catch(error => {
      // setLoading(false);
    });
  }
  const onGridReady = (params) => {
    setGridApi(params);
    setColumnApi(params);
  }
 
  return (
    <>
      <div className="content">
        <NotificationAlert ref={notificationAlert} />
        {!loading ? (
          <Row>
            <div
              className="ag-theme-alpine"
              style={{ height: "100%", width: "100%" }}
            >
              <AgGridReact
                context={{
                  // completeReminder,
                  // editReminder,
                  // deleteReminder
                }}
                rowData={leads}
                rowHeight={40}
                domLayout="autoHeight"
                onGridReady={onGridReady}
                frameworkComponents={frameworkComponents}
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={function (params) {
                  return params.value.toLocaleString();
                }}
                rowSelection="multiple"
              >

                <AgGridColumn
                  headerName="Nombre"
                  field="name"
                  width="250"

                ></AgGridColumn>
                <AgGridColumn
                  headerName="Fecha Nacimiento"
                  field="birthday"
                  cellRenderer="slotDate"
                  width="200"
                />
                <AgGridColumn
                  headerName="Contacto desde"
                  field="fromweb"
                  width="200"
                />
                <AgGridColumn
                  headerName="Destino"
                  field="destiny"
                  width="200"
                />
                <AgGridColumn
                  headerName="Grado"
                  field="grade"
                  width="200"
                />
                <AgGridColumn
                  headerName="Nombre Padres"
                  field="nameParents"
                  width="200"
                />
                <AgGridColumn
                  headerName="Email"
                  field="email"
                  width="250"
                />
                <AgGridColumn
                  headerName="Telefono"
                  field="phone"
                  width="200"
                />
                <AgGridColumn
                  headerName="Ciudad"
                  field="city"
                  width="200"
                />
                <AgGridColumn
                  headerName="Estado"
                  field="state"
                  width="200"
                />
                 <AgGridColumn
                  headerName="Comentarios"
                  field="comments"
                  width="200"
                  cellRenderer="slotComments"
                />
                <AgGridColumn
                  headerName="Fecha Alta"
                  field="updated_at"
                  cellRenderer="slotDate"
                  width="200"
                />
              </AgGridReact>
            </div>
          </Row>
        ) : (
          <div className="row mt-2">
            <NotificationAlert ref={notificationAlert} />
            <Skeleton width="60rem" height={30} count={10} />
          </div>
        )}
      </div>
    </>



  )
}


// SLOTS
// Component SlotComments
export const SlotComments = function SlotComments(props) {
  moment.locale('es-mx')
  let { value } = props;
  const PopoverComponent = (comment) => {
    return (<Popover id="popover-basic">
        <Popover.Content>
       <span>{comment}</span>
        </Popover.Content>
    </Popover>)
  }
  const showComments = (comment) => {
   let tag =  <OverlayTrigger trigger={["hover", "hover"]} placement="top"
overlay={PopoverComponent(comment)}>
  <span >{comment}</span>
  </OverlayTrigger>;

  return tag;
  }
  return (
    <>
    {showComments(value)}
    </>
  )
}


// Component SlotDate
export const SlotDate = function SlotDate(props) {
  moment.locale('es-mx')
  let { value } = props;

  const dateToMoment = (date) => {
    return moment(date).locale('es-mx').format("ddd D MMMM, YYYY");
  }
  return (
    <>
      <span>{dateToMoment(value)}</span>
    </>
  )
}

export default LeadsHistory