import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import axios from 'axios';
import { constaApi } from '../../constants/constants';
import { CSVLink } from "react-csv";
import swal from 'sweetalert';
import StarRatings from 'react-star-ratings';
import { useDispatch, useSelector } from 'react-redux';
import TableRating from './TableRating';
import moment from 'moment';
import TableVinculated from './TableVinculated';
import Vinculated from './Vinculated';

var _ = require('lodash');

export default function TableProspection(props) {

  const cellStyle = (params) => {

    console.log('params',params.data);
    if( params.data.last_comment){
      const color = numberToColor(params.data.last_comment.date);
      return {
        backgroundColor: color,
      };
    }
  };

  const numberToColor = (val) => {
    // Frist date
    let fecha1 = new Date(val);

    // Current date
    let fecha2 = new Date()

    let resta = fecha2.getTime() - fecha1.getTime()
    resta = Math.round(resta/ (1000*60*60*24));


    if(resta  >= 21){
      return '#ffaaaa';
    }


  };



  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "#",
      cellRenderer: 'slotNumber',
      wrapText: 'true',
      filter: "agTextColumnFilter",
      width: '50'
    },
    {
      headerName: "Nombre",
      field: "name",
      cellRenderer: 'slotName',
      wrapText: 'true',
      sortable: "true",
      filter: "agTextColumnFilter",
      width: '300',
        getQuickFilterText: params => {
           return params.value.name;
       }
    },
    {
      headerName: "Nombre sin acento",
      field: "otherName",
      cellRenderer: 'slotName',
      wrapText: 'true',
      sortable: "true",
      filter: "agTextColumnFilter",
      width: '300',
      hide: "true"
    },
    {
      headerName: "Rating",
      field: "rating",
      cellRenderer: 'slotRating',
      wrapText: 'true',
      sortable: 'true',
      filter: "agTextColumnFilter",
      width: '250',
      filterParams: "ratingFilter"
    },
    {
      headerName: "Rating formateado",
      field: "ratingformat",
      wrapText: 'true',
      sortable: 'true',
      filter: "agTextColumnFilter",
      width: '250',
    },
    {
      headerName: "Prospeccion",
      field: "name_prospection",
      sortable: "true",
      filter: "agTextColumnFilter",
      width: '250',
    },
    {
      headerName: "Colegio Local",
      field: "colName",
      sortable: "true",
      filter: "agTextColumnFilter",
      width: '250',
    },


    {
      headerName: "Grado",
      field: "grade",
      sortable: "true",
      filter: "agTextColumnFilter",
      width: '150',
    },
    {
      headerName: "Ciudad",
      field: "city",
      sortable: "true",
      filter: "agTextColumnFilter",
      width: '200',
      cellRenderer: "slotOrigin",
      cellStyle: "fontFamily:'Montserrat,sans-serif',fontSize:'13px',fontWeight:'500', color:'#3B3B3B'"
    },
    {
      headerName: "Ciudad",
      field: "city",
      sortable: "true",
      filter: "agTextColumnFilter",
      width: '150',
      hide: "true",

    },
    {
      headerName: "Estado",
      field: "state",
      sortable: "true",
      filter: "agTextColumnFilter",
      width: '150',
    },

    {
      headerName: "Status",
      field: "status",
      sortable: "true",
      filter: "agTextColumnFilter",
      width: '150',
    },
    {
      headerName: "Status",
      field: "otherStatus",
      filter: "agTextColumnFilter",
      width: '150',
      hide: 'true'
    },
    {
      headerName: "Substatus",
      field: "substatusFormated",
      sortable: "true",
      filter: "agTextColumnFilter",
      width: '150',
    },

    {
      headerName: "Advisor",
      field: "name_advisor",
      filter: "agTextColumnFilter",
      sortable: "true",
      width: '250',
      hide: 'false'
    },
    {
      headerName: "Sexo",
      field: "sex",
      sortable: "true",
      filter: "agTextColumnFilter",
      width: '250',
      hide: 'false'
    },
    {

      headerName: "Nota de prospección",
      field: "noteProspection",
      sortable: "true",
      filter: "agTextColumnFilter",
      width: '300',
      hide: 'false'


    },

    {
       headerName: " Ultima Bitácora",
       field: "lastdate",
       width: "250",
       sortable: "true",
       headerTooltip: 'Fecha asignada de la ultima entrada de bitácora',
       cellStyle: cellStyle,
       cellRenderer: 'slotDate',
       getQuickFilterText: params => {
         return null;
     }
    },
    {
      headerName: "Actualización",
      field: "lastMessage",
      width: '250',
      sortable: "true",
      headerTooltip: 'Fecha de creación de la ultima entrada de bitácora',
      cellRenderer: 'slotDate',
      getQuickFilterText: params => {
        return null;
      }
    },
    {
      headerName: "Fecha Creación",
      field: "created_at",
      width: '250',
      sortable: "true",
      headerTooltip: 'Fecha de creación del contacto',
      cellRenderer: 'slotDate',
      getQuickFilterText: params => {
        return null;
      }
    },
    // {
    //   headerName: "Email",
    //   field: "email",
    //   width: '250',
    //   hide: true,
    // },
    // {
    //   headerName:"EmalRef1",
    //   field: "ref1",
    //   width:"150",
    //   hide: true,

    // },
    // {
    //   headerName:"EmalRef2",
    //   field: "ref2",
    //   width:"150",
    //   hide: true,

    // },
    // {
    //   headerName:"EmalRef3",
    //   field: "ref3",
    //   width:"150",
    //   hide: true,

    // }
  ]);
  let userActive = JSON.parse(localStorage.getItem("user"));

  // Execute when the props change
  useEffect(() => {
    if (props.param) {
      quickSearch(props.param);
    }
  }, [props])

  // Execute when you open this section
  useEffect(() => {
    loadProspections();
    // loadVinculateds();
  }, [])
  const loadVinculateds = () => {
    axios.post(constaApi + 'prospectionSectionAgregated')
    .then(function (response) {
      checkValues(response.data,1);
      const result = _.groupBy(response.data, "name")
      setAuxRow(result);
    }).catch(error => {
    });
  }
  const loadProspections = () => {
    axios.post(constaApi + 'prospectionSection')
      .then(function (response) {
        checkValues(response.data,0);
        const result = _.groupBy(response.data, "name")
        setAuxRow(result);
      }).catch(error => {
      });
  }
  const [vinculateds,setVinculateds] = useState([]);
  const [frameworkComponents, setFramwrokw] = useState({slotCollege:SlotCollege,  slotDate: SlotDate, slotRating: SlotRating, slotName: SlotName, slotNumber: SlotNumber, slotOrigin: SlotOrigin, slotProgram: SlotProgram });
  const [values, setValues] = useState([
    {
      value: 1,
      label: 1,
    },
    {
      value: 2,
      label: 2,
    },
    {
      value: 3,
      label: 3,
    },
    {
      value: 4,
      label: 4,
    }, {
      value: 5,
      label: 5,
    }
  ]);
  const [selectValue, setSelectValue] = useState();


  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rows, setRows] = useState(null);
  const [auxRow, setAuxRow] = useState([]);
  const [newObj, setNewObj] = useState([]);
  const [filterRating, setFilterRating] = useState(null);
  const [rowsRating, setRowsRating] = useState([]);
  const [colors] = useState([
    'red', 'blue', 'green', 'gray', 'white'
  ]);
  const headers = [
    { label: "Nombre", key: "name" },
    { label: "Rating", key: "rating" },
    { label: "Prospeccion", key: "name_prospection" },
    { label: "Colegio Local", key: "colName" },
    { label: "Grado", key: "grade" },
    { label: "Ciudad", key: "city" },
    { label: "Estado", key: "state" },
    { label: "Substatus", key: "substatusFormated" },
    { label: "Status", key: "status" },
    { label: "Sexo", key: "sex" },
    { label: "Nota de prospección", key: "noteProspection" },
    { label: "Advisor", key: "name_advisor" },
    { label: "Actualización", key: "lastMessageFormated" },
    { label: "Ultima Bitácora", key: "lastdateFormated" },
    { label: "Creación", key: "createdAtFormated" },
  ];
  const csvReport = {
    data: newObj,
    headers: headers,
    filename: 'ProspectionReport.csv'
  };
  const handleChange = (e) => {
    const { value } = e;
    let aux = [];
    let finArray = [];
    let obj = { program: '', evaluation: 0, cF: 0, cP: 0, p: 0, aD: 0, deC: 0, aP: 0 };
    rows.map(r => {
      if (value == r.rating) {
        aux.push(r);
      }
    })
    const result = _.groupBy(aux, "name_prospection")
    Object.keys(result).map((name, i) => {
      obj.program = name;
      result[name].map(re => {
        switch (re.status) {
          case 'Decisión':
            obj.deC = obj.deC + 1;
            break;
          case 'Evaluación':
            obj.evaluation = obj.evaluation + 1;
            break;
          case 'Contacto Previo':
            obj.cP = obj.cP + 1;
            break;
          case 'Contacto Formal':
            obj.cF = obj.cF + 1;
            break;
          case 'Presentación':
            obj.p = obj.p + 1;
            break;
          case 'Aclaración de dudas':
            obj.aD = obj.aD + 1;
            break;
          case 'Aplicar':
            obj.aP = obj.aP + 1;
            break;
        }
      })
      finArray.push(obj);
      obj = { program: '', evaluation: 0, cF: 0, cP: 0, p: 0, aD: 0, deC: 0, aP: 0 };
    })
    setRowsRating(finArray);

  }
  const checkValues = (obj,option = 0) => {

    let color = 0;
    let newObj = obj.map((o, index) => {
      if (index > 0) {
        if (obj[index - 1].name == o.name) {
          return {
            ...o,
            otherName: o.name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            otherStatus: o.status.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            otherSchool: o.schoool ? o.schoool.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            substatusFormated : o.substatus ? 'Substatus ' + o.substatus : "",
            repeat: true, color: color,
            ref1: o.contacts_references[0] ? o.contacts_references[0].email : '',
            ref2: o.contacts_references[1] ? o.contacts_references[1].email : '',
            ref3: o.contacts_references[2] ? o.contacts_references[2].email : '',
            localCollege: o.schoool == "Otro" ? o.other_School : o.schoool,
            lastMessage : o.last_comment ? o.last_comment.created_at : 'Error',
            lastdate: o.last_comment ? o.last_comment.date : 'Error',
            createdAtFormated : o.created_at ? dateFormated(o.created_at) : 'Error',
            lastMessageFormated : o.last_comment ? dateFormated(o.last_comment.created_at) : 'Error',
            lastdateFormated: o.last_comment ? dateFormated(o.last_comment.date) : 'Error',

          }
        } else {
          color = color == 0 ? -1 : 0;
          return {
            ...o,
            otherName: o.name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            otherStatus: o.status.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            otherSchool: o.schoool ? o.schoool.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            substatusFormated : o.substatus ? 'Substatus ' + o.substatus : "",
            origin: true, color: color,
            ref1: o.contacts_references[0] ? o.contacts_references[0].email : '',
            ref2: o.contacts_references[1] ? o.contacts_references[1].email : '',
            ref3: o.contacts_references[2] ? o.contacts_references[2].email : '',
            localCollege: o.schoool == "Otro" ? o.other_School : o.schoool,
            lastMessage : o.last_comment ? o.last_comment.created_at : 'Error',
            lastdate: o.last_comment ? o.last_comment.date : 'Error',
            createdAtFormated : o.created_at ? dateFormated(o.created_at) : 'Error',
            lastMessageFormated : o.last_comment ? dateFormated(o.last_comment.created_at) : 'Error',
            lastdateFormated: o.last_comment ? dateFormated(o.last_comment.date) : 'Error',


          }
        }
      } else {
        return {
          ...o,
          otherName: o.name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          otherStatus: o.status.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          otherSchool: o.schoool ? o.schoool.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
          substatusFormated : o.substatus ? 'Substatus ' + o.substatus : "",
          origin: true, color: color,
          ref1: o.contacts_references[0] ? o.contacts_references[0].email : '',
          ref2: o.contacts_references[1] ? o.contacts_references[1].email : '',
          ref3: o.contacts_references[2] ? o.contacts_references[2].email : '',
          localCollege: o.schoool == "Otro" ? o.other_School : o.schoool,
          lastMessage : o.last_comment ? o.last_comment.created_at : 'Error',
          lastdate: o.last_comment ? o.last_comment.date : 'Error',
          createdAtFormated : o.created_at ? dateFormated(o.created_at) : 'Error',
          lastMessageFormated : o.last_comment ? dateFormated(o.last_comment.created_at) : 'Error',
          lastdateFormated: o.last_comment ? dateFormated(o.last_comment.date) : 'Error',
        };
      }
    })
    if (newObj) {
      if(option == 0){
        setRows(newObj);
      } else {
        setVinculateds(newObj);
      }
    }


  }

  const dateFormated = (argumentDate) =>{
  moment.locale('en-US')
    let datef = moment(argumentDate).locale('en-US').format("dddd D MMMM, YYYY ");
    datef = datef[0].toUpperCase() + datef.slice(1);
    datef = datef.replace(".", "");
    return datef;


  }


  const quickSearch = async (value) => {
    let objx = gridApi;
    value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
    setGridApi(objx);
    let rowsDisplay = objx.api.rowRenderer.rowModel.rowsToDisplay;
    let result;
    if (rowsDisplay) {
      result = await rowsDisplay.map(row => {
        let { data } = row;
        return data;
      })
      setNewObj(result);
    }

  }
  const onGridReady = (params) => {
    setGridApi(params);
    setGridColumnApi(params);
  };
  const exporta = async () => {
    let { filter: value } = gridApi.api.filterManager.activeAdvancedFilters[0] ? gridApi.api.filterManager.activeAdvancedFilters[0].appliedModel : 'no';
    if (props.param) {
      await quickSearch(props.param);
      if (filterRating != null && filterRating === value) {
        let aux = [];
        await newObj.map(r => {
          if (r.rating) {
            if (r.rating == filterRating) {
              aux.push(r);
            }
          }
        })
        setNewObj(aux);
      } else {
        await quickSearch(props.param);
      }
    } else {
      if (filterRating != null && filterRating === value) {
        let aux = [];
        await rows.map(r => {
          if (r.rating) {
            if (r.rating == filterRating) {
              aux.push(r);
            }
          }
        })
        setNewObj(aux);
      } else {
        if (newObj.length > 0) {
          // console.log('newObj1',newObj);
        } else {
          setNewObj(rows);
        }
      }
    }
  }
  const ratingFilter = {
    textCustomComparator: (filter, value, filterText) => {
      const filterTextLowerCase = filterText.toLowerCase();
      const valueLowerCase = value.toString().toLowerCase();
      if (filterRating == null) {
        setFilterRating(filterTextLowerCase);
      }
      else {
        if (filterRating != filterTextLowerCase) {
          setFilterRating(filterTextLowerCase);
        }
      }
      switch (filter) {
        case 'contains':
          return valueLowerCase.indexOf(filterTextLowerCase) >= 0;
        case 'notContains':
          return valueLowerCase.indexOf(filterTextLowerCase) === -1;
        case 'equals':
          return valueLowerCase === filterTextLowerCase;
        case 'notEqual':
          return valueLowerCase != filterTextLowerCase;
        case 'startsWith':
          return valueLowerCase.indexOf(filterTextLowerCase) === 0;
        case 'endsWith':
          var index = valueLowerCase.lastIndexOf(filterTextLowerCase);
          return index >= 0 && index === (valueLowerCase.length - filterTextLowerCase.length);
        default:
          // should never happen
          console.warn('invalid filter type ' + filter);
          return false;
      }
    }
  };

 const modelUpdated = ( table ) => {

  const objModified = table.api.rowRenderer.rowModel.rowsToDisplay;
    const result =  objModified.map(row => {
      let { data } = row;
      return data;
    })

    setNewObj(result);


  // objx.api.rowRenderer.rowModel.rowsToDisplay;

    console.log(' Actualización ', result);
  }
  return (
    <>
    <div className="content">
      <div>
        <button
          style={{ float: 'right', textDecoration: 'white' }}
          className="mt-n5 btn btn-sm btn-info" onClick={(e) => exporta(e)}
        >
          <CSVLink {...csvReport}><span>Exportar</span>
          </CSVLink>
        </button>
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: '100%', width: '100%' }}
      >
        <AgGridReact
          tooltipShowDelay = { 0 }
          rowData={rows}
          context={{
            exporta,
            loadProspections
          }}
          rowHeight={40}
          cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
          domLayout="autoHeight"
          rowClassRules={{
            'colorGrayAG': function (params) {
              var backColor = params.data.color;
              return params.data.color === 0;
            },
            'colorWhiteAG': 'data.color === -1',
          }}
          onGridReady={onGridReady}
          onModelUpdated={ modelUpdated }
          suppressRowTransform={true}
          pagination={true}
          paginationPageSize={10}
          frameworkComponents={frameworkComponents}
          paginationNumberFormatter={function (params) {
            return params.value.toLocaleString();
          }}
          rowSelection="multiple"
          columnDefs={columnDefs}

        >


        </AgGridReact>
      </div>

      <TableRating rows={rows} />
    </div>
    <Vinculated />

    </>
  )
}


//

// SlotCollege

// Component SlotCreated
export const SlotCollege = function SlotCollege(props) {
  const { data } = props;
  moment.locale('es-mx')

  const showCollege = (dateBD) => {
    let datef = moment(dateBD).locale('es-mx').format("ddd D MMMM, YYYY ");
    datef = datef[0].toUpperCase() + datef.slice(1);
    datef = datef.replace(".", "");
    let tag = <span className="Inter">{datef}</span>
    return tag;
  }
  return (
    <>
    {data.otherSchool == "Otro" ?
    <span>
      {data.other_School}
    </span>
  :
   <span>
    {data.schoool}
   </span>
  }
    </>
  )
}
// Component SlotCreated
export const SlotDate = function SlotDate(props) {
  const { value } = props;
  moment.locale('es-mx')

  const showDate = (dateBD) => {
    let datef = moment(dateBD).locale('es-mx').format("ddd D MMMM, YYYY ");
    datef = datef[0].toUpperCase() + datef.slice(1);
    datef = datef.replace(".", "");
    let tag = <span className="Inter">{datef}</span>
    return tag;
  }
  return (
    <>
      <span  >{showDate(value)}</span>
    </>
  )
}


export const SlotOrigin = function SlotOrigin(props) {
  const { city, state } = props.data;
  return (
    <>
      <span>{city ? city + ', ' : ''}{state ? state : ''}</span>
    </>
  )
}
export const SlotName = function SlotName(props) {
  const dispatch = useDispatch();
  const { rowIndex, value } = props
  const click = (data) => {
    let newObj = { ...data, id: data.id_last_contact, fullname: data.name }
    // dispatch( activeContact(newObj) );
  }
  const array = props.agGridReact.props.rowData;
  const showName = (obj) => {
    let text = " ";
    const { data } = obj;
    // if(data.origin === true){
    //   text = value;
    // } else {
    //   text = " ";
    // }
    text = value;
    return text;
  }
  return (
    <>
      <Link onClick={(e) => click(props.data)} to={"contacts/" + (props.data.id) + "/Interdashboard"}>
        <span>{showName(props)}</span>
      </Link>
    </>
  )
}


export const SlotNumber = function SlotNumber(props) {
  const { rowIndex, value } = props
  const array = props.agGridReact.props.rowData;
  const getRow = () => {
    return rowIndex + 1;
  }
  return (
    <>
      <span>{getRow(rowIndex)}</span>
    </>
  )
}
export const SlotProgram = function SlotProgram(props) {
  const { value } = props;
  return (
    <b>{value} </b>
  )
}
export const SlotRating = function SlotRating(props) {
  const [rating, setRating] = useState(props.value ? parseInt(props.value) : 0);
  const changeRating = (e) => {
    let contact = props.data;
    contact.rating = e;
    contact.name = contact.last_contact;
    contact.id = contact.id_last_contact;
    swal({
      title: "Estas seguro?",
      text: "Usted modificara la calificacion de este contacto",
      icon: "info",
      dangerMode: true,
      buttons: ["No", "Si"],
    })
      .then((willDelete) => {
        if (willDelete) {
          axios.post(constaApi + 'contact/update', contact, {
            headers: {
              "Accept": "application/json"
            }
          }).then(function (response) {
            props.context.loadProspections();
            //    loadRating();
          }).catch(error => {
          });
        } else {
          swal("Operacion cancelada!");
        }
      });
  }
  return (
    <>
      <StarRatings
        rating={rating}
        starDimension={'16px'}
        starEmptyColor={'gray'}
        starRatedColor={'#ffd200'}
        starHoverColor={'#ffd200'}
        starSpacing="2px"
        changeRating={(e) => changeRating(e)}
        numberOfStars={5}
        name='rating'
      />
    </>
  )
}
