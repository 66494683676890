import React from 'react'
import TableColleges from './TableColleges.jsx';
import AddEditReminders from './AddEditReminders.jsx';

function TableReminderColleges(props) {
 function open(){

 }
    return (
    <div className="row">
    <div className="col-12">
      <div className="mt-5 col d-flex justify-content-end">
        <AddEditReminders openContacts={true} {...props} />
      </div>
      <h6>Colegios</h6>
      <TableColleges />
    </div>
  </div>


  )
}

export default TableReminderColleges