import React, { useState, useRef, useEffect } from 'react';
import {
    Switch, Redirect,
    Route, useLocation
} from 'react-router-dom';
import { constaApi } from '../constants/constants';
import ContactsView from '../pages/ContactsView';
import Bio from '../components/bioComponents/Bio';
import Profile from '../components/profileComponents/Profile';
import References from '../components/referencesComponent/References';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router";
import Docs from '../components/contactComponents/docsComponents/Docs';
import axios from 'axios';
import Nav from "../components/Nav/Nav";
import SideBar from '../components/SideBar/new/SideBar';
import routes from "../routes.js";
import Reminders from '../components/contactComponents/RemindersComponents/Reminders';
import NotificationAlert from "react-notification-alert";
import Prospection from '../components/contactComponents/Prospection/Prospection';
import Aplications from '../components/contactComponents/Aplications/Aplications';
import TrackingsContact from '../components/contactComponents/Trackings/TrackingsContact';
import { activeContact } from '../actions/contacts/contacts/contacts';
import InterDashboard from '../components/contactComponents/Dashboard/InterDashboard';
import Tickets from '../components/contactComponents/TicketsSection/Components/Tickets.jsx';
import Family from '../components/contactComponents/familyComponents/Family';
import Contacts from '../pages/Contacts';
import PermissionsHook from '../customHook/PermissionsHook/PermissionsHook';


function ContactsRouters(props) {

    const { pathname } = useLocation();

    const [loadingSpinner,setLoadinSpinner] = useState(true);
    const notificationAlert = useRef();
    let { id } = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [contact, setContact] = useState(null);
    // const {name} = useSelector(state => state.auth,shallowEqual);
    // const [condiitionalX,setC] =useState(false);
    const mainPanel = useRef();
    useEffect(() => {

        axios.get(constaApi + 'geTotalLeads', {
            headers: {
                "Accept": "application/json"
            }
        }).then(function (response) {
            const { data } = response;
            localStorage.setItem('countLeads', JSON.stringify(data));
        }).catch(error => {


        });
    })

    useEffect(() => {
        consultContact(id);
        if (id) {
            // dispatch(starLoadingRemindersC(id))
        }
    }, [dispatch])
   
    // methods
   
    async function consultContact() {
        setLoading(true);
        await axios.get(constaApi + 'contacts/' + id, {
            headers: {
                "Accept": "application/json"
            }
        }).then(function (response) {
            dispatch(activeContact(response.data[0]));
            setContact(response.data[0])
            setLoading(false);
        });
    }
    function update() {
        consultContact(id);
    }

    const fromSidebar = () => {
        mainPanel.current.classList.toggle('withSidebar');
    }

    const finishLoading = () =>{
        setLoadinSpinner(false);
      }
    return (
        <div className="wrapper">
            <SideBar
                {...props}
                routes={routes}
            fromSidebar={fromSidebar}
            />

            {
             loadingSpinner ?

             <PermissionsHook finishLoadin={finishLoading} module={'Contactos'} />

             :
            <div style={{ backgroundColor: '#f9fafb' }} className="main-panel" ref={mainPanel}>
                <Nav {...props} />
                <NotificationAlert ref={notificationAlert} />
                {loading === true ?
                    <div className="content">
                        <Spinner animation="border" variant="info" />
                    </div>
                    :
                    <>

                    {
                        pathname !== '/contacts' &&
                        <ContactsView
                            updateRoute={update}
                            contact={contact} />

                    }


                        <Nav {...props} />
                        <Switch>


                            <Route exact path="/contacts/:id/Interdashboard"
                                render={(props) => (
                                    <InterDashboard {...props}
                                        updateRoute={update}
                                        contact={contact}
                                    />
                                )}
                            />

                            <Route exact path="/contacts/:id/bio"
                                render={(props) => (
                                    <Bio {...props}
                                        updateRoute={update}
                                        contact={contact}
                                    />
                                )}
                            />
                            <Route exact path="/contacts/:id/profile"
                                render={(props) => (
                                    <Profile {...props}
                                        updateRoute={update}
                                        contact={contact}
                                    />
                                )}
                            />
                            <Route exact path="/contacts/:id/references"
                                render={(props) => (
                                    <References {...props}
                                        updateRoute={update}
                                        contact={contact}
                                    />
                                )}
                            />

                            <Route exact path="/contacts/:id/family"
                                render={(props) => (
                                    <Family {...props}
                                        updateRoute={update}
                                        contact={contact}
                                    />
                                )}
                            />
                            <Route exact path="/contacts/:id/docs"
                                render={(props) => (
                                    <Docs {...props}
                                        updateRoute={update}
                                        contact={contact}
                                    />
                                )}
                            />

                            <Route exact path="/contacts/:id/reminders"
                                render={(props) => (
                                    <Reminders {...props}
                                        updateRoute={update}
                                        contact={contact}
                                    />
                                )}
                            />

                            <Route exact path="/contacts/:id/tickets"
                                render={(props) => (
                                    <Tickets {...props}
                                        updateRoute={update}
                                        contact={contact}
                                    />
                                )}
                            />



                            <Route exact path="/contacts/:id/prospection"
                                render={(props) => (
                                    <Prospection {...props}
                                        updateRoute={update}
                                        contact={contact}
                                    />
                                )}
                            />
                            <Route exact path="/contacts/:id/applications"
                                render={(props) => (
                                    <Aplications {...props}
                                        updateRoute={update}
                                        contact={contact}
                                    />
                                )}
                            />

                            <Route exact path="/contacts/:id/trackings"
                                render={(props) => (
                                    <TrackingsContact {...props}
                                        updateRoute={update}
                                        contact={contact}
                                    />
                                )}
                            />
                            <Route exact path="/contacts"
                                render={(props) => (
                                    <Contacts {...props}
                                        updateRoute={update}
                                        contact={contact}
                                    />
                                )}
                            />



                            <Redirect to="/login" />
                        </Switch>
                    </>
                }
            </div>
}
        </div>
    )
}

export default ContactsRouters
