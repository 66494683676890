
import React, { useState,useRef, useEffect } from 'react'

import 'moment/locale/es'  // without this line it didn't work

import TableBio from './TableBio.jsx';
import AddEditBio from './AddEditBio.js';

function Bio(props) {
    const [show,setShow] = useState(false);
    const [row,setRow] = useState();
    const [allUsers,setAllU] = useState([]);
    const [blocked,setBlocked] = useState(false);
    useEffect(() => {
        if(props.prospection){
            setBlocked(true);
        }else if(props.applications){
            setBlocked(true);

        } else if(props.activeTracking){
            setBlocked(true);

        }
    },[])
    function method(obj){
        setRow(obj);
        setShow(!show);
    }
    function setFlag(){
        setShow(!show);
    }
    function getChild(obj){
        setAllU(obj);
    }
    return (
        <>
        <div className="mt-n2 content">
        {!blocked &&
        <AddEditBio getAllUsers={(e) =>getChild(e)} {...props} activeApplications={props.applications ?? null} activeProspect={props.activeProspect ?? null} noBar={ props.noBar ? true :false} setFlag={setFlag} row={row} flag={show}/>
        }
        <TableBio  allUsers={allUsers} {...props} extern={props.activeProspect ? true : props.activeApplication  ? true : false} bridge={(e) => method(e)}/>
        </div>
        </>
    )
}
export default Bio
