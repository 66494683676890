
import { types } from "../../types/types";


// }
const initialState = {
    biosC :[],
    active: null,
}
export const bioReducer = ( state = initialState,action ) => {
    switch (action.type) {
            case types.biosCActive:
                localStorage.setItem( 'biosCActive', JSON.stringify(action.payload));
                return {
                    ...state,
                    active: {
                        ...action.payload
                    }
                }
            case types.biosCLoad:
                return {
                    ...state,
                    biosC: [...action.payload]
                }
        default:
            return state;
    }

}