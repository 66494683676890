import React, { useState, useEffect,useRef } from "react";
import * as MDIcons from "react-icons/md";
import Select from "react-select";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import {
  SelectionState,
  GroupingState,
  IntegratedGrouping,
  IntegratedSorting,
  SortingState
} from '@devexpress/dx-react-grid';
import NotificationAlert from "react-notification-alert";

import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Modal,
    Row,
    Col,
    Form,
    InputGroup,
    Popover,
    OverlayTrigger,
    FormControl,
  } from "react-bootstrap";
  import { useForm } from "react-hook-form";
import { constaApi } from "../../constants/constants";
import axios from "axios";
import { starLoadingProspect } from "../../actions/contacts/bioContact/bioContact";
import * as FAIcons from "react-icons/fa";
import swal from "sweetalert";
import moment from 'moment'
import 'moment/locale/es'  // without this line it didn't work
import *  as Ioicons from "react-icons/io";
import * as IOIcons from "react-icons/io";
import Skeleton from 'react-loading-skeleton';
import { useParams } from "react-router";
var _ = require('lodash');

// Slots
export const SlotCollege = function SlotCollege(props){



  const {data} = props;
  const showSeconModal = (proposal) => {
    // props.context.openSecondModal(proposal);
  }
  const PopoverComponent = (proposals) => {
    const showProposals = (proposal) => {
      let aux = '';
      let auxTwo = null;
      if(proposals.length){
        var hash = {};
        auxTwo = proposals.filter(function(current) {
        var exists = !hash[current.name];
        hash[current.name] = true;
        return exists;
      });
        auxTwo.map(pro => {
          aux += pro.name + ',';
        })
      } else {
        for (let prop in proposal){
          aux += proposal[prop].name + ',';
        }
  
      }
      return aux;
    }
    return (<Popover id="popover-basic">
        <Popover.Content>
            <ul>
            <li>Colegio 1 - Status</li>
            <li>Colegio 1 - Status</li>
            <li>Colegio 1 - Status</li>
            <li>Colegio 1 - Status</li>
            <li>Colegio 1 - Status</li>
            <li>Colegio 1 - Status</li>
            </ul>
        </Popover.Content>
    </Popover>)
  }
    return (
      <>
      {data.proposal &&
       <OverlayTrigger trigger={["hover", "hover"]} placement="top"
       overlay={PopoverComponent(data.proposal)}>
       <a onClick={(e) => showSeconModal(data.proposal)}>
       <IOIcons.IoIosSchool />
       </a>
   </OverlayTrigger>
    }
      </>
      )
  }
  
export const SlotCountry = function SlotCountry(props){
  const {data} = props;
  const [rows,setRows] = useState([]);
  useEffect(() => {
    let auxTwo = null;
    var hash = {};
    auxTwo = data.proposal.filter(function(current) {
    var exists = !hash[current.country];
    hash[current.country] = true;
    return exists;
  });
  setRows(auxTwo);
  }, [])
  const PopoverComponent = (proposals,data) => {
  const showProposals = (proposal,data) => {
    let auxTwo = [];
    let auxFirst = '';
    let aux =  data.filter(d => d.country == proposal.country);
    aux.map(pro => {
      auxFirst += pro.name + ',';
    })
    return auxFirst;
  }
  // 5575207296
  return (<Popover id="popover-basic">
      <Popover.Content>
      <strong>{showProposals(proposals,data)}</strong>
      </Popover.Content>
  </Popover>)
}
  return (
    <>
    {data.proposal &&
     [rows.map(d => {
       return (
         <>
      <OverlayTrigger trigger={["hover", "hover"]} placement="top"
       overlay={PopoverComponent(d,data.proposal)}>
       <span>{d.country + ' '}</span>
       </OverlayTrigger>
       </>
       )
     })]
  }
    </>
    )
}

export const SlotDates = function SlotDates(props){
  const {data} = props;
  const showDate = (dateBD) => {
    let datef = moment(dateBD).locale('es-mx').format("ddd D MMMM, YYYY ");
    let timef = moment(dateBD).locale('es-mx').format("h:mm A");
    datef = datef[0].toUpperCase() + datef.slice(1);
    datef = datef.replace(".","");
    let tag = <span className="Inter">{datef}{timef} <Ioicons.IoMdTime /></span>
    return tag;
}
  return (
    <>
    <>
      {showDate(data.created_at)}
    </>
    </>  
    )
}
export const SlotActions = function SlotActions(props){
  const {data} = props;
  const deleteReminder = (obj) => {
    swal({
      title: "Estas seguro?",
      text: "Una vez eliminado,no podras recuperar este registro!",
      icon: "warning",
      dangerMode: true,
      buttons: ["No", "Si"],
    }).then(async (willDelete) => {
      if (willDelete) {
        await axios.get(constaApi + "removeProposal/"+obj.proposal[0].id)
        .then(function (response) {
            props.context.loadProposals(response.data.message,'success');
        }).catch(error => {
          props.context.loadProposals('Ocurrio un error','danger');
        });
      } else {
        swal("Operacion cancelada!");
      }
    });
  }
  return (
  <>
  <span>
  <FAIcons.FaTrashAlt title="Eliminar" style={{ color: '#DC3545' }} size={18} onClick={(e) => { deleteReminder(data) }} />
  </span>
  </>  
  )
}
export default function Proposals(props) {


  const { id:id_User, name:name_User } = JSON.parse(localStorage.getItem('user'));


  const [values, setValues] = useState([]);
  const [selectValue, setSelectValue] = useState();
  let { id } = useParams();
  const { id: IDX } = useSelector((state) => state.auth);

  const { loading } = useSelector(state => state.ui);
  const [emailOption,setEmailOption] = useState('1');
  const [nowTime, setNowTime] = useState();
  const [inCurse,setIncurse] = useState(false);
  const [theProposal,setTheProposal] = useState();
  const notificationAlert = useRef();
  const dispatch = useDispatch();
  const [columns] = useState([
    { name: 'name', title: 'Nombre' },
    { name: 'country', title: 'Pais' },
  ]);
  const [modalEmail,setModalOptionEmail] = useState(false);
  const [selection, setSelection] = useState([]);
  const [rowsTwo] = useState();
  const [{active:active},setActive] = useState(useSelector((state) => state.contacts));
  const {colleges} = useSelector( state => state.colleges);
  const [secondModal,setSecondModal] = useState(false);
  const [auxSelection,setAuxSelection] = useState([0]);
  const auth = useSelector(state => state.auth);
  const [records, setRecords] = useState();
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [frameworkComponents, setFramwrokw] = useState({slotActions:SlotActions,slotDates:SlotDates,slotCountry:SlotCountry,slotCollege:SlotCollege});
  const [modal,setModal] = useState(false);
  const [obj,setObj] = useState({})
  const {
    register: student,
    handleSubmit,
    errors,
    formState,
    reset: reset,
  } = useForm({});

  useEffect (() => {
    consult();
  },[])

  useEffect (() => {
    setRecords([]);
    loadProposals();
    setNowTime(moment().format("YYYY-MM-DD HH:mm"));
  },[props.activeProspect])

  useEffect(() => {
    setRecords([]);
    if(colleges && selection){
      convertSel(selection);
    }
  }, [selection])
  const loadProposals = async (message=null,type=null) => {
    if(message && type){
      notification(type,message);
    }
      let idx = active.id;
      let idxTwo = props.activeProspect.id ?? 0;
    await axios.get(constaApi + "getProposal/"+idx+"/separate/"+idxTwo ?? 1)
    .then(function (response) {
        setRecords(response.data);
    });
  }
  const handleChange = (e) => {
    setSelectValue(e);
  }
  const consult = async () => {



    let data = {
      id: id,
      idx: IDX,
    };
    await axios
      .post(constaApi + "defaultSelectBio", data)
      .then(function (response) {
        let { contact,colleges, final,finalTwo,contacts, references, user, users } = response.data;
        let result = user.map((col) => {
          return {
            value: col.email ?? null,
            secondaryParam: col.name + 'u',
            label:
              col.name + " " + (col.father_lastname ? col.father_lastname + " " : " ") + (col.mother_lastname ? col.mother_lastname : " "),
            fullname:
              col.name + " " + (col.father_lastname ? col.father_lastname + " " : " ") + (col.mother_lastname ? col.mother_lastname : " "),
            type: col.type,
          };
        });
        contact.map((u) => {
          result.push({
            value: u.email ?? null,
            secondaryParam: u.name + 'c',
            label: u.name + " "+ (u.father_lastname ? u.father_lastname + " " : " ") + (u.mother_lastname ? u.mother_lastname : " "),
            fullname:
              u.name + " " + (u.father_lastname ? u.father_lastname + " " : " ") + (u.mother_lastname ? u.mother_lastname : " "),
            type: "contactos",
          });
        });
        
        if (Object.keys(references).length != 0) {
          references.map((u) => {
            result.push({
              value: u.email ?? null,
              secondaryParam: u.name + 'r',
              label: u.name + " " + (u.father_lastname ? u.father_lastname + " " : " ") + (u.mother_lastname ? u.mother_lastname : " "),
              fullname:
                u.name + " "  + (u.father_lastname ? u.father_lastname + " " : " ") + (u.mother_lastname ? u.mother_lastname : " ") ,
              type: "referencias",
            });
          });
        } else {
          // nothing selection
        }
        users.forEach((us) => {
          if (us.name === result[0].value) {
          } else {
            result.push({
              value: us.email ?? null,
              secondaryParam: us.name + 's',
              label:
                us.name + " " + (us.father_lastname ? us.father_lastname + " " : " ") + (us.mother_lastname ? us.mother_lastname : " "),
              fullname:
                us.name + " " + (us.father_lastname ? us.father_lastname + " " : " ") + (us.mother_lastname ? us.mother_lastname : " "),
              type: us.type,
            });
          }
        });
        // final.map((c)=>{
        //   result.push({
        //     value: c.email ?? null,
        //     secondaryParam: c.name + 'f',
        //     label: c.name,
        //     fullname:
        //      c.name,
        //     type: "colegios",
        //   })
        // })
        // finalTwo.map((c)=>{
        //   result.push({
        //     value: c.email ?? null,
        //     secondaryParam: c.name + 't',
        //     label: c.name,
        //     fullname:
        //      c.name,
        //     type: "colegios",
        //   })
        // })
        let hash = {};
        result = result.filter(o => hash[o.secondaryParam] ? false : hash[o.secondaryParam] = true);
        setValues(result);
      });
  };
  const onSubmit = async(data)=> {
    setIncurse(true);
    let col = []
    if(selection){
      col = selection.map(se => {
        return  colleges[se];
      })
    }
    // let aux =  await colleges.filter(col => col.id == e.target.value);
    let aux2 =  {
         //created_at: nowTime,
         contact:active.name,
         id_contact:active.id,
         email: auth.email,
         name_user : auth.name,
         type_user : auth.type,
         id_user : auth.id,
         id_type : props.activeProspect ? props.activeProspect.id : 0,
         values: selectValue,
         emailOption: '2',
         otherOption : '2',
         id_responsable : id_User,
         name_responsable: name_User,
         
    };
     aux2 = {...aux2,selection:col,agruped : _.groupBy(col,"country")}
    // setObj({...aux2,selection:col});
      axios.post(constaApi + "saveProposal",aux2)
       .then(function (response) {
         loadProposals();
         if(props.activeProspect){
          dispatch( starLoadingProspect(active.id,props.activeProspect.id));
         }
         notification('success',response.data.message);
         props.updateReminders();
         closeModal();
         setSelection([]);
       }).catch(function( error) {
        notification('danger',error.response.data.message);
        closeModal();
        props.updateReminders();
       });
  }
 
  const onChangeSelection = (selection) => {
  }
  const onGridReady = (params) => {
    setGridApi(params);
    setColumnApi(params);
  };
  const closeTwoModal = () => {
    setSecondModal(false);
  }
  const closeModal = () => {
    setModal(!modal)
    setAuxSelection([]);
    setIncurse(false);
    setModalOptionEmail(false);
  }
  const updatedUpLevel = () => {
    props.updateReminders();
  }
  const convertSel = (seleccion) => {
    if(seleccion.length >= 21){


    } else{
      let col = []
      if(selection){
        col = selection.map(se => {
          return  colleges[se];
        })
      }
      setAuxSelection(col);
    }
  }
  const changeProposal = async (e) => {
    let aux =  await colleges.filter(col => col.id == e.target.value);
    let aux2 = {
        contact:active.name,
        id_contact:active.id,
        type:aux[0].type,
        name:aux[0].name,
        country:aux[0].country,
        email: auth.email,
        name_user : auth.name,
        type_user : auth.type,
        id_user : auth.id,
        id_type : props.activeProspect ? props.activeProspect.id : 0,
    };
  setObj({...aux2});
  }
  function notification(type, message) {
    let place = "tc";
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  }
  function openSecondModal(proposal){
    setTheProposal(proposal);
    setSecondModal(!secondModal);
  }
  return (
    <div className="content" >
              <div className="mt-n5 ml-n4 row">
              <div className="col-12">
      <NotificationAlert ref={notificationAlert} />
      {loading ?
                  <Skeleton width="60rem" height={30} count={10} />
      :
      <>             

        <div className="col d-flex justify-content-end">
             <button  
             disabled={props.blocked ? true:false}
             onClick={(e) => setModalOptionEmail(!modalEmail)} className="btn btn-info">
                <span className="Inter"
                    style={{ fontSize: "18px" }}>+</span> Propuesta</button>
        </div>
      <div
        className="ag-theme-alpine"
        style={{height: "100%", width: "100%" }}
      >
        <AgGridReact
           context={{
             loadProposals,
             openSecondModal,
           }}
          defaultColDef={{ resizable: true }}
          rowData={records}
          rowHeight={40}
          domLayout="autoHeight"
          onGridReady={onGridReady}
          // onFirstDataRendered={onFirstDataRendered}
          frameworkComponents={frameworkComponents}
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={function (params) {
            return params.value.toLocaleString();
          }}
          rowSelection="multiple"
        >
          <AgGridColumn
                      cellRenderer="slotDates"
                      headerName="Fecha" field="created_at" width="280" />
          <AgGridColumn
            headerName="Paises"
            field="country"
            width="230"
            cellRenderer="slotCountry"
          />
          {/* <AgGridColumn cellRenderer="slotCollege" headerName="Colegio" field="name" width="150" /> */}
          
           <AgGridColumn
            headerName="Acciones"
            width="230"
            cellRenderer="slotActions"
          />
        </AgGridReact>


          {/* Modal Email */}
          {/* onClick={(e) => setModal(!modal)} */}
          <Modal
        show={secondModal}
        dialogClassName="modalMax"
        onHide={closeTwoModal}
        dialogClassName="modal-60w"
      >
        <Modal.Header style={{ height: "60px" }} closeButton>
          <Modal.Title
            style={{
              fontFamily: "Inter",
              marginTop: "5px",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Detalle
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5F6", border: "0px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              Eu
            </Col>
          </Row>
            </form>
        </Modal.Body>
      </Modal>
      
        {/* Modal prospeccion */}
      <Modal
        show={modalEmail}
        dialogClassName="modalMax"
        onHide={(e) =>setModalOptionEmail(!modalEmail)}
        dialogClassName="modal-60w"
      >
        <Modal.Header style={{ height: "60px" }} closeButton>
          <Modal.Title
            style={{
              fontFamily: "Inter",
              marginTop: "5px",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Destinatarios
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5F6", border: "0px" }}>
          <>
        <Form >
        <Row>
                  <Col className="col-2">Participantes:</Col>
                  <Col className="col ml-4">
                    {values && (
                      <Select
                        isMulti
                        name="values"
                        value={selectValue}
                        onChange={(e) => handleChange(e)}
                        defaultValue={[values[0]]}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={values}
                      />
                    )}
                  </Col>
                </Row>
        </Form>
            </>
            <Row>
              <Col>
                <Button
                  className="float-right mb-3 mr-2"
                  type="submit"
                  disabled={inCurse ? true : false}
                  onClick={(e) => setModal(!modal)}
                  variant="info"
                >
                  Seleccionar
                </Button>
                <Button
                  onClick={(e) =>setModalOptionEmail(!modalEmail)}
                  className="float-right mb-3 mr-2 montse btnBee"
                  >
                  Cancelar
                </Button>
              </Col>
            </Row>
        </Modal.Body>
      </Modal>

        {/* Second Modal  */}
        <Modal
        show={secondModal}
        dialogClassName="modalMax"
        onHide={closeTwoModal}
        dialogClassName="modal-60w"
      >
        <Modal.Header style={{ height: "60px" }} closeButton>
          <Modal.Title
            style={{
              fontFamily: "Inter",
              marginTop: "5px",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Detalle
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5F6", border: "0px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              Eu
            </Col>
          </Row>
            </form>
        </Modal.Body>
      </Modal>
      
        {/* Modal prospeccion */}
      <Modal
        show={modal}
        dialogClassName="modalMax"
        onHide={closeModal}
        dialogClassName="modal-60w"
      >
        <Modal.Header style={{ height: "60px" }} closeButton>
          <Modal.Title
            style={{
              fontFamily: "Inter",
              marginTop: "5px",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Agregar Propuesta
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5F6", border: "0px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
         {auxSelection.length > 0 &&
          auxSelection.map(sel => {
           return(
           <button disabled className="btn btn-success btn-sm">{sel.name ?? " "}</button>
           );
          })}
          <>
          {loading ?
            <div className="row mt-2">
                       <NotificationAlert ref={notificationAlert} />
                <Skeleton  style={{backgroundColor:'#888C8D'}}width="10rem"  height={10} count={10} />
            </div>
            :
            <div style={{marginTop:'5%'}}>
            <Grid
              // style={{marginTop:'100px'}}
              rows={colleges}
              columns={columns}
            >
               <SortingState
                defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
              />
               <GroupingState
                grouping={[{ columnName: 'country' }]}
              />
              <SelectionState
                selection={selection}
                onSelectionChange={ selection.length >= 20 ?     
                notification('info','Solo se permiten 20 colegios')
                :setSelection }
              />
             
              <IntegratedSorting />
               <IntegratedGrouping />
              <Table />
              <TableHeaderRow />
              <TableSelection /> 
              <TableGroupRow />
            </Grid>
                {/* <Col className="col-7">
                  <Form.Label className="formGray">Colegios</Form.Label>
                  <Form.Control
                  onChange ={(e) => changeProposal(e)}
                    autoComplete="off"
                    name="program"
                    ref={student({
                      required: true,
                    })}
                    as="select"
                    size="sm"
                    custom
                  >
                    <option disabled value="" selected></option>
                     {colleges.map((col) => (
                      <option key={col.id} value={col.id}>
                        {col.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col> */}
            </div>
            }
            </>
            <Row>
              <Col>
                <Button
                  className="float-right mb-3 mr-2"
                  type="submit"
                  disabled={inCurse ? true : false}
                  onSubmit={handleSubmit(onSubmit)}
                  variant="info"
                >
                  Agregar
                </Button>
                <Button
                  onClick={closeModal}
                  className="float-right mb-3 mr-2 montse btnBee"
                  >
                  Cancelar
                </Button>
              </Col>
            </Row>
            </form>
        </Modal.Body>
      </Modal>

      </div>
      </>
      }
    </div>
    </div>
    </div>
  );
}
