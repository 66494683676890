import { types } from "../../types/types";

const initialState = {
    contacts:[],
    active: null,

};
export const contactsReducer = (state = initialState, action) =>{
    switch (action.type) {
        case types.ContactCActive:
            localStorage.setItem('ActiveContact', JSON.stringify(action.payload));
            return {
                ...state,
                active: {
                    ...action.payload
                }
            }
            case types.LoadContacts:
                return {
                    ...state,
                    contacts: [...action.payload]
                }
        default:
            return state;
    }

   

}