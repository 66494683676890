var articlesGeneral = [
  {
    name: "Que es blink",
    title: "Blink",
    id: "EC",
  },
  {
    name: "Como borrar caché",
    title: "Caché",
    id: "EA",
  },
  {
    name: "Actualizar indices",
    title: "Actualizar Indices",
    id: "ED",
  },
  {
    name: "Como guardar un contacto",
    title: "Guardar un contacto",
    id: "EP",
  },
  {
    name: "Como seleccionar un colegio",
    title: "Seleccionar un colegio",
    id: "EP",
  },
];
var articlesProspections = [
  {
    name: "Estatus Cancelar",
    title: "Cancelar",
    text:
      <div className="backText" style={{ fontSize: "24px" }}>

        <p>
          Al momento de que una familia aplica,la prospección se termina y toda la información <br></br>se pasa al modulo de aplicaciónes.
        </p>
        <p>
          Si alguien cancela,es posible volver a ese contacto y activarlo nuevamente en caso de que cambie de opinion.
        </p>
      </div>,
    id: "ECA",
  },
  {
    name: "Estatus Contacto formal",
    title: "Contacto Formal",
    text:
      <div className="backText" style={{ fontSize: "24px" }}>
        <p>En este estatus esta toda la gente a la cual ya se le programo una llamada/video <br></br>
          conferencia/cita, el contacto permanece en este status hasta que se envie el correo con <br></br>
          las opciones.</p>
      </div>,

    id: "ECF",
  },
  {
    name: "Estatus Presentación",
    title: "Presentación",
    text:
      <div className="backText" style={{ fontSize: "28px" }}>
        <p>
          Este status es de transición, puede resultar un poco confuso, pero este status esta
          diseñado para tener a todos los contactos a los cuales les hemos mandado un email
          con opciones y NO nos confirman todavía que les ha llegado la información. Algunas
          veces es muy rápido y podemos confirmar en minutos, en algunas otras ocasiones, no
          nos contestan y por eso tenemos que asegurar que efectivamente tienen la
          información.
        </p>
      </div>,
    id: "EP",
  },
  {
    name: "Estatus Decisión",
    title: "Decisión",
    text:
      <div className="backText" style={{ fontSize: "28px" }}>
        <p>
          La gente que esta aquí es gente que ya no tiene mas dudas, que hemos hablado
          muchas veces con ellos y que lo único que estamos esperando es que tomen una
          decisión, algunas veces necesitaran una llamada adicional (o mas) pero realmente
          estan en el punto en donde ya no queda mas que esperar. De aquí las 3 opciones
          posibles que pueden darse son:
          <br>
          </br>
          <ul>
            <li>
            Cancelar: si cambian de opinión o se van por otro medio

            </li>
            <li>
            Aplicar: con lo que ya se termina el proceso y lo pasamos a aplicaciones

            </li>
            <li style={{textAlign:'left'}}>
            Prospección futura: en donde las familias nos comentan que quisieran esperar a
            &ensp; &ensp; &ensp; &ensp; &ensp; &ensp;mandar a su hijo despues, para lo cual, se cancela esta prospección y se inicia
            una nueva  &ensp; &ensp;con el año correspondiente.
            </li>
          </ul>
       
        </p>
      </div>,
    id: "ED",
  },



  {
    name: "Estatus Aclaración de dudas",
    title: "Aclaración de dudas",
    text:
    <div className="backText" style={{ fontSize: "28px" }}>
    <p>
      Aquí esta toda la gente que ya recibió un correo de nosotros con opciones y estamos
      esperando a ver que dudas pudieran tener. Mucho de este proceso es ver si necesitan
      otra llamada/video conferencia, también podrían ser aquellos clientes que en “Contacto
      Previo” nos pidieron que les mandáramos opciones primero y luego ya despues
      coordinar una llamada formal, esa llamada formal sería aquí (puesto que ya se les
      mando información). Este status podría ser de los mas largos ya que mucha gente
      puede seguir diciéndonos que siguen revisando la información, el objetivo con esa
      gente sería motivarlos a tener una llamada y que así podamos ayudarlos a tomar una
      decisión.
      <br></br>
      <br></br>
      De estas llamadas, es común que ya nos pasemos a aplicación (que es el ultimo paso).
      Pero también es posible que familias necesiten mas llamadas para poder tomar la
      decisión, al cliente lo pasamos a decisión una vez que ya no hay dudas y literalmente
      nos comenta que “van a evaluar las opciones para ver por cual se van”.
      </p>
    </div>
    ,
    id: "EAD",
  },
  {
    name: "Estatus Aplicar",
    title: "Aplicar",
    text:
    <div className="backText" style={{ fontSize: "24px" }}>

      <p>
        Al momento de que una familia aplica,la prospección se termina y toda la información <br></br>se pasa al modulo de aplicaciónes.
      </p>
      <p>
        Si alguien cancela,es posible volver a ese contacto y activarlo nuevamente en caso de que cambie de opinion.
      </p>
    </div>,
    id: "EA",
  }
];

var articlesApplications = [
  {
    name: "Estatus Cancelado",
    title: "En construcción",
    id: "ECA",
  },
  {
    name: "Estatus Aplicación",
    title: "En construcción",
    id: "EA",
  },
  {
    name: "Estatus Activo",
    title: "En construcción",
    id: "EAC",
  },
  {
    name: "Estatus Visado",
    title: "En construcción",
    id: "EV",
  },

  {
    name: "Estatus Admisión",
    title: "En construcción",
    id: "EV",
  },
  {
    name: "Estatus Ultimos detalles",
    title: "En construcción",
    id: "EV",
  }

];

var articlesTracking = [
  {
    name: "Estatus Materias",
    title: "En construcción",
    id: "ECA",
  },
  {
    name: "Estatus Diciembre",
    title: "En construcción",
    id: "EA",
  },
  {
    name: "Estatus Enero",
    title: "En construcción",
    id: "EAC",
  },
  {
    name: "Estatus Revalidación",
    title: "En construcción",
    id: "EV",
  },

  {
    name: "Estatus Fin de curso",
    title: "En construcción",
    id: "EV",
  },
  {
    name: "Estatus Termino",
    title: "En construcción",
    id: "EV",
  }


];

var articlesInConstruction = [
  {
    name: "En construcción",
    title: "En construcción",
    id: "EV",
  },
  {
    name: "En construcción",
    title: "En construcción",
    id: "EV",
  },
  {
    name: "En construcción",
    title: "En construcción",
    id: "EV",
  }
];
export {
  articlesGeneral,
  articlesProspections,
  articlesApplications,
  articlesTracking,
  articlesInConstruction,
};
