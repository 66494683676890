import React, { useState } from 'react'
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom';

export default function AccountingView(props) {
  const { pathname } = useLocation();

  return (
<>
    <div style={{ float: 'left', marginRight: '10px' }} >
              <span className="Inter400" style={{}}>Contabilidad </span>
            </div>

    <div style={{ minHeight: '0px' }} className="pb-0 content">
    <div style={{ marginTop: '-20px' }} className=" mt-3sc-bdVaJa styles__Nav-sc-19n49a3-0 gOZeoI">
    <Link className={['/accounting'].includes(pathname) ?
        'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active Inter600' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl Inter600'}
        to={"/accounting"}>General</Link>

      <Link className={['/accounting/prospection'].includes(pathname) ?
        'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active Inter600' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl Inter600'}
        to={"/accounting/prospection"}>Prospeccion</Link>

      <Link className={['/accounting/application'].includes(pathname) ?
        'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active Inter600' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl Inter600'}
        to={"/accounting/application"}>Aplicación</Link>

      <Link className={['/accounting/tracking'].includes(pathname) ?
        'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active Inter600' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl Inter600'}
        to={"/accounting/tracking"}>Seguimiento</Link>

<Link className={['/accounting/paid'].includes(pathname) ?
        'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active Inter600' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl Inter600'}
        to={"/accounting/paid"}>Pagos</Link>

    </div>
  </div>
  </>
  )
}
