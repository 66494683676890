import React, { useState, useEffect, useCallback } from 'react';
import { Link } from "react-router-dom";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import axios from 'axios';
import { constaApi } from '../../constants/constants';
import swal from 'sweetalert';
import { useDispatch } from 'react-redux';
import { CSVLink } from "react-csv";
import TableTotals from './TableTotals';
import TableSubstatus from './TableSubstatus.jsx';
import StarRatings from 'react-star-ratings';

var _ = require('lodash');

export default function TableApplication(props) {
  // Execute when the props change
  useEffect(() => {
    if (props.param) {
      quickSearch(props.param);
    }
  },[props])



  // Execute when you open this section
  useEffect(() => {
   loadApplications();
  }, [])

  const loadApplications = () => {
    axios.post(constaApi + 'applicationSection')
    .then(function (response) {
      checkValues(response.data);
      const result = _.groupBy(response.data,"name")
      setAuxRow(result);
    }).catch(error => {
    });
  }
  const [ratingTotal, setRatingTotal] = useState([]);
  const [frameworkComponents, setFramwrokw] = useState({slotRating: SlotRating ,slotName: SlotName, slotProspection: SlotProspection,slotOrigin:SlotOrigin});
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rows, setRows] = useState(null);
  const [auxRow,setAuxRow] = useState([]);
  const [newObj,setNewObj] = useState([]);
  const [filterRating,setFilterRating] = useState(null);
  const [rowsRating,setRowsRating] = useState([]);



  useEffect(() => {
    // objx.api.rowRenderer.rowModel.rowsToDisplay;
  },[gridApi?.api?.rowRenderer?.rowModel?.rowsToDisplay])



  useEffect(() => {
      let totalRatings = [];
      let finalCo = { program: 'TOTAL', adm: 0, tra: 0, esp: 0, act: 0,apl:0,vis:0,ace:0,tC:0};
      const ratinGroup = _.groupBy(rows, "name_prospection")
      Object.keys(ratinGroup).map((name, i) => {
        let objRating = { program: name, adm: 0, tra: 0, esp: 0, act: 0, apl:0,vis:0,ace:0,tC:0};
        ratinGroup[name].map(rG => {
          switch (rG.APStatus) {
            case 'Admisión':
              objRating.adm = objRating.adm + 1;
              break;
            case 'Trámites de viaje':
              objRating.tra = objRating.tra + 1;
              break;
            case 'Ultimos detalles':
              objRating.esp = objRating.esp + 1;
              break;
            case 'Activo':
              objRating.act = objRating.act + 1;
              break;
            case 'Aplicación':
              objRating.apl = objRating.apl + 1;
              break;
            case 'Visado':
              objRating.vis = objRating.vis + 1;
              break;
            case 'Aceptación':
              objRating.ace = objRating.ace + 1;
          }
        })
        objRating.tC = objRating.adm + objRating.tra + objRating.esp + objRating.act + objRating.apl + objRating.vis + objRating.ace;
        finalCo.adm = finalCo.adm + objRating.adm;
        finalCo.tra = finalCo.tra + objRating.tra;
        finalCo.esp = finalCo.esp + objRating.esp;
        finalCo.act = finalCo.act + objRating.act;
        finalCo.apl = finalCo.apl + objRating.apl;
        finalCo.vis = finalCo.vis + objRating.vis;
        finalCo.ace = finalCo.ace + objRating.ace;
        finalCo.tC = finalCo.adm + finalCo.tra + finalCo.esp + finalCo.act + finalCo.apl + finalCo.vis + finalCo.ace;
        totalRatings.push(objRating);
  });
  totalRatings.push(finalCo);
  setRatingTotal(totalRatings);
  setRowsRating(totalRatings);
  },[rows])

  const headers = [
    { label: "Nombre", key: "nameContact" },
    { label: "Rating", key: "rating"},
    { label: "Prospeccion", key: "name_prospection" },
    { label: "Colegio", key: "colName" },
    { label: "Pais", key: "colCountry" },
    { label: "Ciudad", key: "city" },
    { label: "Colegio Local", key: "colLocalName" },
    { label: "Status", key: "APStatus" },
    { label: "Substatus", key: "substatusFormated" },
    { label: "Sexo", key: "sex" },
    { label: "Advisor", key: "name_advisor" },
  ];
  const csvReport =  {
    data: newObj,
    headers: headers,
    filename: 'Applicationsreport.csv'
  };
  const [colors] = useState([
    'red', 'blue', 'green', 'gray', 'white'
  ]);
  const checkValues = (obj) => {
    let color = 0;
    let newObj = obj.map((o,index) => {
      if(index > 0){
        if(obj[index - 1 ].name == o.name){
          return {...o,
            otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            otherCity: o.city ? o.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            otherState: o.state ? o.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            otherStatus: o.APStatus ? o.APStatus.normalize("NFD").replace(/[\u0300-\u036f]/g, "") :" ",
            substatusFormated : o.substatus ? 'Substatus ' + o.substatus : "",
            localCollege: o.schoool == "Otro" ? o.other_School : o.schoool,
            repeat:true,color:color}
        } else {
          color = color == 0 ? -1 : 0;
          return {...o,
            otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            otherCity: o.city ? o.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            otherState: o.state ? o.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            otherStatus: o.APStatus ? o.APStatus.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : " ",
            substatusFormated : o.substatus ? 'Substatus ' + o.substatus : "",
            localCollege: o.schoool == "Otro" ? o.other_School : o.schoool,
            origin:true,color:color}
        }
      } else {
        return {...o,
          otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          otherCity: o.city ? o.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
          otherState: o.state ? o.state.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
          otherStatus: o.APStatus ? o.APStatus.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : " ",
          substatusFormated : o.substatus ? 'Substatus ' + o.substatus : "",
          localCollege: o.schoool == "Otro" ? o.other_School : o.schoool,
          origin:true,color:color};
      }
    })
    // ----------------
     newObj = newObj.map((o,index) => {

        if(index > 0){
          if(newObj[index - 1 ].nameContact == o.nameContact && newObj[index - 1 ].name_prospection == o.name_prospection){
            return {...o,
              otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
              prospectionRepeat:true}
          } else {
            color = color == 2 ? 3 : 2;
            return {...o,
              otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
              prospectionOrigin:true}
          }
        } else {
          return {...o,
            otherName: o.nameContact.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            prospectionOrigin:true};
        }
      })

      //   --------------------
    if(newObj){
      setRows(newObj);
      let hash = {};
      let array = [];
array = newObj.filter(o => hash[o.id_last_contact] ? false : hash[o.id_last_contact] = true);
props.updateCount(array);
    }

  }
  const quickSearch = async (value) => {
    let objx = gridApi;
    value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
    setGridApi(objx);
    let rowsDisplay = objx.api.rowRenderer.rowModel.rowsToDisplay;
    let result ;
    if(rowsDisplay){
      result = await rowsDisplay.map(row => {
       let {data} = row;
       return data;
     })
     setNewObj(result);
    }
  }
  const onGridReady = (params) => {
    setGridApi(params);
    setGridColumnApi(params);
  };
  const exporta = async () => {
    let {filter:value} = gridApi.api.filterManager.activeAdvancedFilters[0] ? gridApi.api.filterManager.activeAdvancedFilters[0].appliedModel : 'no';
    if(props.param){
      await quickSearch(props.param);
      if(filterRating != null && filterRating === value){
        let aux = [];
        await newObj.map(r =>{
          if(r.rating){
            if(r.rating == filterRating){
              aux.push(r);
            }
          }
        })
        setNewObj(aux);
      } else {
        await quickSearch(props.param);
      }
    } else {
      if(filterRating != null && filterRating === value){
        let aux = [];
        await rows.map(r =>{
          if(r.rating){
            if(r.rating == filterRating){
              aux.push(r);
            }
          }
        })
        setNewObj(aux);
      } else {
        if(newObj.length > 0){

        } else {
          setNewObj(rows);
        }
      }
    }

    // let name = Array.prototype.slice.call(document.querySelectorAll("div[col-id=name"));
    // let prospection = Array.prototype.slice.call(document.querySelectorAll("div[col-id=name_prospection"));
    // let college = Array.prototype.slice.call(document.querySelectorAll("div[col-id=schoool"));
    // let grade = Array.prototype.slice.call(document.querySelectorAll("div[col-id=grade"));
    // let city = Array.prototype.slice.call(document.querySelectorAll("div[col-id=city"));
    // let state = Array.prototype.slice.call(document.querySelectorAll("div[col-id=state"));
    // let status = Array.prototype.slice.call(document.querySelectorAll("div[col-id=status"));
    // let newObj = name.map((n,index)=>{
    //   if(index > 0){
    //     return {
    //       name:n.innerText,
    //       prospection : prospection[index] ? prospection[index].innerText : "",
    //       college: college[index] ? college[index].innerText : "",
    //       grade: grade[index] ? grade[index].innerText : "",
    //       city: city[index] ? city[index].innerText : "",
    //       state: state[index] ? state[index].innerText : "",
    //       status: status[index] ? status[index].innerText : "",
    //     }
    //   }
    // });
    // let otherArray = [];
    // newObj.map(n => {
    //   if (n){
    //     otherArray.push(n);
    //   }
    // })
    // setNewObj(otherArray);
  }
  const ratingFilter = {
    textCustomComparator: (filter, value, filterText) => {
        const filterTextLowerCase = filterText.toLowerCase();
        const valueLowerCase = value.toString().toLowerCase();
        if(filterRating == null){
          setFilterRating(filterTextLowerCase);
        }
        else {
          if(filterRating != filterTextLowerCase){
            setFilterRating(filterTextLowerCase);
          }
        }
        switch (filter) {
            case 'contains':
                return valueLowerCase.indexOf(filterTextLowerCase) >= 0;
            case 'notContains':
                return valueLowerCase.indexOf(filterTextLowerCase) === -1;
            case 'equals':
                return valueLowerCase === filterTextLowerCase;
            case 'notEqual':
                return valueLowerCase != filterTextLowerCase;
            case 'startsWith':
                return valueLowerCase.indexOf(filterTextLowerCase) === 0;
            case 'endsWith':
                var index = valueLowerCase.lastIndexOf(filterTextLowerCase);
                return index >= 0 && index === (valueLowerCase.length - filterTextLowerCase.length);
            default:
                // should never happen
                console.warn('invalid filter type ' + filter);
                return false;
        }
    }
};

const onCellValueChanged = () => {

  let objx = gridApi;
  let rowsDisplay = objx?.api?.rowRenderer?.rowModel?.rowsToDisplay;
  let result ;
  if(rowsDisplay){
    result =  rowsDisplay.map(row => {
     let {data} = row;
     return data;
   })

   setNewObj(result);
  }
}



  return (
    <div className="content">
      <div>
      <button
      style={{float:'right',textDecoration:'white'}}
      className="mt-n5 btn btn-sm btn-info" onClick={(e) => exporta(e)}
      >
        <CSVLink {...csvReport}><span>Exportar</span>
        </CSVLink>
        </button>
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: '100%', width: '100%' }}
      >
         <AgGridReact
           rowData={rows}
           rowHeight={40}
           cellStyle={{ fontFamily:'Montserrat,sans-serif',fontSize:'13px',fontWeight:'500', color:'#3B3B3B'}}
           domLayout="autoHeight"
           context={{
            loadApplications
           }}
           rowClassRules={{
            'colorGrayAG': function (params) {
              var backColor = params.data.color;
              return params.data.color === 0 ;
            },
            'colorWhiteAG': 'data.color === -1',

          }}
          onModelUpdated={onCellValueChanged}
          onCellValueChanged={onCellValueChanged}
           onGridReady={onGridReady}
           suppressRowTransform={true}
           pagination={true}
           paginationPageSize={10}
           frameworkComponents={frameworkComponents}
           paginationNumberFormatter={function (params) {
               return params.value.toLocaleString();
           }}
           rowSelection="multiple"
        >
          <AgGridColumn
            field="nameContact"
            headerName="Nombre"
            cellRenderer="slotName"
            wrapText={true}
            filter="agTextColumnFilter"
            width={300}
            sortable={true}
          />
           <AgGridColumn
            field="otherName"
            headerName="Nombre"
            cellRenderer="slotName"
            wrapText={true}
            filter="agTextColumnFilter"
            width={300}
            sortable={true}
            hide={true}
          />
          <AgGridColumn
            field="rating"
            headerName="Rating"
            cellRenderer="slotRating"
            wrapText={true}
            sortable={true}
            filter="agTextColumnFilter"
            filterParams={ratingFilter}
            width={200}
          />
          <AgGridColumn
          headerName="Prospeccion"
          field="name_prospection" width={250}
          wrapText={true}
          sortable={true}
          filter="agTextColumnFilter"
          cellRenderer="slotProspection"
          />
           <AgGridColumn
          headerName="Colegio"
          filter="agTextColumnFilter"
          sortable={true}
          field="colName" width={250} />
           <AgGridColumn
          headerName="Pais"
          sortable={true}
          filter="agTextColumnFilter"
          field="colCountry" width={250} />
            <AgGridColumn
                            cellStyle={{ fontFamily:'Montserrat,sans-serif',fontSize:'13px',fontWeight:'500', color:'#3B3B3B'}}
                            filter="agTextColumnFilter"
                            headerName="Ciudad" sortable={true} field="otherCity" width="200" cellRenderer="slotOrigin" />
                              <AgGridColumn
                            cellStyle={{ fontFamily:'Montserrat,sans-serif',fontSize:'13px',fontWeight:'500', color:'#3B3B3B'}}
                            headerName="Ciudad" hide={true} sortable={true} field="otherCity" width="200"  />
                              <AgGridColumn

cellStyle={{ fontFamily:'Montserrat,sans-serif',fontSize:'13px',fontWeight:'500', color:'#3B3B3B'}}
                            headerName="Colegio Local" sortable={true} field="colLocalName" width="300"  />






                              <AgGridColumn
                            cellStyle={{ fontFamily:'Montserrat,sans-serif',fontSize:'13px',fontWeight:'500', color:'#3B3B3B'}}
                            headerName="Estado"  hide={true}sortable={true} field="otherState" width="200"  />


           <AgGridColumn
          headerName="Status"
          filter="agTextColumnFilter"
          sortable={true}
          field="APStatus" width={250} />
             <AgGridColumn
          headerName="Status"
          filter="agTextColumnFilter"
          sortable={true}
          field="otherStatus"  hide={true} width={150} />
          <AgGridColumn
          headerName="Substatus"
          filter="agTextColumnFilter"
          sortable={true}
          field="substatusFormated" width={250} />
           <AgGridColumn
          headerName="Advisor"
          filter="agTextColumnFilter"
          sortable={true}
          field="name_advisor" width={250} />

<AgGridColumn
          headerName="Sexo"
          sortable={true}
          filter="agTextColumnFilter"
          field="sex" width={250} />


            {/* <span style={{textTransform:'none'}}>Nombre</span>
                        <span style={{textTransform:'none', paddingLeft:'18%'}}>Prospeccion</span>
                        <span style={{textTransform:'none',paddingLeft:'8%'}}>Colegio</span>
                        <span style={{textTransform:'none',paddingLeft:'8%'}}>Pais</span>
                        <span style={{textTransform:'none',paddingLeft:'12%'}}>Status</span>
                         </th>
        */}
        </AgGridReact>
      </div>

      <TableTotals rowsRating={rowsRating} />
      <div className='mt-5'>
        <h3>Substatus</h3>
      <TableSubstatus rows={rows} />
      </div>

    </div>
  )
}

export const SlotOrigin = function SlotOrigin(props) {
  const { city, state } = props.data;
  return (
      <>
          <span>{city ? city != 'NULL' ? city + ', ' : ', ': ''}{state ? state != 'NULL' ?  state  : ' ': ''}</span>
      </>
  )
}
export const SlotName = function SlotName(props) {
 const dispatch = useDispatch();
 const {rowIndex,value} = props;
 const array = props.agGridReact.props.rowData;
 const click = (data) => {
  let newObj = {...data,id:data.id_last_contact,fullname:data.nameContact}
  // dispatch( activeContact(newObj) );
 }
  const showName = (obj) => {
    let text = " ";
    const {data} = obj;

    text = value;

    return text;
  }
  return (
      <>
          <Link  onClick={(e) =>click(props.data)} to={"contacts/" + (props.data.id_last_contact) + "/Interdashboard"}>
          <span>{showName(props)}</span>
          </Link>
      </>
  )
}
export const SlotProspection = function SlotProspection(props) {
    const {rowIndex,value} = props
    const array = props.agGridReact.props.rowData;
     const showName = (obj) => {
       const {data} = obj;
       return value;
     }
     return (
         <>
             <span>{showName(props)}</span>
         </>
     )
}

export const SlotRating = function SlotRating(props) {
  const [rating,setRating] = useState(props.value ? parseInt(props.value) : 0);
  const changeRating = (e) => {
      let contact = props.data;
      contact.id = contact.id_last_contact;
      contact.rating = e;
      swal({
          title: "Estas seguro?",
          text: "Usted modificara la calificacion de este contacto",
          icon: "info",
          dangerMode: true,
          buttons: ["No","Si"],
        })
        .then((willDelete) => {
          if (willDelete) {
               axios.post(constaApi+'contact/update',contact, {
                  headers: {
                      "Accept": "application/json"
                  }
              }).then(function (response) {
                  props.context.loadApplications();
              //    loadRating();
              }).catch(error =>{
              });
          } else {
            swal("Operacion cancelada!");
          }
        });
  }
  return (
  <>
   <StarRatings
    rating={rating}
    starDimension={'16px'}
    starEmptyColor={'gray'}
    starRatedColor={'#ffd200'}
    starHoverColor={'#ffd200'}
    starSpacing="2px"
    changeRating={(e) => changeRating(e)}
   numberOfStars={5}
    name='rating'
                  />
  </>
  )
}
