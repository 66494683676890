import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Form, InputGroup } from 'react-bootstrap';
import * as FIIcons from "react-icons/fi";
import { useParams, } from "react-router";
import axios from 'axios';
import { constaApi } from '../../../constants/constants';
import { useForm } from "react-hook-form";

export default function Resume(props) {

    let { id } = useParams();
    const [selectFile,setSelectFile] = useState();
    const [selectFileFolleto,setSelectFileFolleto] = useState();
    const { handleSubmit } = useForm({});
    const [files,setFiles] = useState([]);
    const [editing, setEditing] = useState(false);
    const [resumeData, setResumeData] = useState({
        address: "",
        alumnos: "",
        instalaciones :"",
        programa_academic: "",
        deportes: "",
        arte: "",
        link: "",
        costo: "",
        folleto:""
    });
    
    useEffect(() =>{
        loadInfo(id);
    },[])
    // Metodos

    function loadInfo(id){
         axios.get(constaApi + 'college/show/'+id, { 
        }).then(function (response) {
            const files = response.data.files;
           
            const {address,alumnos,instalaciones,programa_academic,deportes,arte,link,costo:costo,folleto} = response.data.college;
            setResumeData({
                ...resumeData,
                address:address,
                alumnos:alumnos,
                instalaciones:instalaciones,
                programa_academic:programa_academic,
                deportes:deportes,
                arte:arte,
                link:link,
                costo:costo,
                folleto:folleto
            });
            if(files){
                let newArray = [];
                files.map(fil => {
                    newArray.push({
                        name:fil.name_doc,
                        label:fil.name_doc,
                        value:fil.name_doc,
                        path:fil.path_doc
                    })
                })
                setFiles(newArray);
              
                if(costo){

                    setSelectFile(costo);
                //    const {path,label,name,value} =  newArray.find(fil => fil.path == costo);
                //      setSelectFile(
                //         {
                //         label: label,
                //         name : name,
                //         value: value,
                //         path: path
                //         }
                //         )
                }
                if(folleto){
                    const {path,label,name,value} =  newArray.find(fil => fil.path == folleto);

                    setSelectFileFolleto(
                        {
                            label: label,
                            name : name,
                            value: value,
                            path: path
                            }
                    )
                }
            }
        });
    }

    function handleChangefolleto(e){
        setSelectFileFolleto(e);
        setResumeData({...resumeData,folleto: e.path});
    }
    function handleChange(e){
        
        setSelectFile(e.target.value);
        setResumeData({...resumeData,costo: e.target.value});
    }
    function changeAddress(e) {
        setResumeData({ ...resumeData, address: e.target.value })
    }
    function changeInstalation(e){
        setResumeData({ ...resumeData, instalaciones: e.target.value })
    }
    function changeStudents(e) {
        setResumeData({ ...resumeData, alumnos: e.target.value })
    }
    function changeProgram(e) {
        setResumeData({ ...resumeData, programa_academic: e.target.value })
    }
    function changeDeport(e) {
        setResumeData({ ...resumeData, deportes: e.target.value })
    }
    function changeArte(e) {
        setResumeData({ ...resumeData, arte: e.target.value })
    }
    function changeLink(e) {
        setResumeData({ ...resumeData, link: e.target.value })
    }
    function onSubmit(){
        let resume = {
            ...resumeData,
            id:id
        };
        axios.post(constaApi + 'colleges/update',resume, { 
        }).then(function (response) {
            setEditing(!editing);
        });
    }
    return (
        <div className="content">
            <div className="mt-n5 row">
                <div className="col-12">
                    {editing ?
                        <div className="card">
                            <div className=" d-flex justify-content-end">
                                <button onClick={(e) => setEditing(!editing)} type="button" className="montse btn btnBeewhite">Cancelar</button>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                <button
                                     onSubmit={handleSubmit(onSubmit)}
                                    type="submit" className="montse ml-1 btn btn-info">Guardar</button>
                                </form>
                            </div>
                            <div className="col-4">
                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Ubicacion</Form.Label>
                            </div>
                            <div className="col">
                                <Form.Control autoComplete="off"
                                    onChange={(e) => changeAddress(e)} value={resumeData.address}
                                    name="name"
                                    style={{ letterSpacing: '0.1px' }}
                                    className="formGray montse" type="text" placeholder="Ingrese su ubicacion" />
                            </div>

                            <div className="col-4 mt-3">
                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Alumnos</Form.Label>
                            </div>
                            <div className="col">
                                <Form.Control autoComplete="off"
                                    onChange={(e) => changeStudents(e)} value={resumeData.alumnos}
                                    name="name"
                                    style={{ letterSpacing: '0.1px' }}
                                    className="formGray montse" type="text" placeholder="Ingrese los alumnos" />
                            </div>

                            
                            <div className="col-4 mt-3">
                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Instalaciones</Form.Label>
                            </div>
                            <div className="col">
                                <Form.Control autoComplete="off"
                                    onChange={(e) => changeInstalation(e)} value={resumeData.instalaciones}
                                    name="name"
                                    style={{ letterSpacing: '0.1px' }}
                                    className="formGray montse" type="text" placeholder="Instalaciones..." />
                            </div>


                            <div className="col-4 mt-3">
                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Programa Academico</Form.Label>
                            </div>
                            <div className="col">
                                <Form.Control autoComplete="off"
                                    onChange={(e) => changeProgram(e)} value={resumeData.programa_academic}
                                    name="name"
                                    style={{ letterSpacing: '0.1px' }}
                                    className="formGray montse" type="text" placeholder="Ingrese el programa" />
                            </div>

                            <div className="col-4 mt-3">
                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Deportes</Form.Label>
                            </div>
                            <div className="col">
                                <Form.Control autoComplete="off"
                                    onChange={(e) => changeDeport(e)} value={resumeData.deportes}
                                    name="name"
                                    style={{ letterSpacing: '0.1px' }}
                                    className="formGray montse" type="text" placeholder="Ingrese los deportes" />
                            </div>

                            <div className="col-4 mt-3">
                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Artes</Form.Label>
                            </div>
                            <div className="col">
                                <Form.Control autoComplete="off"
                                    onChange={(e) => changeArte(e)} value={resumeData.arte}
                                    name="name"
                                    style={{ letterSpacing: '0.1px' }}
                                    className="formGray montse" type="text" placeholder="Ingrese las artes" />
                            </div>

                            <div className="col-4 mt-3">
                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Link</Form.Label>
                            </div>
                            <div className="col">
                                <Form.Control autoComplete="off"
                                    onChange={(e) => changeLink(e)} value={resumeData.link}
                                    name="name"
                                    style={{ letterSpacing: '0.1px' }}
                                    className="formGray montse" type="text" placeholder="Ingrese la url" />
                            </div>


                            <div className="col-4 mt-3">
                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Costos</Form.Label>
                            </div>
                            <div className="col mb-3">

                            <Form.Control
                        onChange={(e) => handleChange(e)}
                        value={selectFile}
                      as="textarea"
                      placeholder="Escriba su mensaje..."
                      rows={8}
                      style={{whiteSpace:"pre-wrap"}}
                    />


                            {/* <Select
                        name="values"
                         value={selectFile}
                        onChange={(e) => handleChange(e)}
                        // defaultValue={[values[0]]}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={files}
                      /> */}
                            </div>


                            {/* <div className="col-4 mt-3">
                                <Form.Label style={{ fontSize: '16px' }} className="montse formGray">Folleto</Form.Label>
                            </div> */}
                            {/* <div className="col mb-3">
                            <Select
                        name="values"
                         value={selectFileFolleto}
                        onChange={(e) => handleChangefolleto(e)}
                        // defaultValue={[values[0]]}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={files}
                      />
                            </div> */}

                        </div>



                        :
                        <div className="card">
                            <div className="d-flex justify-content-end">
                                <a>
                                    <FIIcons.FiEdit onClick={(e) => setEditing(!editing)} size={18} style={{ color: '#386CEF' }} />
                                </a>
                            </div>
                            <div className="row mt-3 ml-1">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Ubicación</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2 ">
                                     {resumeData.address}
                                </h6>
                            </div>
                        </div>

                        <div className="row mt-3 ml-1">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Alumnos</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2 ">
                                     {resumeData.alumnos}
                                </h6>
                            </div>
                        </div>

                        <div className="row mt-3 ml-1">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Instalaciones</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2 ">
                                     {resumeData.instalaciones}
                                </h6>
                            </div>
                        </div>

                        <div className="row mt-3 ml-1">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Programa academico</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2 ">
                                     {resumeData.programa_academic}
                                </h6>
                            </div>
                        </div>

                        <div className="row mt-3 ml-1">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Deportes</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2 ">
                                     {resumeData.deportes}
                                </h6>
                            </div>
                        </div>

                        <div className="row mt-3 ml-1">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Arte</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2 ">
                                     {resumeData.arte}
                                </h6>
                            </div>
                        </div>

                        <div className="row mt-3 ml-1 ">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Link</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2 ">
                                     {resumeData.link} 
                                </h6>
                            </div>
                        </div>

                        <div className="row mt-3 ml-1 ">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Costo</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2 ">
                                     {resumeData.costo} 
                                </h6>
                            </div>
                        </div>

                        {/* <div className="row mt-3 ml-1 ">
                            <div className="col-3">
                                <h6 className="montse card-subtitle mb-2 formGrayTwo">Folleto</h6>
                            </div>
                            <div className="col">
                                <h6 style={{ color: '#243243', fontWeight: '600' }}
                                    className="montse card-subtitle mb-2 ">
                                     {resumeData.folleto} 
                                </h6>
                            </div>
                        </div> */}

                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
