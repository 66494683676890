import React, { useState, useEffect } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import StarRatings from 'react-star-ratings';
import SearchBar from '../../components/GeneralComponents/SearchBar';
var _ = require('lodash');

export default function TableTotals(props) {


  const [frameworkComponents, setFramwrokw] = useState({ slotProgram: SlotProgram,slotTotal:SlotTotal });
  const [ratingTotal, setRatingTotal] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [newObj, setNewObj] = useState([]);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [param, setParam] = useState(null);
  const [flag,setFlag] = useState(false);
  const [rowsRating, setRowsRating] = useState([]);

  useEffect(() =>{
    setRowsRating(props.rowsRating);

  },[props.rowsRating])
  const consult = (e) => {
    e.target.value === "" ? quickSearch('keyWordSeccret302') : quickSearch(e.target.value);
  }
  const quickSearch = async (value) => {
    let objx = gridApi;
    value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
    setGridApi(objx);
  }
  const onGridReady = (params) => {
    setGridApi(params);
    setGridColumnApi(params);
  };
  const handleChange = (e) => {
    const { value } = e;
    let aux = [];
    let finArray = [];
    let obj = { program: '', evaluation: 0, cF: 0, cP: 0, p: 0, aD: 0, deC: 0, aP: 0, tC: 0, tR: 0 };
    let objTotal = { program: 'TOTAL', evaluation: 0, cF: 0, cP: 0, p: 0, aD: 0, deC: 0, aP: 0, tC: 0, tR: 0 };
    if (value === 'General') {
      setRowsRating(ratingTotal);
    } else {
      props.rows.map(r => {
        if (value == r.rating) {
          aux.push(r);
        }
      })
      const result = _.groupBy(aux, "name_prospection")
      Object.keys(result).map((name, i) => {
        obj.program = name;
        result[name].map(re => {
          switch (re.status) {
            case 'Decisión':
              obj.deC = obj.deC + 1;
              break;
            case 'Evaluación':
              obj.evaluation = obj.evaluation + 1;
              break;
            case 'Contacto Previo':
              obj.cP = obj.cP + 1;
              break;
            case 'Contacto Formal':
              obj.cF = obj.cF + 1;
              break;
            case 'Presentación':
              obj.p = obj.p + 1;
              break;
            case 'Aclaración de dudas':
              obj.aD = obj.aD + 1;
              break;
            case 'Aplicar':
              obj.aP = obj.aP + 1;
              break;
          }
        })
        obj.tC = 0;
        objTotal.tC += obj.tC;
        objTotal.evaluation += obj.evaluation;
        objTotal.deC += obj.deC;
        objTotal.cP += obj.cP;
        objTotal.cF += obj.cF;
        objTotal.p += obj.p;
        objTotal.aD += obj.aD;
        objTotal.aP += obj.aP;

        finArray.push(obj);
        obj = { program: '', evaluation: 0, cF: 0, cP: 0, p: 0, aD: 0, deC: 0, aP: 0, tC: 0 };
      })
      finArray.push(objTotal);
      objTotal = { program: 'TOTAL', evaluation: 0, cF: 0, cP: 0, p: 0, aD: 0, deC: 0, aP: 0, tC: 0, tR: 0 };
      setRowsRating(finArray);

    }
  }
  return (
      <div className='mt-3'>
        <div className='row'>
          <div className='col-4'>
            <SearchBar consult={(e) => consult(e)} />
          </div>
        </div>
        <div
          className="ag-theme-alpine mt-1"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact 

            rowData={rowsRating}
            rowHeight={40}
            onGridReady={onGridReady}
            cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
            domLayout="autoHeight"
            rowClassRules={{
              'colorGrayAG': function (params) {
                var backColor = params.data.color;
                return params.data.color === 0;
              },
              'colorWhiteAG': 'data.color === -1',
            }}
            suppressRowTransform={true}
            //    pagination={true}
            //    paginationPageSize={10}
            frameworkComponents={frameworkComponents}
          //    paginationNumberFormatter={function (params) {
          //        return params.value.toLocaleString();
          //    }}
          //    rowSelection="multiple"
          >
            <AgGridColumn
              headerName="Programa"
              wrapText={true}
              field="program"
              cellRenderer="slotProgram"
              width={250}
            />
            <AgGridColumn 
            headerName='Aplicación'
            field="apl"
            wrapText={true}
            width={150}
            />
            <AgGridColumn
              field="adm"
              headerName="Admisión"
              wrapText={true}
              width={100}
            />
              <AgGridColumn
              headerName="Aceptación"
              field="ace"
              wrapText={true}
              width={150}
            />
              <AgGridColumn
              headerName="Visado"
              field="vis"
              wrapText={true}
              width={150}
            />
              <AgGridColumn
              field="tra"
              headerName="Trámites de viajes"
              wrapText={true}
              width={150}
            />
               <AgGridColumn
              field="esp"
              headerName="Ultimos detalles"
              wrapText={true}
              width={150}
            />
            <AgGridColumn
              headerName="Activo"
              field="act"
              wrapText={true}
              width={150}
            />
            <AgGridColumn
              headerName="Total"
              cellRenderer="slotTotal"
              field="tC" width={150}
            />
          </AgGridReact>
        </div>
      </div>
  );
}

// Slots 
export const SlotProgram = function SlotProgram(props) {
  function whatIs(value) {
    return typeof value === 'string' ? 1 : 0;
  }
  const { value } = props;
  return (
    [
      whatIs(value) === 1
        ?
        <b>{value} </b>
        :
        <StarRatings
          rating={value}
          starDimension={'20px'}
          starEmptyColor={'gray'}
          starRatedColor={'#ffd200'}
          starHoverColor={'#ffd200'}
          starSpacing="2px"
          numberOfStars={5}
          name='rating'
        />
    ]
  )
}

export const SlotTotal = function SlotTotal(props){
  const {value} = props;
  return(
    <b>{value} </b>
  )
}