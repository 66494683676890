import React, { useEffect, useState } from 'react'
import * as FAIcons from "react-icons/fa";

function LeadsHook() {

  const [totalLeads,setTotalLeads] = useState(0)
    useEffect(() => {
      setTotalLeads(JSON.parse(localStorage.getItem('countLeads')));

        })

        
  return (
    <>
  <div
  style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'start'}}
  >
  <div>
  <FAIcons.FaUserPlus size={20} style={{marginTop:'-10px'}} />

  </div>

  <div>
 {totalLeads != 0 &&
    <span 
    id="leads"
    style={{position:'static',fontSize:'10px',marginLeft:'-10px'}}
    className="start-100 translate-middle badge rounded-pill bg-danger"
    >
     {totalLeads}
    </span>
    }
  </div>
  

   
  </div>
  <div>

  </div>
    </>
  )
}

export default LeadsHook