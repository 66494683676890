

import { types } from "../../../types/types";


// }
const initialState = {
    staffs :[],
    active: null,
}
export const staffReducer = ( state = initialState,action ) => {
    switch (action.type) {
            case types.StaffCActive:
                localStorage.setItem( 'staffActive', JSON.stringify(action.payload));
                return {
                    ...state,
                    active: {
                        ...action.payload
                    }
                }
            case types.StaffCLoad:
                return {
                    ...state,
                    staffs: [...action.payload]
                }
        default:
            return state;
    }

}