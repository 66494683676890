import React , { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet , Title , Font } from '@react-pdf/renderer';
import moment from 'moment';
import { loadLocalColleges } from '../../../helpers/collegesHelpers/loadColleges';

import montseBold from './../../../resources/fonts/montserrat/Montserrat-Bold.ttf';
import montseLight from './../../../resources/fonts/montserrat/Montserrat-Light.ttf';
import axios from 'axios';
import { constaApi } from '../../../constants/constants';



export default function Layout( props ) {

  const _ = require("lodash");


const [programsToRender,setProgramsToRender] = useState([]);

  const [user,setUser] = useState({
    title: ' Aplicante',
    name : 'Test'
  });

  const [localColleges, setLocalColleges] = useState([]);


  Font.register({
    family: 'Montse',
    fonts: [
      { src: montseBold, fontWeight: 'heavy' },
      { src: montseLight, fontWeight: 100 },
    ],
  });

  useEffect(()=>{
    Promise.all([loadLocalColleges()])
    .then(function (result) {
        if (result) {
            setLocalColleges(result[0]);
        }
    })

  },[])

  useEffect(() => {




    if(props.userToRender){
      consultContact();
      setUser(props.userToRender);

    }


  },[props])

  async function consultContact() {
    await axios.get(constaApi + 'contacts/' + props.userToRender.id + '/dashboard', {
      headers: {
        "Accept": "application/json"
      }
    }).then(function (response) {
      let array = [];
      if (response.data.merge) {


        // Requerimientos
        // - Programas de prospección que estén en cualquier status excepto aplicar [ LISTO ]
        // - Programas de prospección que estén en status de cancelado solo si no tienen otra prospección del mismo año en aplicación o seguimiento.

        // - Programas de aplicaciones que estén en cualquier status excepto Activo [ LISTO ]
        // - Programas de aplicaciones que estén en status de Cancelado solo si no tiene otra programa  del mismo año que hubiera pasado a seguimiento

        // - Programas de seguimiento que esten en cualquier status, incluso terminado o cancelado


        response.data.merge.map((item,index) => {

          if(item.type == 'Seguimiento'){

            array.push(item);

          } else if (item.type == 'Prospeccion'){

            if( !(item.applications.length > 0) ){
              array.push(item);
            }

          } else if(item.type == 'Aplicacion'){
            if(item.trackings.length > 0 ){

              if( !(item.id_application == item.trackings[0].id_application) ){
                array.push(item);
              }
            } else {
              array.push(item);
            }

          }

        })
      }

      let arraySorter = _.sortBy(array,['created_at']);
      setProgramsToRender(arraySorter);


    });
  }




    // Create styles

const typeProgram = (type,program) =>{


  let param = 'Sin tipo';

  switch (program.status) {
    case 'Cancelar':
      param = <Text key={0} style={styles.circleCssGray}>{type.charAt(0)}</Text>;
      break;
        case 'Contacto Previo':
          param = <Text key={0} style={styles.circleCssBlue}>{type.charAt(0)}</Text>;
          break;
          case 'Contacto Formal':
            param = <Text key={0} style={styles.circleCssBlue}>{type.charAt(0)}</Text>;
            break;
            case 'Presentación':
              param = <Text key={0} style={styles.circleCssBlue}>{type.charAt(0)}</Text>;
              break;
              case 'Aclaración de dudas':
                param = <Text key={0} style={styles.circleCssBlue}>{type.charAt(0)}</Text>;
                break;
                case 'Decisión':
                  param = <Text key={0} style={styles.circleCssBlue}>{type.charAt(0)}</Text>;
                  break;
                  case 'Evaluación':
                    param = <Text key={0} style={styles.circleCssBlue}>{type.charAt(0)}</Text>;
                    break;
                  // Until here, Status prospection , now aplication status

                  case 'Cancelado':
                    param = <Text key={0} style={styles.circleCssGray}>{type.charAt(0)}</Text>;
                    break;
                     case 'Aplicación':
                         param = <Text key={0} style={styles.circleCssYellow}>{type.charAt(0)}</Text>;
                         break;
                         case 'Admisión':
                          param = <Text key={0} style={styles.circleCssYellow}>{type.charAt(0)}</Text>;
                          break;
                          case 'Aceptación':
                            param = <Text key={0} style={styles.circleCssYellow}>{type.charAt(0)}</Text>;
                            break;
                            case 'Visado':
                              param = <Text key={0} style={styles.circleCssYellow}>{type.charAt(0)}</Text>;
                              break;
                              case 'Trámites de viaje':
                                param = <Text key={0} style={styles.circleCssYellow}>{type.charAt(0)}</Text>;
                                break;
                                case 'Ultimos detalles':
                                  param = <Text key={0} style={styles.circleCssYellow}>{type.charAt(0)}</Text>;
                                  break;

                                  // Tracking
                                  case 'Activo':
                                    param = <Text key={0} style={styles.circleCssGreen}>{type.charAt(0)}</Text>;
                                    break;
                                    case 'Materias':
                                      param = <Text key={0} style={styles.circleCssGreen}>{type.charAt(0)}</Text>;
                                      break;
                                      case 'Fall Check Point':
                                        param = <Text key={0} style={styles.circleCssGreen}>{type.charAt(0)}</Text>;
                                        break;
                                        case 'Diciembre':
                                          param = <Text key={0} style={styles.circleCssGreen}>{type.charAt(0)}</Text>;
                                          break;
                                          case 'Enero':
                                            param = <Text key={0} style={styles.circleCssGreen}>{type.charAt(0)}</Text>;
                                            break;
                                            case 'Spring Check Point':
                                              param = <Text key={0} style={styles.circleCssGreen}>{type.charAt(0)}</Text>;
                                              break;
                                              case 'Fin de curso':
                                                param = <Text key={0} style={styles.circleCssGreen}>{type.charAt(0)}</Text>;
                                                break;
                                                case 'Revalidación':
                                                  param = <Text key={0} style={styles.circleCssGreen}>{type.charAt(0)}</Text>;
                                                  break;
                                                  case 'Reembolso':
                                                    param = <Text key={0} style={styles.circleCssGreen}>{type.charAt(0)}</Text>;
                                                    break;
                                                    case 'Recomendacion':
                                                      param = <Text key={0} style={styles.circleCssGreen}>{type.charAt(0)}</Text>;
                                                      break;


                                  default:
                                    param = <Text key={0} style={styles.circleCssPurple}>{type.charAt(0)}</Text>;
                                    break;

}

return param;


}
const styles = StyleSheet.create({

  circleCssPurple:{
       textAlign:'center',
        borderRadius: '50%',
        boxShadow: '0 0 0 1px',
        display: 'inline-flex',
        fontSize: '20px',
        height: '30px',
        lineHeight: 'normal',
        minHeight: '30px',
        minWidth: '30px',
        width: '30px',
        backgroundColor: '#9b03ae',
        color: '#fff'
  },

  circleCssGray:{
       textAlign:'center',
        borderRadius: '50%',
        boxShadow: '0 0 0 1px',
        display: 'inline-flex',
        fontSize: '20px',
        height: '30px',
        lineHeight: 'normal',
        minHeight: '30px',
        minWidth: '30px',
        width: '30px',
        backgroundColor: '#6c6c6c',
        color: '#fff'
  },

  circleCssBlue:{
       textAlign:'center',
        borderRadius: '50%',
        boxShadow: '0 0 0 1px',
        display: 'inline-flex',
        fontSize: '20px',
        height: '30px',
        lineHeight: 'normal',
        minHeight: '30px',
        minWidth: '30px',
        width: '30px',
        backgroundColor: '#497cff',
        color: '#fff'
  },
  circleCssGreen:{
       textAlign:'center',
        borderRadius: '50%',
        boxShadow: '0 0 0 1px',
        display: 'inline-flex',
        fontSize: '20px',
        height: '30px',
        lineHeight: 'normal',
        minHeight: '30px',
        minWidth: '30px',
        width: '30px',
        backgroundColor: '#3ad88f',
        color: '#fff'
  },


  circleCssYellow:{
       textAlign:'center',
        borderRadius: '50%',
        boxShadow: '0 0 0 1px',
        display: 'inline-flex',
        fontSize: '20px',
        height: '30px',
        lineHeight: 'normal',
        minHeight: '30px',
        minWidth: '30px',
        width: '30px',
        backgroundColor: '#ffd200',
        color: '#fff'
  },


  sameWidthContainerFlex:{
    display: 'flex',
    flexDirection:'row',
    flexWrap: 'wrap',
    fontSize: '20px',
  },
  marginTopContent:{
    marginTop:'10px',
    marginBottom:'10px'

  },
  title : {
    marginBottom:'20px',
    fontSize:'32px',
    fontFamily:'Montse',
    display:'flex',justifyContent:'center',textAlign:'center'
  },
  bold:{
    fontFamily:'Montse',
    fontWeight:600
  },
    page: {
      flexDirection: 'column',
      backgroundColor: '#E4E4E4'
    },
    // title: { fontFamily: 'Montse', fontSize: 20, fontWeight: 'heavy' }, // doesn't work without fontFamily
    text: { fontFamily: 'Montse', fontSize: 16, fontWeight: 100 }, // doesn't work without fontFamily
    textBold: { fontFamily: 'Montse', fontSize: 20, fontWeight: 600 }, // doesn't work without fontFamily

    section: {
      margin: 10,
      padding: 5,
      flexGrow: 1
    }
  });


  const showOrnot = (record) => {


    // where('type','Llamada')->where('type','Cita')->where('type','Video llamada')


    switch (record.type){

      case 'Llamada':
     return(   <View  style={{flexDirection:'row',flexWrap:'wrap',marginLeft:'10px'}}>
      <Text style={styles.textBold}>•</Text>
      <Text style={styles.text}>{showDate(record.date)} |</Text>
         <Text style={styles.text}>{record.subject} | </Text>
         <Text style={styles.text}>{record.name_responsable}</Text>
         <View style={styles.marginTopContent}>
         <Text style={styles.text}>{record.text}</Text>
         </View>

      </View>)

case 'Cita':
  return(   <View  style={{flexDirection:'row',flexWrap:'wrap',marginLeft:'10px'}}>
      <Text style={styles.textBold}>•</Text>
      <Text style={styles.text}>{showDate(record.date)} |</Text>
      <Text style={styles.text}>{record.subject} | </Text>
      <Text style={styles.text}>{record.name_responsable}</Text>
      <View style={styles.marginTopContent}>
      <Text style={styles.text}>{record.text}</Text>
      </View>

   </View>)

case 'Video llamada':
  return(   <View  style={{flexDirection:'row',flexWrap:'wrap',marginLeft:'10px'}}>
      <Text style={styles.textBold}>•</Text>
      <Text style={styles.text}>{showDate(record.date)} |</Text>
      <Text style={styles.text}>{record.subject} | </Text>
      <Text style={styles.text}>{record.name_responsable}</Text>
      <View style={styles.marginTopContent}>
      <Text style={styles.text}>{record.text}</Text>
      </View>

   </View>)

      default:

      break;


    }

    // if(record.type == 'Llamada') {
    //   return (
    //     <View  style={{flexDirection:'row',flexWrap:'wrap',marginLeft:'10px'}}>
    //   <Text style={styles.textBold}>{record.type}</Text>
    //      <Text style={styles.text}>{showDate(record.date)} |</Text>
    //      <Text style={styles.text}>{record.subject} | </Text>
    //      <Text style={styles.text}>{record.name_responsable}</Text>
    //      <View style={styles.marginTopContent}>
    //      <Text style={styles.text}>{record.text}</Text>
    //      </View>
    //   </View>
    //   )
    // }  if (record.type == 'Cita') {
    //   return (
    //     <View  style={{flexDirection:'row',flexWrap:'wrap',marginLeft:'10px'}}>
    //   <Text style={styles.textBold}>{record.type}</Text>
    //      <Text style={styles.text}>{showDate(record.date)} |</Text>
    //      <Text style={styles.text}>{record.subject} | </Text>
    //      <Text style={styles.text}>{record.name_responsable}</Text>
    //      <View style={styles.marginTopContent}>
    //      <Text style={styles.text}>{record.text}</Text>
    //      </View>

    //   </View>
    //   )

    // }  if (record.type == 'Whatssap') {
    //   return (
    //     <View  style={{flexDirection:'row',flexWrap:'wrap',marginLeft:'10px'}}>
    //   <Text style={styles.textBold}>{record.type}</Text>
    //      <Text style={styles.text}>{showDate(record.date)} |</Text>
    //      <Text style={styles.text}>{record.subject} | </Text>
    //      <Text style={styles.text}>{record.name_responsable}</Text>
    //      <View style={styles.marginTopContent}>
    //      <Text style={styles.text}>{record.text}</Text>
    //      </View>

    //   </View>
    //   )

    // }

  }

  const showDate = (dateBD) => {
    moment.locale('en-US')
    let datef = moment(dateBD).locale('en-US').format("MMMM D, YYYY ");
    datef = datef[0].toUpperCase() + datef.slice(1);
    datef = datef.replace(".", "");
    return datef;
  }
  const ShowAge = (birthday) => {
    moment.locale('es-US')
    // How old are you?
    let agee = Math.floor((new Date() - new Date(birthday)) / 1000 / 60 / 60 / 24 / 365.25)
    return agee + " years";
  }


const checkGradeWished = (data , user ) => {

  let grade = '';

    let hoy = moment().locale("es-mx").format("MM");
    if (data.name_prospection.includes('Boarding') || data.name_prospection.includes('District')) {
      if (user.cicly) {
        let sub = user.cicly.split('-');
        if (user.grade) {
          let grad = user.grade.split(" ");
          let yearProspection = data.name_prospection.split(" ");
          let result = (parseInt(grad[1]) + (parseInt(yearProspection[2]) - parseInt(sub[0])));
          grade = result;

        }
      }
    } else {
      if(user.cicly){
        if (user.cicly) {
          let sub = user.cicly.split('-');
          if (user.grade) {
            let grad = user.grade.split(" ");
            let yearProspection = data.name_prospection.split(" ");
            let result = (parseInt(grad[1]) + (parseInt(yearProspection[2]) - parseInt(sub[0])));
            grade = result;

          }
        };
      }
    }

    return grade;


}
  const actualGrade = (cicly,grade) =>{
    let hoy = moment().locale("es-mx").format("MM");
    let year = moment().locale("es-mx").format("YYYY");
    let result = 0;
    if (cicly) {
      let sub = cicly.split('-');
      if (grade) {
        let grad = grade.split(" ");
        if (parseInt(hoy) >= 0 && parseInt(hoy) <= 7) {
          result = (parseInt(grad[1]) + (parseInt(year) - parseInt(sub[1])));
          return result;
        } else {
          result = (parseInt(grad[1]) + (parseInt(year) - parseInt(sub[0])));
          return result;
        }
      }
    }
  }


const formatName = (obj) => {

    let tag = '';
    let param = '';
    let year = obj.name_prospection ? obj.name_prospection.substr((obj.name_prospection.length - 4), obj.name_prospection.length) : null;
    if (obj) {
      if (obj.name_prospection.includes('Boarding')) {
        param = obj.name ? ('BS ' + year + ' - ') + obj.name : 'BS ' + year;

      }
      else if (obj.name_prospection.includes('Año')) {
        param = obj.name ? ('AE ' + year + ' - ') + obj.name : 'AE ' + year;
      }
      else if (obj.name_prospection.includes('Summer')) {
        param = obj.name ? ('Summer ' + year + ' - ') + obj.name : 'Summer ' + year;
      }
      else if (obj.name_prospection.includes('Idiomas')) {
        param = obj.name ? ('Idiomas ' + year + ' - ') + obj.name : 'Idiomas ' + year;
      }
      else if (obj.name_prospection.includes('Au')) {
        param = obj.name ? ('Au Pair ' + year + ' - ') + obj.name : 'Au Pair ' + year;
      }
      else if (obj.name_prospection.includes('Carreras')) {
        param = obj.name ? ('C & M ' + year + ' - ') + obj.name : 'C & M ' + year;
      }
      else {
        param = obj.name_prospection;
      }
    }
return param;
  }

  const findCorrectCollege = (user,id) => {

    if(id == 0 || id == null){
      return user.other_School ?? ''
    }
    else{

      const correctCollege = localColleges.find((college) => college.id == user.id_college_vinculated);
      if(correctCollege){
          return correctCollege.name
      } else {
          return 'Cargando...';
      }

    }
}



  return (
    <Document title={props.userToRender.name || 'Resumen'}>
   {user
   &&

   <Page size="A2" wrap="false" >
   <View >
   <Text style={styles.title}>Resumen de { user.name ?? ''} { user.father_lastname ?? ''} { user.mother_lastname?? ''} </Text>
   </View>


   <View style={styles.sameWidthContainerFlex}>

   <View  style={{marginLeft:'10px', border:'1px solid black'}}>
   <Text style={{fontSize:'20px',fontWeight:'700'}}>Resumen</Text>

   <View  style={{flexDirection:'row',flexWrap:'wrap'}}>
   <Text style={styles.textBold}>DOB:</Text>
      <Text style={styles.text}>{user.birthday ? showDate(user.birthday) : ''}</Text>
   </View>

   <View  style={{flexDirection:'row',flexWrap:'wrap'}}>
   <Text style={styles.textBold}>Age:</Text>
      <Text style={styles.text}>{user.birthday ? ShowAge(user.birthday) : ''}</Text>
   </View>


   <View  style={{flexDirection:'row',flexWrap:'wrap'}}>
   <Text style={styles.textBold}>City:</Text>
      <Text style={styles.text}>{user.city ?? ''}</Text>
   </View>


   <View  style={{flexDirection:'row',flexWrap:'wrap'}}>
   <Text style={styles.textBold}>Current Grade:</Text>
      <Text style={styles.text}>{ user.cicly ? actualGrade(user.cicly , user.grade) : ''}</Text>
   </View>

   <View  style={{flexDirection:'row',flexWrap:'wrap'}}>
   <Text style={styles.textBold}>School:</Text>
      <Text style={styles.text}>
      {findCorrectCollege(user,user.id_college_vinculated)}
     </Text>
   </View>

   <View  style={{flexDirection:'row',flexWrap:'wrap'}}>
   <Text style={styles.textBold}>Gender:</Text>
      <Text style={styles.text}>{user.sex == "Femenino" ? 'Female' : 'Male'}</Text>
   </View>





   </View>


   <View  style={{marginLeft:'50px', border:'1px solid black'}}>
   <Text style={{fontSize:'20px',fontWeight:'700'}}>Referencias</Text>


  {
    props.dataToExport &&

    [props.dataToExport.references.map((reference,x) => {
      return (
        <View>

        <View  style={{marginTop:x > 0 ? '30px' : '10px',flexDirection:'row',flexWrap:'wrap'}}>
        <Text style={styles.textBold}>Name:</Text>
           <Text style={styles.text}>{reference?.name + ' ' + (reference.father_lastname ?? '') + ' ' + (reference.mother_lastname?? '')}</Text>
        </View>

      {reference.emails &&
        [reference.emails.map(refEmail => {
          return (
            <View  style={{flexDirection:'row',flexWrap:'wrap'}}>
            <Text style={styles.textBold}>Email:</Text>
               <Text style={styles.text}>{refEmail?.email}</Text>
            </View>

          )
        })]
      }


      {reference.phones &&
        [reference.phones.map(refEmail => {
          return (
            <View  style={{flexDirection:'row',flexWrap:'wrap'}}>
            <Text style={styles.textBold}>Phone:</Text>
               <Text style={styles.text}>{refEmail?.phone}</Text>
            </View>

          )
        })]
      }



        </View>
      )
    })]
  }






   </View>


   </View>





   <View >
   <Text style={{fontSize:'24px',fontWeight:'700' ,marginLeft:'10px', marginTop:'15px'}}>Primeros Bitacora</Text>

  {props.dataToExport &&

  [props.dataToExport.first &&

    [props.dataToExport.first.map(firstReminders => {
      return(
        <View  style={{flexDirection:'row',flexWrap:'wrap',marginLeft:'10px'}}>
     <Text style={styles.textBold}>•</Text>
        <Text style={styles.text}>{showDate(firstReminders.date)} |</Text>
        <Text style={styles.text}>{firstReminders.subject} | </Text>
        <Text style={styles.text}>{firstReminders.name_responsable}</Text>
        <View style={styles.marginTopContent}>
        <Text style={styles.text}>{firstReminders.text}</Text>
        </View>

     </View>

      )
     }) ]

  ]

  }


   </View>


   <View >
   <Text style={{fontSize:'24px',fontWeight:'700',marginLeft:'10px',marginTop:'15px'}}>Ultimos Bitacora</Text>

   {props.dataToExport &&

[props.dataToExport.last &&

  [props.dataToExport.last.map(lastReminders => {
    return(


      <View  style={{flexDirection:'row',flexWrap:'wrap',marginLeft:'10px'}}>
      <Text style={styles.textBold}>•</Text>
         <Text style={styles.text}>{showDate(lastReminders.date)} |</Text>
         <Text style={styles.text}>{lastReminders.subject} | </Text>
         <Text style={styles.text}>{lastReminders.name_responsable}</Text>
         <View style={styles.marginTopContent}>
         <Text style={styles.text}>{lastReminders.text}</Text>
         </View>

      </View>



  //     <View  style={{flexDirection:'row',flexWrap:'wrap'}}>
  //  <Text style={styles.text}>•</Text>
  //     <Text style={styles.text}>{lastReminders.subject}</Text>
  //  </View>

    )
   }) ]

]

}


   </View>



   <View >
   <Text style={{fontSize:'24px',fontWeight:'700',marginTop:'15px'}}> Bitacora</Text>

   {props.dataToExport &&

[props.dataToExport.allRecords &&

  [props.dataToExport.allRecords.map(record => {
    return(
      [showOrnot(record)]
    )
   }) ]

]

}


   </View>


   {/* where('type','Llamada')->where('type','Cita')->where('type','Video llamada') */}




   <View >
   <Text style={{fontSize:'24px',fontWeight:'700',marginLeft:'10px',marginTop:'15px'}}>Recordatorios</Text>

     {props.dataToExport &&

[props.dataToExport.lastenReminders &&

  [props.dataToExport.lastenReminders.map(lastenRemindersReminders => {
    return(


      <View  style={{flexDirection:'row',flexWrap:'wrap',marginLeft:'10px'}}>
      <Text style={styles.textBold}>•</Text>
         <Text style={styles.text}>{showDate(lastenRemindersReminders.dateReminder)} |</Text>
         <Text style={styles.text}>{lastenRemindersReminders.subject} | </Text>
         <Text style={styles.text}>{lastenRemindersReminders.responsable}</Text>
         <View style={styles.marginTopContent}>
         <Text style={styles.text}>{lastenRemindersReminders.notes}</Text>
         </View>

      </View>



  //     <View  style={{flexDirection:'row',flexWrap:'wrap'}}>
  //  <Text style={styles.text}>•</Text>
  //     <Text style={styles.text}>{lastenRemindersReminders.subject}</Text>
  //  </View>

    )
   }) ]

]

}
   </View>


   <View >
   <Text style={{fontSize:'24px',fontWeight:'700',marginLeft:'10px',marginTop:'15px'}}>Tickets Vigentes</Text>

     {props.dataToExport &&

[props.dataToExport.tickets &&

  [props.dataToExport.tickets.map(tickets => {
    return(


      <View  style={{flexDirection:'row',flexWrap:'wrap',marginLeft:'10px'}}>
      <Text style={styles.textBold}>•</Text>
         <Text style={styles.text}>{showDate(tickets.created_at)} |</Text>
         <Text style={styles.text}>{tickets.title} | </Text>
         <Text style={styles.text}>{tickets.fullname_creator}</Text>
         <View style={styles.marginTopContent}>
         <Text style={styles.text}>{tickets.description}</Text>
         </View>

      </View>



  //     <View  style={{flexDirection:'row',flexWrap:'wrap'}}>
  //  <Text style={styles.text}>•</Text>
  //     <Text style={styles.text}>{tickets.title}</Text>
  //  </View>

    )
   }) ]

]

}
   </View>


   <View >
   <Text style={{fontSize:'24px',fontWeight:'700',marginLeft:'10px',marginTop:'15px'}}>Propuestas</Text>

     {props.dataToExport &&

[props.dataToExport.proposals &&

  [props.dataToExport.proposals.map(proposals => {
    return(


      <View  style={{flexDirection:'row',flexWrap:'wrap',marginLeft:'10px'}}>
      <Text style={styles.textBold}>•</Text>
         <Text style={styles.text}>{showDate(proposals.created_at)} |</Text>
         <Text style={styles.text}>{proposals.name} | </Text>
         <Text style={styles.text}>{proposals.country}</Text>
         <View style={styles.marginTopContent}>
         </View>

      </View>




    )
   }) ]

]

}
   </View>


   <View >
   <Text style={{fontSize:'24px',fontWeight:'700',marginLeft:'10px',marginTop:'15px'}}>Programas</Text>


   <View  style={{flexDirection:'row',flexWrap:'wrap',marginLeft:'10px'}}>
      <Text style={styles.text}>Grado Sugerido |     </Text>
      <Text style={styles.text}>Tipo           |     </Text>
      <Text style={styles.text}>Substatus                    </Text>
      <Text style={styles.text}>Nombre            </Text>
      <View style={styles.marginTopContent}>
      </View>

   </View>


     {props.dataToExport &&


[programsToRender &&

  [programsToRender.map(programs => {
    return(


      <View  style={{flexDirection:'row',flexWrap:'wrap',marginLeft:'10px'}}>
      <Text style={{marginRight:'150px',marginLeft:'10px'}}>{checkGradeWished(programs , user)}</Text>
      {typeProgram(programs.type,programs)}
      <Text style={{marginLeft:'80px'}}>{programs.status + ' ' + programs.substatus}</Text>
      <Text style={{marginLeft:'80px'}}>{formatName(programs)}</Text>
         <View style={styles.marginTopContent}>
         </View>

      </View>




    )
   }) ]

]

}
   </View>


 </Page>
 }
  </Document>
  )
}
