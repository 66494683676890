import React, { useState, useRef, useEffect } from 'react'
import {
    BrowserRouter as Router, Switch, Redirect,
    Route, useLocation
} from 'react-router-dom';
import Nav from "../../components/Nav/Nav";
import SideBar from '../../components/SideBar/new/SideBar';
import routes from "../../routes.js";
import { useParams } from "react-router";
import { useDispatch } from 'react-redux';
import TicketsView from './components/TicketsView.jsx';
import TicketsProspection from './components/TicketsProspection.jsx';
import TicketsApplication from './components/TicketsApplication.jsx';
import TicketsTracking from './components/TicketsTracking.jsx';
import TicketsOther from './components/TicketsOther.jsx';
import PermissionsHook from '../../customHook/PermissionsHook/PermissionsHook';


function TicketsRouter(props) {

    const dispatch = useDispatch();
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [backgroundColor, setBackGroundColor] = useState(JSON.parse(localStorage.getItem('bgColor')) || 'white');
    const [activeColor, setActiveColor] = useState(JSON.parse(localStorage.getItem('activeColor')) || 'info');
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const mainPanel = useRef();
    

    const fromSidebar = () => {
        mainPanel.current.classList.toggle('withSidebar');
    }

    const finishLoading = () =>{
        setLoading(false);
      }
    return (
        <div className="wrapper">
            <SideBar
                {...props}
                routes={routes}
                bgColor={backgroundColor}
                activeColor={activeColor}                    fromSidebar={fromSidebar}

            />

{
             loading ? 
  
             <PermissionsHook  finishLoadin={finishLoading} module={'Tickets'} /> 
             
             :
             

            <div style={{ backgroundColor: '#f9fafb' }} className="main-panel" ref={mainPanel}>
                <Nav {...props} />
                <TicketsView />
                <Switch>
                    <Route exact path="/moduleTickets/applications"
                        render={(props) => (
                            <TicketsApplication {...props}
                            />
                        )}
                    />

                    <Route exact path="/moduleTickets/trackings"
                        render={(props) => (
                            <TicketsTracking {...props}
                            />
                        )}
                    />

                    <Route exact path="/moduleTickets/others"
                        render={(props) => (
                            <TicketsOther {...props}
                            />
                        )}
                    />

                    <Route exact path="/moduleTickets"
                        render={(props) => (
                            <TicketsProspection {...props}
                            />
                        )}
                    />

                    <Redirect to="/login" />
                </Switch>

            </div>
          
                        }
        </div>

    )
}

export default TicketsRouter