import { ALLREM } from '../../actions/colleges/remindersColleges/remindersColleges';

import AddEditContacts from '../../components/contactComponents/RemindersComponents/AddEditReminders.jsx';
import SearchBar from '../../components/GeneralComponents/SearchBar';
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { DateRangePickerComponent ,DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import '../cssPages/app.css';
import { constaApi } from '../../constants/constants';
import axios from 'axios';
import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { CSVLink } from "react-csv";
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
} from "reactstrap";
import { SlotUrgent } from '../../components/contactComponents/RemindersComponents/TableReminders.jsx';
// import { SlotDate } from '../../components/contactComponents/RemindersComponents/TableReminders.jsx';
import { SlotDescription } from '../../components/contactComponents/RemindersComponents/TableReminders.jsx';
import { SlotUsers } from '../../components/contactComponents/RemindersComponents/TableReminders.jsx';
import { SlotActions } from '../../components/contactComponents/RemindersComponents/TableReminders.jsx';
import { SlotDateCreated } from '../../components/contactComponents/RemindersComponents/TableReminders.jsx';
// import { SlotName } from '../../components/contactComponents/RemindersComponents/TableReminders.jsx';
import { SlotProgram } from '../../components/contactComponents/RemindersComponents/TableReminders.jsx';
import { activeReminderC } from '../../actions/contacts/remindersContacts/remindersContact';
import moment from 'moment';
import Calendar from 'react-calendar';

export default function MonitorView(props) {

  
  const [activeOption,setActiveOption] = useState(0)
  const startValue = new Date(new Date().getFullYear(), new Date().getMonth(), 14);
  const endValue = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 15);
  const { users } = useSelector(state => state.users);
  const [usersFormat, setUsersFormat] = useState([]);
  const [selectUser, setSelectUser] = useState(null);
  const [rangeDate, setRangeDate] = useState([startValue, endValue]);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  let [color, setColor] = useState("#72B6E1");
  const [flag, setFlag] = useState(false);
  const { remindersC } = useSelector(state => state.remindersC);
  const [frameworkComponents, setFramwrokw] = useState({
    slotUrgent: SlotUrgent,
    slotDate: SlotDate,
    slotDescription: SlotDescription,
    slotUsers: SlotUsers,
    slotActions: SlotActions,
    slotCreated: SlotDateCreated,
    slotName: SlotName,
    slotProgram: SlotProgram
  });
  // Can be a string as well. Need to ensure each key-value pair ends with ;
  const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        `;

        useEffect(()=>{

          adjustDate(activeOption);

        },[activeOption])

  // useEffect(() => {
  //   Search();
  // }, [remindersC])


  console.log('selectUser',selectUser);
  useEffect(() => {
    // dispatch(ALLREALLREM
    // M());


    // let datex = new Date();
    // if (e.value === 'Futuros') {
    //   let start = new Date(Date.now()).toLocaleDateString('es-MX'); // 8/19/2020
    //   let end = new Date(datex.getFullYear() + 1, datex.getMonth(), datex.getDate()).toLocaleDateString('es-MX'); // 8/19/2020


    let yearOptionsArray = []
    let newYear = currentYear
     for (let index = 0; index < 12 ; index++) {
       yearOptionsArray[index ]=
        {
         value:(newYear - 2),
         label: (newYear - 2),
       }

       newYear = newYear + 1;
      
     }

    let dayOptionsArray = []
    for (let index = 1; index < 32; index++) {
       dayOptionsArray[index - 1]=
       {
        value:index,
        label: index
      }
      
    }
    let arr = users.map(us => {
      return {
        ...us,
        value: us.name + 'VALUE',
        label: us.name + ' ' + us.father_lastname + ' ' + us.mother_lastname,
        fullname:
          us.name + 'FULL',
      }
    });
    setUsersFormat(arr);
    setDayOptions(dayOptionsArray)
    setYearOptions(yearOptionsArray)
  }, [users])

  const searchMonth = async ( number ) => {

     monthOptions.find((mont) =>{
      
      if(mont.value == number.value){
        return 'Febrero'
      }
    })


  }
  const [yearOptions,setYearOptions] = useState([])
  const  [daysOptions,setDayOptions] = useState( [
    1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]);
    const  [monthOptions,setMonthOptions] = useState( [
      {value:"1",label:"Enero"},{value:"2",label:"Febrero"},{value:"3",label:"Marzo"},{value:"4",label:"Abril"},{value:"5",label:"Mayo"},
      {value:"6",label:"Junio"},{value:"7",label:"Julio"},{value:"8",label:"Agosto"},{value:"9",label:"Septiembre"},{value:"10",label:"Octubre"},
      {value:"11",label:"Noviembre"},{value:"12",label:"Diciembre"}]);
  

  const currentYear = new Date(Date.now()).getFullYear()
  const currentDate = new Date(new Date(Date.now()).getFullYear() , new Date(Date.now()).getMonth() , new Date(Date.now()).getDay()).toLocaleDateString('es-MX');

  const [kindofFilter,setKindOfFilter] = useState(0);
  const [defaultDate,setDefaultDate] = useState({
    day: { value : moment().date(), label:moment().date()},
    month: { value : moment().month() + 1, label: moment().month() + 1},
    year: { value : moment().year(), label: moment().year()},

    // year: new Date(Date.now()).getFullYear() 
  })

  const [secondDate,setSecondDate] = useState({
    day: { value : moment().date(), label:moment().date()},
    month: { value : moment().month() + 1, label: moment().month() + 1},
    year: { value : moment().year(), label: moment().year()},

    // year: new Date(Date.now()).getFullYear() 
  })



  useEffect(()=>{

    adjustDate(activeOption);

  },[activeOption])


  const adjustDate = (option) =>{
    
    let current_date = {
      day: { value : moment().date(), label:moment().date()},
      month: { value : moment().month() + 1, label: moment().month() + 1},
      year: { value : moment().year(), label: moment().year()},
  
      // year: new Date(Date.now()).getFullYear() 
    } ;

    switch(option){
      case 0:
        current_date = {...current_date, month:{ value:(current_date.month.value == 1 ?  12 : (current_date.month.value - 1) ) }
        ,year:{value:(current_date.month.value == 1 ? (current_date.year.value - 1) : current_date.year.value),label:(current_date.month.value == 1 ? (current_date.year.value - 1) : current_date.year.value) } } 
        break;
      case 1:
        current_date = {...current_date, month:{ value:(current_date.month.value <= 3 ?  (current_date.month.value - 3 + 12) : (current_date.month.value - 3) ) }
        ,year:{value:(current_date.month.value <= 3  ? (current_date.year.value - 1) : current_date.year.value),label:(current_date.month.value <= 3  ? (current_date.year.value - 1) : current_date.year.value) } } 
      break;
      case 2:
        current_date = {...current_date, month:{ value:(current_date.month.value <= 6 ?  (current_date.month.value - 6 + 12) : (current_date.month.value - 6) ) }
        ,year:{value:(current_date.month.value <= 6  ? (current_date.year.value - 1) : current_date.year.value),label:(current_date.month.value <= 6  ? (current_date.year.value - 1) : current_date.year.value) } } 
      break;
      case 3:
        current_date = {...current_date,year:{value:(current_date.year.value - 1),label:(current_date.year.value - 1)} } 
        break;
    }

    setDefaultDate(current_date)

  }
  const changeSecondDate = ( e, select ) =>{
    switch(select){
      case 'day':
        setSecondDate({...secondDate,day:{value:e.value,label:e.label}})
        break;
        case 'month':
          setSecondDate({...secondDate,month:{value:e.value,label:e.label}})
          break;
          case 'year':
            setSecondDate({...secondDate,year:{value:e.value,label:e.label}})
            break;
  }
}
  const changeCurrentDate = ( e , select) =>{

    switch(select){
      case 'day':
        setDefaultDate({...defaultDate,day:{value:e.value,label:e.label}})
        break;
        case 'month':
          setDefaultDate({...defaultDate,month:{value:e.value,label:e.label}})
          break;
          case 'year':
            setDefaultDate({...defaultDate,year:{value:e.value,label:e.label}})
            break;
    }
   

  }





  const [param, setParam] = useState(null);
  const [load, setLoad] = useState(false);
  const [init] = useState(JSON.parse(localStorage.getItem('user')) || { logged: false });
  const [optionsFormat, setOptionsFormat] = useState([
    {
      value: 'Atrasados',
      label: 'Atrasados'
    },
    {
      value: 'Futuros',
      label: 'Futuros'
    },
    {
      value: 'Rango',
      label: 'Rango'
    },

  ]);
  const [selectOption, setSelectionOption] = useState('');

  const consult = (e) => {
    e.target.value === "" ? setParam('keyWordSeccret302') : setParam(e.target.value);
  }



  useEffect(() => {
    if (param) {
      quickSearch(param);
    }
  }, [param])



  async function quickSearch(value) {
    let objx = gridApi;
    value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
    setGridApi(objx);
    let rowsDisplay = objx.api.rowRenderer.rowModel.rowsToDisplay;
    let result ;
    if(rowsDisplay){
      result = await rowsDisplay.map(row => {
       let {data} = row;
       return data;
     })
     setNewObj(result);
    }

  }


  function open() { }
  function changeDates(e) {
    let start = new Date(e.value[0]).toLocaleDateString('es-MX'); // 8/19/2020
    let end = new Date(e.value[1]).toLocaleDateString('es-MX'); // 8/19/2020
    let arr = [];
    arr[0] = start + ' ' + '00:00:00';
    arr[1] = end + ' ' + '23:59:59';
    let d = new Date(arr[0]);
    setRangeDate(arr);
  }
  function handleChangeSelect(e) {
    setResults([]);
    setSelectUser(e);
  }
  function handleChangeSelectOption(e) {
    setSelectionOption(e);
    let datex = new Date();
    if (e.value === 'Futuros') {
      let start = new Date(Date.now()).toLocaleDateString('es-MX'); // 8/19/2020
      let end = new Date(datex.getFullYear() + 1, datex.getMonth(), datex.getDate()).toLocaleDateString('es-MX'); // 8/19/2020
      let arr = [];
      arr[0] = start + ' ' + '00:00:00';
      arr[1] = end + ' ' + '23:59:59';
      let d = new Date(arr[0]);
      setRangeDate(arr);
    }
    if (e.value === 'Atrasados') {
      let start = new Date('10-11-2020').toLocaleDateString('es-MX'); // 8/19/2020
      let end = new Date(datex.getFullYear(), datex.getMonth(), datex.getDate() - 1).toLocaleDateString('es-MX'); // 8/19/2020
      let arr = [];
      arr[0] = start + ' ' + '00:00:00';
      arr[1] = end + ' ' + '23:59:59';
      let d = new Date(arr[0]);
      setRangeDate(arr);
    }
  }

  async function prepareToExport(arr) {
    let aux = [];
    await arr.map(re => {
      let obj = { ...re };
      let nam = '';
      let program = '';
      // if(re.emails_to.length >0){
      //   re.emails_to.map((eme,i) => {
      //     nam += (((re.emails_to.length - 1)  - i) > 0) ? eme.name_user + ',' : eme.name_user + ',';
      //   })
      // }
      // if(re.prospections != null){
      //   program = re.prospections.name_prospection;
      //   if(re.applications != null){
      //     program = re.applications.name_prospection + ' - ' + re.applications.name;
      //     if(re.trackings != null){
      //       program = re.trackings.name_prospection + ' - ' + re.trackings.name;
      //     }
      //   }
      // }
      obj = { ...obj, exportUsers: nam, programToExport: program };
      aux.push(obj);
      setResults({ monitore: aux });
    })
    // aux.map(au =>{
    //   let program = '';
    //   if(au.prospections.length > 0){
    //     program = au.prospections[0].name_prospection;
    //   }
    // })
  }
  function Search( arr = 0) {
    setShow(true);
    setLoading(true);
    let obj = {
      selectUser: selectUser,
      rangeDate:  arr != 0? arr : rangeDate
    }
    axios.post(constaApi + 'loadmonitoreo', obj)
      .then(async function (response) {
        if (response.data) {
          const { data } = response;
          setResults(data);
          if(data.monitoreo){
            setNewObj(data.monitoreo);
          }
          // prepareToExport(data);
          setLoading(false);
        } else {
          setResults([]);
          setLoading(false);
        }
      }).catch(err => {
        setLoading(false);
      });
  }
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [newObj, setNewObj] = useState([]);
  const [filterRating,setFilterRating] = useState(null);

  const headers = [
    { label: "Nombre_contacto", key: "fullname_contact" },
    { label: "Nombre_usuario", key: "fullname_user" },
    { label: "Accion", key: "action_user" },
    { label: "Fecha", key: "created_at" },
   
  ];
  const csvReport = {
    data: newObj,
    headers: headers,
    filename: selectUser ? (selectUser[0].label+'_'+'monitor_module.csv') : 'monitor_movule.csv'
  };
  const exporta = async () => {
    let {filter:value} = gridApi.api.filterManager.activeAdvancedFilters[0] ? gridApi.api.filterManager.activeAdvancedFilters[0].appliedModel : 'no';
    if(props.param){
      await quickSearch(props.param);
      if(filterRating != null && filterRating === value){
        let aux = [];
        await newObj.map(r =>{
          if(r.rating){
            if(r.rating == filterRating){
              aux.push(r);
            }
          }
        })
        setNewObj(aux);
      } else {
        await quickSearch(props.param);
      }
    } else {
      if(filterRating != null && filterRating === value){
        let aux = [];
        await results.monitoreo.map(r =>{
          if(r.rating){
            if(r.rating == filterRating){
              aux.push(r);
            }
          }
        })
        setNewObj(aux);
      } else {
        if(newObj.length > 0){

          
        } else {
          setNewObj(results.monitoreo);
        }
      }
    }

  }
  const ratingFilter = {
    textCustomComparator: (filter, value, filterText) => {
        const filterTextLowerCase = filterText.toLowerCase();
        const valueLowerCase = value.toString().toLowerCase();
        if(filterRating == null){
          setFilterRating(filterTextLowerCase);
        }
        else {
          if(filterRating != filterTextLowerCase){
            setFilterRating(filterTextLowerCase);
          }
        }
        switch (filter) {
            case 'contains':
                return valueLowerCase.indexOf(filterTextLowerCase) >= 0;
            case 'notContains':
                return valueLowerCase.indexOf(filterTextLowerCase) === -1;
            case 'equals':
                return valueLowerCase === filterTextLowerCase;
            case 'notEqual':
                return valueLowerCase != filterTextLowerCase;
            case 'startsWith':
                return valueLowerCase.indexOf(filterTextLowerCase) === 0;
            case 'endsWith':
                var index = valueLowerCase.lastIndexOf(filterTextLowerCase);
                return index >= 0 && index === (valueLowerCase.length - filterTextLowerCase.length);
            default:
                // should never happen
                console.warn('invalid filter type ' + filter);
                return false;
        }
    }
};
  const onGridReady = (params) => {
    setGridApi(params);
    setColumnApi(params);
  };
  const autoSizeAll = (skipHeader) => {
    var allColumnIds = [];
    columnApi.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    columnApi.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  };
  const onFirstDataRendered = (event) => {
    autoSizeAll(false);
  };
  function editReminder(obj) {
    dispatch(activeReminderC(obj.id, obj));
  }

  const testSearch = async () =>{
    let start = '';
    let end = '';
    let arr = [];

    if(kindofFilter == 0){
     start = new Date (defaultDate.year.value+'-'+defaultDate.month.value+'-'+defaultDate.day.value).toLocaleDateString('es-MX'); // 8/19/2020
     end = new Date (secondDate.year.value+'-'+secondDate.month.value+'-'+secondDate.day.value).toLocaleDateString('es-MX'); // 8/19/2020
    } else {
     start = new Date (defaultDate.year.value+'-'+defaultDate.month.value+'-'+1).toLocaleDateString('es-MX'); // 8/19/2020
     end = new Date (defaultDate.year.value+'-'+defaultDate.month.value+'-'+31).toLocaleDateString('es-MX'); // 8/19/2020
    }
    arr[0] = start + ' ' + '00:00:00';
    arr[1] = end + ' ' + '23:59:59';
    setRangeDate(arr);
    Search(arr);

  }
  return (
    <div className="content">
      <div className="row">
        <div style={{ padding: 10 , height:'130px' }} className="card col-3">
          <Select
            isMulti
            name="values"
            value={selectUser}
            onChange={(e) => handleChangeSelect(e)}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Seleccionar un usuario"
            isLoading={usersFormat ? false : true}
            options={usersFormat}
          />
          {/* <button
            disabled={(!selectUser || !selectOption) ? true : false}
            className="btn btn-info" onClick={(e) => Search()}>Buscar</button> */}
        </div>
       

          <div style={{ marginLeft: 10,paddingLeft:'2%',paddingBottom:'3%' }} className="card col-8">
          <div className='row' style={{display:'flex',justifyContent:'flex-end', borderBottom:'1px solid black', width:'100%' , paddingBottom:'20px'}} >
          <span style={{paddingRight:'5%'}}>Vista : </span>
          <span onClick={(e) =>setActiveOption(0)} style={{paddingRight:'5%',textDecoration: activeOption == 0 ?'none':'underline',fontWeight: activeOption == 0 ?'bold':'normal'}}>Ultimas 4 semanas </span>
          <span onClick={(e) =>setActiveOption(1)}style={{paddingRight:'5%',textDecoration: activeOption == 1 ?'none':'underline',fontWeight: activeOption == 1 ?'bold':'normal'}}>Ultimos 3 meses </span>
          <span onClick={(e) =>setActiveOption(2)}style={{paddingRight:'5%',textDecoration: activeOption == 2 ?'none':'underline',fontWeight: activeOption == 2 ?'bold':'normal'}}>Ultimos 6 meses </span>
          <span onClick={(e) =>setActiveOption(3)} style={{paddingRight:'5%',textDecoration: activeOption == 3 ?'none':'underline',fontWeight: activeOption == 3 ?'bold':'normal'}}>Ultimos 12 meses </span>
          <span onClick={(e) =>setActiveOption(4)} style={{paddingRight:'5%',textDecoration: activeOption == 4 ?'none':'underline',fontWeight: activeOption == 4 ?'bold':'normal'}}>Busqueda personalizada </span>
          </div>
          <div className='row' style={{marginTop:'2%'}} >
            {activeOption == 4 &&
            
            <div className="col-6" style={{borderRight:'1px solid black'}}>
            <div className="row">  <input type="radio" checked={kindofFilter==0 ? true : false} onChange={(e) => setKindOfFilter(0)}  value="range" name="range" /><span style={{marginLeft:'3px'}}>Filtro por rango</span></div> 
            <div className="row">
              <div className="col-3">Desde :</div>
              <div className="col">
             <Select
            name="days"
            value={defaultDate.day}
            onChange={(e) => changeCurrentDate(e,'day')}
            // className="basic-multi-select"
            classNamePrefix="select"
            // placeholder="1"
            // isLoading={usersFormat ? false : true}
            options={daysOptions}
          /> 
          
        
              </div>

              <div className="col-4">
              <Select
            name="months"
            value={monthOptions.find((mont) => mont.value == defaultDate.month.value)}
            onChange={(e) => changeCurrentDate(e,'month')}
            // className="basic-multi-select"
            classNamePrefix="select"
            // placeholder="Diciembre"
            // isLoading={usersFormat ? false : true}
            options={monthOptions}
          /> 
              </div>

              <div className="col-3">
              <Select
            name="years"
            value={defaultDate.year}
            onChange={(e) => changeCurrentDate(e,'year')}
            // className="basic-multi-select"
            classNamePrefix="select"
            // placeholder={currentYear}
            // isLoading={usersFormat ? false : true}
            options={yearOptions}
          /> 
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-3">Hasta :</div>
              <div className="col">
             <Select
            name="days"
            value={secondDate.day}
            onChange={(e) => changeSecondDate(e,'day')}
            // className="basic-multi-select"
            classNamePrefix="select"
            placeholder="1"
            // isLoading={usersFormat ? false : true}
            options={daysOptions}
          /> 
          
        
              </div>

              <div className="col-4">
              <Select
            name="months"
            value={monthOptions.find((mont) => mont.value == secondDate.month.value)}
            onChange={(e) => changeSecondDate(e,'month')}
            // className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Diciembre"
            // isLoading={usersFormat ? false : true}
            options={monthOptions}
          /> 
              </div>

              <div className="col-3">
              <Select
            name="years"
            value={secondDate.year}
            onChange={(e) => changeSecondDate(e,'year')}
            // className="basic-multi-select"
            classNamePrefix="select"
            placeholder={currentYear}
            // isLoading={usersFormat ? false : true}
            options={yearOptions}
          /> 
              </div>
            </div>
            <div className="row">  <input type="radio" value="bymonth"  checked={kindofFilter==1 ? true : false} onChange={(e) => setKindOfFilter(1)} name="bymonth" /><span style={{marginLeft:'3px'}}>Filtro por mes</span></div> 


            <div className="row">
            

              <div className="col-4">
              <Select
            name="months"
            value={monthOptions.find((mont) => mont.value == defaultDate.month.value)}
            onChange={(e) => changeCurrentDate(e,'month')}
            // className="basic-multi-select"
            classNamePrefix="select"
            // placeholder="Diciembre"
            // isLoading={usersFormat ? false : true}
            options={monthOptions}
            /> 
              </div>

              <div className="col-3">
              <Select
            name="years"
            value={defaultDate.year}
            onChange={(e) => changeCurrentDate(e,'year')}
            // className="basic-multi-select"
            classNamePrefix="select"
            // placeholder={currentYear}
            // isLoading={usersFormat ? false : true}
            options={yearOptions}
          /> 
              </div>
            </div>
           

              </div>
        

      }
        

            <div className="col"
             style={{display:'flex',justifyContent:'flex-end',alignItems:'flex-end'}}
             >




               {/* <button
                        style={{ float: 'right', textDecoration: 'white' }}
                        className="mt-n5 btn btn-sm btn-info" onClick={(e) => exporta(e)}
                      >
                        <CSVLink {...csvReport}><span>Exportar</span>
                        </CSVLink>
                      </button> */}



               <button
               disabled={(results ? results.monitoreo ? results.monitoreo.length > 0  ? false : true  : true : true)}
            className="btn btn-info"
            onClick={(e) => exporta(e)} >
               <CSVLink {...csvReport}><span>Exportar</span>
               </CSVLink>

            </button>
              <button
               disabled={(!selectUser) ? true : false}
            className="btn btn-warning" onClick={(e) => testSearch()}>Filtrar</button>
                </div>
          </div>
          </div>
        
      </div>
      {show &&
        [loading ?
          <FadeLoader color={color} loading={loading} css={override} size={50} />
          :
          <>
            <div className="row">
              <div className="col-12">
                <div className="mt-5 col d-flex justify-content-end">
                  {/* <AddEditContacts
                    route={'Section'}
                    load={load}
                    userSection={init}
                    openContacts={true} {...props} /> */}
                </div>


                <div className="searchBarTable">
                  <SearchBar consult={(e) => consult(e)} />
                </div>

                {(results.quantity_tickets || results.quantitybio) != 0 &&

                  <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '-50px', marginLeft: '100px', marginBottom: '30px' }}>
                    <div style={{ fontSize: '20px', fontWeight: '600' }}>
                      Bitacoras : {results.quantitybio}
                    </div>
                    <div style={{ fontSize: '20px', fontWeight: '600' }}>
                      Tickets : {results.quantity_tickets}
                    </div>

                  </div>
                }

                {/* <h6>Contactos</h6> */}

                <div className="content">
                  <div>
                    {/* <button
                      style={{ marginTop: '-16%', marginLeft: '80%', textDecoration: 'white' }}
                      className="btn btn-info" onClick={(e) => exporta(e)}
                    >
                      <CSVLink {...csvReport}><span>Exportar</span>
                      </CSVLink>
                    </button> */}
                  </div>
                  {!loading ?
                    <Row>

                      <div
                        className="ag-theme-alpine"
                        style={{ height: "100%", width: "100%" }}
                      >
                        <AgGridReact
                          // context={{
                          //   editReminder,
                          //   allUsers: users,
                          //   route: props.route
                          // }}
                          rowData={results.monitoreo}
                          defaultColDef={{ resizable: true }}
                          rowHeight={40}
                          domLayout="autoHeight"
                          onGridReady={onGridReady}
                          onFirstDataRendered={onFirstDataRendered}
                          frameworkComponents={frameworkComponents}
                          pagination={true}
                          paginationPageSize={10}
                          paginationNumberFormatter={function (params) {
                            return params.value.toLocaleString();
                          }}
                          rowSelection="multiple"
                        >
                          <AgGridColumn
                            headerName="Contacto"
                            // cellRenderer="slotName"
                            field="fullname_contact"
                            sortable={true}
                            width="250"
                          ></AgGridColumn>
                          <AgGridColumn
                            headerName="Usuario"
                            // cellRenderer="slotName"
                            field="fullname_user"
                            sortable={true}
                            width="250"
                          ></AgGridColumn>

                          <AgGridColumn
                            headerName="Accion"
                            // cellRenderer="slotName"
                            field="action_user"
                            sortable={true}
                            width="250"
                          ></AgGridColumn>


                          <AgGridColumn
                            headerName="Fecha"
                            field="created_at"
                            width="250"
                            sortable={true}
                            cellRenderer="slotDate"
                          />


                        </AgGridReact>
                      </div>
                    </Row>
                    :
                    <div className="row mt-2">
                    </div>
                  }

                </div>



              </div>
            </div>
            {/* {init.type === 'Administrador' ?
            <TableReminderColleges />
              :
              <div></div>
            } */}
          </>
        ]
      }
    </div>
  )
}



export const SlotDate = function (props) {

  // const previewReminder = (obj) => {
  //   props.context.previewReminder(obj);
  // }
  function showDate(obj, dateBD, time) {
    let datef = moment(dateBD).locale("es-mx").format("ddd D MMMM, YYYY ");
    let timef = moment(dateBD).locale("es-mx").format("h:mm A");
    datef = datef[0] + datef.slice(1);
    datef = datef.replace(".", "");
    let tag = (
      <p
        //  onClick={(e) => previewReminder(obj) }
        className="Inter">
        {datef}
        {timef}
      </p>
    );
    return dateBD ? tag : "";
  }
  return <>{showDate(props.data, props.data.created_at)}</>;
};


export const SlotName = function SlotName(props) {
  const dispatch = useDispatch();
  const { rowIndex, value } = props
  const click = (data) => {
    let newObj = { ...data, id: data.id_last_contact, fullname: data.name }
    // dispatch( activeContact(newObj) );
  }
  const array = props.agGridReact.props.rowData;
  const showName = (obj) => {
    let text = " ";
    const { data } = obj;
    // if(data.origin === true){
    //   text = value;
    // } else {
    //   text = " ";
    // }
    text = value;
    return text;
  }
  return (
    <>
      <Link onClick={(e) => click(props.data)} to={"contacts/" + (props.data.id_contact) + "/reminders"}>
        <span>{showName(props)}</span>
      </Link>
    </>
  )
}

