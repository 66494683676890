
import Dashboard from '../src/pages/Dashboard';
import Users from './pages/Users/Users';
import Contacts from '../src/pages/Contacts';
import Colleges from '../src/pages/Colleges';
import UsersForm from '../src/pages/Form/UsersForm';
import * as TiIcons from "react-icons/ti";
import * as MDicons from "react-icons/md";
import * as FAIcons from "react-icons/fa";
import * as BIicons from "react-icons/bi";
import * as GRicons from 'react-icons/gr';
import * as RiIcons from 'react-icons/ri';
import RemindersSection from './pages/ReminderSection/RemindersSection';
import CollegesLocales from './pages/CollegesLocales';
import TrackingSection from './pages/Tracking/TrackingSection';

// import LogoProspection from 'resources/images/prospection.png';
import React from 'react'
import HistorySection from './pages/History/HistorySection';
import Leads from './pages/Leads/Leads';
import LeadsHook from './customHook/LeadsHook';
import Accounting from './pages/Accounting/Accounting';
import Tickets from './pages/Tickets/Tickets';
import Analitics from './pages/Analitics/Analitics';
import ProspectionSection from './pages/ProspectionSection/ProspectionSection';
import ApplicationSection from './pages/AplicationsSection/ApplicationSection';
import PreviewForm from './pages/Form/Preview/PreviewForm.jsx';

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <MDicons.MdDashboard size={20} />,
    component: Dashboard,
    layout: "/admin",
    show:false
  },
  {
    path: "/contacts",
    name: "Contactos",
    icon: <TiIcons.TiContacts size={20} />,
    component: Contacts,
    layout: "/admin",
    show:false
  },
  {
    
    path: "/SectionProspections",
    name: "Prospecciones",
    icon: <i class='bx bxs-binoculars bx-sm'></i>,
    component: ProspectionSection,
    layout: "/admin",
    show:false
  },
  {
    path: "/SectionApplications",
    name: "Aplicaciones",
    icon:<i class='bx bx-file-blank bx-sm'></i>,
    component: ApplicationSection,
    layout: "/admin",
    show:false
  },
  {
    path: "/Tracking",
    name: "Seguimiento",
    icon: <FAIcons.FaRegEye size={20} />,
    component: TrackingSection,
    layout: "/admin",
    show:false
  },
  {
    path: "/module/analitics",
    name: "Analitics",
    icon: <FAIcons.FaAmilia size={20} />,
    component: Analitics,
    layout: "/admin",
    show:false
  },
  {
    path: "/moduleTickets",
    name: "Tickets",
    icon: <FAIcons.FaRegIdCard size={20} />,
    component: Tickets,
    layout: "/admin",
    show:false
  },

  {
    path:"/History",
    name: "Historial",
    icon :<RiIcons.RiFileHistoryFill size={20}/>,
  component: HistorySection,
 layout:"/admin",
 show:false
  },
  {
    path: "/SectionReminders",
    name: "Recordatorios",
    icon: <BIicons.BiTime size={20} />,
    component: RemindersSection,
    layout: "/admin",
    show:false,
  },
  {
    path: "/leads",
    name: "Leads",
    icon:   <LeadsHook />,
    component: Leads,
    layout: "/admin",
    show:false,
  },
  {
    path: "/accounting",
    name: "Contabilidad",
    icon:   <FAIcons.FaFileInvoiceDollar  disabled size={20}/>,
    component: Accounting,
    layout: "/admin",
    show:true,
  },
  {
    path: "/colleges",
    name: "Colegios",
    icon: <MDicons.MdSchool size={20} />,
    component: Colleges,
    layout: "/admin",
    show:false
  },
  {
    path: "/localColleges",
    name: "Colegios Locales",
    icon: <FAIcons.FaSchool size={20} />,
    component: CollegesLocales,
    layout: "/admin",
    show:false

  },

   {
     path: "/users",
     name: "Usuarios",
     icon: <FAIcons.FaUser size={20} />,
     component: Users,
     layout: "/admin",
     show:true
   },

  {
    path: "/formUsers",
    name: "Usuarios Form",
    icon: <FAIcons.FaWpforms size={20} />,
    component: UsersForm,
    layout: "/admin",
    show:false
  },

  {
    path: "/previewForm",
    name: "Vista previa de la forma",
    icon: <FAIcons.FaFolderMinus size={20} />,
    component: PreviewForm,
    layout: "/admin",
    show:false
  },

  // {
  //   path: "/reports",
  //   name: "Reportes",
  //   icon: "nc-icon nc-bell-55",
  //    component: Report,
  //   layout: "/admin",
  // },
];
export default routes;
