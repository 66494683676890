
import { types } from "../../../types/types";


// }
const initialState = {
    remindersCollege :[],
    active: null,
}
export const remindersCollegesReducer = ( state = initialState,action ) => {
    switch (action.type) {
            case types.remindersCollActive:
                localStorage.setItem( 'remindersCollegeActive', JSON.stringify(action.payload));
                return {
                    ...state,
                    active: {
                        ...action.payload
                    }
                }
            case types.remindersCollLoad:
                return {
                    ...state,
                    remindersCollege: [...action.payload]
                }
        default:
            return state;
    }

}