import React from 'react'
import '../styles/GlobalStyles.css';
import '../cssResponsive/loginSection/loginSection.css'
import { useForm } from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import { Row, Col, Button, Form, Alert } from 'react-bootstrap';
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux';
import { callLogin  } from '../actions/auth/auth';
import logo from '../resources/images/logoLogin.png'
function Login() {
    const alert = useAlert();
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.ui);
    const { msgError } = useSelector(state => state.ui);
    const { register, handleSubmit, errors } = useForm();
    function onSubmit(data) {
        dispatch(callLogin(data));
    }

    return (
        <div className='responsiveLogin'>
            <div className="">
                <Row className="justify-content-md-center mt-5">
                    <Col className="login" xs={12} md={4}>
                        <Row className="mt-5">
                            <img className='responsiveImg' style={{ display: 'block', margin: 'auto' }} width="100" src={logo} alt="Logo"></img>
                        </Row>
                        <Row className="justify-content-md-center responsiveForm mt-5">
                            <Form style={{ width: '50%' }} onSubmit={handleSubmit(onSubmit)}>
                                {msgError &&
                                    (<Row style={{ margin: 'auto' }} className=" responsiveerror">
                                        <div className="alert alert-danger" role="alert">
                                            {msgError}
                                        </div>
                                    </Row>
                                    )
                                }
                                <Form.Group controlId="formGroupEmail responsiveEmail">
                                    <Form.Label className="">Email</Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        ref={register({
                                            required: true,
                                            validate: (input) => isEmail(input), // returns true if valid
                                        })}
                                        type="email" name="email" placeholder="Email"
                                        style={{ borderColor: errors.email && "red" }}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formGroupPassword">
                                    <Form.Label>Contraseña</Form.Label>
                                    <Form.Control
                                        autoComplete="off"
                                        ref={register({
                                            required: true,
                                            minLength: 6,
                                        })}
                                        style={{ borderColor: errors.password && "red" }}
                                        type="password" name="password" placeholder="Contraseña" />
                                </Form.Group>
                                <Row style={{display:'flex',justifyContent:'center'}} className="mt-1">
                                    <Button
                                        disabled={loading}
                                        variant="info"
                                        type="submit" >Entrar
                                    </Button>
                                </Row>
                            </Form>
                        </Row>
                    </Col>
                    <Row>
                    </Row>
                </Row>
            </div>
        </div>
    )
}

export default Login
