import React, { useState } from 'react'
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom';

export default function AnaliticsView() {

    const { pathname } = useLocation();

  return (
    
    <div style={{ minHeight: '0px' }} className="pb-0 content">
    <div style={{ marginTop: '-20px' }} className=" mt-3sc-bdVaJa styles__Nav-sc-19n49a3-0 gOZeoI">
     <Link className={['/module/analitics'].includes(pathname) ?
       'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active Inter600' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl Inter600'}
       to={"/module/analitics"}>Analitics</Link>

     <Link className={['/module/analitics/seccion'].includes(pathname) ?
       'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active Inter600' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl Inter600'}
       to={"/module/analitics/seccion"}>Sección</Link>

    
       </div>
 </div>
  )
}
