
import axios from 'axios';
import { constaApi } from '../../../constants/constants';
import { setError,removeError, startLoading, finishLoading } from "../../../actions/uiNotificactions/ui";

export const loadRemindersColleges = async  (id) => {
        // dispatch( startLoading() );
    const contactReminders = [];
    const {data} = await axios.post(constaApi+'reminders/colleges',{id:id})
    data.forEach(child => {
        contactReminders.push({
            id:child.id,
            ...child
        });
    });
        return contactReminders;
}
export const loadAllRemindersColleges = async  (id) => {
    // dispatch( startLoading() );
const contactReminders = [];
const {data} = await axios.get(constaApi+'reminders/all/colleges/'+id)
data.forEach(child => {
    contactReminders.push({
        id:child.id,
        ...child
    });
});
    return contactReminders;
}

export const loadAllRemColleges = async  (id) => {
    // dispatch( startLoading() );
const contactReminders = [];
const {data} = await axios.get(constaApi+'reminders/allColleges')
data.forEach(child => {
    contactReminders.push({
        id:child.id,
        ...child
    });
});
    return contactReminders;
}