var countriesBoardingSchools = [
    {
        name: 'USA'
    },
    {
        name: 'Canada'
    },
    {
        name: 'UK'
    },
    {
        name: 'Irlanda'
    },
    {
        name: 'Francia'
    },
    {
        name: 'Alemania'
    },
    {
        name:'España'
    },
    {
        name:'Portugal'
    },
    {
        name:'Italia'
    },
    {
        name:'Belgica'
    },
    {
        name:'Suiza'
    },
    {
        name:'Austria'
    },
    {
        name:'Australia'
    },
    {
      name:'Japon'
    },
    {
      name:'Croacia'
    },
    {
      name:'Cyprus'
    },
    {
        name:'Bahamas'
    }
];
var countriesSchoolD = [
    {
        name:'Canada'
    }
]
var countriesSummer = countriesBoardingSchools;
export { countriesBoardingSchools, countriesSchoolD,countriesSummer }
