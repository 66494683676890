// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../resources/uncheck.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../resources/check.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-radio-checkbox{display:inline-flex;align-items:center;cursor:pointer;font-family:arial}.custom-radio-checkbox__show--checkbox{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___})}.custom-radio-checkbox>.custom-radio-checkbox__input{display:none}.custom-radio-checkbox>.custom-radio-checkbox__show{display:inline-block;width:15px;height:15px;margin-right:.5rem;background-size:cover}.custom-radio-checkbox>.custom-radio-checkbox__input:checked+.custom-radio-checkbox__show--checkbox{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_1___})}`, "",{"version":3,"sources":["webpack://./src/styles/checkStyle.css"],"names":[],"mappings":"AACA,uBACI,mBAAA,CACA,kBAAA,CACA,cAAA,CACA,iBAAA,CAIJ,uCACI,wDAAA,CAIJ,qDACI,YAAA,CAIJ,oDACI,oBAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA,CACA,qBAAA,CAIJ,oGACI,wDAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
