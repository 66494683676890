var states = 
[
    { "clave": "AGS", "nombre": "Aguascalientes" },
    { "clave": "BC",  "nombre": "Baja California" },
    { "clave": "BCS", "nombre": "Baja California Sur" },
    { "clave": "CHI", "nombre": "Chihuahua" },
    { "clave": "CHS", "nombre": "Chiapas" },
    { "clave": "CMP", "nombre": "Campeche" },
    { "clave": "CMX", "nombre": "CDMX"},
    { "clave": "edoMex", "nombre": "Estado de mexico"},
    { "clave": "COA", "nombre": "Coahuila" },
    { "clave": "COL", "nombre": "Colima" },
    { "clave": "DGO", "nombre": "Durango" },
    { "clave": "GRO", "nombre": "Guerrero" },
    { "clave": "GTO", "nombre": "Guanajuato" },
    { "clave": "HGO", "nombre": "Hidalgo" },
    { "clave": "JAL", "nombre": "Jalisco" },
    { "clave": "MCH", "nombre": "Michoacán" },
    { "clave": "MOR", "nombre": "Morelos" },
    { "clave": "NAY", "nombre": "Nayarit" },
    { "clave": "NL",  "nombre": "Nuevo León" },
    { "clave": "OAX", "nombre": "Oaxaca" },
    { "clave": "PUE", "nombre": "Puebla" },
    { "clave": "QR",  "nombre": "Quintana Roo" },
    { "clave": "QRO", "nombre": "Querétaro" },
    { "clave": "SIN", "nombre": "Sinaloa" },
    { "clave": "SLP", "nombre": "San Luis Potosí" },
    { "clave": "SON", "nombre": "Sonora" },
    { "clave": "TAB", "nombre": "Tabasco" },
    { "clave": "TLX", "nombre": "Tlaxcala" },
    { "clave": "TMS", "nombre": "Tamaulipas" },
    { "clave": "VER", "nombre": "Veracruz" },
    { "clave": "YUC", "nombre": "Yucatán" },
    { "clave": "ZAC", "nombre": "Zacatecas" } 
];
export {states}