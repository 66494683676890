import React, { useEffect, useState } from 'react'
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { constaApi } from '../../constants/constants';
import axios from 'axios';


import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { css } from "@emotion/react";


import '../../styles/StylesPaidsSection.css';
import TicketModalPays from './TicketModalPays';


import PulseLoader from "react-spinners/PulseLoader";
import { SlotLastActivityTime } from '../../components/contactComponents/TicketsSection/TableTickets.jsx';
import { SlotTime } from '../../components/contactComponents/TicketsSection/TableTickets.jsx';
import { SlotActivity } from '../../components/contactComponents/TicketsSection/TableTickets.jsx';
import SearchBar from '../../components/GeneralComponents/SearchBar';


export default function Pays() {

    // VARIABLES

    const [rows, setRows] = useState([]);
    const [rowsClosed, setRowsClosed] = useState([]);

    const [frameworkComponents, setFramwrokw] = useState({
        slotLastActivityTime:SlotLastActivityTime,
        slotTime: SlotTime,
        slotActivity: SlotActivity

    });


    // modal
    const [show, setShow] = useState(false);
    const [editing,setEditing] = useState(false);
    const [idassigned,setIDassigned] = useState(null);
    let user = JSON.parse(localStorage.getItem('user'));
    const [data, setData] = useState([]);
    const [loadEdit, setLoadEdit] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [titleButton, setTitleButton] = useState('Añadir ticket');
    const [load, setLoad] = useState(false);


          // params of Search
  const [paramTableOne , setParamTableOne] = useState("");
  const [paramTableTwo , setParamTableTwo] = useState("");
  const [gridApi, setGridApi] = useState();

  const [columnApi, setColumnApi] = useState();



    const override = css`
    display: inline;
    border-color: red;
    `;

    useEffect(()=>{
        getTickets();
    },[])



    // Methods

    useEffect(()=>{

        if(paramTableOne){
            quickSearch(paramTableOne);
        }
    },[paramTableOne])


    async function quickSearch(value) {
		let objx = gridApi;
		value === 'keyWordSeccret302' ? objx.api.setQuickFilter("") : objx.api.setQuickFilter(value);
		setGridApi(objx);
		let rowsDisplay = objx.api.rowRenderer.rowModel.rowsToDisplay;
		let result;
		if (rowsDisplay) {
			result = await rowsDisplay.map(row => {
				let { data } = row;
				return data;
			})
			// props.newObj(result);
		}
	}


    // Methods used in a table of QuickSearch
	const onGridReady = (params) => {
		setGridApi(params);
		setColumnApi(params);
	}

    const getTickets = () => {
        axios.get(constaApi+'getTickets/module/paids', {
            headers: {
                "Accept": "application/json"
            }
        }).then(function (response) {
            let { data } = response;
            setRows(data.open);
            setRowsClosed(data.close);
            // setRows(data);
        //    loadRating();
        }).catch(error =>{
        });
    }

    const closeModals = () => {
        getTickets();
        handleClose();
        setEditing(false);
      }
    const screenOffLoading = () => {
        setLoad(false);
        setTitleButton('Añadir ticket');
        handleShow();
        setLoadEdit(false);
      }

    //   const showTicket = () =>{
    //     console.log('showTicket',data);
    //   }
    const whereIam = () => {
        return 'Seguimiento';
      }

      const showTicket = ({data}) => {
        setLoadEdit(true);
        setEditing(true);
    
        axios.post(constaApi + 'showTicketPaidTicket', { id: data.id})
          .then(function (response) {
            setData(response.data);

            setTimeout(screenOffLoading, 1000);
          }).finally(function (response) {
          });
      }


      const ragCellClassRules = {
		'rag-green-outer': (params) => params.value == 'Inicio',
		'rag-amber-outer': (params) => params.value == 'Intermedio',
		'rag-red-outer': (params) => params.value == 'Final',
	};

    const cellClass = (params) => {
		let back = params.value === 'Inicial' ? 'rag-aqua' : params.value === 'Intermedio' ? 'rag-blue' :
			params.value === 'Final' ?  'rag-green' : 'rag-aqua';
		return back;
	};

    const searchOnTableOne = (e) => {
        console.log('e',e);
        let fullnameFormated  = e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        e.target.value === "" ?  setParamTableOne('keyWordSeccret302') :  setParamTableOne(fullnameFormated);
    
      }
    
    return (
        <div className='content'>
             {loadEdit &&
        <center>
          <PulseLoader color={'lightgreen'} loading={true} css={override} size={16} />
        </center>
      }

      



                        <h4> Abiertos </h4>

                        <div className="searchBarTable">
          <SearchBar consult={(e) => searchOnTableOne(e)} />
        </div>

            <div 
              className="ag-theme-alpine"
              style={{ height: '100%', width: '100%' }}
              >

            <AgGridReact
            				onGridReady={onGridReady}
                rowData={rows}
                rowHeight={40}
                cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                domLayout="autoHeight"
                frameworkComponents={frameworkComponents}
                rowClassRules={{
                    'colorGrayAG': function (params) {
                        var backColor = params.data.color;
                        return params.data.color === 0;
                    },
                    'colorWhiteAG': 'data.color === -1',

                }}
                suppressRowTransform={true}
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={function (params) {
                    return params.value.toLocaleString();
                }}
                rowSelection="multiple"
            >
                <AgGridColumn
                	onCellClicked={showTicket}
                    field="who"
                    headerName="Nombre"
                    wrapText={true}
                    filter="agTextColumnFilter"
                    width={300}
                />
         
                <AgGridColumn
                    onCellClicked={showTicket}
                    field="name_program"
                    headerName="Programa"
                    wrapText={true}
                    sortable={true}
                    filter="agTextColumnFilter"
                    width={200}
                />
                <AgGridColumn
                    onCellClicked={showTicket}
                    headerName="Colegio"
                    field="name_college" width={250}
                    wrapText={true}
                    filter="agTextColumnFilter"
                    cellRenderer="slotProspection"
                />
              
                <AgGridColumn
                    onCellClicked={showTicket}
                    headerName="Estatus"
                    filter="agTextColumnFilter"
                    field="status_ticket" width={250} />

                <AgGridColumn
                    onCellClicked={showTicket}
                    headerName="Estatus ticket"
                    filter="agTextColumnFilter"
					cellClassRules={ragCellClassRules}
                    cellClass={cellClass}
                    field="status_paid" width={250} />

<AgGridColumn
					filter="agTextColumnFilter"

					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Fecha creación" sortable={true} field="created_at"
					cellRenderer="slotTime" width="300" />


<AgGridColumn

					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Fecha Ultima iteracción" sortable={true} field="lastUp"
					cellRenderer="slotTime" width="300" />

				{/* Last Activity */}
				<AgGridColumn

					cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
					headerName="Ultima interacción" cellRenderer="slotActivity" sortable={true} field="activities" width="300" />





                {/* <span style={{textTransform:'none'}}>Nombre</span>
                        <span style={{textTransform:'none', paddingLeft:'18%'}}>Prospeccion</span>
                        <span style={{textTransform:'none',paddingLeft:'8%'}}>Colegio</span>
                        <span style={{textTransform:'none',paddingLeft:'8%'}}>Pais</span>
                        <span style={{textTransform:'none',paddingLeft:'12%'}}>Status</span>
                         </th>   
        */}
            </AgGridReact>
            </div>




            <h4> Cerrados </h4>


            <div 
              className="ag-theme-alpine"
              style={{ height: '100%', width: '100%' }}
              >

            <AgGridReact
                rowData={rowsClosed}
                rowHeight={40}
                cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                domLayout="autoHeight"
               
                rowClassRules={{
                    'colorGrayAG': function (params) {
                        var backColor = params.data.color;
                        return params.data.color === 0;
                    },
                    'colorWhiteAG': 'data.color === -1',

                }}
                suppressRowTransform={true}
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={function (params) {
                    return params.value.toLocaleString();
                }}
                rowSelection="multiple"
            >
                <AgGridColumn
                	onCellClicked={showTicket}
                    field="fullname_contact"
                    headerName="Nombre"
                    wrapText={true}
                    filter="agTextColumnFilter"
                    width={300}
                />
         
                <AgGridColumn
                    onCellClicked={showTicket}
                    field="name_program"
                    headerName="Programa"
                    wrapText={true}
                    sortable={true}
                    filter="agTextColumnFilter"
                    width={200}
                />
                <AgGridColumn
                    onCellClicked={showTicket}
                    headerName="Colegio"
                    field="name_college" width={250}
                    wrapText={true}
                    filter="agTextColumnFilter"
                    cellRenderer="slotProspection"
                />
              
                <AgGridColumn
                    onCellClicked={showTicket}
                    headerName="Estatus"
                    filter="agTextColumnFilter"
                    field="status_ticket" width={250} />

                <AgGridColumn
                    onCellClicked={showTicket}
                    headerName="Estatus ticket"
                    filter="agTextColumnFilter"
                    cellClassRules={ragCellClassRules}
                    cellClass={cellClass}
                    field="status_paid" width={250} />


                {/* <span style={{textTransform:'none'}}>Nombre</span>
                        <span style={{textTransform:'none', paddingLeft:'18%'}}>Prospeccion</span>
                        <span style={{textTransform:'none',paddingLeft:'8%'}}>Colegio</span>
                        <span style={{textTransform:'none',paddingLeft:'8%'}}>Pais</span>
                        <span style={{textTransform:'none',paddingLeft:'12%'}}>Status</span>
                         </th>   
        */}
            </AgGridReact>
            </div>

            {show == true &&
          <TicketModalPays editing={editing}  idassigned={idassigned} user={user} whereIam={whereIam()} data={data} closeModal={(e) => closeModals()} show={show} />
        }
        </div>
    )
}
