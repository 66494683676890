import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, } from "react-router";
import {
  BrowserRouter as Router, Switch,
  Route, Link, useLocation
} from 'react-router-dom';
import swal from 'sweetalert';
import axios from 'axios';
import { constaApi } from '../constants/constants';
import NotificationAlert from "react-notification-alert";
import { removeMessage } from '../actions/uiNotificactions/ui';
import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  InputGroup,
  Popover,
  OverlayTrigger,
  FormControl,
  Tabs,
  Tab,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { activeContact } from '../actions/contacts/contacts/contacts';
import Select from "react-select";
import StarRatings from 'react-star-ratings';
import Loader from 'react-spinners/PulseLoader';


export default function ContactsView(props) {
  const dispatch = useDispatch();
  const notificationAlert = useRef();
  const { msgError } = useSelector(state => state.ui);
  let { id } = useParams();
  const { pathname } = useLocation();
  const [fullName, setFullName] = useState(`${props.contact.name} ${props.contact.father_lastname} ${props.contact.mother_lastname ?? " "}`);
  const [rating, setRating] = useState(props.contact.rating ? parseInt(props.contact.rating) : 0);
  const [users, setUsers] = useState([]);
  const [theUser, setTheUser] = useState();
  const [modalAdvisor, setModalAdvisor] = useState(false);
  const [modalMultipleContacts, setModalMultipleContactsl] = useState(false);
  const [init] = useState(JSON.parse(localStorage.getItem('user')) || { logged: false });
  const [rowData, setRowData] = useState([]);
  const [selectValue, setSelectValue] = useState([]);
  const [loadAdvisor,setLoadAdvisor] = useState(false);

  const {
    register: student,
    handleSubmit,
    errors,
    formState,
    reset: reset,
  } = useForm({});
  useEffect(() => {
    consultRow();
    if (props.contact.contact_multiple_users) {
      let aux = [];
      props.contact.contact_multiple_users.map(cM => {
        let obj = {
          label: cM.name + " " + cM.father_lastname + " " + cM.mother_lastname ?? "",
          value: cM.name + " " + cM.father_lastname + " " + cM.mother_lastname ?? "",
          id: cM.id,
        }
        aux.push(obj);
      })
      setSelectValue(aux);
    }
  }, [])
  useEffect(() => {
    if (msgError != null) {
      notification('success', msgError);
      dispatch(removeMessage());
    }

  }, [msgError])

  async function consultRow() {
    // setModalEdit(false);
    // setModal(false);
    const headers = {
      "Accept": "application/json"
    };
    let data = { type: init.type };
    await axios.get(constaApi + 'selectUsers', {
    }).then(function (response) {
      let array = [];
      Object.keys(response.data).map(item => {
        let obj = {
          label: response.data[item].fullname,
          value: response.data[item].fullname,
          id: response.data[item].id
        };
        array.push(obj);
      });
      setRowData(array);
    });
  }
  const loadRating = async () => {
    await axios.get(constaApi + 'contacts/' + props.contact.id, {
      headers: {
        "Accept": "application/json"
      }
    }).then(function (response) {
      setRating(parseInt(response.data[0].rating));
    }).catch(error => {
    });
  }
  const changeRating = (e) => {
    let { contact } = props;
    contact.rating = e;
    swal({
      title: "Estas seguro?",
      text: "Usted modificara la calificacion de este contacto",
      icon: "info",
      dangerMode: true,
      buttons: ["Borrar rating", "Si"],
    })
      .then((willDelete) => {
        if (willDelete) {
          axios.post(constaApi + 'contact/update', contact, {
            headers: {
              "Accept": "application/json"
            }
          }).then(function (response) {
            loadRating();
          }).catch(error => {
          });
        } else {
          // Rating a Cero
          contact.rating = 0;
          swal({
            title: "Asignar calificación",
            text: "Usted modificara la calificación a cero",
            icon: "warning",
            dangerMode: true,
            buttons: ["No", "Si"],
          })
            .then((willDelete) => {
              if (willDelete) {
                axios.post(constaApi + 'contact/update', contact, {
                  headers: {
                    "Accept": "application/json"
                  }
                }).then(function (response) {
                  loadRating();
                }).catch(error => {
                });
              } else {
                swal("Operacion cancelada!");
              }
            });
        }
      });
  }
  const notification = (type, message) => {
    let place = "tc";
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    }
    notificationAlert.current.notificationAlert(options);
  }
  const changeModalAdvisor = () => {
    setLoadAdvisor(true);
    axios.post(constaApi + "allUsers")
      .then(function (response) {
        setUsers(response.data);
        setModalAdvisor(!modalAdvisor);
        setLoadAdvisor(false)
      });
    setTheUser(props.contact.id_advisor);
  }
  const closeModal = async () => {
    setModalAdvisor(false);
  };
  const onSubmit = (data) => {
  }
  const changeAdvisor = (e) => {
    setTheUser(e.target.value);
  }
  const updatedContact = (option = 0) => {
    let obj = users.filter(us => us.id == theUser);
    if (option == 0) {
      obj = {
        contact_user: selectValue ?? null,
        id: props.contact.id
      }
    } else {
      obj = {
        name_advisor: obj[0].name + " " + obj[0].father_lastname + " " + obj[0].mother_lastname,
        id_advisor: obj[0].id,
        id: props.contact.id,
      };
    }

    axios.post(constaApi + 'contact/update', obj)
      .then(function (response) {
        if (response.status === 200) {
          dispatch(activeContact(response.data));
          setTheUser(null);
          closeModal();
          props.updateRoute();

          // notification('info', 'Datos actualizados correctamente');
        } else {
          // notification('danger', 'Ocurrio un error,por favor contacta a soporte');
        }
      });
  }
  function drawButton(obj) {
    let tag = '';
    if (obj.contact_multiple_users) {
      if (obj.contact_multiple_users.length > 0) {
        tag = <span>SI</span>;

      } else {
        tag =
          <button
            onClick={(e) => setModalMultipleContactsl(!modalMultipleContacts)}
            type="button"
            className="montse btn-success btn-sm"
          >
            +
          </button>

      }
    } else {
      tag = <span>Borrar cache</span>;
    }
    return tag;
  }
  const handleChange = (e) => {
    setSelectValue(e);
  }
  const closeModalMultiple = () => {
    setModalMultipleContactsl(!modalMultipleContacts);
  }
  const showName = (id = null, fullname = "") => {

    const PopoverComponent = (obj) => {
      return (<Popover id="popover-basic">
        <Popover.Content>
          <span>{obj}</span>
        </Popover.Content>
      </Popover>)
    }
    let n = fullname ? fullname.split(" ") : " ";
    let tag = '';
    n = n[0].charAt(0).toUpperCase() + n[1].charAt(0).toUpperCase() + n[2].charAt(0).toUpperCase();
    tag = <>
      <OverlayTrigger trigger={["hover", "hover"]} placement="top"
        overlay={PopoverComponent(fullname)}>
        <span onClick={(e) => setModalMultipleContactsl(!modalMultipleContacts)}
          className="sc-caSCKo ZomcK styles__Company-sc-103gogw-0 jdbeFRTwo">{n}</span>
      </OverlayTrigger>
    </>;
    return tag;
  }
  return (
    <>
      <div style={{ minHeight: '0px' }} className="pb-0 content">
        <div className="row">

          {/* Column of name contact and rating */}
          <div className='col'>

           <div className='row'>
            
             {/* Column name contact */}
             <div className='col'>
            <span className="Inter400">{fullName}</span>
            </div>

            {/* Column rating */}
            <div className='col'>
            <StarRatings
                rating={rating}
                starDimension={'20px'}
                starEmptyColor={'gray'}
                starRatedColor={'#ffd200'}
                starHoverColor={'#ffd200'}
                starSpacing="2px"
                changeRating={(e) => changeRating(e)}
                numberOfStars={5}
                name='rating'
              />
            </div>

           </div>

          </div>

          {/* Column of advisor and members */}
          <div className='col'>

            <div className='row'>

              {/* Column of advisor */}
              <div className='col'>

              <button
                  onClick={(e) => changeModalAdvisor()}
                  type="button"
                  className="montse btn-disabled btn-sm"
                >
                  { 
                  loadAdvisor ?
                  <Loader color="white" />
                :
                [props.contact.name_advisor]
                }
                </button>
            
              </div>

              {/* Column of members */}
              <div className='col'>
              {
              props.contact.contact_multiple_users.length > 0 ?
                [props.contact.contact_multiple_users.map(mU => {
                  return (
                    <span>{showName(mU.id, mU.fullname ? mU.fullname : (mU.name + " " + mU.father_lastname + " " + mU.mother_lastname))}</span>
                  )
                })]
                :
                <button
                  onClick={(e) => setModalMultipleContactsl(!modalMultipleContacts)}
                  type="button"
                  className="montse btn-success btn-sm"
                >
                  +
                </button>
            }
              </div>

            </div>
          </div>


          
          {/* <div className="col">
            <div style={{ float: 'left', marginRight: '10px' }} >
              <span className="Inter400" style={{}}>{fullName}</span>
            </div>
            <div>
              <StarRatings
                rating={rating}
                starDimension={'20px'}
                starEmptyColor={'gray'}
                starRatedColor={'#ffd200'}
                starHoverColor={'#ffd200'}
                starSpacing="2px"
                changeRating={(e) => changeRating(e)}
                numberOfStars={5}
                name='rating'
              />
            </div>
          </div>
          <div style={{ marginRight: '5%' }} className="col-2">
            <span onClick={(e) => changeModalAdvisor()}>
              <Form.Control name="last_modification"
                disabled
                autoComplete="off" className="formGray" type="text"
                placeholder="Ultima fecha"
                style={{ letterSpacing: '0.2px', cursor: 'pointer' }}
                value={props.contact.name_advisor}
              />
            </span>
          </div>
          <div style={{ marginRight: '45%' }}>
            {
              props.contact.contact_multiple_users.length > 0 ?
                [props.contact.contact_multiple_users.map(mU => {
                  return (
                    <span>{showName(mU.id, mU.fullname ? mU.fullname : (mU.name + " " + mU.father_lastname + " " + mU.mother_lastname))}</span>
                  )
                })]
                :
                <button
                  onClick={(e) => setModalMultipleContactsl(!modalMultipleContacts)}
                  type="button"
                  className="montse btn-success btn-sm"
                >
                  +
                </button>
            }
          </div>
 */}
        </div>




        <NotificationAlert ref={notificationAlert} />

        <div style={{ marginTop: '10px' }} className=" mt-3sc-bdVaJa styles__Nav-sc-19n49a3-0 gOZeoI">
          <Link className={['/contacts/' + id + '/Interdashboard'].includes(pathname) ?
            'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active montse' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl montse'}
            to={"/contacts/" + id + "/Interdashboard"}>Dashboard</Link>

          <Link className={['/contacts/' + id + '/bio'].includes(pathname) ?
            'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active montse' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl montse'}
            to={"/contacts/" + id + "/bio"}>Bitácora</Link>

          <Link className={['/contacts/' + id + '/profile'].includes(pathname) ?
            'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active montse' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl montse'}
            to={"/contacts/" + id + "/profile"}>Perfil</Link>

          <Link className={['/contacts/' + id + '/references'].includes(pathname) ?
            'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active montse' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl montse'}
            to={"/contacts/" + id + "/references"}>Referencias</Link>

          {/* <Link className={['/contacts/' + id + '/family'].includes(pathname) ?
            'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active montse' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl montse'}
            to={"/contacts/" + id + "/family"}>Familia</Link> */}

          <Link className={['/contacts/' + id + '/docs'].includes(pathname) ?
            'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active montse' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl montse'}
            to={"/contacts/" + id + "/docs"}>Doc</Link>

          <Link className={['/contacts/' + id + '/reminders'].includes(pathname) ?
            'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active montse' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl montse'}
            to={"/contacts/" + id + "/reminders"}>Recordatorios</Link>

          <Link className={['/contacts/' + id + '/tickets'].includes(pathname) ?
            'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active montse' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl montse'}
            to={"/contacts/" + id + "/tickets"}>Tickets</Link>



          <Link className={['/contacts/' + id + '/prospection'].includes(pathname) ?
            'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active montse' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl montse'}
            to={"/contacts/" + id + "/prospection"}>Prospecciones</Link>

          <Link className={['/contacts/' + id + '/applications'].includes(pathname) ?
            'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active montse' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl montse'}
            to={"/contacts/" + id + "/applications"}>Aplicaciones</Link>

          <Link className={['/contacts/' + id + '/trackings'].includes(pathname) ?
            'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl active montse' : 'mr-4 styles__NavLink-sc-19n49a3-1 iGbtBl montse'}
            to={"/contacts/" + id + "/trackings"}>Seguimientos</Link>
          {/* <span>
              <button
                className="Inter btn-sm bgformGray"
               >{props.contact.name_advisor} </button>                 </span> */}
        </div>
      </div>


      {/* Modal Advisor */}
      <Modal
        show={modalAdvisor}
        dialogClassName="modalMax"
        onHide={closeModal}
      >
        <Modal.Header style={{ height: "60px" }} closeButton>
          <Modal.Title
            style={{
              fontFamily: "Inter",
              marginTop: "5px",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Cambiar Advisor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5F6", border: "0px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Row>
                <Col className="col-6">
                  <Form.Label className="formGray">Usuario</Form.Label>
                  <Form.Control
                    onChange={(e) => changeAdvisor(e)}
                    autoComplete="off"
                    name="advisor"
                    value={theUser}
                    as="select"
                    size="sm"
                    custom
                  >
                    <option disabled value="" selected></option>
                    {users.map((pro) => (
                      <option key={pro.id} value={pro.id}>
                        {pro.name} {pro.father_lastname} {pro.mother_lastname}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <Button
                  className="float-right mb-3 mr-2"
                  type="button"
                  onClick={(e) => updatedContact(1)}
                  variant="info"
                >
                  Guardar
                </Button>
                <Button
                  onClick={closeModal}
                  className="float-right mb-3 mr-2 montse btnBee"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
      {/* End modal Advisor */}


      {/* Modal MultipleContacts */}
      <Modal
        show={modalMultipleContacts}
        dialogClassName="modal-90w"
        onHide={closeModalMultiple}
      >
        <Modal.Header style={{ height: "60px" }} closeButton>
          <Modal.Title
            style={{
              fontFamily: "Inter",
              marginTop: "5px",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Agregar Responsables
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5F6", border: "0px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid">
              <Row>
                <Col className="col-6">
                  <Form.Label className="formGray">Responsables</Form.Label>
                  {rowData &&
                    <Select
                      isMulti
                      name="values"
                      value={selectValue}
                      onChange={(e) => handleChange(e)}
                      // defaultValue={[rowData[0]]}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Seleccionar Responsables.."
                      options={rowData}
                    />
                  }
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <Button
                  className="float-right mb-3 mr-2"
                  type="button"
                  onClick={(e) => updatedContact(0)}
                  variant="info"
                >
                  Guardar
                </Button>
                <Button
                  onClick={closeModalMultiple}
                  className="float-right mb-3 mr-2 montse btnBee"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
      {/* End modal Advisor */}


    </>


  )
}
