import { secret_token, constaApi } from '../../../../constants/constants';
import React ,{ useState , useEffect } from 'react'
import *  as GRicons from "react-icons/gr";

function PreviewImages(props) { 
    const {path_doc} = props;
    const extension = path_doc ? path_doc.split(".")[1] : "";
    const [file,setFile] = useState();
    const [pdf,setPdf] = useState();
    let obj = "Sin image.jpg";
    const maximImg = (e) => {
        // document.getElementById("btnSample").click();
        let data ={
            direction : e.target.currentSrc,
            nameImg: e.target.alt,
            value : path_doc,
            file: file
        }
        props.clickEvent(data);
    }
    useEffect(()=>{
        const petition = (path_doc) => {
            var myImage = document.getElementById('img'+path_doc);
            const src = constaApi + 'ticketActiviti/'+path_doc;
            const options = {
                headers: {
                             "Accept": "application/json",
                             "Authorization": "Basic " + secret_token,
                             "token" : "2e2e222e2xe",
                             credentials: "same-origin",
                         }
            };
            console.log('SENDIN OPTIONS',options)
            fetch(src, options)
            .then(res => res.blob())
            .then(blob => {
                setFile(blob);
                let fileURL = URL.createObjectURL(blob);
                setPdf(fileURL);
                var objectURL = URL.createObjectURL(blob);
                if(myImage){
                    myImage.src = objectURL;
                }
            });
        }
        petition(path_doc);
    },[])
    function convertPdf(){
        if(file){
            let fileURL = URL.createObjectURL(file);
            return fileURL;
        } else {
            return "img/src";
        }
    }
    return (
        <>
        {extension == "pdf"
        ?
        <a> <GRicons.GrDocumentPdf onClick={(e) => maximImg(e)} style={{display:'block',marginTop:'20px', color: '#497cff' }} 
        size={38} /></a>
        :
        <img id={'img'+path_doc} onClick={(e) => maximImg(e)} style={{display:'block',marginTop:'20px' ,width:'100px',height:'100px'}} alt={props.path_doc} src={obj}></img>
        }
        </>
    )
  }
// END SLOT Slot Preveiw


export default PreviewImages