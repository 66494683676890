import TableApplication from '../../components/ApplicationSectionComponent/TableApplication'
import SearchBar from '../../components/GeneralComponents/SearchBar';
import React,{useState} from 'react'

export default function ApplicationSection() {
    const [param,setParam] = useState(null);
    const[totalStudents,setTotalStudents] = useState(0);

    const consult = (e) => {
        e.target.value === "" ?  setParam('keyWordSeccret302') :  setParam(e.target.value);
    }
    const updateCount = (e) => {
        if(e){
            setTotalStudents(e.length);
        }
    }
    return (
        <div className="content">
        <div className="row">
        <div className="col-11">
        <div className="searchBarTable">
        <span style={{position:'absolute',left:'35%',top:'1%'}}
        >Estudiantes aplicando: {totalStudents}</span>
        <SearchBar consult={(e) => consult(e)}/>
        </div>
            <TableApplication  param={param} updateCount={(e) => updateCount(e)}/>
        </div>
        
        </div>
        </div>
    )
}
