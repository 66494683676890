import { types } from "../../types/types";

const initialState = {
    users :[],
    active: null,
}
export const userReducer = ( state = initialState,action ) => {
    switch (action.type) {
            case types.userActive:
                return {
                    ...state,
                    active: {
                        ...action.payload
                    }
                }
            case types.UsersLoad:
                return {
                    ...state,
                    users: [...action.payload]
                }
        default:
            return state;
    }

}