import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import { constaApi } from '../../constants/constants';
import NotificationAlert from "react-notification-alert";
import {
  Row,
  Col,
} from "reactstrap";

import { Button, Modal, Form, InputGroup , Popover,OverlayTrigger } from 'react-bootstrap';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Skeleton from 'react-loading-skeleton';
import * as FAIcons from "react-icons/fa";
import * as SIcons from "react-icons/si";
import moment from 'moment';
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { municipios } from '../../MyJson/municipiosJson';


var _ = require('lodash');

function LeadsModule(props) {
  useEffect(() => {
    // loadLeds
    loadLeds();
    consultCountries();
    thisDay();
        present();

  }, [])

  // [variables]  - > data
  const [leads, setLeads] = useState([]);
  const notificationAlert = useRef();
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [frameworkComponents, setFramwrokw] = useState({slotComments:SlotComments,  slotDate: SlotDate, slotActions: SlotActions });
  const [optionalFname , setOptionalFname] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal5, setModal5] = useState(false);
  const [validField, setvalidField] = useState(true);
  const [validFieldTwo, setvalidFieldTwo] = useState(true);
  const [validFieldThree, setvalidFieldThree] = useState(true);
  const [validFieldFour, setvalidFieldFour] = useState(true);
  const [validFieldFive, setvalidFieldFive] = useState(true);
  const [validFieldSix, setvalidFieldSix] = useState(true);
  const [extra, setExtra] = useState(false);
  const [flagOther, setFlagOther] = useState("");
  const [typeSourc, setTypeSourc] = useState("");
  const [optionSourc, setOptioonSourc] = useState("");
  const [localColleges, setLocalColleges] = useState([]);
  const [country, setCountry] = useState();
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const [CollegSource, setCollegSource] = useState("");
  const [blocked, setBlocked] = useState(false);
  const [aux, setAux] = useState();
  const [timeReminder, setTimeReminder] = useState("");
  const [dateReminder, setDateReminder] = useState("");
  const [now, setNow] = useState();
  const [nowTime, setNowTime] = useState();
  const [notificationReminder, setNotificationReminder] = useState();
  const [notes, setNotes] = useState("");
  const [idContact, setIDContact] = useState(null);
  const [countries, setCountries] = useState([]);
  const [foreignCol, setForeignCol] = useState([]);
  const [addrtype, setAddrtype] = useState(["Papa", "Mama", "Hermano/Hermana", "Otro"]);
  const Add = addrtype.map(Add => Add);
  const [leadToeleiminate,setLeadToeliinate] = useState();
  const sources = ['Recomendado por', 'Internet', 'Instagram', 'Facebook', 'Otro', 'Forma de contacto'];
  const [program, setProgram] = useState(["Boarding School", "School District", "Summer Camp", "Language School", "University/College", "Work & Travel"]);
  const years = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
  const styles = {
    container: {
      width: "80%",
      margin: "0 auto",
    },
    input: {
      width: "100%",
    },
  };
  const { register: student, getValues, handleSubmit, errors, formState, reset: reset } = useForm({
    // defaultValues:{
    //     email: 'example@email.com'
    // }, 
    mode: 'onChange'
  }
  );
  const { register: reference, handleSubmit: handleSubmitReference, errors: errorsReference, formState: formStateReference, reset: resetReference
  } = useForm({ mode: 'onChange' });

  const [lead,setLead] = useState({
    program:'',year:'',name:'',father_lastname:'',mother_lastname:'',birthday:'',grade:'',cicly:'',college:'',email:'',phone:'',
    country:'',state:'',city:'',source:'',schoool:'',other_School:''
  });
  
  const [leadReference,setLeadReference] = useState({
    name:'',
    type_ref: '',
    name_ref: '',
    father_lastname:'',
    mother_lastname:'',
    email:'',
    phone:'',
    city:'',
    state:'',
  })
  // Methods

  function changeOptionFname () {
    setOptionalFname(!optionalFname);
  }
  function thisDay(){
    let date2 = new Date();
    date2.setDate(date2.getDate() + 1);
    setTimeReminder("09:00:00");
    setNotificationReminder("-0 hour");
    setDateReminder(date2.toISOString().substr(0, 10).replace('T', ' '));
}
function present() {
  setNow(moment().format("YYYY-MM-DD"));
  setNowTime(moment().format("HH:mm"));
}

function setDatareference (e,option){
  const { value } = e.target;
    let obj = {};
    switch(option){
      case 'name':
        obj ={...leadReference,name:value};
        break;
      case 'type_ref':
        obj ={...leadReference,type_ref:value};
        break;
        case 'name_ref':
          obj ={...leadReference,name_ref:value};
          break;
        case 'father_lastname':
          obj ={...leadReference,father_lastname:value};
          break;
          case 'mother_lastname':
            obj ={...leadReference,mother_lastname:value};
            break;
            case 'email':
              obj ={...leadReference,email:value};
              break;
              case 'phone':
                obj ={...leadReference,phone:value};
                break;
                case 'city':
                  obj ={...leadReference,city:value};
                  break;
                  case 'state':
                    obj ={...leadReference,state:value};
                    break;
                }
    setLeadReference(obj);
}
  function setData (e,option){
    const { value } = e.target;
    let obj = {};
    switch(option){
      case 'name':
        obj ={...lead,name:value};
        break;
      case 'program':
        obj ={...lead,program:value};
        break;
        case 'year':
          obj ={...lead,year:value};
          break;
        case 'father_lastname':
          obj ={...lead,father_lastname:value};
          break;
          case 'mother_lastname':
            obj ={...lead,mother_lastname:value};
            break;
            case 'grade':
              obj ={...lead,grade:value};
              break;
              case 'cicly':
                obj ={...lead,cicly:value};
                break;
                case 'schoool':
                  obj ={...lead,schoool:value};
                  break;
                  case 'other_School':
                    obj ={...lead,other_School:value};
                    break;
                }
    setLead(obj);
  }

  async function consultCountries() {
    await axios.get('https://restcountries.com/v3.1/all', {
        // headers: {
        //     Authorization: 'Bearer ' + auth,
        //     Accept: "application/json"
        // }
    }).then(async function (response) {
        let newCountries = await response.data.map( re => {
            return {
                name: re.translations.spa.common
            }
        });
        let or = _.orderBy(newCountries,'name','asc');
        setCountries(or);
      
    });
}
function convertGrade (grad) {
  let convertedGrade = '';
  switch(grad){
    case 'Quinto de Primaria':
      convertedGrade = 'Grado 5';
      break;
      case 'Sexto de Primaria':
        convertedGrade = 'Grado 6';
        break;
        case 'Primero de Secundaria':
          convertedGrade = 'Grado 7';
          break;
          case 'Segundo de Secundaria':
            convertedGrade = 'Grado 8';  
          break;
            case 'Tercero de Secundaria':
              convertedGrade = 'Grado 9';
              break;
              case 'Primero de Preparatoria':
                convertedGrade = 'Grado 10';
                break;
                case 'Segundo de Preparatoria':
                  convertedGrade = 'Grado 11';
                  break;
                  case 'Tercero de Preparatoria':
                    convertedGrade = 'Grado 12';
                    break;
                    default :
                    convertedGrade = 'Grado 1';
                    break;
  }
return convertedGrade;
}
  function convertLead (objeto) {
    let transformName = objeto.name.split(" ");
    let nameCompost = {};
    if(transformName.length <= 3){
      nameCompost = {
        name: transformName[0] ?? '',
        fName : transformName[1] ?? '',
        mName : transformName[2] ?? ''
      }
      // Un solo nombre
    }
    else if (transformName.length > 3){
      nameCompost = {
        name: (transformName[0] + " " ?? '') + (transformName[1] ?? '') ,
        fName : transformName[2] ?? '',
        mName : transformName[3] ?? ''
      }
    }
    setLeadToeliinate(objeto.id);
    let obj={
      program:'',year:'',
      name:nameCompost.name,
      father_lastname:nameCompost.fName,
      mother_lastname:nameCompost.mName,
      birthday:objeto.birthday?? '',
      grade:objeto.grade ? convertGrade(objeto.grade) : '',
      cicly:'',
      college:'',
      email:'',
      phone:'',
    country:'',
    state:objeto.state ?? '',
    city:objeto.city ?? '',
    source:''
    }

    let transformNameParents = objeto.nameParents?.split(" ");
    let nameCompostParents = {};
    if(transformNameParents.length <= 3){
      nameCompostParents = {
        name: transformNameParents[0] ?? '',
        fName : transformNameParents[1] ?? '',
        mName : transformNameParents[2] ?? ''
      }
      // Un solo nombre
    }
    else if (transformNameParents.length > 3){
      nameCompostParents = {
        name: (transformNameParents[0] + " " ?? '') + (transformNameParents[1] ?? '') ,
        fName : transformNameParents[2] ?? '',
        mName : transformNameParents[3] ?? ''
      }
    }
    let objRef = {
      name: nameCompostParents.name ?? '',
      type_ref: '',
      name_ref: '',
      father_lastname: nameCompostParents.fName ?? '',
      mother_lastname: nameCompostParents.mName ?? '',
      email: objeto.email ?? '',
      phone: objeto.phone ?? '',
      city: objeto.city ?? '',
      state: objeto.state ?? '',
    }
    setLeadReference(objRef);
    setLead(obj);
    setModal1(!modal1);

  }
  function changeTimeReminder(e) {
    let currendate = moment(dateReminder + " " + timeReminder).format("YYYY-MM-DD HH:mm");
    let nowDatecomparison = moment(now + " " + nowTime).format("YYYY-MM-DD HH:mm");
    let strings = e.target.value;
    strings = strings.charAt(1) + strings.charAt(2);
    let nowDate = moment(currendate).subtract(parseInt(strings), 'hour').format("YYYY-MM-DD HH:mm");
    if (nowDate < nowDatecomparison) {
      notification('warning', 'Cuidado,estas ingresando un rango de valor no permitido');
      setNotificationReminder("");
    } else {
      setNotificationReminder(e.target.value);
    }

  }

  function changeNotes(e) {
    setNotes(e.target.value);
  }
  function changeDate(e) {
    setDateReminder(e.target.value)
  }
  function changeTime(e) {
    setTimeReminder(e.target.value);
  }

  function confirmClose() {
    swal({
      title: "¿Desea cancelar el registro?",
      text: "Esto evitara que el contacto sea guardado",
      icon: "info",
      dangerMode: false,
      buttons: ["No", "Si"],
    }).then(async (willDelete) => {
      if (willDelete) {
        setModal5(false);
        setNotes();
      } else {
        swal("Operacion cancelada!");
      }
    });
  }
  function handlevalidFour(e) {
    if (e.target.value) {
      setvalidFieldFour(false);
    } else {
      setvalidFieldFour(true);
    }
  setDatareference(e,"name")
  }
  const showOtherReference = (e) => {
    if (e.target.value) {
      setvalidFieldFour(false);
    } else {
      setvalidFieldFour(true);
    }
    setDatareference(e,"name_ref")
  }
  function valid() {
    return validField;
  }
  function validTwo() {
    return validFieldTwo;
  }

  const showReference = (e) => {
    if ((addrtype[e.target.value]) == "Otro") {
      setExtra(true);
      setvalidFieldFour(true);
    } else {
      setExtra(false);
      setvalidFieldFour(false);
    }
    if (e.target.value) {
      setvalidFieldThree(false);
    } else {
      setvalidFieldThree(true);
    }
    setDatareference(e,"type_ref")
  }

  function changeoptionSo(e) {
    setOptioonSourc(e.target.value);
  }
  function changeColSource(e) {
    setCollegSource(e.target.value);
  }
  function changeTypeSo(e) {
    setTypeSourc(e.target.value);
  }
  function changeCountries(e, i) {
    setCountry(e.target.value);
  }
  function changeCities(e) {
    let val = e.target.value;
    let other = municipios[0];
    let aux = [];
    Object.keys(other).map((name, i) => {
      if (name === e.target.value) {
        aux = other[name];
      }
    })
    setCities(aux);
  }
  function handleValidEmail(e) {
    let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  }
  function handlevalidPhone(e) {
    let regex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    // if (!regex.test(e.target.value)) {
    //     setvalidFieldTwo(true);
    // } else {
    //     setvalidFieldTwo(false);

    // }

  }

  function changeFlag(e) {
    setFlagOther(e.target.value);
    setData(e,'schoool');
  }
  function handlevalidTwo(e) {
    if (e.target.value) {
      setvalidFieldTwo(false);
    } else {
      setvalidFieldTwo(true);
    }
  }
  function handlevalidSix(e) {
    if (e.target.value) {
      setvalidFieldSix(false);
    } else {
      setvalidFieldSix(true);
    }
  }
  function handleValid(e) {
    if (e.target.value) {
      setvalidField(false);
    } else {
      setvalidField(true);
    }
  }
  function handlevalidFive(e) {
    if (e.target.value) {
      setvalidFieldFive(false);
    } else {
      setvalidFieldFive(true);
    }
  }
  function notification(type, message) {
    let place = "tc";
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    }
    notificationAlert.current.notificationAlert(options);
  }

  async function onSubmit(data) {
    try {
      setBlocked(true);
      if (modal1 === true) {
        setAux({ ...lead });
        setModal1(false);
        setModal5(true);
        setBlocked(false);
      }
      if (modal5 === true) {
        setModal5(false);
        const { email, id, name, token, type } = JSON.parse(localStorage.getItem('user'));
        let selectValue = [{
          id: id,
          type: type,
          fullname: name,
          email: email
        }];
        let datex = dateReminder + " " + timeReminder;
        let obj = {
          id: null,
          id_college: null,
          college: null,
          subject: 'Recordatorio Automático',
          emailTo: selectValue ?? null,
          dateReminder: datex ?? null,
          timenotification: notificationReminder ?? null,
          notes: notes ?? null,
          departament: null,
          urgent: 0,
        };
        let auxTwo = {
          ...aux,
          id_advisor: id,
          name_advisor: name,
          type_source: typeSourc ?? "",
          option_source: optionSourc ?? "",
          college_source: CollegSource ?? ""
        }
        let auxThree = { ...auxTwo, reminder: { ...obj } };
        axios.post(constaApi + 'contacts/save', auxThree)
          .then(function (response) {
            setIDContact(response.data.id);
            dropLead(leadToeleiminate);
          });
        showModal2();
      }
      if (modal3 === true) {
        let datax = leadReference;
        datax.idContact = idContact;
        await axios.post(constaApi + 'references', datax)
          .then(function (response) {
            // Aqui guarda la referencia
            setLeadReference(
              {
                name:'',
                type_ref: '',
                name_ref: '',
                father_lastname:'',
                mother_lastname:'',
                email:'',
                phone:'',
                city:'',
                state:'',
              }
            );
          })
        showModal4();
        setBlocked(false);
      }
      reset();
      resetReference()
      
    }
    catch (error) {
      // notification(error);
    }
  }


  // function to view modals
  const showModal1 = function close() {
    setvalidField(true);
    setModal2(false);
    setModal3(false);
    setModal4(false);
    setModal1(true);
  };
  const showModal2 = function close() {
    setModal1(false);
    setModal2(true);
  };
  const showModal3 = function close() {
    setvalidFieldThree(true);
    setExtra(false);
    setModal1(false);
    setModal2(false);
    setModal4(false);
    setModal3(true);
  };
  const showModal4 = function close() {
    setModal1(false);
    setModal2(false);
    setModal3(false);
    setModal4(true);
  };
  const handleClose = function close() {
    reset();
    resetReference();
    setvalidField(true);
    setExtra(false);
    setModal1(false);
    setModal2(false);
    setModal3(false);
    setModal4(false);
    setModal5(false);
    setFlagOther("");
    setTypeSourc("");
    setOptioonSourc("");
    setCollegSource("");
  }



  const loadLeds = () => {
    axios.get(constaApi + 'getLeads', {
      headers: {
        "Accept": "application/json"
      }
    }).then(function (response) {
      let { data } = response;
      setLeads(data);
      // setLeads(response.data);
    }).catch(error => {
      // setLoading(false);
    });
  }
  const onGridReady = (params) => {
    setGridApi(params);
    setColumnApi(params);
  }
  function dropLead(id) {
    axios.post(constaApi + 'updateLead', { id: id }, {
      headers: {
        "Accept": "application/json"
      }
    }).then(function (response) {
      let { data } = response;
      setLeads(data);
    }).catch(error => {
      // setLoading(false);
    });
  }
  return (
    <>
      <div className="content">
        {!loading ? (
          <Row>
            <div
              className="ag-theme-alpine"
              style={{ height: "100%", width: "100%" }}
            >
              <AgGridReact
                context={{
                  dropLead,
                  convertLead
                  // completeReminder,
                  // editReminder,
                  // deleteReminder
                }}
                rowData={leads}
                rowHeight={40}
                domLayout="autoHeight"
                onGridReady={onGridReady}
                frameworkComponents={frameworkComponents}
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={function (params) {
                  return params.value.toLocaleString();
                }}
                rowSelection="multiple"
              >

                <AgGridColumn
                  headerName="Nombre"
                  field="name"
                  width="250"

                ></AgGridColumn>
                <AgGridColumn
                  headerName="Fecha Nacimiento"
                  field="birthday"
                  cellRenderer="slotDate"
                  width="200"
                />
                <AgGridColumn
                  headerName="Contacto desde"
                  field="fromweb"
                  width="200"
                />

                <AgGridColumn
                  headerName="Destino"
                  field="destiny"
                  width="200"
                />
                <AgGridColumn
                  headerName="Grado"
                  field="grade"
                  width="200"
                />
                <AgGridColumn
                  headerName="Nombre Padres"
                  field="nameParents"
                  width="200"
                />
                <AgGridColumn
                  headerName="Email"
                  field="email"
                  width="250"
                />
                <AgGridColumn
                  headerName="Telefono"
                  field="phone"
                  width="200"
                />
                <AgGridColumn
                  headerName="Ciudad"
                  field="city"
                  width="200"
                />
                <AgGridColumn
                  headerName="Estado"
                  field="state"
                  width="200"
                />
                <AgGridColumn
                  headerName="Comentarios"
                  field="comments"
                  width="200"
                  cellRenderer="slotComments"
                />
                <AgGridColumn
                  headerName="Fecha Alta"
                  field="created_at"
                  cellRenderer="slotDate"
                  width="200"
                />
                <AgGridColumn headerName="Acciones" cellRenderer="slotActions" width={220} />
              </AgGridReact>
            </div>
          </Row>
        ) : (
          <div className="row mt-2">
            <Skeleton width="60rem" height={30} count={10} />
          </div>
        )}
      </div>


      {/* Modals */}


      {/* FirstModal */}
      <Modal
        style={{ marginTop: '50px' }}
        show={modal1}
        dialogClassName="modal-90w"
        onHide={handleClose}
      >
        <Modal.Header style={{ height: '60px' }} closeButton>
          <Modal.Title style={{ fontFamily: 'Inter', marginTop: '5px', fontWeight: '600', fontSize: '18px' }}>Convertir contacto </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: '#F4F5F6', border: '0px' }}>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid">
              <Row>
                <Col className="col-4">
                  <Form.Label className="formGray">Programa</Form.Label>
                  <Form.Control  autoComplete="off" name="program" 
                  onChange={(e) => setData(e,'program')}
                  as="select" size="sm" custom>
                    <option disabled value="" selected></option>
                    {program.map(pro => (
                      <option key={pro} value={pro}>
                        {pro}
                      </option>
                    ))}
                  </Form.Control>
                  <p className='errores'>{errors.name && "Programa requerido"}</p>
                </Col>
                <Col className="col-2">
                  <Form.Label className="formGray">Año</Form.Label>
                  <Form.Control  autoComplete="off" name="year"
                   onChange={(e) => setData(e,'year')}
                    as="select" size="sm" custom>
                    <option disabled value="" selected></option>
                    {years.map(y => (
                      <option key={y} value={y}>
                        {y}
                      </option>
                    ))}
                  </Form.Control>
                  <p className='errores'>{errors.year && "Año requerido"}</p>
                </Col>
                <Col>

                </Col>
              </Row>
              <Row className="mt-1">
                <Col className="col-6">
                  <Form.Label className="formGray">Nombre</Form.Label>
                  <Form.Control 
                  name="name" 
                  onChange={(e) => setData(e,'name')}
                  value={lead.name}
                    autoComplete="off" className="formGray" type="text" placeholder="Ingrese su nombre"
                    style={{ ...styles.input, borderColor: errors.name && "red" }}
                  />
                  <p className='errores'>{errors.name && "Nombre requerido"}</p>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col className="col-6">
                  <Form.Label className="formGray">Apellido Paterno</Form.Label>
                  <Form.Control  
                  name="father_lastname"
                  onChange={(e) => setData(e,'father_lastname')}
                  value={lead.father_lastname}
                  
                    autoComplete="off" className="formGray" type="text" placeholder="Ingrese su primer apellido" />
                  <p className='errores'
                  >{errors.father_lastname && "Apellido requerido"}</p>
              <input type="checkbox" 
              onClick={(e) => changeOptionFname()}
              id="cbox2" value={optionalFname}/> <label for="cbox2">Opcional</label>
                </Col>
                <Col className="col-6">
                  <Form.Label className="formGray">Apellido Materno</Form.Label>
                  <Form.Control autoComplete="off" name="mother_lastname"
                  value={lead.mother_lastname}
                  onChange={(e) => setData(e,'mother_lastname')}
                    className="formGray" type="text" placeholder="Ingrese su segundo apellido" />
                  <p className='errores'
                  >{errors.mother_lastname && "Apellido requerido"}</p>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col className="col-6">
                  <Form.Label className="formGray">Fecha de nacimiento</Form.Label>
                  <Form.Control autoComplete="off" name="birthday"
                  value={lead.birthday}
                    className="formGray" type="date" placeholder="Ingrese su Fecha" />
                  <p className='errores'>{errors.date && "Fecha requerida"}</p>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col className="col-3">
                  <Form.Label className="formGray">Grado</Form.Label>
                  <Form.Control autoComplete="off" name="grade"
                  value ={lead.grade }
                  onChange={(e) => setData(e,'grade')}
                  as="select" size="sm" custom>
                    <option disabled value="" selected></option>
                    <option value="Grado 1">Grado 1</option>
                    <option>Grado 2</option>
                    <option>Grado 3</option>
                    <option>Grado 4</option>
                    <option>Grado 5</option>
                    <option>Grado 6</option>
                    <option>Grado 7</option>
                    <option>Grado 8</option>
                    <option>Grado 9</option>
                    <option>Grado 10</option>
                    <option>Grado 11</option>
                    <option>Grado 12</option>
                  </Form.Control>
                </Col>
                <Col className="col-3">
                  <Form.Label className="formGray">Ciclo escolar</Form.Label>
                  <Form.Control autoComplete="off" name="cicly"
                  onChange={(e) => setData(e,'cicly')} as="select" size="sm" custom>
                    <option disabled value="" selected></option>
                    <option value="2015-2016">2015 - 2016</option>
                    <option>2016 - 2017</option>
                    <option>2017 - 2018</option>
                    <option>2018 - 2019</option>
                    <option>2019 - 2020</option>
                    <option>2020 - 2021</option>
                    <option>2021 - 2022</option>
                    <option>2022 - 2023</option>
                    <option>2023 - 2024</option>
                    <option>2024 - 2025</option>
                    <option>2025 - 2026</option>
                    <option>2026 - 2027</option>
                    <option>2027 - 2028</option>
                    <option>2028 - 2029</option>
                    <option>2029 - 2030</option>
                    <option>2030 - 2031</option>
                  </Form.Control>
                </Col>
                <Col className="col-6">
                  <Form.Label className="formGray">Colegio</Form.Label>
                  <Form.Control autoComplete="off" name="schoool"
                    onChange={(e) => changeFlag(e,'schoool')}
                    className="formGray" type="text" placeholder="Ingrese su Colegio" as="select" size="sm" custom>
                    <option value="" selected disabled></option>
                    {localColleges &&
                      [localColleges.map(colL => {
                        return (
                          <option key={colL.id} value={colL.name}>
                            {colL.name}
                          </option>
                        )
                      })]
                    }
                    <option key="Otro" value="Otro">Otro</option>
                  </Form.Control>
                  <p className='errores'>{errors.school && "Colegio requerido"}</p>
                  <div className="mt-3 row">
                    {flagOther == 'Otro' &&
                      <div className="col">
                        <Form.Control autoComplete="off"
                          name="other_School"
                          onChange={(e) => setData(e,'other_School')}
                          className="formGray" type="text" placeholder="Ingrese el nombre de su colegio" />
                      </div>
                    }
                  </div>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col className="col-6">
                  <Form.Label className="formGray">Email</Form.Label>
                  <Form.Control autoComplete="off" name="email"
                    ref={student({
                      required: false,
                      pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
                    })}
                    className="formGray" type="email" placeholder="Ingrese su email" />
                  <p className='errores'>{errors.email && "Formato invalido"}</p>

                </Col>
                <Col className="col-6">
                  <Form.Label className="formGray">Telefono</Form.Label>
                  <Form.Control onChange={(e) => handlevalidPhone(e)} autoComplete="off" name="phone" ref={student({
                    pattern: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
                    maxLength: 10
                  })}
                    className="formGray" type="tel" placeholder="Ingrese su telefono" />
                  <p className='errores'>{errors.phone && "Formato invalido,solo 10 digitos"}</p>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col className="col-4">
                  <Form.Label className="formGray">Pais</Form.Label>
                  <Form.Control autoComplete="off" name="country" ref={student} as="select" size="sm" custom>
                    {/* <option value="Mexico">Mexico</option> */}
                    {countries.map(countri => (
                      <option ket={countri.name} value={countri.name}>
                        {countri.name}
                      </option>
                    ))}
                  </Form.Control>
                  <p className='errores'>{errors.country && "Pais requerido"}</p>
                </Col>
                <Col className="col-4">
                  <Form.Label className="formGray">Estado</Form.Label>
                  <Form.Control autoComplete="off"
                          name="state"
                          value={lead.state}
                          disabled
                          className="formGray" type="text" placeholder="Estado" />
                  <p className='errores'>{errors.state && "Estado requerido"}</p>
                </Col>
                <Col className="col-4">
                  <Form.Label className="formGray">Ciudad</Form.Label>
                  <Form.Control autoComplete="off"
                          name="city"
                          disabled
                          value={lead.city}
                          className="formGray" type="text" placeholder="Estado" />
                  <p className='errores'>{errors.state && "Estado requerido"}</p>
                </Col>
              </Row>
              <Row>
                <hr></hr>
                <Col className="col-12">
                  <Form.Label className="formGray">Source</Form.Label>
                </Col>
              </Row>
              <Row>
                <Col className="col-6">
                  <Form.Control
                    onChange={(e) => changeTypeSo(e)}
                    autoComplete="off" name="typeSourc" value={typeSourc} as="select" size="sm" custom>
                    <option disabled value="Forma de contacto" selected>Forma de contacto</option>
                    
                  </Form.Control>
                </Col>
              </Row>
              {typeSourc == 'Forma de contacto'
                &&
                <Row className="mt-2">
                  <Col className="col-6">
                    <Form.Label className="formGray">Opcion</Form.Label>
                    <Form.Control
                      onChange={(e) => changeoptionSo(e)}
                      autoComplete="off" name="typeSourc" value={optionSourc} as="select" size="sm" custom>
                      <option disabled value="" selected></option>
                      <option value="General" >General</option>
                      <option value="Colegio" >Colegio</option>
                    </Form.Control>
                  </Col>
                </Row>
              }
              {optionSourc == 'Colegio'
                &&
                <Row className="mt-2">
                  <Col className="col-8">
                    <Form.Control
                      onChange={(e) => changeColSource(e)}
                      autoComplete="off" name="typeSourc" value={CollegSource} as="select" size="sm" custom>
                      <option disabled value="" selected></option>
                      {foreignCol.map(fC => (
                        <option key={fC.name} value={fC.name}>
                          {fC.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
              }
            </div>
            <Row>

              <Col>
                <Button
                  disabled={
                    optionalFname ? 
                    !lead.name ? true :  !lead.program ? true : !lead.year ? true: false
                    :
                    !lead.name ? true : !lead.father_lastname ? true : !lead.program ? true : !lead.year ? true: false
                  }
                  className="float-right mb-3 mr-2" type="submit"
                  onSubmit={handleSubmit(onSubmit)}
                  variant="info">Guardar</Button>
                <Button onClick={handleClose}
                  className="float-right mb-3 mr-2 montse btnBee" >
                  Cancelar
                </Button>

              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
      


      {/* Second Modal */}
      <Modal
        style={{ marginTop: '50px', height: '500px', maxHeight: '604px' }}
        show={modal2}
        dialogClassName="modal-90w"
        onHide={handleClose}
      >
        <Modal.Header style={{ height: '60px' }} closeButton>
          <Modal.Title style={{ fontFamily: 'Inter', marginTop: '5px', fontWeight: '600', fontSize: '18px' }}>Agregar contacto</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: '#F4F5F6', border: '0px' }}>
          <div className="row mt-5"></div>
          <div className="row mt-5"></div>
          <div className="container">
            <div className="row">
              <div className="mx-auto">
                <h1 className="Inter600B">Contacto Guardado!</h1>
              </div>
            </div>
            <div style={{ marginTop: '-15px' }} className="row">
              <div className="mx-auto">
                <span className="Inter500" > ¿Qué mas te gustaría hacer?</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="mx-auto">
              <button className="btn btn-info Inter600" onClick={showModal3}>Agregar una referencia</button>

            </div>
          </div>
          <div className="row ">
            <div className="mx-auto">
              <button onClick={showModal1} className="btn btn-info Inter600">Agregar un contacto</button>
            </div>
          </div>
          <div className="row">
            <div className="mx-auto">
              <a className="Inter500B" onClick={handleClose}>Listo!</a>
            </div>
          </div>
          <div className="row mb-5"></div>
          <div className="row mb-5"></div>
          <div className="row mb-5"></div>
        </Modal.Body>
      </Modal>



      {/* Three Modal */}
      <Modal
        show={modal3}
        onHide={handleClose}
        dialogClassName="modal-90w"
        style={{ marginTop: '50px', height: '500px', maxHeight: '604px' }}
      >
        <Modal.Header style={{ height: '60px' }} closeButton>
          <Modal.Title style={{ fontFamily: 'Inter', marginTop: '5px', fontWeight: '600', fontSize: '18px' }}>Agregar referencia</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: '#F4F5F6', border: '0px' }}>
          <form onSubmit={handleSubmitReference(onSubmit)}>
            <div className="container-fluid">
              <Row>
                <Col className="col-6">
                  <Form.Label className="formGray">Tipo de referencia</Form.Label>
                  < select
                    name="type_ref"
                   value={leadReference.type_ref}
                   onChange={(e) => showReference(e)}
                    className="browser-default custom-select" >
                    {
                      Add.map((address, key) => <option key={key} value={key}>{address}</option>)
                    }
                    <option disabled value="" selected ></option>
                  </select >

                </Col>
                {extra &&
                  <Col className="col-6">
                    <Form.Label className="formGray">Referencia</Form.Label>
                    <Form.Control autoComplete="off" 
                     name="name_ref"
                    value={leadReference.name_ref}
                   onChange={(e)=> showOtherReference(e)}
                      className="formGray" type="text" placeholder="Ingrese la referencia" />
                  </Col>

                }
              </Row>

              <Row className="mt-1">
                <Col className="col-6">
                  <Form.Label className="formGray">Nombre</Form.Label>
                  <Form.Control 
                  name="name" 
                 onChange={(e) => handlevalidFour(e)} 
                 value={leadReference.name}
                  autoComplete="off" className="formGray" type="text" placeholder="Ingrese su nombre" />
                </Col>
              </Row>
              <Row className="mt-1">
                <Col className="col-6">
                  <Form.Label className="formGray">Apellido Paterno</Form.Label>
                  <Form.Control autoComplete="off" 
                   name="father_lastname" 
                  onChange={(e) => setDatareference(e,"father_lastname")} 
                  value={leadReference.father_lastname}
                   className="formGray" type="text" placeholder="Ingrese su primer apellido" />
                </Col>
                <Col className="col-6">
                  <Form.Label className="formGray">Apellido Materno</Form.Label>
                  <Form.Control autoComplete="off" 
                    name="mother_lastname" 
                   onChange={(e) => setDatareference(e,"mother_lastname")} 
                   value={leadReference.mother_lastname}
                     className="formGray" type="text" placeholder="Ingrese su segundo apellido" />
                </Col>
              </Row>
              <Row className="mt-1">
                <Col className="col-6">
                  <Form.Label className="formGray">Email</Form.Label>
                  <Form.Control autoComplete="off" 
                  name="email"
                 onChange={(e) => setDatareference(e,"email")} 
                   value={leadReference.email}
                  className="formGray" type="email" placeholder="Ingrese su email" />
                </Col>
                <Col className="col-6">
                  <Form.Label className="formGray">Telefono</Form.Label>
                  <Form.Control autoComplete="off" 
                   name="phone"
                  onChange={(e) => setDatareference(e,"phone")} 
                  value={leadReference.phone}
                   className="formGray" type="tel" placeholder="Ingrese su telefono" />
                </Col>
              </Row>
            </div>
            <Row className="mt-1">

              <Col>
                <Button
                  disabled={validFieldThree || validFieldFour}
                  className="float-right mb-3 mr-2" type="submit" variant="info">Guardar</Button>
                <Button onClick={handleClose}
                  className="float-right mb-3 mr-2 montse btnBee" >
                  Cancelar
                </Button>

              </Col>
            </Row>
          </form>
        </Modal.Body>

      </Modal>



      {/* Four Modal */}
      <Modal
        show={modal4}
        onHide={handleClose}
        dialogClassName="modal-90w"
        style={{ marginTop: '50px', height: '500px', maxHeight: '604px' }}
      >
        <Modal.Header style={{ height: '60px' }} closeButton>
          <Modal.Title style={{ fontFamily: 'Inter', marginTop: '5px', fontWeight: '600', fontSize: '18px' }}>Agregar Referencia</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: '#F4F5F6', border: '0px' }}>
          <div className="row mt-5"></div>
          <div className="container">
            <div className="row">
              <div className="mx-auto">
                <h1 className="Inter600B">Referencia Guardada!</h1>
              </div>
            </div>
            <div className="row">
              <div className="mx-auto">
                <span className="Inter500" > ¿Te gustaria agregar otra referencia?</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="mx-auto">
              <button className="btn btn-info Inter600" onClick={showModal3}>Agregar una referencia</button>

            </div>
          </div>
          <div className="row">
            <div className="mx-auto">
              <button onClick={showModal1} className="btn btn-info Inter600">Agregar un contacto</button>

            </div>
          </div>
          <div className="row ">
            <div className="mx-auto">
              <a className="Inter500B" onClick={handleClose}>Terminar</a>
            </div>
          </div>
          <div className="row mb-5"></div>
          <div className="row mb-5"></div>
          <div className="row mb-5"></div>
        </Modal.Body>

      </Modal>


      {/* Modal Five */}
      {/* Four Modal */}
      <Modal
        show={modal5}
        onHide={(e) => confirmClose()}
        dialogClassName="modal-90w"
        style={{ marginTop: '50px', height: '500px', maxHeight: '604px' }}
      >
        <Modal.Header style={{ height: '60px' }} closeButton>
          <Modal.Title style={{ fontFamily: 'Inter', marginTop: '5px', fontWeight: '600', fontSize: '18px' }}>Agregar Recordatorio</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: '#F4F5F6', border: '0px' }}>
          <NotificationAlert ref={notificationAlert} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mt-3">
              <Col>
                <Form.Label className="formGray">Notas</Form.Label>
                <InputGroup style={{ borderTop: '0', width: '100%', marginTop: '0px' }}>
                  <Form.Control
                    onChange={(e) => changeNotes(e)}
                    value={notes} as="textarea" placeholder="Escriba su mensaje..." rows={8} />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col >
                <Form.Control style={{ height: '100px', width: '180px' }}
                  onChange={(e) => changeDate(e)}
                  disabled
                  value={dateReminder} autoComplete="off" name="date"
                  className="formGray" type="date" placeholder="Ingrese su Fecha" />
              </Col>
              <Col className="mt-4">
                <Form.Control style={{ height: '30px', width: '150px' }}
                  onChange={(e) => changeTime(e)}
                  disabled
                  value={timeReminder} autoComplete="off" name="date"
                  className="formGray" type="time" placeholder="Ingrese su Fecha" />
              </Col>
              <Col className="col-5">
                <Form.Label className="formGray">Notificación</Form.Label>
                <Form.Control onChange={(e) => changeTimeReminder(e)}
                  autoComplete="off"
                  disabled
                  value={notificationReminder} name="type" as="select" size="sm" custom>
                  <option disabled selected value=""></option>
                  <option value="-0 hour">Misma hora</option>
                  <option value="-1 hour">1 Hora Antes</option>
                  <option value="-24 hour">1 Dia Antes</option>
                  <option value="-48 hour">2 Dias Antes</option>
                  <option value="-168 hour">1 Semana Antes</option>
                </Form.Control>
              </Col>
            </Row>
            <Button
              disabled={!notes}
              onSubmit={handleSubmit(onSubmit)}
              className="float-right mb-3 mr-2" type="submit" variant="info">Guardar</Button>
          </form>
        </Modal.Body>

      </Modal>
    </>



  )
}


// SLOTS
// Component SlotComments
export const SlotComments = function SlotComments(props) {
  moment.locale('es-mx')
  let { value } = props;
  const PopoverComponent = (comment) => {
    return (<Popover id="popover-basic">
        <Popover.Content>
       <span>{comment}</span>
        </Popover.Content>
    </Popover>)
  }
  const showComments = (comment) => {
   let tag =  <OverlayTrigger trigger={["hover", "hover"]} placement="top"
overlay={PopoverComponent(comment)}>
  <span >{comment}</span>
  </OverlayTrigger>;

  return tag;
  }
  return (
    <>
    {showComments(value)}
    </>
  )
}



// Component SlotDate
export const SlotDate = function SlotDate(props) {
  moment.locale('es-mx')
  let { value } = props;

  const dateToMoment = (date) => {
    return moment(date).locale('es-mx').format("ddd D MMMM, YYYY");
  }
  return (
    <>
      <span>{dateToMoment(value)}</span>
    </>
  )
}

// / Component SLotActions
export const SlotActions = function SlotActions(props) {
  function dropLead(id) {
    swal({
      title: "Estas seguro?",
      text: "Una vez eliminado,no podras recuperar este registro!",
      icon: "warning",
      dangerMode: true,
      buttons: ["No", "Si"],
    }).then(async (willDelete) => {
      if (willDelete) {
        props.context.dropLead(id);
      } else {
        swal("Operacion cancelada!");
      }
    });

  }
  function convertLead(obj) {
    props.context.convertLead(obj);
  }

  return (
    <>
      <a title='Convertir' ><SIcons.SiConvertio style={{ color: '#497cff' }} size={18} onClick={(e) => convertLead(props.data)} /> </a>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <a title='Eliminar' ><FAIcons.FaTrashAlt style={{ color: '#FE0000' }} size={18} onClick={(e) => dropLead(props.data.id)} /> </a>
    </>
  )
}


export default LeadsModule