import axios from 'axios';
import { constaApi } from '../../../../constants/constants';
import React, { useState } from 'react'
import {
    Button,
    Modal,
    Row,
    Col,
    Form,
    InputGroup,
    Popover,
    OverlayTrigger,
    FormControl,
    Tabs,
    Tab,
  } from "react-bootstrap";
import { useForm } from 'react-hook-form';


export default function ModalSubstatusFast(props) {

    const statusOptions = 
    
    props.obj.type == 'Prospeccion'
    ?
    
    [
        "Contacto Previo",
        "Contacto Formal",
        "Presentación",
        "Aclaración de dudas",
        "Decisión",
        // "Aplicar",
        // "Cancelar",
      ]
      :
      props.obj.type == 'Aplicacion'
       ?
       [
        "Aplicación",
        "Admisión",
        "Aceptación",
        "Visado",
        "Trámites de viaje",
        "Ultimos detalles",
        // "Activo",
        // "Cancelado",
      ]
      :
      [
        "Activo",
        "Materias",
        "Fall Check Point",
        "Diciembre",
        "Enero",
        "Spring Check Point",
        "Fin de curso",
        "Revalidación",
        "Reembolso",
        "Recomendacion",
        // "Termino"
      ];



    const [status,setStatus] = useState(props.obj.status?? "");
    const [substatus,setSubstatus] = useState(props.obj.substatus?? "");

    const [modalSubstatus,setModalSubstatus] = useState(true);
    const {
        register: student,
        handleSubmit,
        errors,
        formState,
        reset: reset,
      } = useForm({});

      const onSubmit = (data) => { 
        const { obj } = props;
        const newObj = {
          id : obj.id,
          status : status,
          substatus: substatus
        }

        const url = props.obj.type == 'Prospeccion'? "interDashboardFastUpdateProspection" : props.obj.type == 'Aplicacion' ? "interDashboardFastUpdateAplication" : "interDashboardFastTracking";


        axios.post(constaApi + url, newObj)
          .then(function (response) {
            closeModal();
            props.updateRoute();
          })
 
      }
      const changeStatus = (e) => {
        setStatus(e.target.value);
        setSubstatus(1)
      }
      const changeSubstatus = (e) => { 
        setSubstatus(e.target.value)
      } 
      const saveSubstatus = (e) => { 
        setStatus(e.target.value);
      } 

      const closeModal = (e) => {
        setModalSubstatus(false);
        props.closemodal();
       } 

  return (
    <div>

           {/* Modal Substatus */}
       <Modal
        show={modalSubstatus}
        // onHide={closeModal}
        dialogClassName="modal-90w"
      >
        <Modal.Header style={{ height: "60px" }} closeButton>
          <Modal.Title
            style={{
              fontFamily: "Inter",
              marginTop: "5px",
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            Modificar Status y Substatus
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#F4F5F6", border: "0px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid">
              <Row>
                <Col className="col-4">
                  <Form.Control
                    onChange={(e) => changeStatus(e)}
                    autoComplete="off"
                    name="status"
                     value={status}
                    as="select"
                    size="sm"
                    custom
                  >
                       <option disabled value="" selected>
                </option>
                    {statusOptions.map((st) => (
                      <option key={st} value={st}>
                        {st}
                      </option>
                    ))}
                  </Form.Control>
                </Col>

                <Col className="col-4">
                  <Form.Control
                    onChange={(e) => changeSubstatus(e)}
                    autoComplete="off"
                    name="year"
                     value={substatus}
                    as="select"
                    size="sm"
                    custom
                  >
                    <option value="1" selected>
                      1
                </option>
                <option key="2" value="2">
                        2
                      </option>
                      <option key="3" value="3">
                        3
                      </option>
                  </Form.Control>
                </Col>

              </Row>
            </div>
            <Row>
              <Col>
                <Button
                  className="float-right mb-3 mr-2 montse"
                  type="submit"
                  // onClick={(e) => saveSubstatus()}
                  variant="info"
                >
                  Guardar
                </Button>
                <Button
                  onClick={closeModal}
                  className="float-right mb-3 mr-2 montse btnBee"
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>

      {/* End modal Substatus */}

    </div>
  )
}
