import React, { Suspense, useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
import routes from '../../../routes';

// styles
import './modalUser.css';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import { constaApi } from '../../../constants/constants';

export default function ModalUsers(props) {

    const [loading, setLoading] = useState(false);
    const [modules, SetModules] = useState([]);
    const [userPermissions, setUserPermissions] = useState({
        modules: [],
    });
    let arrayResult = [];

    useEffect(() => {
        if (props.UsertoChangePermissions) {
            loadPermissions();
        }
        loadModules();
    }, [props])


    const loadPermissions = () => {
        setLoading(true)
        axios.post(constaApi + 'getPermissionsByContact', { id: props.UsertoChangePermissions.id }, {
        }).then(function (response) {
            const { data } = response;
            // setUserPermissions({})

            arrayResult = data.map((dato, index) => {
                return dato.module;
            })

            let objFinal = {
                modules: arrayResult
            }

            setUserPermissions(objFinal);
            setLoading(false)
        }).catch((e) =>
        {
            setLoading(false)

        });

    }

    const loadModules = () => {
        const route = routes.map(route => {
            return {

                name: route.name
            }
        })

        SetModules(route);
    }
    const handleClose = () => {


    }

    const changeCheckBox = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        const { modules } = userPermissions;

       

        // Case 1 : The user checks the box
        if (checked) {
            setUserPermissions({
                modules: [...modules, value],
            });
        }

        // Case 2  : The user unchecks the box
        else {
            setUserPermissions({
                modules: modules.filter((e) => e !== value),
            });
        }
    }

    const savePermissions = () => {
        const { id: whoAssigned } = JSON.parse(localStorage.getItem('user'));
        const permissions = userPermissions.modules.map(modul => {
            return {
                module: modul,
                active: 1,
                id_user: props?.UsertoChangePermissions?.id,
                whoAssigned: whoAssigned
            }
        })

        axios.post(constaApi + 'setPermissionsByContact', permissions, {
        }).then(function (response) {
            props.handleupdateTable();
            props.handleClose();
        });
    }



    return (
        <div>

            {/* Modal Permisos */}

            {/* FirstModal */}
            <Modal
                show={props.showModalPermissions}
                // dialogClassName="modal-90w"
                onHide={(e) => props.handleClose}

            >
                <Modal.Header style={{ height: '60px' }} closeButton>
                    <Modal.Title style={{ fontFamily: 'Inter', marginTop: '5px', fontWeight: '600', fontSize: '18px' }}>Modificar Permisos de {props?.UsertoChangePermissions?.fullname}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: '#F4F5F6', border: '0px' }}>
                    {
                        loading ?
                            <Skeleton direction={'ltr'} duration={1.5} height="100px" count={5} />

                            :
                            <form>

                                <div className="container-fluid">
                                    <div style={{ justifyContent: 'left' }} class="grid-container">

                                        {modules.map((modul, index) => {
                                            return (
                                                <div class="grid-item">

                                                    <div style={{ left: 0, justifyContent: 'right' }} class="input-group mb-3">

                                                        <span style={{ paddingRight: "10px" }} class="input-group-addon" id="basic-addon1">{modul.name}</span>
                                                        <input
                                                            checked={userPermissions?.modules?.find(mod => mod == modul.name) ? true : false}
                                                            name={modul.name}
                                                            value={modul.name}
                                                            id="s1-14"
                                                            type="checkbox"
                                                            onChange={changeCheckBox}
                                                            class="switch" />


                                                    </div>


                                                </div>
                                            )
                                        })}

                                    </div>
                                    <Row style={{ marginTop: '20px', justifyContent: 'right' }}>
                                        <Button
                                            // disabled={errorsPassword}
                                            className="float-right mb-3 mr-2" type="button"
                                            onClick={(e) => savePermissions()}
                                            // onSubmit={handleSubmit(onSubmit)}
                                            variant="info">Guardar</Button>
                                        <Button onClick={props.handleClose} className="float-right mb-3 mr-2 btnCancelar " >
                                            Cancelar
                                        </Button>
                                    </Row>

                                </div>
                                <Row className="mt-3">

                                </Row>
                            </form>
                    }

                </Modal.Body>
            </Modal>


        </div>
    )
}
