import React, { useState, useEffect, useRef } from 'react'
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import { constaApi } from '../../../../constants/constants';
import NotificationAlert from "react-notification-alert";
import * as FIIcons from "react-icons/fi";
import * as FAIcons from "react-icons/fa";
import swal from 'sweetalert';


var auxData = [];
export default function Subjects(props) {
    const notificationAlert = useRef();
    const [rowData,setRowData] = useState([]);
    const [aux,setAux] = useState([]);
    const [gridApi, setGridApi] = useState();
    const [columnApi, setColumnApi] = useState();
    const [updated,setupdated] = useState(false);
    const [frameworkComponents, setFramwrokw] = useState({slotActions:SlotActions});
    const [objSubject,setObjectSubject] = useState([
        {
        type:"Science",
        name:""
    },   {
        type:"Math",
        name:""
    },   {
        type:"Language",
        name:""
    },   {
        type:"Social Studies",
        name:""
    },   {
        type:"Elective",
        name:""
    }
])
    const [modalS,setModalS] = useState(false);
    const [typesSubject,setTypesSubject] = useState([
        "Science",
        "Math",
        "Language",
        "Social Studies",
        "Elective"
    ]);
    useEffect(() => {
        if(props.activeTracking){
            consultAllTrackings(props.activeTracking.id,props.activeTracking.id_last_contact);
        }
        let element = document.getElementById('ag-4');
        
    },[props.activeTracking])
    const onGridReady = (params) => {
        setGridApi(params);
        setColumnApi(params);
    }
   
    const consultAllTrackings = (id,id_contact) =>{
        let newObj ={
            id_tracking: props.activeTracking.id,
            id_contact: props.activeTracking.id_last_contact
         };
        axios.post(constaApi+'allTrackingSubject',newObj)
        .then(function (response) {
            setRowData(response.data);
            auxData = response.data;
            handleClose();
        });
    }
    const openModalSubjects = (data) =>{
        if(data){
            setupdated(true);
            setObjectSubject([...auxData]);
        }
        setModalS(!modalS);
    }
    const cleanCamps = () =>{
        setObjectSubject([   {
                type:"Science",
                name:""
            },   {
                type:"Math",
                name:""
            },   {
                type:"Language",
                name:""
            },   {
                type:"Social Studies",
                name:""
            },   {
                type:"Elective",
                name:""
        }]);
    }
    const handleClose = () => {
        setModalS(false);
        cleanCamps();
    }
    const handleAddSubject = () =>{
        setObjectSubject([...objSubject,{type:"Science",name:""}]);
    }
    const handleChangeObj = (e,index) => {
        const { name, value } = e.target;
        const list = [...objSubject];
        list[index][name] = value;
        setObjectSubject(list);
    }
    const deleteSubject = (id) => {
        let obj ={
            id:id
        };
        axios.post(constaApi+'deleteTrackingSubject',obj)
        .then(function (response) {
            consultAllTrackings(props.activeTracking.id,props.activeTracking.id_last_contact);
        });
    }
    const onSubmit = (data) =>{
        let url = updated  == true ? 'updatedTrackingSubject' : 'saveTrackingSubject';
         let newObj ={
            id_tracking: props.activeTracking.id,
            id_contact: props.activeTracking.id_last_contact,
         };
         let theobj = {
             objSubject:objSubject,
             otherData : newObj
         }
         let flag = false;
         objSubject.map(obj => {
             if(!obj.type){
                 flag = true;
             }
             if(!obj.name){
                 flag = true;
             }
         })
         if(!flag){
             axios.post(constaApi+url,theobj)
             .then(function (response) {
                 consultAllTrackings(props.activeTracking.id,props.activeTracking.id_last_contact);
                 cleanCamps();
             });
         } else {
            notification('warning','Campos vacios');
         }
    }
    function notification(type, message) {
        let place = "tc";
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "nc-icon nc-bell-55",
            autoDismiss: 7,
        }
        notificationAlert.current.notificationAlert(options);
    }
    // const SaveType = (e) => {
    //     setSubject({...subject,type:e.target.value});
    // }
    // const SaveName = (e) => {
    //     setSubject({...subject,name:e.target.value})
    // }
    return (
        <div className="content">
                            <NotificationAlert ref={notificationAlert} />
        <div className="mt-n5 row">
            <div className="col-12">
            <div className="col d-flex justify-content-end">
            <button  onClick={ e => openModalSubjects()}className="btn btn-info">
             Materia</button>
            </div>
            <span>
                {gridApi &&
                 [gridApi.api &&
                    gridApi.api.paginationProxy.masterRowCount + ' Materias'
                ]
                }
            </span>
            <div
                className="ag-theme-alpine"
                style={{ height: '150%', width: '100%' }}
                >
                    <AgGridReact
                        context={{
                            openModalSubjects,
                            deleteSubject
                        }}
                        rowData={rowData}
                        rowHeight={40}
                        cellStyle={{ fontFamily:'Montserrat,sans-serif',fontSize:'13px',fontWeight:'500', color:'#3B3B3B'}}
                        domLayout="autoHeight"
                        onGridReady={onGridReady}
                        frameworkComponents={frameworkComponents}
                        paginationPageSize={500}
                        pagination={true}
                         suppressPaginationPanel={true}
                         debounceVerticalScrollbar={true}
                         // paginationNumberFormatter={function (params) {
                        //     return "Son 12 ";
                        // }}
                        rowSelection="multiple"
                        >
                            {/* Column Name */}
                        <AgGridColumn
                            cellStyle={{ fontSize:'13px',fontWeight:'500', color:'#3B3B3B'}}
                            headerName="Tipo" sortable={true} field="type" width="300" />
                             {/* Column Rating */}
                             <AgGridColumn
                           cellStyle={{ fontSize:'13px',fontWeight:'500', color:'#3B3B3B'}}
                           headerName="Materia" sortable={true} field="name" width="300" />
                           <AgGridColumn
                           cellStyle={{ fontSize:'13px',fontWeight:'500', color:'#3B3B3B'}}
                           cellRenderer="slotActions"
                           headerName="Acciones" sortable={true}  width="300" />
                            
                    </AgGridReact>
                </div>
            </div>
        </div>
            <div style={{height:'3em',backgroundColor:'white',borderLeft:'1px solid #babfc7',borderBottom:'1px solid #babfc7',borderRight:'1px solid #babfc7'}} >
            <div className="col d-flex justify-content-end">
            <span className="montse" style={{ color: '#3B3B3B !important',fontWweight: '500',fontSize:'13px !important'}}>
            {gridApi &&
                 [gridApi.api &&
                    'Numero de materias : '+ gridApi.api.paginationProxy.masterRowCount
                ]
                }
            </span>
            </div>
            </div>

            {/* MODAL */}
        <Modal
                style={{marginTop:'50px'}}
                dialogClassName="modal-90w"
                show={modalS}
                onHide={e => handleClose(e)}
            >
                <Modal.Header style={{height:'60px'}} closeButton>
                    <Modal.Title style={{ fontFamily: 'Inter', marginTop:'5px', fontWeight: '600', fontSize: '18px' }}>Agregar Materia </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: '#F4F5F6', border: '0px' }}>

                    <form onSubmit={e => onSubmit(e)}>
                        <div className="container-fluid">
                            {objSubject.map((ob,i) =>{
                                return(
                            <Row className="mt-1">
                                  <Col className="col-4">
                                    <Form.Label className="formGray">Materia:</Form.Label>
                                    <Form.Control  autoComplete="off" 
                                    onChange={(e) => handleChangeObj(e,i)}
                                    name="type" value={ob.type} as="select" size="sm" custom>
                                        {typesSubject.map(ty => (
                                                        <option key={ty} value={ty}>
                                                            {ty}
                                                        </option>
                                                    ))}
                                    </Form.Control>
                                </Col>
                                <Col className="col-4">
                                <Form.Label className="formGray">Nombre</Form.Label>
                                    <Form.Control  autoComplete="off" name="name"
                                    onChange={(e) => handleChangeObj(e,i)}
                                    value={ob.name}
                                        className="formGray" type="text" placeholder="Nombre de la materia" />
                                </Col>
                                </Row>
                                )
                            })}
                            <Row>
                                <Col>
                            <button onClick={handleAddSubject}
                                                    type="button"
                                                    className="montse ml-1 btn btn-info btn-sm"><span style={{ fontSize: '18px' }} className="montse">+</span></button>
                                </Col>
                            </Row>
                        </div>
                        <Row>

                            <Col>
                                <Button
                                    className="float-right mb-3 mr-2" type="button"
                                    onClick={e => onSubmit(e)}
                                    variant="info">Guardar</Button>
                                <Button onClick={e => handleClose(e)} style={{  fontFamily: 'Inter', fontWeight: '500' }} className="float-right mb-3 mr-2 montse btnBee" >
                                    Cancelar
                </Button>

                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
            {/* FIN MODAL */}


        </div>


    
    )
}

export const SlotActions = function (props){
    function editSubject(data){
        props.context.openModalSubjects(data);
    }
    function deleteSubject(id){
        swal({
        title: "Estas seguro?",
        text: "Usted Eliminara esta materia",
        icon: "info",
        buttons: ["No","Si"],
      })
      .then((willDelete) => {
        if (willDelete) {
            props.context.deleteSubject(id);
        } else {
          swal("Operacion cancelada!");
        }
      });

    }
    return(
        <>
        <a>
            <FIIcons.FiEdit title="Editar" onClick={(e) => editSubject(props.data)} size={18} style={{ color: '#386CEF' }} />
            </a>
            <a className="ml-1">
            <FAIcons.FaTrashAlt title="Eliminar" style={{ color: '#DC3545' }} size={18} onClick={(e) => { deleteSubject(props.data.id) }} />
             </a>
    </>
    )
}