import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { SlotRating } from '../../components/ApplicationSectionComponent/TableApplication';
import React, { useEffect, useState } from 'react'

export const SlotProgramAccounting = function GeneralFormat(props) {
    const { data } = props;

    return (
        <>
        <span>
           {data.NP != null ? data.NP : data.NPA != null ? data.NPA : data.NPT != null ? data.NPT : "Sin programa"}
        </span>
        </>
    )
}


function TableAccountingProspection(props) {
    const [gridApi, setGridApi] = useState();
    const [columnApi, setColumnApi] = useState();
    const [frameworkComponents, setFramwrokw] = useState({ slotRating: SlotRating, slotAccounting: SlotProgramAccounting });
    useEffect(() => {

    }, [props])

    const onGridReady = (params) => {
        setGridApi(params);
        setColumnApi(params);
    }

    return (
        <>
            <h3>{props.title}</h3>
            <div
                className="ag-theme-alpine"
                style={{ height: '100%', width: '100%' }}
            >
                <AgGridReact
                    frameworkComponents={frameworkComponents}
                    rowData={props.invoice}
                    rowHeight={40}
                    cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                    domLayout="autoHeight"
                    onGridReady={onGridReady}
                    pagination={true}
                    paginationPageSize={10}
                    paginationNumberFormatter={function (params) {
                        return params.value.toLocaleString();
                    }}
                    rowSelection="multiple"
                >
                    {/* Column Name */}
                    <AgGridColumn
                        cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                        cellRenderer="slotName"
                        headerName="Nombre" sortable={true} field="fullname" width="300" />
                    {/* Column OtherName */}
                    <AgGridColumn
                        cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                        cellRenderer="slotnameF"
                        hide={true}
                        headerName="otherName" sortable={true} field="otherName" width="300" />

                    {/* Column Rating */}
                    <AgGridColumn
                        cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                        cellRenderer="slotRating"
                        filter="agTextColumnFilter"
                        headerName="Rating" sortable={true} field="rating" width="300" />

                    {/* Columna programa */}
                    <AgGridColumn
                        cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                        cellRenderer="slotAccounting"
                        filter="agTextColumnFilter"
                        headerName="Programa" sortable={true} width="300" />
                    {/* Column City */}
                    <AgGridColumn
                        cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                        headerName="Ciudad" sortable={true} field="ciy" width="200" cellRenderer="slotOrigin" />

                    {/* Column City Hidde */}
                    <AgGridColumn
                        cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                        headerName="Ciudad" sortable={true} field="cityTwo" hide={true} width="200" />

                    {/* Column State hide */}
                    <AgGridColumn
                        cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                        headerName="Estado" sortable={true} field="stateTwo" hidde={true} width="200" />


                    {/* Column Program */}
                    <AgGridColumn
                        cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                        headerName="Programa" field="prospections" width="200" cellRenderer="slotProgram" />

                    {/* Column references */}
                    <AgGridColumn
                        headerName="Referencia" cellRenderer="slotReferences" width="200" />
                    {/* Column Advisor */}
                    <AgGridColumn
                        headerName="Advisor" sortable={true} field="name_advisor" width="200" />
                    {/* Column Actions  */}
                    <AgGridColumn
                        headerName="Acciones"
                        cellRenderer="slotActions"
                        width={220}
                    />

                    <AgGridColumn
                        cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                        // cellRenderer="slotRef1"
                        headerName="Referencia 1" sortable={true} field="ref1" width="300" />

                    <AgGridColumn
                        cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                        // cellRenderer="slotRef1"
                        hide={true}
                        headerName="Referencia 1" sortable={true} field="ref1D" width="300" />



                    <AgGridColumn
                        cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                        // cellRenderer="slotRef2"
                        headerName="Referencia 2" sortable={true} field="ref2" width="300" />

                    <AgGridColumn
                        cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                        // cellRenderer="slotRef1"
                        hide={true}
                        headerName="Referencia 1" sortable={true} field="ref2D" width="300" />

                    <AgGridColumn
                        cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                        // cellRenderer="slotRef3"
                        headerName="Referencia 3 " sortable={true} field="ref3" width="300" />
                    <AgGridColumn
                        cellStyle={{ fontFamily: 'Montserrat,sans-serif', fontSize: '13px', fontWeight: '500', color: '#3B3B3B' }}
                        // cellRenderer="slotRef1"
                        hide={true}
                        headerName="Referencia 1" sortable={true} field="ref3D" width="300" />





                </AgGridReact>
            </div>
        </>
    )
}

export default TableAccountingProspection