import React, { useEffect, useState } from 'react';
import * as FIIcons from "react-icons/fi";
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { useAlert } from 'react-alert';
import { constaApi } from '../../../constants/constants';
import SyncLoader from "react-spinners/SyncLoader";
import { FaIcons } from 'react-icons/fa';
import AsyncSelect from 'react-select/async';

function Family(props) {


    const [modalFamily, setModalFamily] = useState(false);

    const idContact = props.contact.id ?? 0;

    const [contactItems, setContactItems] = useState([]);
    const [inputValue, setValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);

    const [arrFamily, setArrayFamily] = useState(
        [
            {
                id_family: 0,
                type: 'Hermano',
                id_own_contact: idContact
            }
        ]
    )

    useEffect(() => {

    }, [props])


    const addMember = () => {
        setArrayFamily([...arrFamily, { id_family:0, type: "" , id_own_contact : idContact }]);
    }
    const deleteMember = () => { }

    const selectContact = (event,position) => {
        const {value} = event.target;
        const list = [...arrFamily];
        list[position]["type"] = value;
        setArrayFamily(list);
    }

    const fetchOptions = async (value) => {
        if (value.length >= 3) {
            let data = "";
            await axios
                .get(constaApi + "search/selectContact/" + value, {
                    headers: {
                        Accept: "application/json",
                    },
                }).then((response) => {
                    data = response.data
                })
            return data;
        }
    }


    const promiseOptions = (value) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve(fetchOptions(inputValue));
            }, 1000);
        });

    // handle input change event
    const handleInputChange = value => {

        // if value exists
        if (value) {

            // if value lenght is >= 3 
            if (value.length >= 3) {
                // fetchContacts(value);
            }
        }

        setValue(value);
    };

    // handle selection
    const handleChange = ({value},position) => {
       const list = [...arrFamily];
        list[position]["id_family"] = value;
        setArrayFamily(list);

    }


    const saveFamily = async () =>{

        let datax = {
           family: arrFamily
        }
        await axios.post(constaApi + 'reference/update', datax)
            .then(function (response) {
                if (response.status === 200) {
                    props.handlerUpdate();
                    alert.show('Datos actualizados correctamente', {
                        timeout: 2000, // custom timeout just for this one alert
                        type: 'info'
                    })
                } else {
                    alert.show('Ocurrio un error por favor intentar mas tarde');
                }
            });


    }

    return (
        <div className="mt-n2 content">
            <div class="flex-container" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <button onClick={(e) => setModalFamily(!modalFamily)} className='btn btn-info'>Agregar familiar</button>

                {/* section */}



                {/* modalAddFamily */}



                {/* First Modal */}
                <Modal
                    show={modalFamily}
                    onHide={(e) => setModalFamily(!modalFamily)}
                    dialogClassName="modal-90w"
                    style={{ marginTop: '50px', height: 'auto', maxHeight: '1000px' }}
                >
                    <Modal.Header style={{ height: '60px' }} closeButton>
                        <Modal.Title style={{ fontFamily: 'Inter', marginTop: '5px', fontWeight: '600', fontSize: '18px' }}>Agregar Familiar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ background: '#F4F5F6', border: '0px' }}>
                        <div className='container'>
                            {arrFamily.map((member, i) => {
                                return (
                                    <>
                                        <div className='row mb-5'>
                                            <div className='col'>
                                                Contacto:
                                            </div>
                                            <div className='col-10'>

                                                <AsyncSelect
                                                    cacheOptions
                                                    defaultOptions
                                                    loadOptions={promiseOptions}
                                                    onInputChange={handleInputChange}
                                                    onChange={(e) => handleChange(e,i)}
                                                />



                                            </div>
                                        </div>
                                        <div className='row mb-5'>
                                            <div className='col-2'>
                                                Tipo:
                                            </div>
                                            <div className='col'>
                                                <Form.Control
                                                    onChange={(e) => selectContact(e,i)}
                                                    autoComplete="off"
                                                    name="advisor"
                                                    // value={theUser}
                                                    as="select"
                                                    size="sm"
                                                    custom
                                                >
                                                    <option disabled value="" selected></option>
                                                    <option value="Hermano" >Hermano</option>
                                                    <option value="Hermana" >Hermana</option>
                                                    <option value="Primo" >Primo</option>
                                                    <option value="Prima" >Prima</option>

                                                </Form.Control>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-4 ">
                                                {arrFamily.length - 1 === i &&
                                                    <button
                                                        onClick={(arrFamily.length - 1) === i ? (e) => addMember(member, i) : deleteMember(member, i)}
                                                        type="submit" style={{ fontSize: '16px' }}
                                                        className="montse ml-1 btn btn-info btn-sm">
                                                        {(arrFamily.length - 1) === i ?
                                                            <span>+</span>
                                                            :
                                                            <FaIcons.FaTrashAlt style={{ color: 'white' }} size={18} />

                                                        }

                                                    </button>
                                                }
                                            </div>
                                           
                                        </div>


                                    </>
                                )
                            })}
                                           
                             <div style={{ display:'flex',justifyContent:'right'}}>
                             <button className='btn btn-info' 
                             disabled = { arrFamily[0]["id_family"] == 0 ? true : false}
                             onClick={(e) => saveFamily()} >Guardar</button>
    
                            </div>            
                        </div>
                    </Modal.Body>

                </Modal>





            </div>
        </div>
    )
}

export default Family
