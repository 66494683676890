import { ALLREM } from '../../actions/colleges/remindersColleges/remindersColleges';

import AddEditContacts from '../../components/contactComponents/RemindersComponents/AddEditReminders.jsx';
import SearchBar from '../../components/GeneralComponents/SearchBar';
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import '../cssPages/app.css';
import { constaApi } from '../../constants/constants';
import axios from 'axios';
import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { CSVLink } from "react-csv";
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
} from "reactstrap";
import { SlotUrgent } from '../../components/contactComponents/RemindersComponents/TableReminders.jsx';
import { SlotDate } from '../../components/contactComponents/RemindersComponents/TableReminders.jsx';
import { SlotDescription } from '../../components/contactComponents/RemindersComponents/TableReminders.jsx';
import { SlotUsers } from '../../components/contactComponents/RemindersComponents/TableReminders.jsx';
import { SlotActions } from '../../components/contactComponents/RemindersComponents/TableReminders.jsx';
import { SlotDateCreated } from '../../components/contactComponents/RemindersComponents/TableReminders.jsx';
// import { SlotName } from '../../components/contactComponents/RemindersComponents/TableReminders.jsx';
import { SlotProgram } from '../../components/contactComponents/RemindersComponents/TableReminders.jsx';
import { activeReminderC } from '../../actions/contacts/remindersContacts/remindersContact';
import TableReminderColleges from './TableReminderColleges.jsx';

export default function RemindersSection(props) {
  const startValue = new Date(new Date().getFullYear(), new Date().getMonth(), 14);
  const endValue = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 15);
  const { users } = useSelector(state => state.users);
  const [usersFormat, setUsersFormat] = useState([]);
  const [selectUser, setSelectUser] = useState(null);
  const [rangeDate, setRangeDate] = useState([startValue, endValue]);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  let [color, setColor] = useState("#72B6E1");
  const [flag,setFlag] = useState(false);
  const { remindersC } = useSelector(state => state.remindersC);
  const [frameworkComponents, setFramwrokw] = useState({
    slotUrgent: SlotUrgent,
    slotDate: SlotDate,
    slotDescription: SlotDescription,
    slotUsers: SlotUsers,
    slotActions: SlotActions,
    slotCreated: SlotDateCreated,
    slotName: SlotName,
    slotProgram: SlotProgram
  });
  // Can be a string as well. Need to ensure each key-value pair ends with ;
  const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
        `;
        useEffect(() => {
            Search();
        },[remindersC])
  useEffect(() => {
    dispatch(ALLREM());
    let arr = users.map(us => {
      return {
        ...us,
        value: us.name + 'VALUE',
        label: us.name + ' ' + us.father_lastname + ' ' + us.mother_lastname,
        fullname:
          us.name + 'FULL',
      }
    });
    setUsersFormat(arr);
  }, [users])
  const [param, setParam] = useState(null);
  const [load, setLoad] = useState(false);
  const [init] = useState(JSON.parse(localStorage.getItem('user')) || { logged: false });
  const [optionsFormat, setOptionsFormat] = useState([
    {
      value: 'Atrasados',
      label: 'Atrasados'
    },
    {
      value: 'Futuros',
      label: 'Futuros'
    },
    {
      value: 'Rango',
      label: 'Rango'
    },

  ]);
  const [selectOption, setSelectionOption] = useState('');

  const consult = (e) => {
    e.target.value === "" ? setParam('keyWordSeccret302') : setParam(e.target.value);
  }
  function open() { }
  function changeDates(e) {
    let start = new Date(e.value[0]).toLocaleDateString('es-MX'); // 8/19/2020
    let end = new Date(e.value[1]).toLocaleDateString('es-MX'); // 8/19/2020
    let arr = [];
    arr[0] = start + ' ' + '00:00:00';
    arr[1] = end + ' ' + '23:59:59';
    let d = new Date(arr[0]);
    setRangeDate(arr);
  }
  function handleChangeSelect(e) {
    setSelectUser(e);
  }
  function handleChangeSelectOption(e) {
    setSelectionOption(e);
    let datex = new Date();
    if(e.value === 'Futuros'){
      let start = new Date(Date.now()).toLocaleDateString('es-MX'); // 8/19/2020
      let end = new Date(datex.getFullYear() + 1, datex.getMonth() , datex.getDate() ).toLocaleDateString('es-MX'); // 8/19/2020
      let arr = [];
      arr[0] = start + ' ' + '00:00:00';
      arr[1] = end + ' ' + '23:59:59';
      let d = new Date(arr[0]);
      setRangeDate(arr);
    }
    if(e.value === 'Atrasados'){
      let start = new Date('10-11-2020').toLocaleDateString('es-MX'); // 8/19/2020
      let end = new Date(datex.getFullYear(), datex.getMonth() , datex.getDate() - 1 ).toLocaleDateString('es-MX'); // 8/19/2020
      let arr = [];
      arr[0] = start + ' ' + '00:00:00';
      arr[1] = end + ' ' + '23:59:59';
      let d = new Date(arr[0]);
      setRangeDate(arr);
    }
  }

  async function prepareToExport(arr){
    let aux = [];
    await arr.map(re => {
      let obj = {...re};
      let nam = '';
      let program = '';
      if(re.emails_to.length >0){
        re.emails_to.map((eme,i) => {
          nam += (((re.emails_to.length - 1)  - i) > 0) ? eme.name_user + ',' : eme.name_user + ',';
        })
      }
      if(re.prospections != null){
        program = re.prospections.name_prospection;
        if(re.applications != null){
          program = re.applications.name_prospection + ' - ' + re.applications.name;
          if(re.trackings != null){
            program = re.trackings.name_prospection + ' - ' + re.trackings.name;
          }
        }
      }
      obj = {...obj,exportUsers:nam,programToExport:program};
      aux.push(obj);
      setResults(aux);
    })
    // aux.map(au =>{
    //   let program = '';
    //   if(au.prospections.length > 0){
    //     program = au.prospections[0].name_prospection;
    //     console.log('program',program);
    //   }
    // })
  }
  function Search() {
    setShow(true);
    setLoading(true);
    let obj = {
      selectUser: selectUser,
      rangeDate: rangeDate
    }
    axios.post(constaApi + 'checkReminders', obj)
      .then(async function (response) {
        if (response.data) {
          const {data} = response;         
          setResults(data);
          prepareToExport(data);
          setLoading(false);
        } else {
          setResults([]);
          setLoading(false);
        }
      }).catch(err => {
        setLoading(false);
      });
  }
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [newObj, setNewObj] = useState([]);
  const headers = [
    { label: "Nombre", key: "fullname" },
    { label: "Asunto", key: "subject" },
    { label: "Fecha", key: "dateReminder" },
    { label: "Descripcion", key: "notes" },
    { label: "Departamento", key: "departament" },
    { label: "", key: "userOne" },
    { label: "", key: "userTwo" },
    { label: "", key: "userThree" },
    { label: "", key: "userFour" },
    { label: "", key: "userFive" },
    {label: "Programa", key: "programToExport"},
    {label: "UsuariosExp", key: "exportUsers"}
  ];
  const csvReport = {
    data: newObj,
    headers: headers,
    filename: 'RemindersModule.csv'
  };
  const exporta = () => {
    if (newObj.length > 0) {
      // console.log('newObj1',newObj);
    } else {
      setNewObj(results);
    }
  }
  const onGridReady = (params) => {
    setGridApi(params);
    setColumnApi(params);
  };
  const autoSizeAll = (skipHeader) => {
    var allColumnIds = [];
    columnApi.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    columnApi.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  };
  const onFirstDataRendered = (event) => {
    autoSizeAll(false);
  };
  function editReminder(obj) {
    dispatch(activeReminderC(obj.id, obj));
  }
  return (
    <div className="content">
      <div className="row">
        <div style={{ padding: 10 }} className="card col-3">
          <Select
            isMulti
            name="values"
            value={selectUser}
            onChange={(e) => handleChangeSelect(e)}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Seleccionar un usuario"
            isLoading={usersFormat ? false : true}
            options={usersFormat}
          />
          <button
            disabled={(!selectUser || !selectOption) ? true : false}
            className="btn btn-info" onClick={(e) => Search()}>Buscar</button>
        </div>
        <div style={{ padding: 10, marginLeft: 10 }} className="card col-3">
          <Select
            name="values"
            value={selectOption}
            onChange={(e) => handleChangeSelectOption(e)}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Seleccionar una opcion"
            options={optionsFormat}
          />
        </div>
        {selectOption.value === 'Rango' &&
        <div style={{ padding: 10, marginLeft: 10 }} className="card col-3">
          <DateRangePickerComponent
            startDate={startValue}
            endDate={endValue}
            placeholder="Rango de fechas"
            id="daterangepicker"
            format="dd-MMM-yy"
            onChange={(e) => changeDates(e)}
          />
        </div>
        }
      </div>
      {show &&
        [loading ?
          <FadeLoader color={color} loading={loading} css={override} size={50} />
          :
          <>
            <div className="row">
              <div className="col-12">
                <div className="mt-5 col d-flex justify-content-end">
                  <AddEditContacts
                    route={'Section'}
                    load={load}
                    userSection={init}
                    openContacts={true} {...props} />
                </div>


                <div className="searchBarTable">
                  <SearchBar consult={(e) => consult(e)} />
                </div>
                <h6>Contactos</h6>

                <div className="content">
                  <div>
                    <button
                      style={{ marginTop: '-16%', marginLeft: '80%', textDecoration: 'white' }}
                      className="btn btn-info" onClick={(e) => exporta(e)}
                    >
                      <CSVLink {...csvReport}><span>Exportar</span>
                      </CSVLink>
                    </button>
                  </div>
                  {!loading ?
                    <Row>

                      <div
                        className="ag-theme-alpine"
                        style={{ height: "100%", width: "100%" }}
                      >
                        <AgGridReact
                          context={{
                            editReminder,
                            allUsers: users,
                            route: props.route
                          }}
                          rowData={results}
                          defaultColDef={{ resizable: true }}
                          rowHeight={40}
                          domLayout="autoHeight"
                          onGridReady={onGridReady}
                          onFirstDataRendered={onFirstDataRendered}
                          frameworkComponents={frameworkComponents}
                          pagination={true}
                          paginationPageSize={10}
                          paginationNumberFormatter={function (params) {
                            return params.value.toLocaleString();
                          }}
                          rowSelection="multiple"
                        >
                          <AgGridColumn
                            headerName="Contacto"
                            cellRenderer="slotName"
                            field="fullname"
                            width="250"
                          ></AgGridColumn>
                 <AgGridColumn headerName="Programa" cellRenderer="slotProgram" field="departament" width={220} />
                          <AgGridColumn
                            headerName=""
                            cellRenderer="slotUrgent"
                            field="urgent"
                            width="50"
                          ></AgGridColumn>
                          <AgGridColumn
                            headerName="Contacto"
                            field="fullname"
                            width="250"
                            cellRenderer="slotName"
                            hide={props.fromRemindersSection ? false : true}
                          >
                          </AgGridColumn>
                          <AgGridColumn
                            headerName="Asunto"
                            field="subject"
                            width="200"
                          />
                          {/* <AgGridColumn
                  headerName="Fecha creación"
                  field="created_at"
                  width="250"
                  cellRenderer="slotCreated"
                  hide={activeP ? false : true}
                /> */}
                          <AgGridColumn
                            headerName="Fecha"
                            field="ciy"
                            width="250"
                            cellRenderer="slotDate"
                          />
                          <AgGridColumn
                            headerName="Descripción"
                            field="notes"
                            width="200"
                            cellRenderer="slotDescription"
                          />
                          <AgGridColumn
                            headerName="Usuarios"
                            cellRenderer="slotUsers"
                            width="300"
                          />
                          <AgGridColumn
                            headerName="Usuario uno"
                            field="userOne"
                            width="300"
                            hide="true"
                          />
                          <AgGridColumn
                            headerName="Usuario dos"
                            field="userTwo"
                            width="300"
                            hide="true"
                          />
                          <AgGridColumn
                            headerName="Usuario tres"
                            field="userThree"
                            width="300"
                            hide="true"
                          />
                          <AgGridColumn
                            headerName="Usuario cuatro"
                            field="userFour"
                            width="300"
                            hide="true"
                          />
                          <AgGridColumn
                            headerName="Usuario cinco"
                            field="userFive"
                            width="300"
                            hide="true"
                          />
                          {/* <AgGridColumn headerName="Departamento" cellRenderer="slotProgram" field="departament" width={220} /> */}
                          <AgGridColumn headerName="Acciones" cellRenderer="slotActions" width={220} />
                        </AgGridReact>
                      </div>
                    </Row>
                    :
                    <div className="row mt-2">
                    </div>
                  }

                </div>



              </div>
            </div>
            {init.type === 'Administrador' ?
            <TableReminderColleges />
              :
              <div></div>
            }
          </>
        ]
      }
    </div>
  )
}

export const SlotName = function SlotName(props) {
  const dispatch = useDispatch();
 const {rowIndex,value} = props
 const click = (data) => {
   let newObj = {...data,id:data.id_last_contact,fullname:data.name}
  // dispatch( activeContact(newObj) );
 }
 const array = props.agGridReact.props.rowData;
  const showName = (obj) => {
    let text = " ";
    const {data} = obj;
    // if(data.origin === true){
    //   text = value;
    // } else {
    //   text = " ";
    // }
    text = value;
    return text;
  }
  return (
      <>
       <Link onClick={(e) =>click(props.data)} to={"contacts/" + (props.data.id_contact) + "/reminders"}>
       <span>{showName(props)}</span>
       </Link>
      </>
  )
}

