import SearchBar from '../../components/GeneralComponents/SearchBar'
import React,{useState} from 'react'
import TableVinculated from './TableVinculated'

function Vinculated() {
    const [param,setParam] = useState(null);

    const consult = (e) => {
        e.target.value === "" ?  setParam('keyWordSeccret302') :  setParam(e.target.value);
    }

  return (
    <div className="content">
    <div className="row">
    <div className="col-12">
    <div className=" mt-5 searchBarTable">
    <SearchBar consult={(e) => consult(e)}/>
    </div>
         <TableVinculated  param={param} /> 
    </div>
    </div>
    </div>
  )
}

export default Vinculated