// import logo from './logo.svg';
// import '../App.css';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import ContactsRouters from './ContactsRouters';
import CollegesRouter from './CollegesRouter';
import axios from 'axios';
import Login from '../pages/Login';
import React, { useState, useEffect } from 'react';
import PrivateRouter from './PrivateRouter';
import PublicRouter from './PublicRouter';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { login } from '../actions/auth/auth';
import Loader from '../components/loaderComponents/Loader';
import {  starLoadingColleges, activeCollege } from '../actions/colleges/colleges';
import CollegesLocalRouter from './CollegesLocalRouter';
import { constaApi } from '../constants/constants';
import { setUsers } from '../actions/users/users';
import Leads from '../pages/Leads/Leads';
import Accounting from '../pages/Accounting/Accounting';
import TicketsRouter from '../pages/Tickets/TicketsRouter';
import FaqRouter from '../pages/FAQ/FaqRouter';
import Analitics from '../pages/Analitics/Analitics';
import DashboardRoute from './Dashboard/DashboardRoute';
import ProspectionRouter from '../pages/ProspectionSection/ProspectionRouter';
import AplicationRouter from '../pages/AplicationsSection/AplicationsRouter';
import TrackingRouter from '../pages/Tracking/TrackingRouter';
import HistoryRouter from '../pages/History/HistoryRouter';
import ReminderRouter from '../pages/ReminderSection/ReminderRouter';
import UsersRouter from '../pages/Users/UsersRouter';
import WithoutPermissons from '../pages/ErrorPages/WithoutPermissons';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import UsersFormRouter from '../pages/Form/UsersFormRouter';
import PreviewFormRouter from '../pages/Form/Preview/PreviewFormRouter';
import MonitorRouter from '../pages/Monitor/MonitorRouter';

export const AppRouter = (props) => {

    const [permissions,setPermissions] = useState([]);
    const dispatch = useDispatch();
    const [checking, setChecking] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    // // const {token:authToken} = useSelector( state => state.auth,shallowEqual);
    const select = useSelector(state => state.auth, shallowEqual);
    // const[flag,setFlag] = useState(false);
    // const [user,setUser] = useState();

    useEffect(()=>{

         function onKeyDown(e) {
        if (e.key === "k") {

            
        }
    }
    
    document.addEventListener("keydown", onKeyDown) 

    return () => document.removeEventListener('keydown', onKeyDown);

    },[])

    useEffect(() => {
        
        checkIsLogged();

    }, [dispatch, select, setChecking, setIsLoggedIn])


    const checkIsLogged = async () => {
        axios
        .post(constaApi + "defaultSelectBio")
        .then(function (response) {
            let { users } = response.data;
            dispatch(setUsers(users));
        });
    if (JSON.parse(localStorage.getItem('user'))) {
        const { email, id, name, token, type } = JSON.parse(localStorage.getItem('user'));
        if (!email || !id || !name || !token || !type) {
            localStorage.removeItem('user');
            // window.location.reload(false);
        }
        dispatch(login(email, id, name, token, type));
        dispatch(starLoadingColleges());
        if (JSON.parse(localStorage.getItem('collegeActive'))) {
            const json = JSON.parse(localStorage.getItem('collegeActive'));
            if (json[0]) {
                dispatch(activeCollege(json[0].id, json[0]));
            }
        }
        setIsLoggedIn(true);
    } else {
        setIsLoggedIn(false);
    }
    setChecking(false);

    // const { id } = JSON.parse(localStorage.getItem('user'));

    // loadPermissions(id);

    }
    
    if (checking) {
        return (
            <Loader />
        )
    }

    return (
        <Router>
            <div>
                <Switch>


                <Route
                        exact path="/error/401"
                        component={WithoutPermissons}
                        isAuthenticated={isLoggedIn}
                    />


                    <PublicRouter
                        exact path="/login"
                        component={Login}
                        isAuthenticated={isLoggedIn}
                    />

                    
                    <PrivateRouter
                        path="/contacts/:id"
                        component={ContactsRouters}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Contactos')}
                        module={'Contactos'}
                    />

                    <PrivateRouter
                        path="/colleges/:id"
                        component={CollegesRouter}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Colegios')}
                        module={'Colegios'}


                    />
                    <PrivateRouter
                        path="/localColleges/:id"
                        component={CollegesLocalRouter}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Colegios Locales')}
                        module={'Colegios Locales'}


                    />


                    
                    <PrivateRouter
                        path="/leads"
                        component={Leads}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Leads')}
                        module={'Leads'}


                    />

                    <PrivateRouter
                        path="/moduleTickets"
                        component={TicketsRouter}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Tickets')}
                        module={'Tickets'}

                    />
                    <PrivateRouter
                        path="/accounting"
                        component={Accounting}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Contabilidad')}
                        module={'Contabilidad'}

                    />


                    <PrivateRouter
                        path="/module/analitics"
                        component={Analitics}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Analitics')}
                        module={'Analitics'}


                    />


                     <PrivateRouter
                        path="/FAQ"
                        component={FaqRouter}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Contactos')}
                        module={'Contactos'}


                    />


                    <PrivateRouter
                        path="/dashboard"
                        component={DashboardRoute}
                        isAuthenticated={isLoggedIn}

                    />

                        <PrivateRouter
                        path="/contacts"
                        component={ContactsRouters}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Contactos')}
                        module={'Contactos'}


                    />

<PrivateRouter
                        path="/colleges"
                        component={CollegesRouter}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Colegios')}
                        module={'Colegios'}


                    />


<PrivateRouter
                        path="/localColleges"
                        component={CollegesLocalRouter}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Colegios Locales')}
                        module={'Colegios Locales'}


                    />





<PrivateRouter
                        path="/SectionProspections"
                        component={ProspectionRouter}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Prospecciones')}
                        module={'Prospecciones'}


                    />


<PrivateRouter
                        path="/SectionApplications"
                        component={AplicationRouter}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Aplicaciones')}
                        module={'Aplicaciones'}


                    />



<PrivateRouter
                        path="/Tracking"
                        component={TrackingRouter}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Seguimiento')}
                        module={'Seguimiento'}


                    />

<PrivateRouter
                        path="/History"
                        component={HistoryRouter}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Historial')}
                        module={'Historial'}


                    />


<PrivateRouter
                        path="/SectionReminders"
                        component={ReminderRouter}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Recordatorios')}
                        module={'Recordatorios'}


                    />

                    
<PrivateRouter
                        path="/monitor"
                        component={MonitorRouter}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Monitoreo' )}
                        module={'Monitoreo'}


                    />

<PrivateRouter
                        path="/users"
                        component={UsersRouter}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Usuarios')}
                        module={'Usuarios'}


                    />

<PrivateRouter
                        path="/formUsers"
                        component={UsersFormRouter}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Usuarios Form')}
                        module={'Usuarios Form'}


                    />

<PrivateRouter
                        path="/previewForm"
                        component={PreviewFormRouter}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Vista previa de la forma')}
                        module={'Vista previa de la forma'}


                    />



 <PublicRouter
                        exact path="*"
                        component={Login}
                        isAuthenticated={isLoggedIn}
                    /> 


                    {/* <PrivateRouter
                        path="/"
                        component={DashboardRoutes}
                        {...props}
                        isAuthenticated={isLoggedIn}
                        hasPermitid={permissions.find(perm => perm == 'Contactos')}
                        module={'Contactos'}

                    /> */}

                </Switch>
            </div>
        </Router>
    )
}
export default AppRouter;

