
import axios from 'axios';
import { constaApi } from '../../constants/constants';

export const loadBios = async  (id) => {
    const BiosC = [];
    const {data} = await axios.get(constaApi+'bio/contacts/'+id +'/getBioRecords')
    data.forEach(child => {
        BiosC.push({
            id:child.id,
            ...child
        });
    });
        return BiosC;
}

export const loadBiosProspect = async  (id,id_type_prospection) => {
    const BiosC = [];
    const {data} = await axios.post(constaApi+'bio/prospects',{id:id,id_type_prospection:id_type_prospection})
    data.forEach(child => {
        BiosC.push({
            id:child.id,
            ...child
        });
    });
        return BiosC;
}
export const loadBiosApplication = async  (id,id_type_prospection) => {
    const BiosC = [];
    const {data} = await axios.post(constaApi+'bio/applications',{id:id,id_type_prospection:id_type_prospection})
    data.forEach(child => {
        BiosC.push({
            id:child.id,
            ...child
        });
    });
        return BiosC;
}
