import React, { useState, useEffect, useRef } from 'react'
import routes from '../../routes';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router";
import axios from 'axios';
import { constaApi } from '../../constants/constants';
import { Nav } from 'react-bootstrap';
import {
    BrowserRouter as Router, Switch, Redirect,
    Route, useLocation
} from 'react-router-dom';
import AccountingProspection from '../../components/AccountingComponents/AccountingProspection';
import AccountingApplication from '../../components/AccountingComponents/AccountingApplication';
import AccountingTracking from '../../components/AccountingComponents/AccountingTracking';
import AccountingView from '../../components/AccountingComponents/AccountingView.jsx';
import AccountingGeneral from '../../components/AccountingComponents/AccountingGeneral.js';
import Pays from '../../components/AccountingComponents/Pays';
import SideBar from '../../components/SideBar/new/SideBar';
import PermissionsHook from '../../customHook/PermissionsHook/PermissionsHook';


function Accounting(props) {
    const dispatch = useDispatch();
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [backgroundColor, setBackGroundColor] = useState(JSON.parse(localStorage.getItem('bgColor')) || 'white');
    const [activeColor, setActiveColor] = useState(JSON.parse(localStorage.getItem('activeColor')) || 'info');
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const mainPanel = useRef();

    useEffect(() => {

        axios.get(constaApi + 'geTotalLeads', {
            headers: {
                "Accept": "application/json"
            }
        }).then(function (response) {
            const { data } = response;
            localStorage.setItem('countLeads', JSON.stringify(data));
        }).catch(error => {
            console.log('Error')
        });
    })


    const fromSidebar = () => {
        mainPanel.current.classList.toggle('withSidebar');
    }

    const finishLoading = () =>{
        setLoading(false);
      }
      
    return (
            <div className="wrapper">
                <SideBar
                    {...props}
                    routes={routes}
                    bgColor={backgroundColor}
                    activeColor={activeColor}
                    fromSidebar={fromSidebar}
                />

                 { loading ? 
  
             <PermissionsHook  finishLoadin={finishLoading} module={'Contabilidad'} /> 
             
             :
                <div style={{ backgroundColor: '#f9fafb' }} className="main-panel" ref={mainPanel}>
                    <Nav {...props} />
                    <AccountingView />
                    <Switch>
                    <Route exact path="/accounting"
                            render={(props) => (
                                <AccountingGeneral {...props}
                                />
                            )}
                        />

                        <Route exact path="/accounting/prospection"
                            render={(props) => (
                                <AccountingProspection {...props}
                                />
                            )}
                        />
                        <Route exact path="/accounting/application"
                            render={(props) => (
                                <AccountingApplication {...props}
                                />
                            )}
                        />

                        <Route exact path="/accounting/tracking"
                            render={(props) => (
                                <AccountingTracking {...props}
                                />
                            )}
                        />

                        <Route exact path="/accounting/paid"
                            render={(props) => (
                                <Pays {...props}
                                />
                            )}
                        />

                        <Redirect to="/login" />
                    </Switch>

                </div>
              }

            </div>


        )
}

export default Accounting