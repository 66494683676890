import React, { useState, useEffect, useRef } from "react";
import "../../../../styles/StylesTickets/TicketModal.css";
import * as GRicons from "react-icons/gr";
import * as AIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import * as FIcons from "react-icons/fi";
import * as Ioicons from "react-icons/io";
import * as FCIcons from "react-icons/fc";

import swal from "sweetalert";

import { useParams } from "react-router";
import ModalImage from "react-modal-image";
import moment from "moment";

import NotificationAlert from "react-notification-alert";

import {
  Button,
  Modal,
  Form,
  InputGroup,
  Popover,
  OverlayTrigger,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import { constaApi } from "../../../../constants/constants";
import PreviewImages from "./PreviewImages.jsx";
import useAutosizeTextArea from "./useAutosizeTextArea.jsx";

import Linkify from "react-linkify";


import { useLocation } from 'react-router-dom';


export default function TicketModal(props) {
  // Configuration regional of language
  moment.locale("es-mx");


  //GET ROUTE
  const { pathname } = useLocation();


  // Get id of contact from URL
  let { id } = useParams();
  const [id_contact, setIDcontact] = useState(id ?? props.dataidassigned);
  const userActive = JSON.parse(localStorage.getItem("user"));

  // Parts of Paste image/file to Upload after onPaste
  const [doc, setDoc] = useState(null);
  const [fullImg, setFullImg] = useState("");
  const [nameImg, setNameImg] = useState("");
  const [urlX, setUrlx] = useState("");
  const [showModal, setSecondModal] = useState(false);
  const [editDescription, setEditDescription] = useState(false);

  const [initialMembers, setInitialMembers] = useState([]);
  const [membersAdd, setMembersAdd] = useState([]);

  const [editing, setEditing] = useState(false);

  // Parts of a Search
  const [param, setParam] = useState("");
  const [resultofSearch, setResultofSearch] = useState([]);

  // Components of a table used to find at User
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [dataUsers, setDataUsers] = useState([]);
  const [operation, setOperation] = useState("");

  // Notifications of events , errors or what ever
  const notificationAlert = useRef();

  const [show, setShow] = useState(props.show);

  //Section Changed
  const [sectionwaschanged,setSectionwaschanged] = useState(false);

  // Focus and onBlur used
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const [showLocation, setShowLocation] = useState(false);
  const [location, setLocation] = useState("");

  const [focusedMembers, setFocusedMembers] = useState(false);
  const onFocusMember = () => setFocusedMembers(true);
  const onBlurMember = () => setFocusedMembers(false);

  const [focuseDescription, setfocuseDescription] = useState(false);
  const onFocusDescription = () => setfocuseDescription(true);
  const onBlurDescription = () => setfocuseDescription(false);

  const [focusComment, setfocusComment] = useState(false);
  const onFocusComment = () => setfocusComment(true);
  const onBlurComment = () => setfocusComment(false);

  const [ticket, setTicket] = useState({
    id: 0,
    id_contact: 0,
    title: "",
    description: "",
    section: "",
    open: 1,
    tag_name: "Etiqueta 1",
    color: "#3ac0d8",
    program: "",
  });
  const [members, setmembers] = useState([{}]);
  const [activities, setactivities] = useState([{}]);
  const [comment, setComment] = useState("");

  const [changes, setChanges] = useState(false);

  // ref TextArea
  const textAreaRef = useRef(null);

  useAutosizeTextArea(textAreaRef.current, comment);

  // Methods

  // Methods used in a table of QuickSearch
  const onGridReady = (params) => {
    setGridApi(params);
    setColumnApi(params);
  };

  // Method to send a notification of messages
  const notification = (type, message) => {
    let place = "tc";
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  useEffect(() => {
    // Inicialmente aqui partira el conteo de miembros de el ticket
    setInitialMembers(props.data.members, { responsable: "Raul" });
    setTicket(props.data.ticket);
    setmembers(props.data.members);
    setactivities(props.data.activities);
    setEditing(props.editing);
  }, [props.data]);

  const sendNotification = (action = "agregar", memb = []) => {
    const url = "sendNotification/tickets/" + action;
    swal({
      title: "Se enviaran notificaciones",
      text: "Se notificara a los nuevos miembros sobre su inclusión en este ticket.",
      icon: "info",
      dangerMode: true,
      buttons: ["No", "Si"],
    }).then((willDelete) => {
      if (willDelete) {
        axios.post(constaApi + url, memb).then(function (response) {
        });
      } else {
        swal("Operacion cancelada!");
      }
    });
  };


  const validationIfthereDescription = () => {
    if(ticket.description == "" || ticket.description == null){


      // Descripcion obligatoria si o si 


      swal(
        '!Sin descripción asignada!',
        'No has asignado una descripción al ticket.',
        'info'
      )

    //   swal({
    //     title: "Sin Descripción asignado",
    //     text: "No has asignado una descripción al ticket.",
    //     icon: "info",
    //     dangerMode: true,
    //     buttons: ["Agregar descripción", "Continuar sin descripción"],
    //   }).then((willDelete) => {
    //     if (willDelete) {

    //        validationIfthereProgram();

    //     } else {
    //       // this is no
    //       // swal("Operacion cancelada!");
    //     }
    //   });
    // } else {
    //   validationIfthereProgram();
    // }
      } else {
        validationIfthereProgram();
      }

  }

  const validationIfthereProgram = () =>{
    if(ticket.section == 'Aplicación' || ticket.section == 'Seguimiento'){
      if(ticket.program == 'General' || ticket.program == null){

        swal({
          title: "Sin Programa asignado",
          text: "No has asignado un program al ticket.",
          icon: "info",
          dangerMode: true,
          buttons: ["No", "Continuar"],
        }).then((willDelete) => {
          if (willDelete) {

            validationPreClose();

          } else {
            swal("Operacion cancelada!");
          }
        });

      }
      else {
        validationPreClose();
      }
    } else {
      validationPreClose();
    }
  }

  const validationPreClose = async() => {
    if(pathname.includes('moduleTickets')){
      closeModalTicket();

    } else {

      // if the section wasnt changed and not is editing
    if(!sectionwaschanged && !editing){
      swal({
        title: "Sin sección asignada",
        text: "No has asignado el Area a la que corresponde el ticket",
        icon: "question",
        dangerMode: true,
        buttons: ["No", "Si"],
      }).then((willDelete) => {
        if (willDelete) {
          closeModalTicket();

        } else {
          swal("Operacion cancelada!");
        }
      });

    } else {
      closeModalTicket();
    }


    }
    
  }
  const closeModalTicket = () => {
    const newArraymembers = initialMembers.map((mem) => {
      return {
        email: mem.email,
        id_ticket: mem.id_ticket,
        responsable: props.user.name,
        operation: operation,
        responsable_change: userActive.email,
      };
    });

    if (membersAdd.length > 0) {
      if (changes) {
        sendNotification("modificar", membersAdd);
      } else {
        // Notification wont send if we add a new member , 12 april 2023
        sendNotification("agregar", membersAdd);
      }
    } else {
      if (changes) {
        if (editing) {
          sendNotification("modificar", newArraymembers);
        } else {
          sendNotification("agregar", newArraymembers);
        }
      }
    }

    props.closeModal();
  };

  const changeTitle = ({ target }) => {
    setTicket({ ...ticket, title: target.value });
    setOperation("el titulo");
  };

  const updateTicket = (e) => {
    setChanges(true);
    let data = ticket;
    data = {
      ...data,
      action: "cambiar",
      id_contact: id_contact,
      description_action: "ha cambiado el titulo",
      creator: props.user.id,
      fullname_creator: props.user.name,
    };
    axios
      .post(constaApi + "editTicketProspection/title", data)
      .then(function (response) {
        setactivities(response.data);
        onBlur();
      });
  };

  const PopoverComponent = (name) => {
    return (
      <Popover id="popover-basic">
        <Popover.Content>
          <span>{name}</span>
        </Popover.Content>
      </Popover>
    );
  };

  const preFormatterUser = (id) => {
    const name = dataUsers.filter((user) => user.id == id);
    if (name[0]) {
      let mem = {
        fullname_creator: name[0].fullname,
      };
      return formatterUser(mem, "preformatter");
    }
  };
  // Funcion to Formatt Name of members of tickets
  const formatterUser = (member, where = "general") => {
    let fMember = {
      fullname: "Sistema blink default",
    };
    if (typeof member === "object") {
      fMember = member;
      if (member.fullname_creator) {
        fMember.fullname = member.fullname_creator;
      }
    }
    let n = fMember.fullname ? fMember.fullname.toUpperCase().split(" ") : " ";
    let tag = "";
    let classDefined = "";
    if (n.length >= 3) {
      n = n[0].charAt(0) + n[1].charAt(0) + n[2].charAt(0);
    } else if (n.length >= 2) {
      n = n[0].charAt(0) + n[1].charAt(0);
    } else {
      n = n[0].charAt(0);
    }
    classDefined =
      "sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnP blueStrong";
    tag = (
      <OverlayTrigger
        key={"Overlay" + fMember.fullname}
        trigger={["hover", "hover"]}
        placement="top"
        overlay={PopoverComponent(fMember.fullname)}
      >
        <span className={classDefined}>{n}</span>
      </OverlayTrigger>
    );
    return tag;
  };

  const secondPartOfFindAtUser = (value) => {
    let objx = gridApi;
    value === "keyWordSeccret302"
      ? objx.api.setQuickFilter("")
      : objx.api.setQuickFilter(value);
    setGridApi(objx);
    let rowsDisplay = objx.api.rowRenderer.rowModel.rowsToDisplay;
    let result;
    if (rowsDisplay) {
      result = rowsDisplay.map((row) => {
        let { data } = row;
        return data;
      });
      setResultofSearch(result);
      //  this.setState({resultofSearch: result});
    }
  };
  const findAtUser = (e) => {
    // if exist
    if (e.target.value) {
      let fullnameFormated = e.target.value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      setParam(fullnameFormated);
      secondPartOfFindAtUser(fullnameFormated);
    } else {
      setParam("");
      setResultofSearch([]);
    }
  };

  const addOrDeleteProgram = (program) => {
    saveProgramChange(program);
  };

  const saveProgramChange = (program) => {
    setOperation("El programa");
    setChanges(true);
    let data = ticket.id != 0 ? ticket : props.data.ticket;
    data["program"] = program.name ?? "General";
    data["college"] = program.college ?? "General";
    data = {
      ...data,
      action: "cambiar",
      id_contact: id_contact,
      description_action: "ha asignado un programa",
      creator: props.user.id,
      fullname_creator: props.user.name,
    };
    axios
      .post(constaApi + "editTicketProspection/program", data)
      .then(function (response) {
        setactivities(response.data.activities);
        setTicket(response.data.ticket);
        onBlurDescription();
      });
  };

  const addOrDeleteTag = (id, tag) => {
    saveTagChange(tag.name, tag.color);
  };

  const saveTagChange = (name, color) => {
    setOperation("la Etiqueta");
    setChanges(true);
    let data = ticket;
    data["tag_name"] = name;
    data["color"] = color;
    data = {
      ...data,
      action: "cambiar",
      id_contact: id_contact,
      description_action: "ha cambiado la Etiqueta",
      creator: props.user.id,
      fullname_creator: props.user.name,
    };
    axios
      .post(constaApi + "editTicketProspection/tag", data)
      .then(function (response) {
        setactivities(response.data.activities);
        setTicket(response.data.ticket);
        onBlurDescription();
      });
  };

  const addorDeleteMember = (id, email = "") => {
    let IwillCallYou = false;

    let data = {};

    let action = "";
    
    // Validations
    // First we going to validate that not is the last member
    const result = members.filter((member) => member.id_user == id);
    if (result.length > 0 && members.length == 1) {
      notification("danger", "No puedes retirar al ultimo miembro.");
    }
    // Si se encuentra el elemento pero no es el unico (Eliminacion de miembro)
    else if (result.length > 0 && members.length != 1) {
      IwillCallYou = true;
      action = "delete";
      data = {
        id_user: id,
        id_ticket: ticket.id,
        action: "eliminar",
        description_action: "ha eliminado",
        creator: props.user.id,
        who: id,
      };
    }
    // No se encontro el elemento (adición del miembro)
    else {
      membersAdd.push({
        email: email,
        id_ticket: ticket.id,
        responsable: props.user.name,
        operation: operation,
        responsable_change: userActive.email,
      });
      IwillCallYou = true;
      action = "add";
      data = {
        id_user: id,
        id_ticket: ticket.id,
        action: "agregar",
        id_contact: id_contact,
        description_action: "ha añadido",
        creator: props.user.id,
        who: id,
        fullname_creator: props.user.name,
      };
    }

    if (IwillCallYou) {
      axios
        .post(constaApi + "members/" + action, data)
        .then(function (response) {
          setmembers(response.data.members);
          setactivities(response.data.activities);
        });
    }
  };

  const deleteComment = (activity , path_doc = -1) => {

    swal({
      title: "Se eliminara el archivo",
      text: "El archivo sera eliminado",
      icon: "warning",
      dangerMode: true,
      buttons: ["No", "Si"],
    }).then((willDelete) => {
      if (willDelete) {
        let data = ticket;
        data = {
          ...data,
          action: "eliminar",
          description_action: "ha realizado una eliminacion",
          comment: comment,
          creator: props.user.id,
          id_ticket: ticket.id,
          id_contact: id_contact,
          fullname_creator: props.user.name,
          id_activity: activity.id,
          path_doc: path_doc === -1 ? null : path_doc,
          name_file: activity.name_file ?? null,
          // type_doc: activity.type_doc ?? null
        };
        axios.post(constaApi + "activity/delete", data).then(function (response) {
          setactivities(response.data);
          onBlurComment();
          setComment("");
        });
      } else {
        swal("Operacion cancelada!");
      }
    });


    
  };

  const saveActivity = () => {
    setOperation("la Actividad");
    setChanges(true);
    let data = ticket;
    data = {
      ...data,
      action: "agregar",
      description_action: "ha agregado un commentario",
      comment: comment,
      creator: props.user.id,
      id_ticket: ticket.id,
      id_contact: id_contact,
      fullname_creator: props.user.name,
    };
    axios.post(constaApi + "activity/add", data).then(function (response) {
      setactivities(response.data);
      onBlurComment();
      setComment("");
    });
  };
  const saveChangeDescription = () => {
    setOperation("la Descripción");
    setChanges(true);
    let data = ticket;
    data = {
      ...data,
      action: "cambiar",
      description_action: "ha cambiado la descripción",
      creator: props.user.id,
      id_contact: id_contact,
      fullname_creator: props.user.name,
    };
    axios
      .post(constaApi + "editTicketProspection/description", data)
      .then(function (response) {
        setactivities(response.data);
        onBlurDescription();
        setEditDescription(!editDescription);
      });
  };
  const addActivity = (e) => {
    setComment(e.target.value);
  };
  const handleChangeDescription = (e) => {
    setTicket({ ...ticket, description: e.target.value });
  };

  // Methods to capture image / file

  function handleClose() {
    setSecondModal(false);
  }

  const onPastecaptureDescription = async (e) => {
    setOperation("los Archivos");
    setChanges(true);

    let must_upload = false;
    const isClipBoardorLoad = e.clipboardData?.files ? 'clip' : 'load';
    let item = isClipBoardorLoad == 'clip' ? e.clipboardData.files[0] : e.target.files[0]; 
    if (e) {
      let data = {
        id_user: props.user.id,
        id_ticket: ticket.id,
        action: "adjuntar",
        description_action: "ha adjuntado",
        creator: props.user.id,
        type_doc: "",
        id_contact: id_contact,
        file: item,
        fullname_creator: props.user.name,
      };
      if (item.type.indexOf("image/png") === 0) {
        data = { ...data, type_doc: "image" };
        must_upload = true;
      } else if (item.type.indexOf("application/pdf") === 0) {
        data = { ...data, type_doc: "pdf" };
        must_upload = true
      } else {
        must_upload = false
        notification("danger", "Formato no permitido.");
      }

      if( must_upload ) {
        let datax = new FormData();
        datax.append("id_user", data.id_user);
        datax.append("id_ticket", data.id_ticket);
        datax.append("action", data.action);
        datax.append("description_action", data.description_action);
        datax.append("creator", data.creator);
        datax.append("fullname_creator", data.fullname_creator);
        datax.append("type_doc", data.type_doc);
        datax.append("id_contact", data.id_contact);
        datax.append("file", data.file);
        axios({
          method: "post",
          url: constaApi + "activity/files/upload",
          data: datax,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
          setactivities(res.data);
        });
  
        setDoc(item);
      }
    }
  };

  const listenOnBlur = () => {
    setParam("");
  };

  // Popover Section

  const PopoverSection = (name, obj) => {
    const [bounds, setBounds] = useState([
      { name: "Prospección", value: "Prospección" },
      { name: "Aplicación", value: "Aplicación" },
      { name: "Seguimiento", value: "Seguimiento" },
      { name: "Otro", value: "Otro" },
    ]);

    useEffect(() => {}, []);

    return (
      <Popover id={"popover-program"} title="Popover bottom">
        <div style={{ width: "200px", height: "100%" }}>
          <div style={{ display: "flex" }}>
            <div>
              <strong style={{ marginLeft: "60px", marginTop: "1px" }}>
                Area
              </strong>
            </div>
          </div>
          <hr style={{ marginLeft: "5px", width: "90%" }}></hr>
          <div
            style={{
              display: "flex",
              width: "200px",
              justifyContent: "center",
            }}
          >
            <Form.Control
              value={param}
              onChange={(e) => findAtUser(e)}
              style={{
                width: "80%",
                backgroundColor: "white",
                border: "2px solid #0079BF",
              }}
              type="text"
              placeholder="Buscar Area..."
            />
          </div>
          <div
            style={{
              marginTop: "10px",
              marginLeft: "10%",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <strong style={{ marginTop: "1px" }}>Areas</strong>
          </div>
          <ul>
            {bounds.map((bound) => {
              return (
                <li
                  key={"liP" + bound.name}
                  style={{
                    marginLeft: "3%",
                    marginTop: "5px",
                    listStyle: "none",
                  }}
                >
                  <button
                    key={"btnRes" + bound.name}
                    style={{
                      width: "95%",
                      height: "38px",
                      backgroundColor: "#091e420a",
                    }}
                  >
                    <span
                      onClick={(e) => changeSection(bound.name)}
                      style={{
                        fontSize: "10px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span>{bound?.name?.toUpperCase()}</span>
                    </span>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </Popover>
    );
  };

  const PopoverProgram = (name, obj) => {
    const [bounds, setBounds] = useState([]);

    useEffect(() => {
      let idx = id ?? props.data.ticket.id_contact ?? id_contact;
      let tracki = "";
      let aplicati = "";
      axios
        .get(constaApi + "bound/" + idx)
        .then((response) => {
          const { data } = response;
          const { applications, prospection, trackings } = data;
          let result = [];
          if (applications) {
            applications.map((ap) => {
              if (ap.status == "Cancelado") {
              } else {
                result.push({
                  name: ap.name_prospection,
                  college: ap.name,
                  value: ap.id,
                  label: ap.name_prospection + "-" + ap.name,
                  type: "aplicacion",
                  color: "yellow",
                });

                // Save program
                aplicati = {
                  name: applications[applications.length - 1].name_prospection,
                  college: applications[applications.length - 1].name,
                };
              }
            });
          }
          if (trackings) {
            trackings.map((ap) => {
              if (ap.status == "Terminado") {
              } else {
                result.push({
                  name: ap.name_prospection,
                  college: ap.name,
                  value: ap.id,
                  label: ap.name_prospection + "-" + ap.name,
                  type: "Seguimiento",
                  color: "rgb(0, 255, 133)",
                });

                // Save program
                tracki = {
                  name: ap.name_prospection,
                  college: ap.name,
                };
              }
            });
          }
          if (prospection) {
            prospection.map((ap) => {
              if (ap.status == "cancelar") {
              } else {
                result.push({
                  name: ap.name_prospection,
                  college: ap.name,
                  value: ap.id,
                  label: ap.name_prospection,
                  type: "Prospeccion",
                  color: "rgb(0, 147, 255)",
                });
              }
            });
          }
          result.push({
            value: "General",
            label: "General",
            type: "General",
            color: "rgb(190, 192, 144)",
          });
          setBounds([...result]);

          if (props.data.ticket.section == "Seguimiento") {
            if (props.data.ticket.program == null) {
              addOrDeleteProgram(tracki);
            }
          }

          if (props.data.ticket.section == "Aplicación") {
            if (props.data.ticket.program == null) {
              addOrDeleteProgram(aplicati);
            }
          }
        })
        .catch((err) => {});
    }, []);

    return (
      <Popover id={"popover-program"} title="Popover bottom">
        <div style={{ width: "200px", height: "100%" }}>
          <div style={{ display: "flex" }}>
            <div>
              <strong style={{ marginLeft: "60px", marginTop: "1px" }}>
                Programas
              </strong>
            </div>
          </div>
          <hr style={{ marginLeft: "5px", width: "90%" }}></hr>
          <div
            style={{
              display: "flex",
              width: "200px",
              justifyContent: "center",
            }}
          >
            <Form.Control
              value={param}
              onChange={(e) => findAtUser(e)}
              style={{
                width: "80%",
                backgroundColor: "white",
                border: "2px solid #0079BF",
              }}
              type="text"
              placeholder="Buscar Programas..."
            />
          </div>
          <div
            style={{
              marginTop: "10px",
              marginLeft: "10%",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <strong style={{ marginTop: "1px" }}>Programas</strong>
          </div>
          <ul>
            {bounds.map((bound) => {
              return (
                <li
                  key={"liP" + bound.label}
                  style={{
                    marginLeft: "3%",
                    marginTop: "5px",
                    listStyle: "none",
                  }}
                >
                  <button
                    key={"btnRes" + bound.label}
                    style={{
                      width: "95%",
                      height: "38px",
                      backgroundColor: "#091e420a",
                    }}
                  >
                    <span
                      onClick={(e) => addOrDeleteProgram(bound)}
                      style={{
                        fontSize: "10px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span>
                        {bound?.type == "Prospeccion"
                          ? "[PRO]"
                          : bound.type == "Seguimiento"
                          ? "[SEG]"
                          : bound.type == "aplicacion"
                          ? "[APL]"
                          : ""}
                      </span>
                      <span>{bound?.label?.toUpperCase()}</span>
                    </span>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </Popover>
    );
  };

  // Popover Miembros
  const PopoverBottom = (name) => {
    useEffect(() => {
      let aux = [];
      axios.post(constaApi + "allUsersToTickets").then(function (response) {
        if (response.data) {
          aux = response.data.map((re) => {
            return {
              fullname: re.fullname,
              id: re.id,
              email: re.email,
            };
          });
        }
        setDataUsers(aux);
      });
    }, []);

    const checkIfExist = (user) => {
      let tag = "";
      members.map((member) => {
        if (user.fullname == member.fullname) {
          tag = <FCIcons.FcCheckmark size={32} />;
        }
      });

      return tag;
    };

    return (
      <Popover id={name} title="Popover bottom">
        <div style={{ width: "200px", height: "100%" }}>
          <div style={{ display: "flex" }}>
            <div>
              <strong style={{ marginLeft: "60px", marginTop: "1px" }}>
                Miembros
              </strong>
            </div>
          </div>
          <hr style={{ marginLeft: "5px", width: "90%" }}></hr>
          <div
            style={{
              display: "flex",
              width: "200px",
              justifyContent: "center",
            }}
          >
            <Form.Control
              value={param}
              onChange={(e) => findAtUser(e)}
              style={{
                width: "80%",
                backgroundColor: "white",
                border: "2px solid #0079BF",
              }}
              type="text"
              placeholder="Buscar miembros..."
            />
          </div>
          <div
            style={{
              marginTop: "10px",
              marginLeft: "10%",
              display: "flex",
              justifyContent: "left",
            }}
          >
            <strong style={{ marginTop: "1px" }}>Miembros del tablero</strong>
          </div>
          <ul
            style={{
              overflowY: "scroll",
              overflowX: "visible",
              height: "350px",
            }}
          >
            {param != ""
              ? [
                  resultofSearch.map((re) => {
                    return (
                      <li
                        key={"liR" + re.id}
                        style={{
                          marginLeft: "3%",
                          marginTop: "5px",
                          listStyle: "none",
                        }}
                      >
                        <button
                          key={"btnRes" + re.id}
                          style={{
                            width: "90%",
                            height: "38px",
                            backgroundColor: "#091e420a",
                          }}
                        >
                          <span
                            onClick={(e) => addorDeleteMember(re.id, re.email)}
                            style={{
                              display: "flex",
                              justifyContent: "left",
                              marginLeft: "5px",
                            }}
                          >
                            {formatterUser(re)}
                            <span className="formaterName">
                              {re?.fullname?.toUpperCase()}
                            </span>
                          </span>
                        </button>
                      </li>
                    );
                  }),
                ]
              : [
                  dataUsers.map((dUser) => {
                    return (
                      <li
                        key={"liR" + dUser.id}
                        style={{
                          marginLeft: "3%",
                          marginTop: "5px",
                          listStyle: "none",
                        }}
                      >
                        <button
                          key={"btnRes" + dUser.id}
                          style={{
                            width: "90%",
                            height: "38px",
                            backgroundColor: "#091e420a",
                          }}
                        >
                          <span
                            onClick={(e) =>
                              addorDeleteMember(dUser.id, dUser.email)
                            }
                            style={{
                              display: "flex",
                              justifyContent: "left",
                              marginLeft: "5px",
                            }}
                          >
                            {formatterUser(dUser)}
                            <span className="formaterName">
                              {dUser?.fullname?.toUpperCase()}
                            </span>
                            {checkIfExist(dUser)}
                          </span>
                        </button>
                      </li>
                    );
                  }),
                ]}

            {/* {param != ""
              ? [
                  resultofSearch.map((re) => {
                    return (
                      <li
                        key={"liR" + re.id}
                        style={{
                          marginLeft: "3%",
                          marginTop: "5px",
                          listStyle: "none",
                        }}
                      >
                        <button
                          key={"btnRes" + re.id}
                          style={{
                            width: "90%",
                            height: "38px",
                            backgroundColor: "#091e420a",
                          }}
                        >
                          <span
                            onClick={(e) => addorDeleteMember(re.id, re.email)}
                            style={{
                              display: "flex",
                              justifyContent: "left",
                              marginLeft: "5px",
                            }}
                          >
                            {formatterUser(re)}
                            <span className="formaterName">
                              {re?.fullname?.toUpperCase()}
                            </span>
                          </span>
                        </button>
                      </li>
                    );
                  }),
                ]
              : [
                  members.map((member) => {
                    return (
                      <li
                        key={"li" + member.id_user}
                        style={{ marginLeft: "3%", listStyle: "none" }}
                      >
                        <button
                          key={"btn" + member.id_user}
                          style={{
                            width: "90%",
                            height: "38px",
                            backgroundColor: "#091e420a",
                          }}
                        >
                          <span
                            onClick={(e) => addorDeleteMember(member.id_user)}
                            style={{
                              display: "flex",
                              justifyContent: "left",
                              marginLeft: "5px",
                            }}
                          >
                            {formatterUser(member)}
                            <span className="formaterName">
                              {member?.fullname?.toUpperCase()}
                            </span>
                            <FCIcons.FcCheckmark size={32} />
                          </span>
                        </button>
                      </li>
                    );
                  }),
                ]} */}
          </ul>
        </div>
      </Popover>
    );
  };

  // Popover Etiqueta

  const PopoverTag = (name, id_ticket) => {
    const [etiquetas, setEtiquetas] = useState([
      { name: "Etiqueta 1", color: "#3ac0d8" },
      { name: "Etiqueta 2", color: "#3ad88f" },
      { name: "Etiqueta 3", color: "#3db070" },
      { name: "Etiqueta 4", color: "#497cff" },
      { name: "Etiqueta 5", color: "#adc4fd" },
      { name: "Etiqueta 6", color: "#ff0000" },
      { name: "Etiqueta 7", color: "#6c6c6c" },
      { name: "Etiqueta 8", color: "#c1c1c2" },
      { name: "Etiqueta 9", color: "#ffd200" },
      { name: "Etiqueta 10", color: "#9B03AE" },
    ]);

    useEffect(() => {}, []);

    return (
      <Popover id={name} title="Popover tag">
        <div style={{ width: "200px", height: "100%" }}>
          <div style={{ display: "flex" }}>
            <div>
              <strong style={{ marginLeft: "60px", marginTop: "1px" }}>
                Etiquetas
              </strong>
            </div>
          </div>
          <hr style={{ marginLeft: "5px", width: "90%" }}></hr>
          <div
            style={{
              display: "flex",
              width: "200px",
              justifyContent: "center",
            }}
          ></div>

          <ul>
            {etiquetas.map((re) => {
              return (
                <li
                  key={"liR" + re.id}
                  style={{
                    marginLeft: "3%",
                    marginTop: "5px",
                    listStyle: "none",
                  }}
                >
                  <button
                    key={"btnRes" + re.id}
                    style={{
                      backgroundColor: re.color,
                      width: "90%",
                      height: "38px",
                    }}
                  >
                    <span
                      onClick={(e) => addOrDeleteTag(ticket.id, re)}
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        marginLeft: "5px",
                      }}
                    >
                      <span>{re?.name}</span>
                    </span>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </Popover>
    );
  };

  const petitionPdf = (value, file) => {
    if (file) {
      var fileURL = URL.createObjectURL(file);
      setUrlx(fileURL);
      setSecondModal(true);
    }

    // var win = window.open();
    //   win.document.write('<iframe src="' + fileURL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
  };

  const clickEvent = (data) => {
    let direction = data.direction;
    let nameImg = data.nameImg;
    let value = data.value;
    let file = data.file;

    if (file) {
      if (file.type == "application/pdf") {
        petitionPdf(value, file);
      } else {
        setFullImg(direction);
        setNameImg(nameImg);
        let a = document.querySelector(".imgs").click();
      }
    }
  };

  const countFiles = (activities) => {
    if (activities.length) {
      const activitie = activities.filter(
        (activiti) => activiti.type_doc == "image" || activiti.type_doc == "pdf"
      );
      if (activitie.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const showDate = (dateBD) => {
    let datef = moment(dateBD).locale("es-mx").format("ddd D MMMM, YYYY ");
    let timef = moment(dateBD).locale("es-mx").add(0, "hours").format("h:mm A");
    datef = datef[0].toUpperCase() + datef.slice(1);
    datef = datef.replace(".", "");
    let tag = (
      <span className="Inter">
        {datef} <Ioicons.IoMdTime /> {timef}
      </span>
    );
    return tag;
  };

  const justActivities = (activity) => {
    let tag = "";
    if (activity.type_doc == null) {
      tag = (
        <li
          style={{
            display: "flex",
            marginTop: "1%",
            justifyContent: "left",
            listStyle: "none",
          }}
        >
          <span>{formatterUser(activity)}</span>
          <span
            style={{
              marginTop: activity.comment != null ? "1%" : "-5px",
              marginLeft: "5px",
              backgroundColor: activity.comment != null ? "white" : "inherit",
              border: activity.comment != null ? "none" : "none",
              padding: "5px 5px",
              fontSize: "16px",
              boxShadow:
                activity.comment != null
                  ? "var(--ds-shadow-raised,0 1px 2px -1px #091e4240,0 0 0 1px var(--ds-border,#091e4214))"
                  : "none",
            }}
          >
            {activity.comment != null ? (
              <label
                style={{
                  color: "black",
                  border: "none",
                  textDecoration: "none",
                  padding: "2px 2px",
                  fontSize: "14px",
                  fontFamily:
                    '"Montserrat", "Helvetica Neue", "Arial", "sans-serif"',
                }}
                onClick={(e) => showEditDescription(e)}
              >
                <div
                id="boxLinkify"
                  style={{
                    whiteSpace: "pre-wrap",
                    fontSize: "16px",
                    padding: "1px 1px 1px 1px",
                  }}
                >
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a target="blank" href={decoratedHref} id="specialLinkHref" key={key}>
                        {" "}
                        {decoratedText}{" "}
                      </a>
                    )}
                  >
                    {activity.comment}
                  </Linkify>
                </div>
              </label>
            ) : (
              [
                activity.description_action == "ha añadido" ? (
                  <>
                    <span>ha añadido a </span>
                    {preFormatterUser(activity.id_memberAdded)}
                  </>
                ) : (
                  [
                    activity.description_action == "ha eliminado" ? (
                      <>
                        <span>ha eliminado a </span>
                        {preFormatterUser(activity.id_memberAdded)}
                      </>
                    ) : (
                      [activity.description_action ?? activity.action]
                    ),
                  ]
                ),
              ]
            )}
            <br>
            </br>
            <span
              style={{
                fontStyle: "italic",
                marginleft: "10px",
                fontSize: "12px",
              }}
              >
              &nbsp;&nbsp;&nbsp;
              {showDate(activity.created_at)}
            </span>

            {activity.comment != null && (
              <span
                onClick={(e) => deleteComment(activity)}
                style={{
                  textDecoration: "underline",
                  fontStyle: "italic",
                  marginleft: "10px",
                  fontSize: "12px",
                }}
              >
                &nbsp;&nbsp;&nbsp; Eliminar
              </span>
            )}
          </span>
        </li>
      );
    } else {
      tag = <></>;
    }
    return tag;
  };

  const multipleRender = (activity) => {
    let tag = "";
    if (activity.type_doc == "image" || activity.type_doc == "pdf") {
      tag = (
        <>
        <FaIcons.FaTrashAlt
                              style={{position:'absolute',left:'20%',marginTop:'10px' ,color: "#DC3545" }}
                              size={18}
                              onClick={(e) => {
                                deleteComment(activity,activity.path_doc);
                              }}
                            />
          <PreviewImages
          clickEvent={(e) => clickEvent(e)}
          path_doc={activity.path_doc}
          />
        </>
      );
    } else {
      tag = "";
    }
    return tag;
  };

  const closedOpenTicket = () => {
    setChanges(true);

    swal({
      title: "Estas seguro?",
      text: "Una vez cerrado,no podras volver a reactivar este Ticket!",
      icon: "warning",
      dangerMode: true,
      buttons: ["No", "Si"],
    }).then((willDelete) => {
      if (willDelete) {
        let data = ticket;
        data = {
          ...data,
          open: ticket.open == 1 ? 0 : 1,
          action: "cerrar",
          id_contact: id_contact,
          description_action:
            ticket.open == 1 ? "ha cerrado el Ticket" : "ha abierto el ticket",
          creator: props.user.id,
          fullname_creator: props.user.name,
        };
        axios
          .post(constaApi + "editTicketProspection/status", data)
          .then(function (response) {
            setactivities(response.data.activities);
            setTicket(response.data.ticket);
            onBlur();
          });
      } else {
        swal("Operacion cancelada!");
      }
    });
  };

  const changeLocation = (e) => {
    setShowLocation(!showLocation);
  };
  const changeSection = (e) => {
    setSectionwaschanged(true);
    setChanges(true);
    setOperation("la sección");

    let data = ticket;
    data = {
      ...data,
      open: ticket.open == 1 ? 0 : 1,
      section: e,
      id_contact: id_contact,
      action: "Ha movido este ticket",
      creator: props.user.id,
      fullname_creator: props.user.name,
    };
    axios
      .post(constaApi + "editTicketProspection/section", data)
      .then(function (response) {
        setactivities(response.data.activities);
        setTicket(response.data.ticket);
        onBlur();
        changeLocation();
      });
  };

  const onBlurSelectSection = () => {
    onBlur();
    changeLocation();
  };

  const whoIsCreator = () => {
    const member = members.find((mem) => mem.id_user == activities[0].creator);

    return member;
  };

  const showEditDescription = () => {
    setEditDescription(!editDescription);
    setfocuseDescription(!focuseDescription);
  };
  return (
    <>
      <div style={{ width: "5px", height: "5px" }}>
        <ModalImage
          style={{ width: "500px" }}
          hidde={true}
          className="imgs"
          small={fullImg}
          medium={fullImg}
          large={fullImg}
          alt={nameImg}
        />
      </div>
      <Modal
        enforceFocus={false}
        size="lg"
        className="modalTicket"
        show={props.show}
        onHide={(e) => validationIfthereDescription()}
      >
        <Modal.Header closeButton>
          <NotificationAlert ref={notificationAlert} />
          <GRicons.GrScorecard size={20} id="cardIcon" />
          <Form.Control
            onChange={(e) => changeTitle(e)}
            value={ticket.title}
            as="textarea"
            style={{
              fontSize: "20px",
              height: "36px",
              fontWeight: "600",
              margin: "-8px 2px",
              padding: "0px 8px 10px 10px",
              minHeight: "24px",
              color: "#253858",
              borderRadius: "3px",
              overflow: "hidden",
              border: focused ? " 2px solid #0079BF" : "none",
              backgroundColor: focused ? "white" : "#F4F5F7",
            }}
            onFocus={onFocus}
            onBlur={(e) => updateTicket(e)}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="father">
            <div className="workspace1">
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#253858",
                  fontWeight: "600",
                  marginTop: "-3%",
                  marginLeft: "4%",
                }}
              >
                {ticket.who ?? ""}
              </p>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#253858",
                  fontWeight: "600",
                  marginTop: "-2%",
                  marginLeft: "4%",
                }}
              >
                En {ticket.section}
              </p>
              <h3
                style={{
                  marginLeft: "4%",
                  fontSize: "12px",
                  fontWeight: "600",
                  lineHeight: "20px",
                  color: "#5E6C84",
                  display: "inline",
                  marginRight: "5px",
                }}
              >
                Creador por :
              </h3>{" "}
              {formatterUser(ticket)}
              
              {/* 
  const [showLocation,setShowLocation] = useState(false);
  const [location,setLocation] = useState(""); */}
              <h3
                style={{
                  marginTop: "10px",
                  marginLeft: "4%",
                  fontSize: "12px",
                  fontWeight: "600",
                  lineHeight: "20px",
                  color: "#5E6C84",
                  display: "block",
                }}
              >
                Miembros
              </h3>
              <div style={{ marginLeft: "4%", marginTop: "-4%" }}>
                {members.map((member) => {
                  return formatterUser(member);
                })}
                <OverlayTrigger
                  onExit={listenOnBlur}
                  trigger={"click"}
                  rootClose
                  placement="bottom"
                  overlay={PopoverBottom("popover-left")}
                >
                  <span
                    style={{ backgroundColor: "#EAECEF", color: "#EAECEF" }}
                    className="ml-1 sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnP"
                  >
                    <GRicons.GrAdd />
                  </span>
                </OverlayTrigger>
                ;
                <button
                  style={{ color: "white", backgroundColor: ticket.color }}
                  className={"status mr-1"}
                >
                  <AIcons.AiFillTag style={{ marginLeft: "-20%" }} size={16} />{" "}
                  {ticket.tag_name}
                </button>
                <OverlayTrigger
                  key={"Overlay" + ticket.program}
                  trigger={["hover", "hover"]}
                  placement="top"
                  overlay={PopoverComponent(
                    ticket.program + " - " + ticket.college
                  )}
                >
                  <button
                    style={{ color: "black", backgroundColor: "#FFFFFF" }}
                    className={"status mr-1"}
                  >
                    <AIcons.AiFillTag
                      style={{ marginLeft: "-20%" }}
                      size={16}
                    />{" "}
                    Programa
                  </button>
                </OverlayTrigger>
                ;
                <button
                  onClick={(e) => closedOpenTicket(e)}
                  className={ticket.open == 1 ? "status open" : "status closed"}
                >
                  {ticket.open == 1 ? "Abierto" : "Cerrado"}
                </button>
              </div>
              {/* Descripcion */}
              <div
                style={{ display: "flex", direction: "row", color: "black" }}
              >
                <div style={{ marginTop: "10%" }}>
                  <FaIcons.FaBars size={26} />
                </div>
                <div style={{ marginTop: "10.5%" }}>
                  <span
                    style={{
                      cursor: "cell",
                      lineHeight: "20px",
                      marginLeft: "10px",
                      fontSize: "20px",
                    }}
                  >
                    Descripción
                  </span>
                  <div
                    style={{ width: "100%", marginTop: "2%", marginLeft: "2%" }}
                  >
                    {editDescription ? (
                      <Form.Control
                        onPaste={(e) => onPastecaptureDescription(e)}
                        onChange={(e) => handleChangeDescription(e)}
                        value={ticket.description}
                        as="textarea"
                        style={{
                          minHeight: "100px",
                          maxHeight: "100%",
                          width: "500px",
                          resize: "none",
                          overflow: "hidden",
                          overflowWrap: "break-word",
                          textDecoration: "none",
                          padding: "5px 5px",
                          backgroundColor: focuseDescription
                            ? "white"
                            : "#E0E3E8",
                          border: focuseDescription
                            ? "2px solid #0079BF"
                            : "none",
                        }}
                        placeholder="Añadir una descripción mas detallada..."
                        onFocus={onFocusDescription}
                      />
                    ) : (
                      <label
                        style={{
                          color: "#5E6C84",
                          border: "none",
                          backgroundColor: "#E0E3E8",
                          textDecoration: "none",
                          padding: "10px 10px",
                          fontSize: "14px",
                          fontFamily:
                            '"Montserrat", "Helvetica Neue", "Arial", "sans-serif"',
                        }}
                        onDoubleClick={ (e) => showEditDescription(e)}
                        // onClick={(e) => showEditDescription(e)}
                      >
                        <div
                          style={{
                            whiteSpace: "pre-wrap",
                            color: "#7A859C",
                            fontSize: "16px",
                            padding: "1px 1px 1px 1px",
                          }}
                        >
                          <Linkify
                            componentDecorator={(
                              decoratedHref,
                              decoratedText,
                              key
                            ) => (
                              <a target="blank" href={decoratedHref} key={key}>
                                {" "}
                                {decoratedText}{" "}
                              </a>
                            )}
                          >
                            {ticket.description
                              ? [ticket.description]
                              : "Añadir una descripción mas detallada..."}{" "}
                          </Linkify>
                        </div>
                      </label>
                    )}

                    {focuseDescription && (
                      <>
                        <button
                          onClick={(e) => saveChangeDescription()}
                          className="btn btn-info"
                        >
                          Guardar
                        </button>
                        <button
                          onClick={(e) => showEditDescription()}
                          className="btn btn-disabledForce"
                        >
                          Cancelar
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* Archivos */}
              {/* {countFiles(activities) > 0 && ( */}
                <div
                  style={{ display: "flex", direction: "row", color: "black" }}
                >
                  <div style={{ marginTop: "10%" }}>
                 <label>
                 <input type="file" accept="image/gif,image/jpeg,image/png,.pdf" onChange={(e) => onPastecaptureDescription(e)}style={{display:'none'}} />
                 <FIcons.FiPaperclip size={26} />
                 </label>
                  </div>
                  <div style={{ marginTop: "10.5%" }}>
                    <span
                      style={{
                        cursor: "cell",
                        lineHeight: "20px",
                        marginLeft: "10px",
                        fontSize: "20px",
                      }}
                    >
                      Adjuntos
                    </span>
                    <div
                      style={{
                        width: "100%",
                        marginTop: "2%",
                        marginLeft: "2%",
                      }}
                    >
                      {activities.length && [
                        activities.map((act) => {
                          {
                            return multipleRender(act);
                          }
                        }),
                      ]}
                    </div>
                  </div>
                </div>
              // )}
              {/* Actividad */}
              <div
                style={{ display: "flex", direction: "row", color: "black" }}
              >
                <div style={{ marginTop: "10%" }}>
                  <FaIcons.FaBars size={26} />
                </div>
                <div style={{ marginTop: "10.5%" }}>
                  <span
                    style={{
                      cursor: "cell",
                      lineHeight: "20px",
                      marginLeft: "10px",
                      fontSize: "20px",
                    }}
                  >
                    Actividad
                  </span>
                  <div
                    style={{ width: "100%", marginTop: "2%", marginLeft: "2%" }}
                  >
                    <Form.Control
                      value={comment}
                      onPaste={(e) => onPastecaptureDescription(e)}
                      onChange={(e) => addActivity(e)}
                      as="textarea"
                      style={{
                        marginLeft: "-25px",
                        width: "500px",
                        minHeight: "40px",
                        maxHeight: "100%",
                        height: "40px",
                        resize: "none",
                        overflow: "hidden",
                        overflowWrap: "break-word",
                        textDecoration: "none",
                        padding: "5px 5px",
                        backgroundColor: focusComment ? "white" : "#E0E3E8",
                        border: focusComment ? "2px solid #0079BF" : "none",
                      }}
                      placeholder="Escriba un comentario.."
                      ref={textAreaRef}
                      id="review-text"
                      onFocus={onFocusComment}
                    />
                    {focusComment && (
                      <>
                        <button
                          onClick={(e) => saveActivity()}
                          className="btn btn-info"
                        >
                          Guardar
                        </button>
                        <button
                          onClick={(e) => onBlurComment()}
                          className="btn btn-disabledForce"
                        >
                          Cancelar
                        </button>
                      </>
                    )}
                  </div>
                  {/* Archivos */}

                  <div
                    style={{
                      display: "flex",
                      direction: "row",
                      color: "black",
                    }}
                  >
                    <div style={{ marginTop: "10%" }}></div>
                    <div style={{ marginTop: "10.5%" }}>
                      <div
                        style={{
                          width: "100%",
                          marginTop: "2%",
                          marginLeft: "2%",
                        }}
                      >
                        {activities.length && [
                          activities.map((act) => {
                            {
                              return justActivities(act);
                            }
                          }),
                        ]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Columna 2  recargada a la derecha */}
            <div className="workspace2">
              <h3
                style={{
                  marginTop: "-10%",
                  fontSize: "12px",
                  fontWeight: "600",
                  lineHeight: "20px",
                  color: "#5E6C84",
                  display: "block",
                }}
              >
                Añadir a el ticket
              </h3>

              <div style={{ alignItems: "left" }}>
                <OverlayTrigger
                  onExit={listenOnBlur}
                  trigger={"click"}
                  rootClose
                  placement="bottom"
                  overlay={PopoverSection("popover-right")}
                >
                  <button
                    style={{
                      position: "left",
                      display: "flex",
                      marginleft: "0px",
                      paddingLeft: "0px",
                    }}
                    className="btnMiembros"
                  >
                    <GRicons.GrAccessibility style={{}} size={16} />
                    Area
                  </button>
                </OverlayTrigger>
              </div>

              <div style={{ alignItems: "left" }}>
                <OverlayTrigger
                  onExit={listenOnBlur}
                  trigger={"click"}
                  rootClose
                  placement="bottom"
                  overlay={PopoverBottom("popover-right")}
                >
                  <button className="btnMiembros">
                    <AIcons.AiOutlineUser
                      style={{ marginLeft: "-50%", marginTop: "-5px" }}
                      size={16}
                    />
                    Miembros
                  </button>
                </OverlayTrigger>
              </div>

              <div style={{ alignItems: "left" }}>
                <OverlayTrigger
                  onExit={listenOnBlur}
                  trigger={"click"}
                  rootClose
                  placement="bottom"
                  overlay={PopoverTag("popover-tag", ticket.id)}
                >
                  <button className="btnMiembros">
                    <AIcons.AiFillTag
                      style={{ marginLeft: "-60%", marginTop: "-5px" }}
                      size={16}
                    />
                    Etiqueta
                  </button>
                </OverlayTrigger>
              </div>
              <div style={{ alignItems: "left" }}>
                <OverlayTrigger
                  onExit={listenOnBlur}
                  trigger={"click"}
                  rootClose
                  placement="bottom"
                  overlay={PopoverProgram("popover-program", ticket)}
                >
                  <button className="btnMiembros">
                    <AIcons.AiFillTag
                      style={{ marginLeft: "-50%", marginTop: "-5px" }}
                      size={16}
                    />
                    Programas
                  </button>
                </OverlayTrigger>
              </div>
              <button disabled className="btnMiembros"></button>
            </div>
          </div>
        </Modal.Body>
        {/* I dont know , but this table should are hidden */}
        <div
          hidden={true}
          className="ag-theme-alpine"
          style={{ height: "100%", width: "100%" }}
        >
          <AgGridReact
            rowData={dataUsers}
            rowHeight={40}
            cellStyle={{
              fontFamily: "Montserrat,sans-serif",
              fontSize: "13px",
              fontWeight: "500",
              color: "#3B3B3B",
            }}
            domLayout="autoHeight"
            onGridReady={onGridReady}
          >
            {/* Column Name */}
            <AgGridColumn
              cellStyle={{
                fontFamily: "Montserrat,sans-serif",
                fontSize: "13px",
                fontWeight: "500",
                color: "#3B3B3B",
              }}
              cellRenderer="slotName"
              headerName="Nombre"
              sortable={true}
              field="fullname"
              width="300"
            />
            {/* Column Email */}

            <AgGridColumn
              cellStyle={{
                fontFamily: "Montserrat,sans-serif",
                fontSize: "13px",
                fontWeight: "500",
                color: "#3B3B3B",
              }}
              headerName="Email"
              sortable={true}
              field="email"
              width="300"
            />
          </AgGridReact>
        </div>
      </Modal>

      <Modal
        style={{ marginTop: "-50px" }}
        show={showModal}
        dialogClassName="modal-100w"
        onHide={handleClose}
      >
        <Modal.Header style={{ height: "10px" }} closeButton></Modal.Header>
        <Modal.Body style={{ background: "#F4F5F6", border: "0px" }}>
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              src={urlX}
              allowfullscreen
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
