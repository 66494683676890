import React , { useRef , useEffect , useState} from 'react'
import logo from '../../../resources/images/blinkLogo.png';

import {
  BrowserRouter as Router, Switch,
  Route, Link, useLocation
} from 'react-router-dom';


import './style/sidebar.css'
import axios from 'axios';
import { constaApi } from '../../../constants/constants';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { SlotNameFormatted } from '../../../components/contactComponents/SlotContacts.jsx';
import { SlotRef1 } from '../../../components/contactComponents/SlotContacts.jsx';
import { SlotRef2 } from '../../../components/contactComponents/SlotContacts.jsx';
import { SlotRef3 } from '../../../components/contactComponents/SlotContacts.jsx';
import { SlotRating } from '../../../components/ApplicationSectionComponent/TableApplication';
import { SlotActions } from '../../../components/ProspectionSectionComponent/TableVinculated';
import { SlotName } from '../../../components/AnaliticsComponents/AnaliticsSection';
import { SlotOrigin } from '../../../components/AnaliticsComponents/AnaliticsSection';
import { SlotProgram } from '../../../components/ApplicationSectionComponent/TableTotals';
import { SlotReferences } from '../../../components/contactComponents/TableContacts.jsx';
import { useDispatch } from 'react-redux';
import { activeContact } from '../../../actions/contacts/contacts/contacts';
import { NavLink, OverlayTrigger, Popover } from 'react-bootstrap';
import routes from '../../../routes';

export default function SideBar(props) {


  // Table grid




  const [frameworkComponents, setFramwrokw] = useState({ 
    slotnameF: SlotNameFormatted,
    slotRef1: SlotRef1,
    slotRef2: SlotRef2,
    slotRef3: SlotRef3,
    slotRating: SlotRating,
    slotActions: SlotActions,
    slotName: SlotName,
    slotOrigin: SlotOrigin,
    slotProgram: SlotProgram,
    slotReferences: SlotReferences,
  
  });

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [arrayOfContacts, setArrayOfContacts] = useState([]);


  // refs
  const sideBarRef = useRef();
  const searchBarRef = useRef();
  const toggleRef = useRef();
  const [isExpendible,setIsExpandible] = useState(false);
  const [resultOfSearch,setResultofSearch] = useState([]);
  const dispatch = useDispatch();

  // var Generals
  const { pathname } = useLocation();
  const [routesWithPermissions,setRoutesWithPermissions] = useState([]);


  // Methods



  useEffect(()=>{

    const result = JSON.parse(localStorage.getItem('user')) ;
    const id = result?.id;
    
    if(routesWithPermissions.length == 0){
       hasPermissions(id);
      }
    consultContacts();

  },[])

  const hasPermissions = async (id) =>{
    let data = [];
    axios.post(constaApi + 'getPermissionsByContact', { id: id }, {
    }).then(function (response) {
        // setText('Espere...');
        data = response.data;

        let aux = [];
      routes.map(route =>{
        data.map(dat => {
          if(route.name == dat.module){
            aux.push(route);
          }
        })
      })
      setRoutesWithPermissions(aux);
      });

      

    }


  const onGridReady = (params) => {
    setGridApi(params);
    setGridColumnApi(params);
  };


  const consult = (e)  => {
    if (e.target.value) {
      let fullnameFormated = e.target.value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      consultTwo(fullnameFormated);
    } else {
      setResultofSearch([]);
    }
  }


  const consultTwo = (value) => {
    let objx = gridApi;
    value === "keyWordSeccret302"
      ? objx.api.setQuickFilter("")
      : objx.api.setQuickFilter(value);
      setGridApi(objx);
      let rowsDisplay = objx.api.rowRenderer.rowModel.rowsToDisplay;
    let result;
    if (rowsDisplay) {
      result = rowsDisplay.map((row) => {
        let { data } = row;
        return data;
      });
      setResultofSearch(result);
    }
  }


    const consultContacts = async ()  => {
     axios
      .post(constaApi + "searchBarResults", {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        const { data: dx } = response;

        let array = [];
        dx.map((d) => {
          let obj = {
            ...d,
            otherName: d.fullname
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, ""),
            ref1: d.search_bar_references[0]
              ? d.search_bar_references[0].name +
                " " +
                d.search_bar_references[0].father_lastname +
                " " +
                (d.search_bar_references[0].mother_lastname ?? " ")
              : " ",
            ref2: d.search_bar_references[1]
              ? d.search_bar_references[1].name +
                " " +
                d.search_bar_references[1].father_lastname +
                " " +
                d.search_bar_references[1].mother_lastname
              : " ",
            ref3: d.search_bar_references[2]
              ? d.search_bar_references[2].name +
                " " +
                d.search_bar_references[2].father_lastname +
                " " +
                d.search_bar_references[2].mother_lastname
              : " ",
            ref1D: (
              d.search_bar_references[0]
                ? d.search_bar_references[0].name +
                    " " +
                    d.search_bar_references[0].father_lastname ??
                  "" + " " + d.search_bar_references[0].mother_lastname ??
                  " "
                : null
            )
              ? (
                  d.search_bar_references[0].name +
                    " " +
                    d.search_bar_references[0].father_lastname ??
                  " " + " " + d.search_bar_references[0].mother_lastname ??
                  " "
                )
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              : "",
            ref2D: (
              d.search_bar_references[1]
                ? d.search_bar_references[1].name +
                    " " +
                    d.search_bar_references[1].father_lastname ??
                  "" + " " + d.search_bar_references[1].mother_lastname ??
                  " "
                : null
            )
              ? (
                  d.search_bar_references[1].name +
                    " " +
                    d.search_bar_references[1].father_lastname ??
                  " " + " " + d.search_bar_references[1].mother_lastname ??
                  " "
                )
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              : "",
            ref3D: (
              d.search_bar_references[2]
                ? d.search_bar_references[2].name +
                    " " +
                    d.search_bar_references[2].father_lastname ??
                  "" + " " + d.search_bar_references[2].mother_lastname ??
                  " "
                : null
            )
              ? (
                  d.search_bar_references[2].name +
                    " " +
                    d.search_bar_references[2].father_lastname ??
                  " " + " " + d.search_bar_references[2].mother_lastname ??
                  " "
                )
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              : "",

            //   // ref3D: (d.search_bar_references[2] ? d.search_bar_references[2].name + " " + d.search_bar_references[2].father_lastname ?? "" + " " + d.search_bar_references[2].mother_lastname ?? " " : null) ? (d.search_bar_references[2].name + " " + d.search_bar_references[2].father_lastname ?? " " + " " + d.search_bar_references[2].mother_lastname ?? " ").normalize(" // ref1D: (d.search_bar_references[0] ? d.search_bar_references[0].name + " " + d.search_bar_references[0].father_lastname ?? "" + " " + d.search_bar_references[0].mother_lastname ?? " " : null) ? (d.search_bar_references[0].name + " " + d.search_bar_references[0].father_lastname ?? " " + " " + d.search_bar_references[0].mother_lastname ?? " ").normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            //   // ref2D: (d.search_bar_references[1] ? d.search_bar_references[1].name + " " + d.search_bar_references[1].father_lastname ?? "" + " " +
            //   d.search_bar_references[1].mother_lastname ?? " " : null) ?
            //   (d.search_bar_references[1].name + " " + d.search_bar_references[1].father_lastname ??
            //    " " + " " + d.search_bar_references[1].mother_lastname ?? " ").normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            //  NFD").replace(/[\u0300-\u036f]/g, "") : "",
          };
          array.push(obj);
        });
        setArrayOfContacts(array);
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const showName = (contact,id = null, fullname = "") => {
    const click = () => {
    dispatch( activeContact(contact) );
    }
    let n = fullname ? fullname.split(" ") : " ";
    let tag = '';
    n = n ? n[0].charAt(0) + n[1].charAt(0) : null;
    tag = <>
        <Link  onClick={(e) => click(props.data)} to={"/contacts/"+id+"/Interdashboard"} action="replace">
            <span className="mt-1 mr-2 sc-caSCKo fXbvQO styles__Company-sc-103gogw-0 jdbeFR forcedShow">{n}</span>
            <span className='forcedShow' >{fullname} </span>
        </Link>
    </>;
    return tag;
}


const  activeRoute = (routeName) =>{
  return pathname.indexOf(routeName) > -1 ? 1 : 0;
}
  const clickToggle = () =>{
    setIsExpandible(!isExpendible);
    sideBarRef.current.classList.toggle('cerrar');
    toggleRef.current.classList.toggle('show');
    props.fromSidebar();
  }

  const clickSearchBar = () =>{
    // consultContacts();
    setIsExpandible(!isExpendible);
    sideBarRef.current.classList.toggle('cerrar');
    toggleRef.current.classList.toggle('show');
    searchBarRef.current.focus();
    props.fromSidebar();

  }


  const updatePermissions = () =>{


  }
  const PopoverComponent = (text) => {
    return (
    <Popover  id="popover-basic">
        <Popover.Content >
            <strong>{text}</strong>
        </Popover.Content>
    </Popover>)
}
  // Box Icon library
  // ExternalLinksHook("https://unpkg.com/boxicons@2.1.4/dist/boxicons.js")

  return (
    <nav style={{overflow:'auto'}} ref={sideBarRef} className='newsidebar cerrar'>

      <header>

        <div className='image-text'>

          <span className='image'>

            <img src={logo} alt=" Blink logo">

            </img>

          </span>

          <div className='text header-text'>

            <span className='name'> Blink </span>

            <span className='profession'>Sistema de estudiantes </span>


          </div>

        </div>

        {/* <i className='bx bx-chevron-right toggle'>IIIIIIIIIIIIIIIIIIIIIIIII</i>     
        <i class='bx bx-chevron-right toggle'>HOLI </i> */}
        <i onClick={(e) => clickToggle(e)} ref={toggleRef} className='bx bx-chevron-left show'></i>
      </header>

      <div className='menu-bar'>

        <div  className='menu'>

          <li  className='search-box'>


            <i onClick={(e) => clickSearchBar(e) }  className='bx bx-search icon' ></i>
            <input ref={searchBarRef}  onChange={(e) => consult(e)} type="search" placeholder='Search...'></input>

            {isExpendible &&  
            <div 
            style={{
              justifyContent:'center',
              alignItems:'center',
              backgroundColor: "#1E1E1E",
              width: "380px",
              position: "fixed",
              top:'100px',
              height:'100%',
              overflow:'scroll',
            }}
            className='scrollHiden'
            name="containerResultofSearch">
            {resultOfSearch.length > 0 &&
                <div      
                >
                  {resultOfSearch.map((contact) => {

                    return (
                      <li style={{listStyle:'none'}}>{showName(contact,contact.id, contact.fullname ? contact.fullname : (contact.name + " " + contact.father_lastname))}</li>

                    )


                    // return <FormatterContact contact={res}></FormatterContact>;
                  })}
                </div>
              }
            </div>

            } 

          </li>

          {routesWithPermissions.map((prop,key) => {

            return(
              <NavLink
              style={{marginTop:'5px'}}
              // onClick={(e => updatePermissions())}
              to={prop.path}
              key={key}
              className="svgIcon"
              // activeClassName="active"
              // style={{color:this.activeRoute(prop.path) == 0 ? 'white' : '#497cff'}}
              >

         <Link  to={prop.path} action="replace" >

               <OverlayTrigger  trigger={["hover", "hover"]} placement="right"
              overlay={
                PopoverComponent(prop.name)}>
              <i 
              key={key} 
              className={ activeRoute(prop.path) == 0 ? "whiteBackground" : "blueBackground"}>
                      {prop.icon}
                      </i>
          </OverlayTrigger>
        </Link>
           
                        
            </NavLink>
            )
          })}

        





        </div>

      </div>


{/* AGRID HIDE */}

<div className="ag-theme-alpine" style={{ width: "0.5px",display:'none', marginTop:'50px' }}>
            <AgGridReact
              rowData={arrayOfContacts}
              onGridReady={onGridReady}
              frameworkComponents={frameworkComponents}
            >
              {/* Column Name */}
              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                cellRenderer="slotName"
                headerName="Nombre"
                sortable={true}
                field="fullname"
                width="300"
              />
              {/* Column OtherName */}
              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                cellRenderer="slotnameF"
                hide={true}
                headerName="otherName"
                sortable={true}
                field="otherName"
                width="300"
              />
              {/* Column references */}

              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                // cellRenderer="slotRef1"
                headerName="Referencia 1"
                sortable={true}
                field="ref1"
                width="300"
              />

              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                // cellRenderer="slotRef1"
                hide={true}
                headerName="Referencia 1"
                sortable={true}
                field="ref1D"
                width="300"
              />

              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                // cellRenderer="slotRef2"
                headerName="Referencia 2"
                sortable={true}
                field="ref2"
                width="300"
              />

              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                // cellRenderer="slotRef1"
                hide={true}
                headerName="Referencia 1"
                sortable={true}
                field="ref2D"
                width="300"
              />

              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                // cellRenderer="slotRef3"
                headerName="Referencia 3 "
                sortable={true}
                field="ref3"
                width="300"
              />
              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                // cellRenderer="slotRef1"
                hide={true}
                headerName="Referencia 1"
                sortable={true}
                field="ref3D"
                width="300"
              />
            </AgGridReact>
          </div>
    </nav>
  )
}
