import React, {useState} from 'react';
import '../stylesFAQ/Accordion.css';

export default (props) => {

    const {title} = props;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="accordion">
            <div className="accordion-title" onClick={(e) =>setIsOpen(!isOpen)}>
                <span>{title}</span>
            </div>
            <div className="accordion-content" aria-expanded={!isOpen}>
                {props.children}
            </div>
        </div>
    );

};