import React, { useState } from 'react'
import TableFilesColleges from './TableFilesColleges.jsx';
import UploadFiles from './UploadFiles.jsx';

function Docs() {
    const [reload,setReload] = useState(false);
    function update(){
        setReload(!reload);
    }
   
    return (
        <>
        <div className="mt-n2 content">
        <div className="row">
            <UploadFiles  update={update}/>
        </div>
        <div className="row">
            <div className="col-12">
            <TableFilesColleges update={reload}/>
            </div>
        </div>
    </div>
    </>
      
    )
}

export default Docs
