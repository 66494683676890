import React from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
} from "reactstrap";
import routes from "../../routes";
import { createStore } from "redux";
import axios from "axios";
import { constaApi } from "../../constants/constants";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { SlotNameFormatted } from "../../components/contactComponents/SlotContacts.jsx"
import { SlotRef1 } from "../../components/contactComponents/SlotContacts.jsx"
import { SlotRef2 } from "../../components/contactComponents/SlotContacts.jsx"
import { SlotRef3 } from "../../components/contactComponents/SlotContacts.jsx"
import { SlotRating } from "../../components/ApplicationSectionComponent/TableApplication";
import { SlotActions } from "../../components/collegeComponents/TableColleges.jsx";
import { SlotName } from "../../components/ApplicationSectionComponent/TableApplication";
import { SlotOrigin } from "../../components/contactComponents/SlotContacts.jsx"
import { SlotProgram } from "../../components/contactComponents/SlotContacts.jsx"
import { SlotReferences } from "../../components/contactComponents/TableContacts.jsx";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import * as FaIcons from "react-icons/fa";

import "../../cssResponsive/navBar/navBar.css";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page : props.page ?? '',
      isOpen: false,
      dropdownOpen: false,
      color: "transparent",
      user: JSON.parse(localStorage.getItem("user")),
      contacts: [],
      frameworkComponents: {
        slotnameF: SlotNameFormatted,
        slotRef1: SlotRef1,
        slotRef2: SlotRef2,
        slotRef3: SlotRef3,
        slotRating: SlotRating,
        slotActions: SlotActions,
        slotName: SlotName,
        slotOrigin: SlotOrigin,
        slotProgram: SlotProgram,
        slotReferences: SlotReferences,
      },
      gridApi: null,
      columnApi: null,
      flag: true,
      resultofSearch: [],
    };
    // if (this.state.contacts.length <= 0) {
    //   this.consultContacts();
    // }
    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.sidebarToggle = React.createRef();
  }

  async consultContacts() {
    await axios
      .get(constaApi + "contacts", {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        const { data: dx } = response;
        let array = [];
        dx.map((d) => {
          let obj = {
            ...d,
            otherName: d.fullname
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, ""),
            ref1: d.contacts_references[0]
              ? d.contacts_references[0].name +
                " " +
                d.contacts_references[0].father_lastname +
                " " +
                (d.contacts_references[0].mother_lastname ?? " ")
              : " ",
            ref2: d.contacts_references[1]
              ? d.contacts_references[1].name +
                " " +
                d.contacts_references[1].father_lastname +
                " " +
                d.contacts_references[1].mother_lastname
              : " ",
            ref3: d.contacts_references[2]
              ? d.contacts_references[2].name +
                " " +
                d.contacts_references[2].father_lastname +
                " " +
                d.contacts_references[2].mother_lastname
              : " ",
            ref1D: (
              d.contacts_references[0]
                ? d.contacts_references[0].name +
                    " " +
                    d.contacts_references[0].father_lastname ??
                  "" + " " + d.contacts_references[0].mother_lastname ??
                  " "
                : null
            )
              ? (
                  d.contacts_references[0].name +
                    " " +
                    d.contacts_references[0].father_lastname ??
                  " " + " " + d.contacts_references[0].mother_lastname ??
                  " "
                )
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              : "",
            ref2D: (
              d.contacts_references[1]
                ? d.contacts_references[1].name +
                    " " +
                    d.contacts_references[1].father_lastname ??
                  "" + " " + d.contacts_references[1].mother_lastname ??
                  " "
                : null
            )
              ? (
                  d.contacts_references[1].name +
                    " " +
                    d.contacts_references[1].father_lastname ??
                  " " + " " + d.contacts_references[1].mother_lastname ??
                  " "
                )
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              : "",
            ref3D: (
              d.contacts_references[2]
                ? d.contacts_references[2].name +
                    " " +
                    d.contacts_references[2].father_lastname ??
                  "" + " " + d.contacts_references[2].mother_lastname ??
                  " "
                : null
            )
              ? (
                  d.contacts_references[2].name +
                    " " +
                    d.contacts_references[2].father_lastname ??
                  " " + " " + d.contacts_references[2].mother_lastname ??
                  " "
                )
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              : "",

            //   // ref3D: (d.contacts_references[2] ? d.contacts_references[2].name + " " + d.contacts_references[2].father_lastname ?? "" + " " + d.contacts_references[2].mother_lastname ?? " " : null) ? (d.contacts_references[2].name + " " + d.contacts_references[2].father_lastname ?? " " + " " + d.contacts_references[2].mother_lastname ?? " ").normalize(" // ref1D: (d.contacts_references[0] ? d.contacts_references[0].name + " " + d.contacts_references[0].father_lastname ?? "" + " " + d.contacts_references[0].mother_lastname ?? " " : null) ? (d.contacts_references[0].name + " " + d.contacts_references[0].father_lastname ?? " " + " " + d.contacts_references[0].mother_lastname ?? " ").normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            //   // ref2D: (d.contacts_references[1] ? d.contacts_references[1].name + " " + d.contacts_references[1].father_lastname ?? "" + " " +
            //   d.contacts_references[1].mother_lastname ?? " " : null) ?
            //   (d.contacts_references[1].name + " " + d.contacts_references[1].father_lastname ??
            //    " " + " " + d.contacts_references[1].mother_lastname ?? " ").normalize("NFD").replace(/[\u0300-\u036f]/g, "") : "",
            //  NFD").replace(/[\u0300-\u036f]/g, "") : "",
          };
          array.push(obj);
        });
        this.setState({ contacts: array });
      })
      .catch((error) => {

        
      });
  }

  // Actualizar Indices
  updateIndex() {
    alert("Los indices seran actualizados");
    window.location.reload();
  }

  closeSession() {
    const { history } = this.props;
    // this.props.store.dispatch(sendMessage(message))
    localStorage.removeItem("user");
    window.location.href = "/login";
  }
  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent",
      });
    } else {
      this.setState({
        color: "dark",
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }
  consult(e) {
    if (e.target.value) {
      let fullnameFormated = e.target.value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      this.consultTwo(fullnameFormated);
    } else {
      this.setState({ resultofSearch: [] });
    }
  }
  consultTwo(value) {
    let objx = this.state.gridApi;
    value === "keyWordSeccret302"
      ? objx.api.setQuickFilter("")
      : objx.api.setQuickFilter(value);
    this.setState({ gridApi: objx });
    let rowsDisplay = objx.api.rowRenderer.rowModel.rowsToDisplay;
    let result;
    if (rowsDisplay) {
      result = rowsDisplay.map((row) => {
        let { data } = row;
        return data;
      });
      this.setState({ resultofSearch: result });
    }
  }
  getBrand( title = 'Default Brand') {
    let brandName = title ;
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    return brandName;
  }
  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
    this.sidebarToggle.current.classList.toggle("toggled");
  }
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "dark",
      });
    } else {
      this.setState({
        color: "transparent",
      });
    }
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor.bind(this));
    return () => {}
    // this.consultContacts();
  }
  onGridReady = (params) => {
    this.setState({ gridApi: params, columnApi: params });
  };
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
      
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.sidebarToggle.current.classList.toggle("toggled");
    }
    return () => {}

  }
  showParticipant = (obj, name) => {
    let n = name ? name.split(" ") : " ";
    let tag = "";
    if (n.length >= 3) {
      n =
        n[0].charAt(0).toUpperCase() +
        n[1].charAt(0).toUpperCase() +
        n[2].charAt(0).toUpperCase();
    } else {
      n = n[0].charAt(0);
    }
    switch (obj.type_user) {
      case "Administrador":
        tag = (
          <span
            key={obj.id}
            className=" sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV"
          >
            {n}
          </span>
        );
        break;
      case "contactos":
        tag = (
          <span
            key={obj.id}
            className=" sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV"
          >
            {n}
          </span>
        );
        break;
      case "referencias":
        tag = (
          <span
            key={obj.id}
            className=" sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV"
          >
            {n}
          </span>
        );
        break;
      default:
        tag = (
          <span
            key={obj.id}
            style={{ backgroundColor: "#497cff" }}
            className="sc-caSCKo ZomcK styles__User-sc-103gogw-2 gBkpnV"
          >
            {n}
          </span>
        );
        break;
    }
    return tag;
  };

  render() {
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        id="navSuperior"
        color={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "dark"
            : this.state.color
        }
        expand="lg"
        className="navbar-absolute fixed-top navbar-transparent"
      >
        {/* First Element */}
        <div className="secondElement">
          <NavbarBrand
            style={{
              fontFamily: "Montserrat, sans-serif",
              margin: "0",
              color: "#000000",
            }}
            href="/"
          >
            {this.getBrand(this.state.page ?? '')}
          </NavbarBrand>
        </div>

        {/* Second Element */}
        <div className="firstElementResponsiv ">
          {/* <div className="sizeSearchBar">
            <SearchBar consult={(e) => this.consult(e)} />
            {this.state.resultofSearch.length > 0 && (
              <div
                style={{
                  backgroundColor: "white",
                  width: "30%",
                  marginLeft: "-0.1vw",
                  position: "absolute",
                }}
                className="col"
              >
                {this.state.resultofSearch.map((res) => {
                  return <FormatterContact contact={res}></FormatterContact>;
                })}
              </div>
            )}
          </div> */}
        </div>

        {/* Third element */}
        <div className="thirdElementResponsive">
          <Dropdown
            nav
            isOpen={this.state.dropdownOpen}
            toggle={(e) => this.dropdownToggle(e)}
          >
            <DropdownToggle caret nav>
              {this.showParticipant(this.state.user, this.state.user.name)}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={(e) => this.closeSession()} tag="a">
                Cerrar sesión
              </DropdownItem>
              <DropdownItem onClick={(e) => this.updateIndex()} tag="a">
                Actualizar Indices
              </DropdownItem>
            
                <Link id="faQ" to={"/FAQ"}>
                <DropdownItem
                id="faQ2"
                style={{ display: "flex", justifyContent: "center" }}
                tag="a"
              >
                  <FaIcons.FaQuestionCircle />
              </DropdownItem>
                </Link>
              {/* <DropdownItem tag="a">Another Action</DropdownItem>
                  <DropdownItem tag="a">Something else here</DropdownItem> */}
            </DropdownMenu>
          </Dropdown>
        </div>
        {/* Other components */}
        {this.state.flag && (
          <div className="ag-theme-alpine" style={{ width: "0.5px" }}>
            <AgGridReact
              rowData={this.state.contacts}
              onGridReady={this.onGridReady}
              frameworkComponents={this.state.frameworkComponents}
            >
              {/* Column Name */}
              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                cellRenderer="slotName"
                headerName="Nombre"
                sortable={true}
                field="fullname"
                width="300"
              />
              {/* Column OtherName */}
              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                cellRenderer="slotnameF"
                hide={true}
                headerName="otherName"
                sortable={true}
                field="otherName"
                width="300"
              />

              {/* Column Rating */}
              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                cellRenderer="slotRating"
                headerName="Rating"
                sortable={true}
                field="rating"
                width="300"
              />
              {/* Column City */}
              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                headerName="Ciudad"
                sortable={true}
                field="ciy"
                width="200"
                cellRenderer="slotOrigin"
              />

              {/* Column Program */}
              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                headerName="Programa"
                field="prospections"
                width="200"
                cellRenderer="slotProgram"
              />

              {/* Column references */}
              <AgGridColumn
                headerName="Referencia"
                cellRenderer="slotReferences"
                width="200"
              />
              {/* Column Advisor
                              <AgGridColumn 
                             headerName="Advisor" sortable={true} field="name_advisor" width="200" /> */}
              {/* Column Actions  */}
              <AgGridColumn
                headerName="Acciones"
                cellRenderer="slotActions"
                width={220}
              />

              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                // cellRenderer="slotRef1"
                headerName="Referencia 1"
                sortable={true}
                field="ref1"
                width="300"
              />

              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                // cellRenderer="slotRef1"
                hide={true}
                headerName="Referencia 1"
                sortable={true}
                field="ref1D"
                width="300"
              />

              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                // cellRenderer="slotRef2"
                headerName="Referencia 2"
                sortable={true}
                field="ref2"
                width="300"
              />

              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                // cellRenderer="slotRef1"
                hide={true}
                headerName="Referencia 1"
                sortable={true}
                field="ref2D"
                width="300"
              />

              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                // cellRenderer="slotRef3"
                headerName="Referencia 3 "
                sortable={true}
                field="ref3"
                width="300"
              />
              <AgGridColumn
                cellStyle={{
                  fontFamily: "Montserrat,sans-serif",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#3B3B3B",
                }}
                // cellRenderer="slotRef1"
                hide={true}
                headerName="Referencia 1"
                sortable={true}
                field="ref3D"
                width="300"
              />
            </AgGridReact>
          </div>
        )}
      </Navbar>
    );
  }
}

export default Header;
